// import React from 'react'
// import { Route } from 'react-website'

// import App from './pages/App'
// import Basic from './pages/Basic'
// import Advanced from './pages/Advanced'

// export default (
//   <Route path="/" Component={ App }>
//     <Route Component={ Basic }/>
//     <Route path="advanced" Component={ Advanced }/>
//   </Route>
// )



import React from 'react'
import { Route } from 'react-website'

import App from '/pages/App'
// import LoginPage from './pages/LoginPage'
// import MainPage from './pages/MainPage'
// import ActivationPage from './pages/ActivationPage'
import { BrowserRouter as Router } from 'react-router-dom'
// import PasswordResetPage from './pages/PasswordResetPage'
// import NotFoundPage from './pages/NotFoundPage'
// import ActivationViaPassPage from './pages/ActivationViaPassPage'

const SUPPOER_LOCALES = [
  {
    name: 'English',
    value: 'en-US'
  },
  {
    name: 'Türkçe',
    value: 'tr-TR'
  },
  {
    name: 'Deutsch',
    value: 'de-DE'
  }
];

export default (
  <Router>
  <Route >
    {/* <Route path="/" Component={ App }/>
    <Route path="login" Component={ App }/>
    <Route path="clients" Component={ App }/>
    <Route path="calendar" Component={ App }/>
    <Route path="reports" Component={ App }/>
    <Route path="charts" Component={ App }/>
    <Route path="account" Component={ App }/>
    <Route path="activation" Component={ App }/>
    <Route path="activationViaPass" Component={ App }/>
    <Route path="passwordReset" Component={ App }/> */}
    <Route path="*" Component={ App }/>
    
    {/* <Route path="/" Component={ App }/>
    <Route path="login" Component={ LoginPage }/>
    <Route path="clients" Component={ MainPage }/>
    <Route path="calendar" Component={ MainPage }/>
    <Route path="reports" Component={ MainPage }/>
    <Route path="charts" Component={ MainPage }/>
    <Route path="account" Component={ MainPage }/>
    <Route path="activation" Component={ ActivationPage }/>
    <Route path="activationViaPass" Component={ ActivationViaPassPage }/>
    <Route path="passwordReset" Component={ PasswordResetPage }/>
    <Route path="*" Component={ NotFoundPage }/> */}

  </Route>
  </Router>
)


