module.exports = [
{
  "COPYRIGHT":"© NSD Software - Alle Rechte vorbehalten - {year}",
  "FIRSTNAME": "Name",
  "LASTNAME": "Familienname",
  "EMAIL": "E-Mail",
  "PASSWORD": "Passwort",
  "CONFIRM_PASSWORD": "Passwort bestätigen",
  "IDENTITY_NUMBER": "Ausweisnummer",
  "PHONE":"Handy Nummer",
  "SUBMIT": "Weiter",
  "FORGOT_PASSWORD": "Passwort Vergessen?",
  "CREATE_NEW_ACCOUNT": "Neuen Account erstellen",
  "COMPANY": "Firmenname",
  "BACK_TO_LOGIN_PAGE":"Zurück zur Login-Seite",
  "CONTINUE":"Weiter",
  "REGISTER":"Registrieren",
  "WARNING":"Warnung",
  "ERROR":"Fehler",
  "INFO":"Info",
  "MISSING_DATA":"Fehlende Angaben",
  "PLEASE_ENTER": "Bitte {missingData} eingeben.",
  "PLEASE_CONFIRM_PASSWORD":"Bitte Passwort bestätigen.",
  "PASSWORDS_DO_NOT_MATCH":"Die Passwörter stimmen nicht überein.",
  "PLEASE_WAIT":"Bitte warten.",
  "SYSTEM_ERROR":"Systemfehler",
  "ADMINISTRATOR":"Administrator",

  "WRONG_EMAIL_OR_PASSWORD":"Falsches Email oder Passwort",
  "COULD_NOT_LOGIN":"Kann nicht einloggen",
  "EMAIL_SENT_SUCCESSFULLY":"E-Mail wurde erfolgreich gesendet.",
  "PLEASE_CHECK_YOUR_INBOX":"Bitte prüfen Sie Ihren Email-Posteingang .",
  "EMAIL_SEND_UNSUCCESSFUL":"EMail konnte nicht gesendet werden.",
  "PLEASE_CONNECT_PRAXIS_TEAM":"Bitte kontaktieren Sie Praxis Support-Team.",
  "PLEASE_GO_BACK_TO_LOGIN":"Bitte gehen Sie auf die Anmeldeseite",
  "PLEASE_LOGIN_AGAIN":"Bitte nochmal einloggen.",

  "USER_WITH_THIS_EMAIL_ALREADY_EXISTS":"Es existiert bereits ein Benutzer mit dieser EMail-Adresse",
  "USER_CANNOT_BE_CREATED":"Benutzerkonto kann nicht erstellt werden.",

  "SERVER_ERROR":"Server Fehler",
  "COULD_NOT_CONNECT_TO_SERVER":"Konnte an Server nicht verbinden",
  "AUTHORIZATION_ERROR":"Berechtigungsfehler",

  "ACCOUNT_CREATED":"Konto eingerichtet.",
  "ACCOUNT_NOT_ACTIVE":"Konto nicht aktiv.",
  "ACTIVATING_ACCOUNT":"Wir aktivieren Ihr Konto.",
  "ACCOUNT_ACTIVATED":"Ihr Konto wurde aktiviert.",
  "CONFIRMATION_LINK_SENT":"Wir haben Ihnen eine E-Mail mit einem Bestätigungslink an die von Ihnen eingegebene Adresse geschickt. </br> Wenn Sie keine E-Mail empfangen, bitte überprüfen Ihren 'Junk-E-Mail-Ordner'",
  "CLICK_CONFIRMATION_LINK":"Bitte klicken Sie das Bestätigungslink in dem Email um Ihr Konto zu aktivieren.",
  "CAN_LOGIN_NOW":"Sie können sich jetzt mit Ihrem Email und Passwort einloggen.",
  "RESEND_ACTIVATION_MAIL":"Bestätigungsmail nochmal senden",
  "ACCOUNT_NOT_ACTIVATED":"Ihr Konto könnte nicht aktiviert werden.",
  "NEW_ACTIVATION_MAIL_SENT":"Neue Bestätigungsmail wurde gesendet.</br> Wenn Sie keine E-Mail empfangen, bitte überprüfen Ihren 'Junk-E-Mail-Ordner'",
  "WILL_SEND_ACTIVATION_CODE_PER_SMS":"Wir senden Bestätigungscode per SMS",

  "CRYPT_KEYWORD":"Verschlüsselungscode",
  "CRYPT_KEYWORD_AGAIN":"Verschlüsselungscode nochmal",
  "YOUR_CRYPT_KEYWORD":"Ihr Verschlüsselungscode",
  "CREATE_NEW_CRYPT_KEYWORD":"Neue Verschlüsselungscode generieren",
  "KEYWORD_FOR_ENCRYPTION_TITLE":"Verschlüsselungscode festlegen",
  "PLEASE_READ_CAREFULLY":"Bitte aufmerksam lesen.",
  "PLEASE_ENTER_CRYPT_KEYWORD":"Bitte Verschlüsselungscode eingeben.",
  "CRYPT_KEYWORDS_DO_NOT_MATCH":"Die Verschlüsselungscodes stimmen nicht überein",
  "CRYPT_KEYWORD_AT_LEAST_EIGHT_CHARS":"Verschlüsselungscode muss mindestens 8 Zeichen lang sein",
  "CONFIRM_YOUR_CRYPT_KEYWORD":"Bestätigen Sie Ihre Verschlüsselungscode?",
  "DO_NOT_FORGET_TO_NOTE_DOWN":"Bitte notieren Sie Ihre Verschlüsselungscode.",
  "I_CONFIRM_MY_CRYPT_KEYWORD":"Ich bestätige meine Verschlüsselungscode",

  "NEW_RECORD":"Neuer Eintrag",
  "SEARCH":"Suchen",
  "LOADING_LIST":"Liste wird geladen...",
  "LOADING":"Wird geladen...",

  "PATIENTS_VIEW":"Patienten",
  "CALENDAR_VIEW":"Termine",
  "REPORTS_VIEW":"Berichte",
  "CHARTS_VIEW":"Grafiken",
  "ACCOUNT_VIEW":"Mein Konto",
  "PAYMENT_VIEW":"Bezahlung",
  "PROFESSIONALS_VIEW":"Ärzte",
  "FORMS_VIEW":"Formulare",
  "SECURITY_VIEW":"Datensicherheit / GDPR",

  "PATIENTS":"Patienten",
  "CALENDAR":"Kalender",
  "REPORTS":"Berichten",
  "CHARTS":"Grafiken",
  "DAILY_REPORT":"Tagesbericht",
  "MONTHLY_REPORT":"Monatsbericht",
  "YEARLY_REPORT":"Jahresbericht",
  "MONTHLY_INCOME_CHART":"Monatliches Einkommen",
  "YEARLY_INCOME_CHART":"Jährliches Einkommen",
  "GENDER_CHART":"Kunden Geschlecht",
  "SOURCE_CHART":"Wie sind Sie zu uns gekommen",
  "MY_ACCOUNT":"Mein Konto",
  "LOGOUT":"Abmelden",
  "DO_YOU_WANT_TO_LOGOUT":"Wollen Sie sich abmelden?",
  "DO_YOU_WANT_TO_DELETE":"Wollen Sie wirklich löschen?",

  "OK":"OK",
  "CANCEL":"Abbrechen",
  "SAVE":"Speichern",
  "UPDATE":"Aktualisieren",
  "EDIT":"Editieren",
  "DELETE":"Löschen",
  "NEW":"Neu",
  "YES":"Ja",
  "NO":"Nein",
  "CLOSE":"OK",
  "EXPAND":"Expandieren",
  "PLEASE_SELECT":"Bitte wählen",
  "SELECT":"Wählen",

  "PACKAGE_REST_TOTAL":"Paket: Rest / Total",


  "NEW_PATIENT":"Neuer Patient",
  "GENDER":"Geschlect",
  "MALE":"Männlich",
  "FEMALE":"Weiblich",
  "NAME":"Name",
  "SURNAME":"Nachname",
  "BIRTHDATE":"Geburtsdatum",
  "NATIONAL_ID_NUMBER":"Ausweissnummer",
  "FATHER_NAME":"Vater Name",
  "SOURCE":"Wie sind Sie zu uns gekommen?",
  "STREET":"Strasse",
  "CITY":"Stadt",
  "COUNTRY":"Land",
  "COUNTY":"Landkreis",
  "POSTAL_CODE":"Postleitzahl",
  "AGE":"Alter",

  "UNKNOWN":"Unbekannt",
  "WEBSITE":"Web Seite",
  "SEARCH_ENGINE":"Suchmaschine",
  "FRIEND":"Freund",
  "SOCIAL_MEDIA":"Sozial Media",

  "APPOINTMENTS":"Termine",
  "PACKAGE":"Pakete",
  "PACKAGES":"Paketen",
  "ACTIVATE":"Aktivieren",
  "DEACTIVATE":"Deaktivieren",

  "EXAMINATION":"Termin",
  "EXAMINATION_DATE":"Termin Datum",
  "EXAMINATION_COUNT":"Termin Anzahl",
  "PAYMENT":"Zahlung",
  "PAID":"Bezahlt",
  "PAYMENT_DATE":"Zahlung Datum",

  "WANT_TO_DELETE_PATIENT":"Wollen Sie wirklich diesen Patient löschen?",
  "DELETE_PATIENT_MESSAGE":"All Patient Daten wurden gelöscht.",

  "FORMS":"Formulare",
  "DELETE_FORM":"Form Löschen",

  "DOCUMENTS":"Dokumenten",
  "ADD_DOCUMENT":"Dokument Einfügen",
  "ADD_NOTICE":"Notizen Einfügen",
  "VIEW_DOCUMENT":"Anzeigen",

  "DELETE_DOCUMENT":"Dokument löschen?",
  "WANT_TO_DELETE_DOCUMENT":"Wollen Sie wirklich das Dokument löschen?",
  
  "PROFESSIONAL":"Arzt",
  "PROFESSIONALS":"Ärzte",
  "ADD_PROFESSIONAL":"Arzt einfügen",
  "NEW_PROFESSIONAL_DATA":"Arzt Data",
  "DELETE_PROFESSIONAL":"Arzt löschen?",
  "WANT_TO_DELETE_PROFESSIONAL":"Wollen Sie wirklich den Arzt löschen?",

  "ASSISTANT":"Assistent",
  "ASSISTANTS":"Assistenten",
  "ADD_ASSISTANT":"Assistent einfügen",
  "NEW_ASSISTANT_DATA":"Assistent Data",
  "DELETE_ASSISTANT":"Assistent löschen?",
  "WANT_TO_DELETE_ASSISTANT":"Wollen Sie wirklich den Assistent löschen?",
  
  "DATE_HOUR":"Datum - Uhr",
  "PRICE":"Preis",
  "CASH":"Bargeld",
  "CREDIT":"Kredit",
  "CREDIT_CARD":"Kreditkarte",
  "TRANSFER":"Transfer",
  "REST":"Rest",
  "NOTE":"Notiz",
  "TYPE":"Type",
  "SORT":"Sortieren",
  "TOTAL":"Total",
  "INCOME":"Einkommen",
  "GROSS_INCOME":"Bruttoeinkommen",
  "COLLECTED":"Gesammelt",
  "RECEIVABLE":"Offen",

  "PROCESS_DATE":"Prozess Datum",
  "NUMBER_OF_APPOINTMENTS":"Termin Anzahl",
  "USED_APPOINTMENTS":"Benutzte Termine",
  "REST_APPOINTMENTS":"Rest Termine",

  "TODAY":"Heute",
  "BACK":"Zurück",
  "FORWARD":"Vorwärst",
  "YEAR":"Jahr",
  "MONTH":"Monat",
  "WEEK":"Woche",
  "DAY":"Tag",
  "AGENDA":"Agenda",
  "DATE":"Datum",
  "TIME":"Zeit",
  "PREVIOUS_DAY":"Vorheriger Tag",
  "NEXT_DAY":"Nächster Tag",
  "PREVIOUS_MONTH":"Vorheriger Monat",
  "NEXT_MONTH":"Nächster Monat",
  "PREVIOUS_YEAR":"Vorheriges Jahr",
  "NEXT_YEAR":"Nächstes Jahr",

  "JANUARY":"Januar",
  "FEBRUARY":"Februar",
  "MARCH":"März",
  "APRIL":"April",
  "MAY":"Mai",
  "JUNE":"Juni",
  "JULY":"Juli",
  "AUGUST":"August",
  "SEPTEMBER":"September",
  "OCTOBER":"Oktober",
  "NOVEMBER":"November",
  "DECEMBER":"Dezember",

  "COMPANY_INFO":"Daten der Firma",
  "BILL_INFO":"Rechnung Info",
  "CHANGE_PASSWORD":"Passwort ändern",
  "CURRENT_PASSWORD":"Aktuelles Passwort",
  "NEW_PASSWORD":"Neues Passwort",
  "CONFIRM_NEW_PASSWORD":"Neues Passwort bestätigen",
  "USER_SETTINGS":"Benutzer Einstellungen",
  "USERS":"Benutzer",
  "NEW_USER":"Neuer Benutzer",
  "LICENCE_INFO":"Lizenz Info",
  "SMS_PACKAGES":"SMS Paketen",
  "AGREEMENTS":"Verträge",
  "ACTIVE":"Aktiv",
  "WANT_TO_DELETE_ACCOUNT":"Ich will mein Konto löschen",
  "DELETE_ACCOUNT":"Mein Konto löschen",
  "CONFIRM_DELETE_ACCOUNT":"Wollen Sie wirklich Ihr Konto löschen? All Patient Daten wurden gelöscht. ",
  "CREATE_ACCOUNT_DESC":"Hier können Sie neuer Benutzer für Ihr Mitarbeiter erstellen. </br> Um dieses Konto zu aktivieren soll Ihr Mitarbeiter das Link im Bestätigungsmail klicken. ",
  "NEW_USER_FIRSTNAME":"Neuer Benutzer Name",
  "NEW_USER_LASTNAME":"Neuer Benutzer Nachname",
  "NEW_USER_EMAIL":"EMail Addresse vom neuen Benutzer",
  "PLEASE_FILL_THIS_FIELD":"Bitte ausfüllen",
  "CREATE_NEW_USER":"Neuer Benutzer erstellen",


  "ANESTHESIOLOGY" : "Anesthesiology" ,
  "ENDOCRINOLOGY" : "Endocrinology",
  "BRAIN_SURGERY" : "Brain Surgery",
  "CARDIOLOGY" : "Cardiology",
  "CHEST_DISEASES" : "Chest Diseases",
  "CLINICAL_MICROBIOLOGY" : "Clinical Microbiology",
  "DERMATOLOGY" : "Dermatology",
  "EYE_DISEASES" : "Eye Diseases",
  "EAR_NOSE_AND_THROAT_DISEASES" : "Ear Nose and Throat Diseases",
  "FAMILY_MEDICINE" : "Family Medicine",
  "GENERAL_SURGERY" : "General Surgery",
  "GYNECOLOGY_AND_OBSTETRICS" : "Gynecology and Obstetrics",
  "INFECTIOUS_DISEASES" : "Infectious Diseases",
  "IMMUNOLOGY" : "Immunology",
  "INTERNAL_MEDICINE" : "Internal Medicine",
  "NEUROLOGY" : "Neurology",
  "NUCLEAR_MEDICINE" : "Nuclear Medicine",
  "NUTRITION_AND_DIET" : "Nutrition and Diet",
  "ONCOLOGY" : "Oncology",
  "ORAL_AND_DENTAL_DISEASES" : "Oral and Dental Diseases",
  "ORTHOPAEDICS" : "Orthopaedics",
  "PEDIATRICS" : "Pediatrics",
  "PHYSICAL_THERAPY_AND_REHABILITATION" : "Physical Therapy and Rehabilitation",
  "PLASTIC_SURGERY" : "Plastic Surgery",
  "PSYCHIATRY" : "Psychiatry",
  "PSYCHOLOGY" : "Psychology",
  "SPORTS_MEDICINE" : "Sports Medicine",
  "UROLOGY" : "Urology",
  "OTHER" : "Other",

  "CRYPT_KEY_EXPLANATION" : "We store your sensible data after encrypting it via a keyword which is defined by you. </br> We encrypt your data with AES-256 bit encryption method which is practically unbreakable. </br> Praxis-24 software developers, database administrators, company managers, employees and also the stuff of the hosting company could not access your data. </br> The only way to reach your data is to know the encrytion keyword. </br> Therefore you should not forget your encryption keyword and keep it in secure. </br> While logging first time on a new machine or after clearing the cache of the browser, you will need reenter your enryption keyword. </br> You could not change this keyword. </br> You could display your keyword on the machines you have logged in before. </br> Please define now your encrytion keyword and do not forget to notice it.",
  
    "SIMPLE": "Simple Sentence de",
    "HELLO": "Hello, {name}. Welcome to {where}!",
    "TIP": "This is <span style='color:red'>HTML</span>",
    "TIP_VAR": "This is <span style='color:red'>{message}</span>",
    "SALE_START": "Sale begins {start, date}",
    "SALE_END": "Sale ends {end, date, long}",
    "COUPON": "Coupon expires at {expires, time, medium}",
    "SALE_PRICE": "The price is {price, number, USD}",
    "PHOTO": "You have {photoNum, plural, =0 {no photos.} =1 {one photo.} other {# photos.}}",
    "MESSAGE_NOT_IN_COMPONENT": "react-intl-universal is able to internationalize message not in React.Component"
}
];