import {Component} from 'react';
import sha256 from 'sha256';
import jwt_decode from 'jwt-decode';
import intl from 'react-intl-universal';
import utils from '../helpers/utils';
import Dexie from 'dexie';
import RestManager from './RestManager';
import { element } from 'prop-types';
import crypt from '../helpers/crypt';


class DBManager extends Component
{
    static instance = null;
    
    state = {
    }

    constructor(props) {
        super(props);
        this.oldDBName  = "praxis-24"
        this.db = null;
        this.syncInProgress = {};
        this.imageUrl  =  "https://api.praxis-24.com/praxisRestApi/images/";
        this.cryptKey;
        this.selectedProfessional = null;
    }

    static getInstance() {

        if (this.instance == null) {
            this.instance = new DBManager();
            // this.instance.init();
        }
        return this.instance;
    }

    setCryptKey(cryptKey, callback)
    {
        this.cryptKey = cryptKey;
        this.addItem("appId", cryptKey, callback);
    }

    getCryptKey(callback){
        if ( callback ){
            this.getItem("appId", (cryptKey)=>{
                this.cryptKey = cryptKey;
                callback(cryptKey);
            });
        } else {
            return this.cryptKey;
        }
    }

    setSelectedProfessional(professional) {
        this.selectedProfessional = professional;
    }

    initDBForUser(user, callback) 
    {
        if ( user && user.id) {
            let dbName = "px" + user.id.substring(5, 15);;
            // this.checkOldDB((cryptKey)=>{
            //     if ( cryptKey ) {
            //         let mainCryptKey = crypt.generateMainCryptKey(cryptKey);
            //         this.initDB(dbName, ()=>{
            //             RestManager.getInstance().setCompanyCryptKey(mainCryptKey, callback)
            //         });
            //     } else {
                    this.initDB(dbName, callback);
                // }
            // });    
        }
    }

    checkOldDB(callback)
    {
        this.db = new Dexie(this.oldDBName);
        this.db.version(1).stores({ application: 'key, value' });
        this.getItem("cryptKey", (cryptKey)=>{
            if ( cryptKey && cryptKey.startsWith('alreadySet')){
                // this.addItem("cryptKey", 'çşğ', {});
                callback();
            } else {
                this.addItem("cryptKey", 'alreadySet_'+cryptKey, ()=> {
                    callback(cryptKey);
                });
            }
        });
    }
    
    initDB(DBName, callback)
    {
        this.db = new Dexie(DBName);
        this.db.version(1).stores({ application: 'key, value' });
        this.db.version(1).stores({ customer: 'id, changeDate, firstName, lastName, gender, birthdate, phone, email, source' });
        // this.db.version(2).stores({ customer: 'id, insertDate, changeDate, firstName, lastName, gender, birthdate, phone, email, source' });
        this.db.version(1).stores({ examination: 'id, changeDate, customerId, examDate, examType, payment.id, payment.type, payment.paymentDate, payment.price, payment.paidCash, payment.paidCredit, payment.paidTransfer, payment.restAmount, payment.changeDate' });
        this.db.version(1).stores({ package: 'id, changeDate, customerId, examCount, usedExams, status, payment.id, payment.type, payment.paymentDate, payment.price, payment.paidCash, payment.paidCredit, payment.paidTransfer, payment.restAmount, payment.changeDate' });
        this.db.version(1).stores({ payment: 'id, type, paymentDate, price, paidCash, paidCredit, paidTransfer, restAmount, changeDate' });

        this.db.version(2).stores({
            customer: "id, insertDate, changeDate, firstName, lastName, gender, birthdate, phone, email, source"
        }).upgrade (trans => {
            return trans.customer.toCollection().modify (cust => {
                console.log('UPGRADE === ', cust);
                cust.insertDate = cust.changeDate;
                console.log('UPGRADE 222 === ', cust);
            });
        });

        this.db.version(3).stores({ customer: "id, insertDate, changeDate, firstName, lastName, gender, birthdate, phone, email, source, consentApproved" });

        this.db.version(4).stores({ customer: "id, insertDate, changeDate, firstName, lastName, gender, birthdate, phone, email, source, consentApproved, nationalID, fatherName" });

        // this.db.version(3).stores({ form: 'id, changeDate, formName, formType, medicalSpeciality, parentFormId, formVersion, formUI' });
        // this.db.version(3).stores({ formdata: 'id, changeDate, formId, customerId, examId, '
        //     + 'S1, S2, S3, S4, S5, S6, S7, S8, S9, S10, S11, S12, S13, S14, S15, S16, S17, S18, S19, S20,'  
        //     + 'T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12, T13, T14, T15, T16, T17, T18, T19, T20,'  
        //     + 'N1, N2, N3, N4, N5, N6, N7, N8, N9, N10, N11, N12, N13, N14, N15, N16, N17, N18, N19, N20,'  
        //     + 'B1, B2, B3, B4, B5, B6, B7, B8, B9, B10, B11, B12, B13, B14, B15, B16, B17, B18, B19, B20,'  
        //     + 'M1'
        // });

        this.getItem("appId", (cryptKey)=>{
            this.cryptKey = cryptKey;
            callback();
        });

    }

    startSync(tablename, callback)
    {
        if ( this.syncInProgress[tablename] && this.syncInProgress[tablename]  == true ){
            callback();
        } 
        this.syncInProgress[tablename] = true;
        
        console.log("startSync !!!! " + tablename)
        this.getLatestElement(tablename, (elem) => {
            var startDate = 0;
            if ( elem ) {
                var nDate = elem.changeDate;
                startDate = nDate.getTime();
            }
            RestManager.getInstance().getEntitiesForSync(tablename , startDate, 100, 
            (result) => {
                if ( result.success == true ) {
                    let objs = result.response;
                    if ( objs.length > 0 ) 
                    {
                        var arrPayments = [];
                        objs.forEach(obj => 
                        {
                            obj = this.convertDateFields(obj);
                            // if ( obj.customerId ) {
                                obj = crypt.decryptEntity(tablename, obj, obj.customerId);                                
                            // }

                            // if ( tablename == "examination" || tablename == "package" ) {
                            if ( obj.payment ) {
                                obj.payment = this.convertDateFields(obj.payment);
                                obj.payment = crypt.decryptEntity("payment", obj.payment, obj.customerId);
                                obj.payment.professionalId = obj.professionalId; 
                                obj.payment.customerId = obj.customerId; 
                                arrPayments.push(obj.payment);
                            }
                        });

                        this.addElements(tablename, objs, (lastKey) => {
                            this.addElements("payment", arrPayments, (lastKey) => {
                                this.syncInProgress[tablename] = false;
                                this.startSync(tablename, callback);
                            });
                        });
                    } else {
                        this.syncInProgress[tablename] = false;
                        callback();
                    }
            }
                console.log("startSync result for "+tablename+" = ", result)
            });
    
        });
    }
    
    addElements(tablename, elements, callback) 
    {
        if (  elements && elements.length > 0 ) {
            this.db[tablename].bulkPut(elements)
            .then((lastKey) => {
                callback(lastKey);
            });
        } else {
            callback();
        }
    }

    addElement(tablename, element, callback) 
    {
        this.db[tablename].put(element)
        .then((lastKey) => {
            callback(lastKey);
        });
    }

    addItem(key, value, callback){
        this.addElement("application", {key:key, value:value}, callback);
    }

    getItem(key, callback) 
    {
        this.db.application
        .where('key').equals(key)
        .toArray()
        .then((response) => {
            if ( response.length > 0 ) {
                callback(response[0].value);
            } else {
                callback();
            }
        });
    }

    getCustomer(id, callback) 
    {
        this.getCustomers(callback, id);        
    }
    getCustomers(callback, id=null) 
    {
        let professional = this.selectedProfessional;
        this.startSync("customer", ()=>
        {
            console.log("DBManager getCustomers");
            this.db.customer
            .orderBy('insertDate')
            .reverse()
            .filter(function (customer) 
            {
                if ( ! customer.deleted ) {
                    if ( id ) {
                        return ( customer.id == id)                    
                    }
                    if (  professional ) {
                        if ( customer.professionalId == professional.id  ) {
                            return true;
                        } else {
                            return false;
                        }
                    }  else {
                        return true;
                    } 
                }
                return false;
            })
            .toArray()
            .then((customers) => {
                console.log("DBManager getCustomers list:", customers);
                if ( id && customers.length > 0 ) {
                    callback({success:true, response:customers[0]});
                } else {
                    callback({success:true, response:customers});
                }
            });
        });
    }

    getElements(tablename, fieldname, value, callback) 
    {
        this.startSync(tablename, ()=>
        {
            this.db[tablename]
            .where(fieldname).equals(value)
            .and(function (element) {
                return ( ! element.deleted );
            })
            .toArray()
            .then((response) => {
                callback({success:true, response:response});
            });
        });
    }

    getElementsBetweenDates(tablename, fieldname, iStartDate, iEndDate, callback) 
    {
        let professional = this.selectedProfessional;

        var startDate = new Date(iStartDate);
        var endDate = new Date(iEndDate);
        this.db[tablename]
        .where(fieldname).aboveOrEqual(startDate)
        .and(function (element) {
            let retVal = ! element.deleted && element[fieldname] < endDate;
            if (  professional && element.professionalId != professional.id  ) {
                return false;
            }
            return retVal;
        })
        .toArray()
        .then((elements) => {
            callback({success:true, response:elements});
        });
    }

    getPackages(customerId, callback) 
    {
        this.getElements("package", "customerId", customerId, callback);
    }

    getExaminations(customerId, callback) 
    {
        this.getElements("examination", "customerId", customerId, callback);
    }

    getExaminationsBetweenDates(startDate, endDate, callback) 
    {
        this.startSync("examination", ()=>{
            this.getElementsBetweenDates('examination', 'examDate', startDate, endDate, (result)=>{
                var exams = result.response;
                if ( exams && exams.length > 0 ) {
                    var lastExamId = exams[exams.length-1].id;
                    var arrNewExams = [];
                    for (let i = 0; i< exams.length; i++) 
                    {
                        let exam = exams[i];
                        this.db.customer
                        .where('id').equals(exam.customerId)
                        .toArray()
                        .then((customers) => {
                            if ( customers && customers.length > 0 ) {
                                var customer = customers[0];
                                exam.firstName = customer.firstName;
                                exam.lastName = customer.lastName;
                            }
                            arrNewExams.push(exam);
                            if ( exam.id == lastExamId) {
                                callback({success:true, response:arrNewExams});
                            }
                        });
                    }    
                } else {
                    callback({success:true, response:[]});
                }
            });
        });
    }

    syncPayment(callback) 
    {
        this.startSync("examination", ()=>{
            this.startSync("package", callback);
        });
    }

    getPayments(iStartDate, iEndDate, callback) {
        this.syncPayment(()=> {
            return this.getElementsBetweenDates('payment', 'paymentDate', iStartDate, iEndDate, callback);
        });
    }

    // getPayments(iStartDate, iEndDate, callback) 
    // {
    //     this.syncPayment(()=>
    //     {
    //         var startDate = new Date(iStartDate);
    //         var endDate = new Date(iEndDate);
    //         this.db.payment
    //         .where('paymentDate').aboveOrEqual(startDate)
    //         .and(function (payment) {
    //             return ( ! payment.deleted && payment.paymentDate < endDate );
    //         })
    //         .toArray()
    //         // .sortBy("paymentDate")
    //         .then((payments) => {
    //             callback({success:true, response:payments});
    //         });
    //     });
    // }

    getPriceSum(month, year, callback)
    {
        let professional = this.selectedProfessional;

        console.log("month = "+month+ ", year = " + year);
        this.syncPayment(()=>
        {
            var firstDay = new Date(year+'-01-01');
            var lastDay = new Date((year+1)+'-01-01');
            var isDaily = false;
            if ( month > 0 ) 
            {  // first day of month
                isDaily = true;
                firstDay = new Date(year+'-'+month+'-01');
                if ( month == 12 ) {
                    lastDay = new Date((year+1)+'-01-01');
                } else {
                    lastDay = new Date(year+'-'+(month+1)+'-01');
                }
            }
    
            // console.log("fistDay = " + fistDay);
            // this.getFirstElement("payment", firstDay, "paymentDate", "paymentDate", (element)=>{
            //     console.log("first element = " , element);
            // });
    
            var arrMonthlyPayments = [];
            this.db.payment
            .where('paymentDate').aboveOrEqual(firstDay)
            .and(function (payment) {
                console.log("IS BELOW LASTDAY : "+lastDay+"-"+payment.paymentDate, payment.paymentDate < lastDay);
                return payment.paymentDate < lastDay;
            })
            .toArray()
            // .sortBy("paymentDate")
            .then((payments) => {
                console.log("In getPriceSum : ", payments);
                if ( payments && payments.length > 0 ) {
                    payments.forEach(payment => 
                    {
                        var gogo = true;
                        if (  professional && payment.professionalId != professional.id  ) {
                            gogo = false;
                        }
                        if ( ! payment.deleted && gogo ) {                
                            var pDate = payment.paymentDate;                        
                            var index = pDate.getMonth();
                            if ( isDaily ) {
                                index = pDate.getDate()-1;
                            }
                            var totalGiro = 0, totalCash = 0, totalCredit = 0, totalTransfer = 0  ;
                            if ( arrMonthlyPayments[index] ) {
                                totalGiro = arrMonthlyPayments[index].totalGiro;
                                totalCash = arrMonthlyPayments[index].totalCash;
                                totalCredit = arrMonthlyPayments[index].totalCredit;
                                totalTransfer = arrMonthlyPayments[index].totalTransfer;
                            }
                            arrMonthlyPayments[index] = {id : index+1, 
                                totalGiro : totalGiro + +payment.price,
                                totalCash : totalCash + +payment.paidCash,
                                totalCredit : totalCredit + +payment.paidCredit,
                                totalTransfer : totalTransfer + +payment.paidTransfer
                            }    
                        }
                    });                
                    console.log("AAAAAA getMonthlyPayments ", arrMonthlyPayments);
                } 
                callback({success:true, response:arrMonthlyPayments});
            });        
    
        })
    }

    getGenderSum(callback)
    {
        let professional = this.selectedProfessional;

        this.startSync("customer", ()=>
        {
            var allSum = {};
            allSum.sumGenderMale = 0 ;
            allSum.sumGenderFemale = 0 ;
            allSum.sumGenderUnknown = 0 ;
            this.db.customer
            .toArray()
            .then((customers) => {
                customers.forEach(customer => 
                {
                    var gogo = true;
                    if (  professional && customer.professionalId != professional.id  ) {
                        gogo = false;
                    }

                    if ( ! customer.deleted && gogo ) {
                        if ( customer.gender == 1 ){
                            allSum.sumGenderMale++;
                        } else if ( customer.gender == 2 ){
                            allSum.sumGenderFemale++;
                        } else if ( customer.gender == 0 ){
                            allSum.sumGenderUnknown++;
                        }
                    }
                });
                console.log("getGenderSum",allSum)
                callback({success:true, response:allSum});
            })
        })
    }

    getSourceSum(callback)
    {
        let professional = this.selectedProfessional;
        this.startSync("customer", ()=>
        {
            var allSum = {};
            allSum.sumSourceUnknown = 0 ;
            allSum.sumSourceWebSite = 0 ;
            allSum.sumSourceSearchEngine = 0 ;
            allSum.sumSourceFriend = 0 ;
            allSum.sumSourceSocialNetwork = 0 ;
            this.db.customer
            .toArray()
            .then((customers) => {
                customers.forEach(customer => 
                {
                    var gogo = true;
                    if (  professional && customer.professionalId != professional.id  ) {
                        gogo = false;
                    }
                    if ( ! customer.deleted && gogo ) {
                        if ( customer.source == 0 ){
                            allSum.sumSourceUnknown++;
                        } else if ( customer.source == 1){
                            allSum.sumSourceWebSite++;
                        } else if ( customer.source == 2){
                            allSum.sumSourceSearchEngine++;
                        } else if ( customer.source == 3){
                            allSum.sumSourceFriend++;
                        } else if ( customer.source == 4){
                            allSum.sumSourceSocialNetwork++;
                        }
                    }
                });
                console.log("getGenderSum",allSum)
                callback({success:true, response:allSum});
            })
        })
    }

    getLatestElement(tablename, callback)
    {
        this.db[tablename]
        .orderBy("changeDate").reverse()
        .limit(1)
        .toArray()
        .then((objs) => {
            if ( objs && objs.length > 0 ) {
                callback(objs[0]);
            } else {
                callback();
            }
        });        
    }

    // getFirstElement(tablename, pDate, sortColumn, queryColumn, callback)
    // {
    //     this.db[tablename]
    //     .where(queryColumn).aboveOrEqual(pDate)
    //     .sortBy(sortColumn+",changeDate")
    //     // .limit(1)
    //     // .toArray()
    //     .then((objs) => {
    //         if ( objs && objs.length > 0 ) {
    //             callback(objs[0]);
    //         } else {
    //             callback();
    //         }
    //     });
    // }

    convertDateFields(element){
        if ( element.changeDate )  {
            element.changeDate = utils.convertServerDate(element.changeDate);
        }
        if ( element.insertDate )  {
            element.insertDate = utils.convertServerDate(element.insertDate);
        }
        if ( element.paymentDate ) {
            element.paymentDate = utils.convertServerDate(element.paymentDate);
        }
        if ( element.examDate ) {
            element.examDate = utils.convertServerDate(element.examDate);
        }

        return element;
    }


}


export default DBManager;