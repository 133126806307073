import React, { useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Button, Toolbar, Menu, MenuItem, IconButton, FormControl, FormLabel, Select} from '@material-ui/core';
import RestManager from '/persist/RestManager'
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import LeftIcon from '@material-ui/icons/ArrowBackIos';
import RightIcon from '@material-ui/icons/ArrowForwardIos';

import intl from 'react-intl-universal';
import utils from '/helpers/utils'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

@connect(({ app }) => ({
  selectedCustomer: app.selectedCustomer,
}), {
})

class SecurityPage extends React.Component {

  state = {
    praxis : null,
    formViewer:null,
    isPreviewMode:false,
    anchorEl: null,
    form : null,
    formDialogOpen:false,
  };

  constructor (props) {
      super(props);  
      this.reloadData();
      // this.state.praxis = window(new PraxisApp());
  }    

  componentDidMount() {
    var formViewer = window.njs_UIViewer;
    this.setState({formViewer: formViewer});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      this.reloadData();
      // this.setState({addNoteMode: false, updateNoteMode:false, textAreaValue:"", selectedDocument:null});
    }
  }

  reloadData = () => {
    RestManager.getInstance().getForm("kvkk",
      (result) => {
        let form = result.response;
        this.setState({form: form});
        this.loadForm(form);
      }
    );
  }
    
  loadForm = (form) => {
    this.state.formViewer.init("formPage", form.formUI);
    this.loadGDPRData();
  }

  loadGDPRData = () => {
    RestManager.instance.getGDPRData( (result) =>
    {
      if ( result && result.success && result.response ) {
        var gdprdata = result.response;
        gdprdata.consentText = gdprdata.consentText.replace(/(?:\\n)/g, '\n');
        this.state.formViewer.setFormData(result.response);
      }
      this.loadCompanyData();
    });
  }

  loadCompanyData = () => {
    RestManager.instance.getCompany( (result) =>
    {
      if ( result && result.success && result.response ) {
        let company = result.response;
        var companyName = "";
        if ( company.companyName ) {
          companyName = company.companyName;
        }
        var companyAddress = "";
        if ( company.address ) {
          companyAddress = company.address.street + ", " +company.address.county + ", " +company.address.postalCode + ", " +company.address.city + ", " +company.address.country;
        }
        this.state.formViewer.setVariables({
          companyName: companyName,
          companyAddress: companyAddress,
        });
      }
    });

  }

  loadFormData = (formData) => {
    this.loadForm(formData.form);
    this.state.formViewer.setFormData(formData);
  }


  saveOrUpdate = () => 
  {
    var formData = this.state.formViewer.getFormData();
    RestManager.getInstance().saveOrUpdateGDPRData( formData, (response) =>
    {
      console.log("response = ", response);
      if ( response.success ) {

      }
    });

  }

  render() 
  {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);


      return (

          <div>

            <Toolbar style={{borderBottom:'1px solid lightgrey'}}>
              <div>
                <Button variant="outlined" size="small" color="primary" className={classes.margin} onClick={this.saveOrUpdate}>
                <SaveIcon />&nbsp;{intl.get("SAVE")}</Button>
              </div>
            </Toolbar>

            <div id = "formPage" style={{width:750, marginLeft:20, marginBottom:500}} ></div>

          </div>

      )
        
    }

}


export default withStyles(styles)(SecurityPage);