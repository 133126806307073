import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';


class PhoneMask extends React.Component 
{
  render() {
    const { inputRef, ...other } = this.props;
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        // mask={['+', /[1-9]/, /\d/,  '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        mask={['+', '9', '0', ' ', '5', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        // placeholderChar={'\u2000'}
        placeholderChar={'_'}
        showMask
        guide='false'
      />
    );
  }
}


export default PhoneMask;