import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Menu, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, MenuItem, Toolbar } from '@material-ui/core/';
import { connect } from 'react-redux';
import NewExamination from './NewExamination'
// import { AddIcon, DeleteIcon, EditIcon } from '@material-ui/icons/';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RestManager from '/persist/RestManager';
import DBManager from '/persist/DBManager';
import utils from '/helpers/utils';
import { setSelectedCustomer } from '../../redux/app'
import SaveIcon from '@material-ui/icons/SaveOutlined';

import intl from 'react-intl-universal';
import ExaminationListItem from './ExaminationListItem';
import FormDialog from '../Form/FormDialog';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import FormList from '../Form/FormList';

// function createData(id, examDate, price, paidCash, paidCredit, paidTransfer, restAmount, examNote) {
//   return { id, examDate, price, paidCash, paidCredit, paidTransfer, restAmount, examNote };
// }
// function createDataForPackage(id, examDate, examNote) {
//   return { id, examDate, examNote };
// }

const styles = theme => ({
  root: {
    // width: '100%',
    borderBottom: '2px solid lightgray'
    // marginTop: theme.spacing.unit,
  },
  table: {
    minWidth: 520,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  margin:{
    marginRight:6,
  }
});

@connect(({ app }) => ({
  selectedCustomer: app.selectedCustomer,
  selectedProfessional: app.selectedProfessional,
}), {
  setSelectedCustomer,
})

class ExaminationList extends React.Component {
  state = {
    customer: null,
    examinations: null,
    selected: [],
    // data: [],
    isNewExaminationVisible: false,
    isEditExaminationVisible: false,
    dialogOpen: false,
    anchorEl: null,
    anchorElCopy:null,
    selectedIndex:-1,
    formdatas:[],
    // forms:[],
    // commonForms:[],
    formDialogOpen:false,
    selectedFormData: null, 
    activeForm:null,
    isFormViewerVisible:false,
    isNewForm:false,
    formViewer:null,
    deleteDialogOpen:false,
    currentUser:null,
  };

  constructor(props) {
    super(props);
    RestManager.instance.getCurrentUser( (currentUser) =>
    {
        if ( currentUser ) {
          this.state.currentUser = currentUser;
          this.reloadData();
        }
    })

  }


  componentDidMount() {
    var formViewer = window.njs_UIViewer;
    this.setState({formViewer: formViewer});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      console.log('componentDidUpdate - !!!')
      // this.setReportDates(this.props.activeReport);
      if ( this.state.isFormViewerVisible ) {
        this.state.formViewer.clear();
      }
  
      this.reloadData();
      this.setState({isNewExaminationVisible: false, isEditExaminationVisible:false, isFormViewerVisible:false});

      // Do whatever you want
    }
  }

  reloadData = () => {
    const { selectedCustomer } = this.props;

    if ( this.state.currentUser.type != "assistant" ) {
      RestManager.getInstance().getAllFormDatasExamIsSet(selectedCustomer.id,
        (result) => {
          var formdatas = result.response;
          this.setState({ formdatas: formdatas });
          console.log("formdatasv : ", formdatas);
          this.loadExams();
        }
      );      
    } else {
      this.loadExams();
    }

  }

  loadExams =  ()=>{
    const { selectedCustomer } = this.props;
    RestManager.getInstance().getExaminations(selectedCustomer.id,
      (result) => {
        console.log("getExaminations result : ", result);
        let examinations = result.response;
        this.setState( {examinations : examinations, selectedIndex : -1}) ;
        if (this.state.customer != selectedCustomer) {
          this.setState({customer : selectedCustomer, selected : [] }) ;
        }

      }
    );  
  }


  onNewForm = (form, formData) => 
  {
    this.setState({ activeForm:form, isNewForm:true}, ()=>
      {
        // this.toggleFormDialog(); 
        this.handleMenuClose();
        this.state.formViewer.init("formPage_examsTab", form.formUI);
        if ( formData ) {
          if ( formData.jsonData && formData.jsonData != "" ) {
            var jsonData  = JSON.parse(formData.jsonData);
            this.state.formViewer.setFormData(jsonData);
          }      
        }
        this.toggleFormViewer();   
      });
  }

  onSaveFormData = () => 
  {
    var formData = this.state.formViewer.getFormData();
    var selExam = this.state.examinations[this.state.selectedIndex];
    if ( this.state.isNewForm ) 
    {  
      var newFormData = {};
      newFormData.jsonData = formData;
        RestManager.getInstance().addFormData( this.props.selectedCustomer.id, newFormData, this.state.activeForm.id, selExam.id, (response) =>
      {
        if ( response.success ) {
          this.reloadData();
          this.toggleFormViewer();   
        }
      });
    }
    else 
    {
      var newFormData = this.state.selectedFormData;
      newFormData.jsonData = formData;  
      RestManager.getInstance().updateFormData( this.props.selectedCustomer.id, newFormData, selExam.id, (response) =>
      {
        if ( response.success ) {
          this.reloadData();
          this.toggleFormViewer();     
        }
      });

    }

  }

  openConfirmDialog = () => {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  }

  onDeleteExam = (addToPackage) => {
    const { selectedCustomer } = this.props;
    console.log("in deleteExam ,,, selected = ", this.state.examinations[this.state.selectedIndex]);

    RestManager.getInstance().deleteExamination(selectedCustomer.id, this.state.examinations[this.state.selectedIndex].id, addToPackage, 
      (result) => {
        console.log("delete result : ", result);
        this.reloadData();
        this.setState({ dialogOpen: false, selected: []});
        if (addToPackage) {
          this.updateRedux(-1);
        }
      });
  }

  updateRedux(val) {
    const { selectedCustomer, setSelectedCustomer } = this.props;
    selectedCustomer.usedExams = selectedCustomer.usedExams + val;
    setSelectedCustomer(JSON.parse(JSON.stringify(selectedCustomer)));
  }

  onExamSaved = (result, examCount = 1) => {
    if (result.success == true) {
      this.reloadData();
      let exam = result.response;
      if (exam.examType == 1) {
        this.updateRedux(+examCount);
      }
      this.state.isNewExaminationVisible = false;
    }
  }

  onExamUpdated = () => {
    this.reloadData();
    this.state.isEditExaminationVisible = false;
  }

  showNewExamPanel = () => {
    this.state.isNewExaminationVisible = true;
    this.forceUpdate();
  }

  showEditExamPanel = () => {
    this.state.isEditExaminationVisible = true;
    this.forceUpdate();
  }
  onCloseExamPanel = () => {
    this.state.isNewExaminationVisible = false;
    this.state.isEditExaminationVisible = false;
    this.forceUpdate();
  }

  handleMenuCopy = event => {
    this.setState({ anchorElCopy: event.currentTarget });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ anchorElCopy: null });
  };

  toggleFormDialog = () => {
    this.setState({ formDialogOpen: ! this.state.formDialogOpen });
  }

  closeFormViewer = () => {
    this.reloadData();
    this.toggleFormViewer();
  }

  toggleFormViewer = () => {
    if ( this.state.isFormViewerVisible ) {
      this.state.formViewer.clear();
    }
    this.setState({ isFormViewerVisible: ! this.state.isFormViewerVisible });
  }

  onClicked = (index) =>
  {
    this.setState({selectedIndex: index });
    this.setState({isNewExaminationVisible: false });
  }

  showFormData = (formData) =>
  {
    this.setState({isNewForm:false});
    this.state.formViewer.init("formPage_examsTab", formData.form.formUI);
    var jsonData  = "";
    if ( formData.jsonData && formData.jsonData != "" ) {
      jsonData  = JSON.parse(formData.jsonData);
    }
    this.state.formViewer.setFormData(jsonData);
    this.setState({ activeForm: formData.form, selectedFormData: formData});
    this.toggleFormViewer();   
  }

  onDeleteFormData = () => {
    if ( this.state.selectedFormData ) {
      RestManager.getInstance().deleteFormData( this.props.selectedCustomer.id, this.state.selectedFormData.id, (response) =>
      {
        this.reloadData();
        this.setState({ deleteDialogOpen: false });
        this.toggleFormViewer();   
      });

    }
  }

  toggleDeleteDialog = () => {
    this.setState({ deleteDialogOpen: ! this.state.deleteDialogOpen });
  }


  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {

    console.log("RENDER - ExaminationList", this.state.selected);
    const { selectedCustomer, classes } = this.props;
    const { examinations, formdatas, anchorEl, anchorElCopy, rowsPerPage, page } = this.state;


    if ( this.state.selectedIndex >= 0) {
      var selectedExam = examinations[this.state.selectedIndex];
    } else {
      this.state.isEditExaminationVisible = false;
    }
    var rows = "";
    console.log("examinations im render : ", examinations);
    if ( examinations ) {
      examinations.sort(function(a, b) {
        return b.examDate - a.examDate;
      });
      examinations.forEach(exam => {
        exam.formdatas = [];
        if (Array.isArray(formdatas)) {
          formdatas.forEach(formdata => {
            if ( exam.id == formdata.examinationId ){
              exam.formdatas.push(formdata);
            }
          });                      
        }
      });
      rows = examinations.map( ( examination, i ) =>
        <div key={i} onClick={() => this.onClicked(i)}>
          <ExaminationListItem customer={selectedCustomer} examination={examination} showFormData={this.showFormData} selected={this.state.selectedIndex == i} title={(examinations.length - i) + ". Randevu"}> </ExaminationListItem>
        </div>
      )

      // var prevExamForms = "";
      // var previousExam = examinations[this.state.selectedIndex+1];
      // if ( previousExam && previousExam.formdatas ) {
      //   prevExamForms = previousExam.formdatas.map( ( formdata, i ) =>
      //       <div onClick={()=>this.onNewForm(formdata.form, formdata)} className={"custListItem"}>{formdata.form.formName}</div>
      //   )
      // }

      var filteredFormdatas = [];
      examinations.forEach(examination => {
        if ( selectedExam && selectedExam.id != examination.id ){
          examination.formdatas.forEach(formData => {
            formData.examDate = examination.examDate;
            filteredFormdatas.push(formData);
          });
        }
      });

      var formDataList = filteredFormdatas.map( ( formData, i ) =>
          <MenuItem
          value={formData}
          key={i}
          onClick= {() => this.onNewForm(formData.form, formData)}
        >
          {utils.humanDate(utils.formatDate(formData.examDate))} - {formData.form.formName }
        </MenuItem>
      )


    }

    const open = Boolean(anchorEl);
    const openCopy = Boolean(anchorElCopy);


    // if ( this.state.forms && this.state.forms.length>0) 
    // {
    //   var forms = this.state.forms.map( ( form, i ) =>
    //     <MenuItem
    //       value={form}
    //       key={i}
    //       onClick= {() => this.onFormSelected(i)}
    //     >
    //       {form.formVersion ? form.formName + " - v" + form.formVersion : form.formName}
    //     </MenuItem>
    //   )

    // }
    // if ( this.state.commonForms && this.state.commonForms.length>0) 
    // {
    //   var commonForms = this.state.commonForms.map( ( form, i ) =>
    //     <MenuItem
    //       value={form}
    //       key={i}
    //       onClick= {() => this.onFormSelected(i)}
    //     >
    //       {form.formVersion ? form.formName + " - v" + form.formVersion : form.formName}
    //     </MenuItem>
    //   )

    // }

    return (
      <div >
        {/* <ExaminationListToolbar selected={selected} onDeleteExam={this.onDeleteExam}/> */}
        <Toolbar
          className={classNames(classes.root, {
            [classes.highlight]: true,
          })}
        >
        { this.state.isFormViewerVisible ? 
          <div>
            <Button onClick={this.closeFormViewer} className={classes.margin} variant="outlined" size="small" >
            <ArrowBackIcon/> {intl.get('BACK')}</Button>
            <Button onClick={this.onSaveFormData} className={classes.margin} variant="outlined" size="small" color="primary"><SaveIcon/>&nbsp;Formu Kaydet</Button>
            { ! this.state.isNewForm ?
              <Button variant="outlined" size="small" color="primary" className={classes.margin} style={{ marginRight: 6 }} onClick={this.toggleDeleteDialog}>
                <DeleteIcon/>  {intl.get("DELETE_FORM")}
              </Button>
            :"" }
          </div>
          :
          <div className={classes.title}>
            {!this.state.isNewExaminationVisible && !this.state.isEditExaminationVisible ?
              <div>
                <Button variant="outlined" size="small" color="primary" className={classes.margin} style={{ marginRight: 6 }} onClick={this.showNewExamPanel}>
                  <AddIcon /> {intl.get('NEW')} </Button>
                <Button variant="outlined" size="small" color="primary" disabled={!selectedExam} className={classes.margin} style={{ marginRight: 6 }} onClick={this.showEditExamPanel}>
                  <EditIcon /> {intl.get('EDIT')} </Button>
                <Button variant="outlined" size="small" color="primary" disabled={!selectedExam} className={classes.margin} onClick={this.openConfirmDialog}>
                  <DeleteIcon /> {intl.get('DELETE')} </Button>


                  {/* <Button aria-owns={openNewForm ? 'menu-newForm' : undefined} aria-haspopup="true" variant="outlined" 
                    size="small" color="primary" className={classes.margin} onClick={this.handleNewFormMenu}>
                    <AddIcon />Yeni Form Olustur 
                  </Button> */}

                {/*
                <Button aria-owns={open ? 'menu-newForm' : undefined} disabled={!selectedExam} aria-haspopup="true" variant="outlined" size="small" color="primary" className={classes.margin} style={{ marginLeft: 40 }} onClick={this.handleMenu}>
                  <AddIcon />Yeni Form Ekle </Button>
                */}

                <Menu id="menu-newForm" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'left',}} transformOrigin={{ vertical: 'top', horizontal: 'left',}}
                    open={open} onClose={this.handleMenuClose}>

                  {/* {prevExamForms != "" ?
                    <div>
                      <MenuItem onClick= {() => this.copyLastForm()} style={{padding:0}} >
                        <div className="commonFormsLabel" style={{width:'100%'}}>
                          Bir önceki randevunun formları
                        </div>
                      </MenuItem>
                      {prevExamForms}
                    </div>
                  :""} */}

                  <FormList
                    onClose={this.handleMenuClose}
                    onItemClicked={this.onNewForm}
                    forms={this.props.forms}
                    isPreviewVisible={false}
                  />
                </Menu>

                {/*
                <Button aria-owns={open ? 'menu-newForm' : undefined} disabled={!selectedExam} aria-haspopup="true" variant="outlined" size="small" color="primary" className={classes.margin} onClick={this.handleMenuCopy}>
                  <AddIcon />Form Kopyala </Button>
                */}

                <Menu id="menu-newForm" anchorEl={anchorElCopy} anchorOrigin={{ vertical: 'top', horizontal: 'left',}} transformOrigin={{ vertical: 'top', horizontal: 'left',}}
                    open={openCopy} onClose={this.handleMenuClose}>
                    {formDataList}
                </Menu>


                {/* <FormDialog
                  dialogOpen={this.state.formDialogOpen}
                  onSelect={this.onNewForm}
                  onClose={this.toggleFormDialog} 
                  // forms = {this.state.forms}
                ></FormDialog> */}
              </div>
              : ""
            }
            {this.state.isNewExaminationVisible ?
              <div style={{ marginTop: 30, marginBottom: 20 }}>
                <NewExamination onClose={this.onCloseExamPanel} onSaved={this.onExamSaved} customer={selectedCustomer} ></NewExamination>
              </div>
              : ""}
            {this.state.isEditExaminationVisible ?
              <div style={{ marginTop: 30, marginBottom: 20 }}>
                <NewExamination onClose={this.onCloseExamPanel} onUpdated={this.onExamUpdated} customer={selectedCustomer} selectedExam={selectedExam}></NewExamination>
              </div>
              : ""}
          </div>
        }
          <div className={classes.spacer} />
        </Toolbar>

        { ! this.state.isFormViewerVisible ? 
          rows
        : ""  }

        <div id = "formPage_examsTab" style={{ width:'100%', maxWidth:900, fontSize:'14px', margin:'10 10 500 10', paddingRight:10}} ></div>


        <Dialog
            open={this.state.dialogOpen}
            onClose={this.openConfirmDialog }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Bu kayıdı silmek istediğinize emin misiniz?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Ödeme bilgileri de bu kayıt ile silinecektir.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={ this.openConfirmDialog } color="primary">
                İptal
              </Button>
              <Button id='delete' onClick={ () => this.onDeleteExam(false) } color="primary" autoFocus>
                SİL
              </Button>
              { 
              selectedExam && selectedExam.examType == 1 ?
              <Button id='deleteAndAdd' onClick={() => this.onDeleteExam (true) } color="primary" autoFocus>
                SİL VE PAKETE GERİ YÜKLE
              </Button>
              : ''
              }
            </DialogActions>
          </Dialog>


          <ConfirmDialog
              dialogOpen={this.state.deleteDialogOpen}
              onClose={this.toggleDeleteDialog} 
              dialogTitle={intl.get('DELETE') + '?'} 
              dialogText={intl.get('DO_YOU_WANT_TO_DELETE')} 
              onCancel={this.toggleDeleteDialog} 
              cancelLabel={intl.get('CANCEL')}
              onConfirm={this.onDeleteFormData} 
              confirmLabel={intl.get('DELETE')}
            ></ConfirmDialog>

      </div>
    );
  }
}

ExaminationList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExaminationList);
