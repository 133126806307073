import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MedicalSpecialitiesList from './MedicalSpecialitiesList';
import { withStyles } from '@material-ui/core/styles';
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import intl from 'react-intl-universal';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
});
class MedicalSpecialitiesDialog extends React.Component {

  
  render() {
    return (
      <Dialog
        open={this.props.dialogOpen}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
        maxWidth='mx'
        >
          <DialogTitle id="alert-dialog-slide-title">
            Uzmanlık Alanı
          </DialogTitle>

          <DialogContent
            style={{height:500, width:500}}
          >
            <MedicalSpecialitiesList 
            onClose={this.props.onClose}
            onSelect={this.props.onSelect}
            />
          </DialogContent>


          <DialogActions>
            <Button onClick={this.props.onClose} color="primary">
              {intl.get('CANCEL')}
            </Button>
          </DialogActions>

      </Dialog> 
    );
  }
}

export default withStyles(styles)(MedicalSpecialitiesDialog);
