import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {TextField, Button, DialogActions, CircularProgress, Menu} from '@material-ui/core';
import RestManager from '/persist/RestManager';
import utils from '/helpers/utils';
import { connect } from 'react-redux';
import PaymentBar from '../Selector/PaymentBar';
import MessageBox from '../Dialogs/MessageBox';
import intl from 'react-intl-universal';
import AddIcon from '@material-ui/icons/Add';
import addCircleOutline from 'material-ui/svg-icons/content/add-circle-outline';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

const styles = () => ({
  item:{
    padding:5, 
    borderBottom:'1px solid gray', 
    cursor:'pointer',
    "&:hover":{
      backgroundColor:'#f7f7f7',
    }
  },
  selItem:{
    padding:5, 
    backgroundColor: '#eef7ff' ,   
    borderBottom:'1px solid gray', 
  },
  title:{
    padding:'5 5 5 10',
    fontWeight:'bold',
    "&:hover":{
    }
  },
  selTitle:{
    padding:'5 5 5 10',
    fontWeight:'bold',
  },
  payment: {
    paddingLeft:5,
    color:"#5a5a5a", 
    fontStyle: "italic", 
    fontSize:"0.9rem",
  },
  formData:{
    margin:'5 5 5 10',
    padding:'5 0 0 5',
    borderTop:'1px solid #e2e2e2',
    cursor:'pointer',
  },

});

// const [loading, setLoading] = React.useState(false);

@connect(({ app }) => ({
  selectedCustomer: app.selectedCustomer,
}))

class ExaminationListItem extends React.Component {

    state = {
      anchorEl: null,

        id:"",
        examType: 2,
        examDate: "",
        examNote: "",
        paymentDate:"",
        paymentId:"",
        price:"",
        paidCash:"",
        paidCredit:"",
        paidTransfer:"",
        restAmount:"",
        loading:false,
        alertDialogOpen:false,
        alertTitle:"",
        alertText:"",
    };  

    render ()
    {
      const { anchorEl } = this.state;
      const { selected, classes, examination, title } = this.props;

      var formdatas = "";
      if ( examination.formdatas ) {
        formdatas = examination.formdatas.map( ( formdata, i ) =>
          <div className={classes.formData + " row"} >
            <span ><InsertDriveFileOutlinedIcon/></span>
            <span style={{marginLeft:5}}>
              <a onClick={()=>this.props.showFormData(formdata)}>{formdata.form.formName}</a>
            </span>
          </div>
        )
      }
      console.log("examination.examType = ", examination.examType);

      return (
        <div className={selected ? classes.selItem: classes.item} >
          <div className={selected ? classes.selTitle: classes.title}  style={{fontWeight: examination.examType == 3 ? 'normal' : 'bold' }} >
            {title} - {utils.humanDate(examination.examDate)} {examination.examType == 3 ? "/ Kontrol " : ""}  
          </div>

          <div className={"row"} style={{paddingLeft:10}} >
            {examination.examNote ? "(" +examination.examNote+ ")" : ""} 
          </div>
          { examination.payment && examination.payment.price ?
            <div className={classes.payment + " row"} >
              <div className="column" style={{width:200, padding:5}}>
                { intl.get('PAYMENT_DATE') + " : " + utils.humanDate(examination.payment.paymentDate)}
              </div>
              <div className="column" style={{width:120, padding:5}}>
              { intl.get('PRICE') + " : " + examination.payment.price}
              </div>
              { examination.payment.paidCash ? 
                <div className="column" style={{width:120, padding:5}}>
                  { intl.get('CASH') + " : " + examination.payment.paidCash}
                </div>            
              : "" }
              { examination.payment.paidCredit ? 
                <div className="column" style={{width:120, padding:5}}>
                  { intl.get('CREDIT_CARD') + " : " + examination.payment.paidCredit}
                </div>            
              : "" }
              { examination.payment.paidTransfer ? 
                <div className="column" style={{width:120, padding:5}}>
                  { intl.get('TRANSFER') + " : " + examination.payment.paidTransfer}
                </div>            
              : "" }
              { examination.payment.restAmount && examination.payment.restAmount > 0 ? 
                <div className="column" style={{width:120, padding:5, color: examination.payment.restAmount > 0 ? 'red' : '' }}>
                  { intl.get('REST') + " : " + examination.payment.restAmount}
                </div>            
              : "" }
            </div>

          :""}


          {formdatas}

        </div>
      )
    }

}


export default withStyles(styles)(ExaminationListItem);