import React, {Component} from 'react';
//import './App.css'
import RestManager from '/persist/RestManager'
import { withStyles } from '@material-ui/core/styles';
import BigCalendar from 'react-big-calendar'
import 'moment/locale/tr'
import moment from 'moment';
import utils from '/helpers/utils';
import { connect } from 'react-redux';
import { setSelectedCustomer, setIsEditMode, setIsNewMode } from '../redux/app'
import { ACTIVE_PAGE } from './MainPage';
import { withRouter } from 'react-router-dom';
import intl from 'react-intl-universal';

let counter = 0;
function createData(title, start, end, customerId) {
  counter += 1;
  return { id: counter, title: title, start: start, end: end, customerId:customerId };
}

@connect(({ app }) => ({
  // customers : app.customers,
  // selectedCustomer:app.selectedCustomer
  selectedProfessional: app.selectedProfessional,
}), {
  setSelectedCustomer,
  setIsEditMode,
  setIsNewMode,
})

class CalendarPage extends Component 
{
  state = {
    examinations:null,
    data:[],
    calView:'month',
    calDay:new Date(),
  };

    constructor (props) {
        super(props);  
        this.reloadData();
    }    

    componentDidUpdate(prevProps, prevState, snapshot) 
    {
      if (prevProps.selectedProfessional !== this.props.selectedProfessional) {
          this.reloadData();
      }
    }

    reloadData = () => 
    {
      const { selectedCustomer} = this.props;
      // counter = 0;
      let startEnd = null;
      let calDay = this.state.calDay;
      if ( this.state.calView == "day" ) {
        startEnd = utils.getStartEndOfDayInMilli(calDay);
      } else if ( this.state.calView == "week" ) {
        startEnd = utils.getStartEndOfWeekInMilli(calDay);
      } else if ( this.state.calView == "month" ) {
        startEnd = utils.getStartEndOfMonthInMilli(calDay);
      } else {
        startEnd = utils.getStartEndOfDayInMilli(calDay);
      }

      RestManager.getInstance().getAllExaminations( 
        startEnd.start, startEnd.end,
        (result) => {
          if ( result.success ) 
          {
            console.log("getExaminations result : ", result);
            let examinations = result.response;
            this.state.examinations = examinations;
            let data = [];
            for ( let i = 0 ; i < examinations.length; i++ ) {
              let exam = examinations[i];    
              // let startDate = utils.UTC2GMT(exam.examDate);
              // let endDate = utils.UTC2GMT(exam.examDate);
              let startDate = exam.examDate;
              let endDate = exam.examDate;
              data.push(createData(exam.firstName+" "+exam.lastName+ " - "+utils.getHours(exam.examDate.toString()), startDate, endDate, exam.customerId ));
            }
            console.log("Calendar Data = ", data);
            this.state.data = data;
            this.setState({ data: data });  
          }
        }
      );
  
    }
  
    onCalendarDateChanged = (calDay) => {
      console.log("onCalendarDateChanged = ", calDay)
      this.setState({ calDay: calDay }, 
        () => { this.reloadData(); }
      );
      
    }
  
    onCalendarViewChanged = (view) => {
      console.log("onCalendarViewChanged = ", view)
      this.setState({ calView: view },
        () => { this.reloadData(); }
      );
      
    }
  
    // onDoubleClickEvent = (event) => {
    onClickEvent = (event) => {
      RestManager.getInstance().getCustomer(event.customerId, (result) => 
      {
        this.props.setSelectedCustomer(result.response);
        this.props.setIsEditMode(false);
        this.props.setIsNewMode(false);
        this.props.history.push(ACTIVE_PAGE.CLIENTS);
      });
  
    }

    render() 
    {
      const { classes} = this.props;
      let allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k])
      moment.locale(intl.getInitOptions().currentLocale);
      const localizer = BigCalendar.momentLocalizer(moment)
      let start = new Date();
      start.setHours(6);
      start.setMinutes(0);
      let end = new Date();
      end.setHours(23);
      end.setMinutes(0);
        return (
          <div >
              <BigCalendar style={{padding:20}}
                events={this.state.data}
                onNavigate={this.onCalendarDateChanged}
                onView={this.onCalendarViewChanged}
                // onDoubleClickEvent={this.onDoubleClickEvent}
                onSelectEvent={this.onClickEvent}
                popup
                views={['month', 'week', 'day']}
                // views={['month']}
                defaultView='day'
                min={start}
                max={end}
                step={15}
                showMultiDayTimes
                // max={dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours')}
                defaultDate={new Date()}
                localizer={localizer}
                messages={{'today': intl.get('TODAY'), "previous":'< '+intl.get('BACK'), "next":intl.get('FORWARD')+" >", "month":intl.get('MONTH'), 
                  "week":intl.get('WEEK'), "work_week":"Working Week Days", "day":intl.get('DAY'), "agenda":intl.get('AGENDA'),
                  "date":intl.get('DATE'), "time":intl.get('TIME'), "event":intl.get('EXAMINATION')}}
              />
        </div>
        )
    }
  }
  const styles = theme => ({
    splitpane: {
      position: 'relative !important',
    },
  });
  
export default withRouter (withStyles(styles)(CalendarPage));
