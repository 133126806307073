export const SOURCE_ITEMS=['UNKNOWN', 'WEBSITE', 'SEARCH_ENGINE', 'FRIEND', 'SOCIAL_MEDIA']
export const GREEN_BUTTON='#42b956'
export const PAYMENT_COLUMNS = ["price", "paidCash", "paidCredit", "paidTransfer", "restAmount"]
export const CRYPT_SUFFIX = "Cr";
export const ALL_PROFESSIONALS = "ALL_PROFESSIONALS";
export const NO_PROFESSIONALS = "NO_PROFESSIONALS";

export const onresize = (dom_elem, callback, comp) => {
    const resizeObserver = new ResizeObserver(() => callback(comp) );
    resizeObserver.observe(dom_elem);
  };