import React, {Component} from 'react';
import { connect } from 'react-redux';
import { setSelectedCustomer, setIsEditMode, setIsNewMode, setCustomers } from '../../redux/app'
import SearchField from 'react-search-field';
import { withStyles } from '@material-ui/core/styles';
import { Button, MenuItem, Select } from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import intl from 'react-intl-universal';
import utils from '../../helpers/utils';
import male from '/images/male50.png';
import female from '/images/female50.png';
import crypt from '../../helpers/crypt';

const styles = () => ({
  packageBox: {
    float: 'right',
    border: '1px solid #41b955',
    borderTop: '10px solid #41b955',
    marginRight: 10,
    padding: 3,
    minWidth: 40,
    fontSize: '0.9em',
    textAlign: 'center',
    backgroundColor:'white',
    color:'black',
  },
  custInfo: {
  },
  searchBox: {
    position: 'absolute',
    background: 'white',
    zIndex: 9,
    width: '100%',
    overflow:'hidden'
  },
  newButton: {
    width: '100%',
    height: 24,
    padding:0,
  }
});

@connect(({ app }) => ({
  customers : app.customers,
  selectedCustomer:app.selectedCustomer,
}), {
  setSelectedCustomer,
  setIsEditMode,
  setIsNewMode,
  setCustomers
})

class CustomerList extends Component 
{
  state = {
    filteredCustomers:[],
    searchText:"",
    selectedIndex:-1,
  }

  // componentWillMount() {
  //   this.unlisten = this.props.history.listen((location, action) => {
  //     console.log("CustomerList -> on route change");

  //     let customers = this.props.customers;
  //     let selPos = customers.indexOf(this.props.selectedCustomer);
  //     customers[selPos] = this.props.selectedCustomer;
  //     this.props.setCustomers(customers);

  //     this.props.setIsEditMode(false);
  //     this.props.setIsNewMode(false);
  //   });
  // }
  // componentWillUnmount() {
  //     this.unlisten();
  // }


  componentDidUpdate(prevProps, prevState, snapshot) 
  {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      // let customers = this.props.customers;
      // if ( prevState.selectedIndex > -1 ) {         
      //   customers[prevState.selectedIndex] = this.props.selectedCustomer;
      //   this.props.setCustomers(customers);
      // }
      // this.props.setIsEditMode(false);
      // this.props.setIsNewMode(false);
    }
  }


  onClicked = (index) =>
  {
    const { filteredCustomers } = this.state;
    // this.state.selectedItem = index;
    // this.setState({selectedItem:index});
    let selCustomer = filteredCustomers[index];
    crypt.getCustomerCryptKey(selCustomer.id);
    // let selPos = this.props.customers.indexOf(selCustomer);s
    this.setState({selectedIndex: index });
    this.props.history.push('/clients?id='+selCustomer.id);

    // RestManager.getInstance().getCustomer(selCustomer.id, (result) => {
    //   this.onCustomerRetrieved(result.customer, selCustomer)
    // });
  }

  // onCustomerRetrieved(customer, selCustomer) 
  // {
  //   // customer.examCount = selCustomer.examCount;
  //   // customer.usedExams = selCustomer.usedExams;
  //   let customers = this.props.customers;
  //   let selPos = customers.indexOf(selCustomer);
  //   customers[selPos] = customer;
  //   this.props.setCustomers(customers);

  //   this.props.setSelectedCustomer(customer);
  //   this.props.setIsEditMode(false);
  //   this.props.setIsNewMode(false);

  //   this.props.history.push('/clients?id='+customer.id);
  // }

  onSearch = (searchText) => {
    this.setState({searchText:searchText});
    // console.log("search text : ", searchText);

  }

  onNewClient = () => {
    this.props.setIsNewMode(true);
  }

  filterCustomers = (customer) => {
    // console.log("in customer: " + customer);
    const { searchText } = this.state;
    let name = customer.firstName.toLowerCase() + " " + customer.lastName.toLowerCase();
    // let lastName = customer.lastName.toLowerCase();
    let lcSearchText = searchText.toLowerCase();
    let isOk =  name.indexOf(lcSearchText) > -1;
    return isOk;
  }

  render(){

    const { customers, selectedCustomer, classes} = this.props;
    const { searchText } = this.state;
    // console.log("RENDER - CustomerList ");

    // if ( customers && this.state.selectedIndex > -1 ) {
    //   console.log("RENDER - CustomerList - this.state.selectedIndex =", this.state.selectedIndex);
    //   customers[this.state.selectedIndex] = selectedCustomer;
    // }

    if ( customers ) 
    {
      this.state.filteredCustomers = customers;
      if ( searchText ) {
        this.state.filteredCustomers = customers.filter(this.filterCustomers);
      } 
      
      var rows = this.state.filteredCustomers.map( ( filteredCustomer, i ) =>
        <div className={selectedCustomer && selectedCustomer.id == filteredCustomer.id ? 'custListItemSelected' : 'custListItem'} style={{height:46}} key={i} onClick={() => this.onClicked(i)}> 
          <img className='avatar' src={filteredCustomer.gender == 2 ? female : male } alt="Avatar" height="36" width="36"></img>
          <div className="custListLabel">
            <div className="column">
              <div>{filteredCustomer.firstName} {filteredCustomer.lastName}</div>
              {selectedCustomer && selectedCustomer.id == filteredCustomer.id ?
                <div>{filteredCustomer.phone} </div> 
                :""
              }
            </div>
            {selectedCustomer && selectedCustomer.id == filteredCustomer.id ? 
              selectedCustomer.examCount - selectedCustomer.usedExams > 0 ? 
                <div className="column" className={classes.packageBox}>
                  <div> {selectedCustomer.examCount - selectedCustomer.usedExams} / {selectedCustomer.examCount}  </div>
                </div>
                : ""
              
            :
              filteredCustomer.examCount - filteredCustomer.usedExams > 0 ? 
                <div className="column" className={classes.packageBox}>
                  <div> {filteredCustomer.examCount - filteredCustomer.usedExams} / {filteredCustomer.examCount}  </div>
                </div>
                : ""
              
            }
          </div>
        </div>
      )
      
      return (
        <div>
          <div className={classes.searchBox}>
            <div style={{padding:5}}>
              <Button onClick={this.onNewClient} className={classes.newButton} variant="contained" color="secondary">{intl.get("NEW_PATIENT")}</Button>
            </div>
            <SearchField classNames='custSearchField' placeholder={intl.get("SEARCH")} onChange={this.onSearch} />
          </div>
          <div style={{position:'relative', top:70}}>
            {rows}
          </div>
        </div>
        );
    } 
    return <div style={{padding:10}} >{intl.get("LOADING_LIST")}</div>;
  }  

}
  
export default withRouter(withStyles(styles)(CustomerList));
