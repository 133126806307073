import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import RestManager from '../persist/RestManager';
import { ACTIVE_PAGE } from './MainPage';

@connect(({ app }) => ({
  paymentToken: app.paymentToken,
}))

class PaymentPage extends Component 
{
    constructor (props) {
        super(props);
        this.checkTokens();
    }

    state = {
      paymentStatus:"",
    };
  
    checkTokens = () => {
      this.setState({paymentStatus:"Ödemeniz kontrol ediliyor..."});
      console.log("CHECK PAYMENT");
      RestManager.instance.getPaymentStatus( 
        (result) => {
          let tab = 'licence';
          if ( result.response.basketId.startsWith('sms') ) {
            tab = '';
          }
          if ( result.response.paymentStatus == "SUCCESS" )
          {
            this.setState({paymentStatus:"Ödemeniz başarı ile gerçekleşti."});
            // RestManager.instance.updateToken();
            this.props.history.push(ACTIVE_PAGE.ACCOUNT+"?tab="+tab+"&paymentStatus=success");

            // window.location.reload();
          } else {
            this.setState({paymentStatus:"Ödeme başarısız."});          
            this.props.history.push(ACTIVE_PAGE.ACCOUNT+"?tab="+tab+"&paymentStatus=fail&errorMessage="+result.response.errorMessage);
          }
          console.log("PAYMENT STAT = " , result);
        });
    }

    render() 
    {
        return (
          <div style={{textAlign:"center",fontSize:24, paddingTop:150, color:'gray'}}>
            { this.state.paymentStatus }
          </div>
        )
    }
  }
  
export default withRouter(PaymentPage);
