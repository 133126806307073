import React, {Component} from 'react';
import {TextField, Button, Checkbox, FormControlLabel, FormControl, InputLabel, Input, FormHelperText} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import RestManager from '/persist/RestManager';
import MessageBox from '../components/Dialogs/MessageBox';
import {withRouter} from 'react-router-dom';
import { ACTIVE_PAGE } from './MainPage';
import Footer from '../components/Common/Footer';
import Header from '../components/Common/Header';
import intl from 'react-intl-universal';
import queryString from 'query-string';
import utils from '/helpers/utils';
import PhoneMask from '../helpers/PhoneMask';
import CodeBox from '../components/Dialogs/CodeBox';

const styles = {
  textField: {
    width:'300px',
    margin:10
  },
  button:{
    margin:15
  }
};

// @connect(({ app }) => ({
// }), {
//   setToken
// })

class LoginPage extends Component 
{
  constructor(props){
    super(props);
    console.log("LoginPage props = ", props);
    this.state={
    email:'',
    password:'',
    phone:'',
    alertDialogOpen:false,
    isCreateAccount: false,
    isForgotPassword: false,
    alertText:'',
    alertTitle:'Eksik Veri',
    loading: false,
    isAgreementAccepted: false,
    isGDPRAccepted: false,
    codeBoxOpen:false,
    isWrongRegCode:false,
    // telRegexp : /^[0-9\b]+$/,
    }

    let resp = RestManager.getInstance().getToken();
    if ( resp.status == RestManager.TOKEN_STATUS.OK ) {
      console.log('LoginPage: passing to clients.');
      // this.props.router.replace('/clients');
      this.props.history.push( ACTIVE_PAGE.CLIENTS );    
      window.location.reload(false);
    }      

  }

  componentDidMount(prevProps, prevState, snapshot) 
  {
    // let resp = RestManager.getInstance().getToken();
    // if ( resp.status == RestManager.TOKEN_STATUS.OK ) {
    //   console.log('LoginPage: passing to clients.');
    //   // this.props.router.replace('/clients');
    //   this.props.history.push( ACTIVE_PAGE.CLIENTS );    
    //   window.location.reload(false);
    // }      
    // else if ( resp.status == RestManager.TOKEN_STATUS.NEEDS_UPDATE){
    //   RestManager.instance().updateToken( () =>
    //     {
    //       this.props.history.push( ACTIVE_PAGE.CLIENTS );    
    //       window.location.reload(false);
    //     });    
    // }

    let params = queryString.parse(this.props.location.search)
    if ( params.stat == "create" ) {
      this.setState({isCreateAccount:true});
    } else {
      this.setState({isCreateAccount:false});
    }

  }

 onSubmit = () => 
 {
  this.setState({ alertTitle: intl.get('MISSING_DATA') }); 
  if ( this.state.isCreateAccount ) 
   {
    if ( ! this.state.companyName) {
      this.setState({ alertText: intl.get('PLEASE_ENTER', {missingData: intl.get('COMPANY')}) });
      this.setState({ alertDialogOpen: true });
      return;
    } else if ( ! this.state.name ) {
      this.setState({ alertText: intl.get('PLEASE_ENTER', {missingData: intl.get('FIRSTNAME')}) });
      this.setState({ alertDialogOpen: true });
      return;
    } else if ( ! this.state.surname ) {
      this.setState({ alertText: intl.get('PLEASE_ENTER', {missingData: intl.get('LASTNAME')}) });
      this.setState({ alertDialogOpen: true });
      return;
    } else if ( ! this.state.phone || this.state.phone.indexOf('_') != -1 ) {
      this.setState({ alertText: intl.get('PLEASE_ENTER', {missingData: intl.get('PHONE')}) });
      this.setState({ alertDialogOpen: true });
      return;
    } else if ( ! this.state.email || ! utils.checkEmail(this.state.email) ) {
      this.setState({ alertText: intl.get('PLEASE_ENTER', {missingData: intl.get('EMAIL')}) });
      this.setState({ alertDialogOpen: true });
      return;
    } else if ( ! this.state.isAgreementAccepted ) {
      this.setState({ alertText: "Lütfen kullanıcı sözleşmesini onaylayınız." });
      this.setState({ alertDialogOpen: true });
      return;
    } else if ( ! this.state.isGDPRAccepted ) {
      this.setState({ alertText: "Lütfen Gizlilik Politikasını onaylayınız." });
      this.setState({ alertDialogOpen: true });
      return;
   }
  }
  if ( ! this.state.email ) {
    this.setState({ alertText: intl.get('PLEASE_ENTER', {missingData: intl.get('EMAIL')}) });
    this.setState({ alertDialogOpen: true });
    return;
  } else if ( ! this.state.password && ! this.state.isForgotPassword ) {
    this.setState({ alertText: intl.get('PLEASE_ENTER', {missingData: intl.get('PASSWORD')}) });
    this.setState({ alertDialogOpen: true });
    return;
  }
  if ( this.state.isCreateAccount ) 
  {
    if ( ! this.state.confirmPassword ) {
      this.setState({ alertText: intl.get('PLEASE_CONFIRM_PASSWORD') });
      this.setState({ alertDialogOpen: true });
      return;
    }
    if ( this.state.password != this.state.confirmPassword ) {
      this.setState({ alertText: intl.get('PASSWORDS_DO_NOT_MATCH') });
      this.setState({ alertDialogOpen: true });
      return;
    }

  }

  this.setState({ loading: true });
  if ( this.state.isCreateAccount ) 
  {
    console.log("Create Account");
    let user = {
      "company":{"companyName":this.state.companyName},
      "firstname":this.state.name, 
      "lastname":this.state.surname, 
      "username":this.state.email, 
      "phone":this.state.phone,
      "password":this.state.password,
      "isAdmin":"true"
    };

    RestManager.getInstance().register( 
      user, 
      (result) => {
        this.setState({ loading: false });
        console.log("result : ", result)
        this.onRegistered(result);
      }
    )
  } 
  else if ( this.state.isForgotPassword ) 
  {
    RestManager.getInstance().sendPasswordResetMail( 
      this.state.email, 
      (result) => {
        console.log("result : ", result)
        if ( result.ok == true ) {
          this.setState({ loading: false });
          this.onPasswordResetSent(result);
        } else {
          // if ( result.status == 5031 ) { } // failure at sending mail
          if ( result.status == 404 ) {
            this.setState({ alertTitle: intl.get('EMAIL_SEND_UNSUCCESSFUL'), loading: false, alertDialogOpen: true });
            this.setState({ alertText: 'Bu mail adresi ile kayıtlı bir kullanıcı yok.'});
          } else {
            this.setState({ alertTitle: intl.get('EMAIL_SEND_UNSUCCESSFUL'), loading: false, alertDialogOpen: true });
            this.setState({ alertText: intl.get('PLEASE_CONNECT_PRAXIS_TEAM')});
          }
        }
      }
    )
  }
  else 
  {
    RestManager.getInstance().login( this.state.email, this.state.password, (result) => {
      this.setState({ loading: false });
      this.onLoggedIn(result)
    });
  }

}

onRegistered = (result) => 
{
  if (result.status == '201') {
    // this.setState({ isCreateAccount: false }, () => {
      console.log("email = " + this.state.email);
      this.setState({ codeBoxOpen: "true" }); 

      // var url  = '/activation?status=checkemail&email='+this.state.email;
      // console.log("url = " + url);
      // this.props.router.replace(url)
    // });    
  } 
  else if  (result.status == '5011') {
    this.setState({ alertTitle: intl.get('WARNING') });
    this.setState({ alertText: intl.get('USER_WITH_THIS_EMAIL_ALREADY_EXISTS') });
    this.setState({ alertDialogOpen: true });
  } 
  else if  (result.status == '5041') {
    this.setState({ alertTitle: intl.get('WARNING') });
    this.setState({ alertText: 'SMS gönderilemedi. Lütfen cep telefonu numaranızı kontrol ediniz.' });
    this.setState({ alertDialogOpen: true });
  } 
  else {
    this.setState({ alertTitle: intl.get('ERROR') });
    this.setState({ alertText: intl.get('USER_CANNOT_BE_CREATED') });
    this.setState({ alertDialogOpen: true });
  }
}

onLoggedIn = (result) => {
  console.log("in onLoggeIn, response body: ",  result );
  if ( result.success == true ) 
  {
    this.props.router.push(ACTIVE_PAGE.CLIENTS)
    window.location.reload(false);
  }  
  else if (result.status == '401') {
    this.setState({ alertTitle: intl.get('AUTHORIZATION_ERROR')});
    this.setState({ alertText: intl.get('WRONG_EMAIL_OR_PASSWORD') });
    this.setState({ alertDialogOpen: true });
  }  
  else if (result.status == '403') {
    this.props.router.replace('/activation?status=waiting&email='+this.state.email)
  }  
  else if (result.fetchError == true ) {
    this.setState({ alertTitle: intl.get('SERVER_ERROR') });
    this.setState({ alertText: intl.get('COULD_NOT_CONNECT_TO_SERVER') });
    this.setState({ alertDialogOpen: true });
  } 
  else {
    this.setState({ alertTitle: intl.get('AUTHORIZATION_ERROR') });
    this.setState({ alertText: intl.get('COULD_NOT_LOGIN') });
    this.setState({ alertDialogOpen: true });
  }
}

onPasswordResetSent = (result) => {
  this.setState({ isCreateAccount: false });    
  this.setState({ isForgotPassword: false });    
  this.setState({ alertTitle: intl.get('EMAIL_SENT_SUCCESSFULLY'), loading: false, alertDialogOpen: true });
  this.setState({ alertText: intl.get('PLEASE_CHECK_YOUR_INBOX') });

}

onRegister = () => 
{
  console.log("is on register");
  this.setState({ isCreateAccount: true });    
  this.props.history.push("/login/?stat=create");
} 

onForgotPassword = () => {
  this.setState({ isForgotPassword: true });    
}
onBackToLogin = () => 
{
  console.log("is onBackToLogin");
  this.setState({ isCreateAccount: false });    
  this.setState({ isForgotPassword: false });    
  this.props.history.push("/login");
} 

handleDialogClose = () =>{
  this.setState({ alertDialogOpen: false });
}

handleCodeBoxClose = code  =>{
  console.log(code);
//  this.setState({ codeBoxOpen: true});
  if ( code ) {
    RestManager.getInstance().activate( code, true, (result) => {
      if (result.ok == true ) {
        this.setState({ codeBoxOpen: false});
        RestManager.getInstance().login(this.state.email, this.state.password, (result) => {
          this.setState({ loading: false });
          this.onLoggedIn(result)
        });
      } else {
        this.setState({isWrongRegCode: true});
      }
    });  
  } else {
    this.setState({codeBoxOpen: false});
  }
}

handleChange = name => event => {
  this.setState({ [name]: event.target.value });
};
// handleTelChange = name => e => {
//   let phone = e.target.value;

//   // if value is not blank, then test the regex
//   if (phone === '' || this.state.telRegexp.test(phone)) {
//       this.setState({ [name]: phone })
//   }
// };
handleCheckbox = (event) =>
{
  this.setState({ ...this.state, [event.target.name]: event.target.checked } )
}

render() 
{
  const { classes } = this.props;

  console.log('RENDER: Login Page');

    return (
      <div style={{textAlign:"center"}}>
          <div >

            <Header></Header>

            <div style={this.state.isCreateAccount == true ? {marginTop:30} : {marginTop:100}}>

                { this.state.isCreateAccount == true ?
                  <div>
                    <TextField required
                      label={intl.get('COMPANY')}
                      onChange = {this.handleChange('companyName')}
                      style={styles.textField} 
                    />
                    <br/>
                    <TextField required
                      label={intl.get('FIRSTNAME')}
                      onChange = {this.handleChange('name')}
                      style={styles.textField} 
                    />
                    <br/>
                    <TextField required
                      label={intl.get('LASTNAME')}
                      onChange = {this.handleChange('surname')}
                      style={styles.textField} 
                    />
                    <br/>

                    <FormControl className={classes.textField}>
                      <InputLabel required htmlFor="formatted-phone">{intl.get('PHONE')}</InputLabel>
                      <Input
                        value={this.state.phone}
                        onChange = {this.handleChange('phone')}
                        id="formatted-phone"
                        inputComponent={PhoneMask}
                        aria-describedby="sms-helper-text"
                      />  
                      <FormHelperText id="sms-helper-text">{intl.get("WILL_SEND_ACTIVATION_CODE_PER_SMS")}</FormHelperText>
                    </FormControl>
                    <br/>
                    
{/* 
                    <TextField
                      label={intl.get('PHONE')}
                      onChange = {this.handleTelChange('phone')}
                      value={this.state.phone}
                      style={styles.textField} 
                    />
                    <br/> */}
                  </div>
                :
                  <div/>
                }

                <TextField required
                    // hintText="Enter your Email"
                    label={intl.get('EMAIL')}
                    onChange = {this.handleChange('email')}
                    style={styles.textField} 
                />
                <br/>
                { this.state.isForgotPassword == false ?
                  <TextField required
                      type="password"
                      label={intl.get('PASSWORD')}
                      onChange = {this.handleChange('password')}
                      style={styles.textField} 
                  />
                  : ''
                }
                <br/>
                { this.state.isCreateAccount == true ?
                  <TextField required
                    type="password"
                    label={intl.get('CONFIRM_PASSWORD')}
                    onChange = {this.handleChange('confirmPassword')}
                    style={styles.textField} 
                  />
                :
                  <div/>
                }
                <br/>

                { this.state.isCreateAccount == true ?
                  <div >
                    <br/>
                    <div >
                      <FormControlLabel style={{width:'350px'}}
                        control={ <Checkbox checked={this.state.isAgreementAccepted} onChange={this.handleCheckbox} name="isAgreementAccepted" color="primary" />}
                        label={<span style={{fontSize:'0.8em'}}> <a href='https://praxis-24.com/kullanici-sozlesmesi/' target="_blank" >Kulllanıcı Sözleşmesi</a> <a>'ni okudum ve kabul ediyorum</a></span>}
                      />
                      <br/>
                      <FormControlLabel style={{width:'350px'}}
                        control={ <Checkbox checked={this.state.isGDPRAccepted} onChange={this.handleCheckbox} name="isGDPRAccepted" color="primary" />}
                        label={<span><span style={{fontSize:'0.8em'}}> <a href='https://praxis-24.com/gizlilik-politikasi/' target="_blank" >Gizlilik ve Kişisel Verilerin Korunması Politikası'nı</a> <div> okudum ve kabul ediyorum</div></span></span>}
                      />
                    </div>
                  </div>
                :
                  <div/>
                }


                { this.state.isCreateAccount == false ?
                  <div>
                    { this.state.isForgotPassword == true ?
                      <Button onClick={this.onSubmit} disabled={this.state.loading} variant="contained" style={styles.button} color="primary">{intl.get('CONTINUE')}</Button>
                      :
                      <Button onClick={this.onSubmit} disabled={this.state.loading} variant="contained" style={styles.button} color="primary">{intl.get('SUBMIT')}</Button>
                    }
                  </div>
                :
                  <Button onClick={this.onSubmit} disabled={this.state.loading} variant="contained" style={styles.button} color="primary">{intl.get('REGISTER')}</Button>
                }
                { this.state.isCreateAccount == false && this.state.isForgotPassword == false ?
                  <div className="row" style={{marginTop:15}}>
                    <div className="row">
                      <Button onClick={this.onForgotPassword} color="primary" >{intl.get('FORGOT_PASSWORD')}</Button>
                    </div>
                    <div className="row" style={{marginTop:15}}>
                      <Button onClick={this.onRegister} color="primary" >{intl.get('CREATE_NEW_ACCOUNT')}</Button>
                    </div>
                  </div>
                  :
                  <div className="row" style={{marginTop:30}}>
                    <Button onClick={this.onBackToLogin} size="small" color="primary" >{intl.get('BACK_TO_LOGIN_PAGE')} </Button>
                  </div>

                }

            </div>
            
            <Footer></Footer>

        </div>

        <MessageBox
          alertDialogOpen={this.state.alertDialogOpen}
          onClose={this.handleDialogClose} 
          alertTitle={this.state.alertTitle} 
          alertText={this.state.alertText} >
        </MessageBox>

        <CodeBox
          isOpen={this.state.codeBoxOpen}
          isWrongCode={this.state.isWrongRegCode}
          onClose={this.handleCodeBoxClose} >
        </CodeBox>

      </div>
    );
  // }
}


}
LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(LoginPage));
