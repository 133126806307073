import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core/';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Checkbox } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import NewPackage from './NewPackage'
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RestManager from '/persist/RestManager'
import utils from '/helpers/utils'
import { setSelectedCustomer } from '../../redux/app'
import intl from 'react-intl-universal';


function createData(id, status, paymentDate, examCount, usedExams, restExam, price, paidCash, paidCredit, paidTransfer, restAmount) {
  return { id, status, paymentDate, examCount, usedExams, restExam, price, paidCash, paidCredit, paidTransfer, restAmount };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class PackageTableHead extends React.Component 
{

  state = {
    rows : [
      { id: 'paymentDate', numeric: false, align:'center', disablePadding: true, label: intl.get('PROCESS_DATE') },
      { id: 'examCount', numeric: true, align:'center', disablePadding: true, label: intl.get('NUMBER_OF_APPOINTMENTS') },
      { id: 'usedExams', numeric: true, align:'center', disablePadding: true, label: intl.get('USED_APPOINTMENTS') },
      { id: 'restExam', numeric: true, align:'center', disablePadding: true, label: intl.get('REST_APPOINTMENTS') },
      { id: 'price', numeric: true, align:'right', disablePadding: true, label: intl.get('PRICE') },
      { id: 'paidCash', numeric: true, align:'right', disablePadding: true, label: intl.get('CASH') },
      { id: 'paidCredit', numeric: true, align:'right', disablePadding: true, label: intl.get('CREDIT') },
      { id: 'paidTransfer', numeric: true, align:'right', disablePadding: true, label: intl.get('TRANSFER') },
      { id: 'restAmount', numeric: true, align:'right', disablePadding: true, label: intl.get('REST') },
    ]
  }

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {this.state.rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.align}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title={intl.get('SORT')}
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

PackageTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const styles = theme => ({
  root: {
    // width: '100%',
    borderBottom:'2px solid lightgray'
    // marginTop: theme.spacing.unit,
  },
  table: {
    minWidth: 520,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  menuButton: {
    marginRight:6
  },
  hidden:{
    display:'none'
  }
});

@connect(({ app }) => ({
  selectedCustomer: app.selectedCustomer,
}), {
  setSelectedCustomer,
})
class PackageTable extends React.Component 
{
  state = {
    order: 'asc',
    orderBy: 'examDate',
    selected: [],
    page: 0,
    rowsPerPage: 25,
    customer:null,
    packages:null,
    data:[],
    isNewPanelVisible:false,
    isEditPanelVisible:false,
    examCount: 0,
    usedExams: 0,
  };

  constructor(props){
    super(props);
    this.reloadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      console.log('componentDidUpdate - !!!')
      // this.setReportDates(this.props.activeReport);
      this.reloadData();
        // Do whatever you want
    }
  }

  // componentDidMount() {
  //   console.log("componentDidMount - PackageTable");
  //   this.reloadData();  
  // }
  
  reloadData = (callback) => 
  {
    const { selectedCustomer } = this.props;
    // counter = 0;
    RestManager.getInstance().getPackages(selectedCustomer.id, 
      (result) => {
        console.log("getPackages result : ", result);
        let packages = result.response;
        this.state.packages = packages;
        let data = [];
        let examCount = 0;
        let usedExams = 0;
        for ( let i = 0 ; i < packages.length; i++ ) {
          let pack = packages[i];
          //  id, paymentDate, examCount, usedExams, restExam, price, paidCash, paidCredit, paidTransfer, restAmount };
          if ( pack.status == 1 ) {
            examCount += pack.examCount;
            usedExams += pack.usedExams;
          }
          try{
            data.push(createData(pack.id, pack.status, pack.payment.paymentDate, pack.examCount, pack.usedExams,
              (pack.examCount-pack.usedExams), pack.payment.price, pack.payment.paidCash, pack.payment.paidCredit, 
              pack.payment.paidTransfer, pack.payment.restAmount));
          } 
          catch(err)
          {}
        }
        this.state.examCount = examCount;
        this.state.usedExams = usedExams;
        if ( this.state.customer != selectedCustomer ) {
          this.state.customer = selectedCustomer;
          this.state.selected = [];
        } 
        this.state.data = data;
        this.setState({ data: data }, callback);
      }
    );

  }

  updateRedux()
  {
    const { setSelectedCustomer } = this.props;
    let customer = this.state.customer;
    customer.examCount = this.state.examCount;
    customer.usedExams = this.state.usedExams;
    setSelectedCustomer(JSON.parse(JSON.stringify(customer)));
  }

  openConfirmDialog = () => {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  }

  onDelete = () => {
    const { selectedCustomer} = this.props;
    console.log("in delete package ,,, selected = " , this.state.selected);

    // confirmAlert({
    //   title: 'Bu kayıdı silmek istediğinize emin misiniz?',
    //   message: 'Ödeme bilgileri de bu kayıt ile silinecektir.',
    //   buttons: [
    //     {
    //       label: 'Sil',
    //       onClick: () => 
    //       {
            for ( let i = 0 ; i < this.state.selected.length; i++ ) {
              RestManager.getInstance().deletePackage(selectedCustomer.id, this.state.selected[i], 
                (result) => {
                  console.log("delete result : ", result);
                  this.reloadData(this.updateRedux);
                  this.setState({ dialogOpen: false, selected: []});
                }
              );
            }
    //       }
    //     },
    //     {
    //       label: 'İptal',
    //       // onClick: () => alert('Click No')
    //     }
    //   ]
    // });

  }

  onSaved = () => {
    this.reloadData(this.updateRedux);
    this.state.isNewPanelVisible = false;
  }

  onUpdated = () => {
    this.reloadData(this.updateRedux);
    this.state.isEditPanelVisible = false;
  }

  showNewPanel = () => {
    this.state.isNewPanelVisible = true;
    this.forceUpdate();
  }

  showEditPanel = () => {
    this.state.isEditPanelVisible = true;
    this.forceUpdate();
  }
  onClosePanel = () => {
    this.state.isNewPanelVisible = false;
    this.state.isEditPanelVisible = false;
    this.forceUpdate();
  }

  onActivate = (isActive) => 
  {
    const { selectedCustomer} = this.props;
    const { packages, selected} = this.state;

    var selectedItem = packages.filter(obj => {
      return obj.id === selected[0]
    })[0]; 

    RestManager.getInstance().activatePackage( 
      selectedCustomer.id, selectedItem.id, isActive, 
      (result) => {
        this.reloadData(this.updateRedux);
      }
    )
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
    console.log("selected items : ", newSelected);

  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() 
  {
    const { selectedCustomer} = this.props;
    const { classes } = this.props;
    const { packages, data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    // if ( this.state.customer != selectedCustomer) {
    //   this.reloadData();
    // }
    console.log("RENDER - PackageTable");
    if (selected.length == 1 ) {
      var selectedItem = packages.filter(obj => {
        return obj.id === selected[0]
      })[0]; 
    } else {
      this.state.isEditPanelVisible = false;
    }
    if ( selectedItem ) console.log("selectedItem.status = ", selectedItem.status)
    return (
      <div >
        {/* <PackageTableToolbar selected={selected} onDelete={this.onDelete}/> */}
        <Toolbar
            className={classNames(classes.root, {
              [classes.highlight]: true,
            })}
        >
          <div className={classes.title}>
          { ! this.state.isNewPanelVisible &&   ! this.state.isEditPanelVisible ? 
            <div>
                  <Button variant="outlined" size="small" color="primary" className={classes.menuButton}  onClick={this.showNewPanel}>
                    <AddIcon /> {intl.get('NEW')} </Button>
                  <Button variant="outlined" size="small" color="primary"  disabled={selected.length!=1} className={classes.menuButton} onClick={this.showEditPanel}>
                    <EditIcon /> {intl.get('EDIT')} </Button>
                  <Button variant="outlined" size="small" color="primary" disabled={selected.length==0} className={classes.menuButton}  onClick={this.openConfirmDialog}>
                    <DeleteIcon /> {intl.get('DELETE')} </Button>
                  <Button variant="outlined" size="small" color="primary" disabled={selected.length!=1} 
                      className={classNames(classes.menuButton, ( ! selectedItem || selectedItem.status == 0 ) ? classes.hidden : '')}  onClick={()=>this.onActivate(false)}>
                     {intl.get('DEACTIVATE')} </Button>
                  <Button variant="outlined" size="small" color="primary" disabled={selected.length!=1} 
                      className={classNames(classes.menuButton, ( ! selectedItem || selectedItem.status == 1 ) ? classes.hidden : '')}  onClick={()=>this.onActivate(true)}>
                     {intl.get('ACTIVATE')} </Button>
              </div>
                : ""
              }
            {this.state.isNewPanelVisible ? 
              <div style={{marginTop:30, marginBottom:20}}>
                <NewPackage onClose={this.onClosePanel} onSaved={this.onSaved} customer={selectedCustomer} ></NewPackage>
              </div>
                : ""}
            {this.state.isEditPanelVisible ? 
              <div style={{marginTop:30, marginBottom:20}}>
                <NewPackage onClose={this.onClosePanel} onUpdated={this.onUpdated} customer={selectedCustomer} selectedItem={selectedItem}></NewPackage>
              </div>
                : ""}
          </div>
          <div className={classes.spacer} />
        </Toolbar>




        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <PackageTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                      style={ n.status == 0 ? {fontStyle:'italic', background:'lightgray'} : {}}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isSelected} />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {utils.formatDate(n.paymentDate, ".", true)}
                      </TableCell>
                      <TableCell align="center" padding="none">{n.examCount}</TableCell>
                      <TableCell align="center" padding="none">{n.usedExams}</TableCell>
                      <TableCell align="center" padding="none">{n.restExam}</TableCell>
                      <TableCell align="right" padding="none">{n.price}</TableCell>
                      <TableCell align="right" padding="none" >{n.paidCash}</TableCell>
                      <TableCell align="right" padding="none" >{n.paidCredit}</TableCell>
                      <TableCell align="right" padding="none" >{n.paidTransfer}</TableCell>
                      <TableCell align="right" padding="none" >{n.restAmount}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />


          <Dialog
            open={this.state.dialogOpen}
            onClose={this.openConfirmDialog }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Bu kayıdı silmek istediğinize emin misiniz?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Ödeme bilgileri de bu kayıt ile silinecektir.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={ this.openConfirmDialog } color="primary">
                İptal
              </Button>
              <Button onClick={this.onDelete} color="primary" autoFocus>
                SİL
              </Button>
            </DialogActions>
          </Dialog>



      </div>
    );
  }
}

PackageTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PackageTable);
