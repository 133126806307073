import React, {Component} from 'react';
//import './App.css'
import { withStyles } from '@material-ui/core/styles';
import 'moment/locale/tr'
import { connect } from 'react-redux'
import { Chart } from "react-google-charts";
import RestManager from '/persist/RestManager';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MonthSelectorBar from '../components/Selector/MonthSelectorBar';
import YearSelectorBar from '../components/Selector/YearSelectorBar';
import * as Constants from '/helpers/Constants'
import {withRouter} from 'react-router-dom';
import intl from 'react-intl-universal';
import queryString from 'query-string';

export const CHART_TYPES = {
  MONTHLY_INCOME: 'monthly_income',
  YEARLY_INCOME: 'yearly_income',
  GENDER: 'gender',
  SOURCE: 'source',
};


@connect(({ app }) => ({
  activeChart : app.activeChart,
  selectedProfessional: app.selectedProfessional,
}))

class ChartPage extends Component 
{
  state = {
    activeChart:null,
    data:[],
    genderData:[],
    sourceData:[],
    payments:null,
    checkedTotal:true,
    checkedCash:false,
    checkedCredit:false,
    checkedTransfer:false,
    barColors:null,
    numFormat:null
  };

    constructor (props) {
        super(props);
        if ( ! this.state.activeChart ) {
          this.setSelectedChartFromUrl();
        } else {
          this.reloadData(this.state.activeChart);
        }
        console.log('Chartpage constructor');
    }    

    // componentWillMount() {
    //   this.unlisten = this.props.history.listen((location, action) => {
    //     // this.setSelectedChartFromUrl();
    //     console.log("ChartPage -> on route change");
    //   });
    // }
    // componentWillUnmount() {
    //     this.unlisten();
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.activeChart !== this.props.activeChart) {
        console.log('componentDidUpdate ChartPage - !!!')
        this.setSelectedChartFromUrl();
      }
      if (prevProps.selectedProfessional !== this.props.selectedProfessional) {
        this.setSelectedChartFromUrl();
      }
      }

    setSelectedChartFromUrl()
    {
      let params = queryString.parse(this.props.location.search)
      if ( params.type ) {
        this.state.activeChart = {type:params.type};
        this.reloadData(this.state.activeChart);
      }
    }
  
    onMonthSelected = (selectedMonth, selectedYear) => {
      console.log("in onMonthSelected : ", selectedMonth, selectedYear);
      let chart = this.state.activeChart;
      chart.date = new Date(selectedYear, selectedMonth-1, 10);
      this.reloadData(chart);
    }
  
    onYearSelected = (selectedYear) => {
      console.log("in onYearSelected : ", selectedYear);
      let chart = this.state.activeChart;
      chart.date = new Date(selectedYear, 1, 10);
      this.reloadData(chart);
    }

    reloadData = (activeChart) => 
    {
      console.log('ChartPage reloadData : ', activeChart)
      if ( activeChart ) 
      {
        if ( activeChart.type == CHART_TYPES.MONTHLY_INCOME || activeChart.type == CHART_TYPES.YEARLY_INCOME  ) 
        {
          let day = new Date();
          if ( activeChart.date ) {
            day = new Date(activeChart.date);
          } else {
            activeChart.date = day;
          }
      
          let month = 0;
          if ( activeChart.type == CHART_TYPES.MONTHLY_INCOME ) {
            month = day.getMonth()+1;
          }
          let year = day.getFullYear();
    
          RestManager.getInstance().getPriceSum(month, year, 
            (result) => {
              if ( result && result.response ) {
                console.log("getPriceSum result : ", result);
                let payments = result.response;
                this.generateIncomeChartData(payments, activeChart);
                console.log("getPriceSum = ", payments);
              }
            }
          );
        }
        else if ( activeChart.type == CHART_TYPES.GENDER  ) 
        {
          RestManager.getInstance().getCustomerSum('gender',
            (result) => {
              if ( result && result.response ) {
                let genderSum = result.response;
                this.generateGenderChartData(genderSum);
                console.log("genderSum = ", genderSum);
              }
            }
          );
        }
        else if ( activeChart.type == CHART_TYPES.SOURCE  ) 
        {
          RestManager.getInstance().getCustomerSum('source',
            (result) => {
              let sourceSum = result.response;
              this.generateSourceChartData(sourceSum);
              console.log("sourceSum = ", sourceSum);
            }
          );

        }

      }
  
    }

    generateGenderChartData = (genderSum) =>
    {
      let data=[
        ['Task', intl.get('GENDER_CHART')],
        [ intl.get('MALE') + ' - (' + genderSum.sumGenderMale + ')' , genderSum.sumGenderMale],
        [ intl.get('FEMALE') + ' - (' + genderSum.sumGenderFemale + ')' , genderSum.sumGenderFemale],
        [ intl.get('UNKNOWN') + ' - ('+ genderSum.sumGenderUnknown + ')' , genderSum.sumGenderUnknown]
      ];

      this.setState({ genderData: data });
    }

    generateSourceChartData = (sourceSum) =>
    {
      let data=[
        ['Task', intl.get('SOURCE_CHART') ],
        [ intl.get(Constants.SOURCE_ITEMS[0]) + ' - (' + sourceSum.sumSourceUnknown + ')' , sourceSum.sumSourceUnknown],
        [ intl.get(Constants.SOURCE_ITEMS[1]) + ' - (' + sourceSum.sumSourceWebSite + ')' , sourceSum.sumSourceWebSite],
        [ intl.get(Constants.SOURCE_ITEMS[2]) + ' - (' + sourceSum.sumSourceSearchEngine + ')' , sourceSum.sumSourceSearchEngine],
        [ intl.get(Constants.SOURCE_ITEMS[3]) + ' - (' + sourceSum.sumSourceFriend + ')' , sourceSum.sumSourceFriend],
        [ intl.get(Constants.SOURCE_ITEMS[4]) + ' - (' + sourceSum.sumSourceSocialNetwork + ')' , sourceSum.sumSourceSocialNetwork],
      ];

      this.setState({ sourceData: data });
    }

    generateIncomeChartData = (payments, activeChart) =>
    {
      let data = new Array();
      let item = new Array();
      let colors = new Array();
      item.push('Ay');
      if ( this.state.checkedTotal ) { item.push(intl.get('INCOME')); colors.push({color: 'blue'})}
      if ( this.state.checkedCash ) { item.push(intl.get('CASH')); colors.push({color: 'red'})}
      if ( this.state.checkedCredit ) { item.push(intl.get('CREDIT_CARD')); colors.push({color: 'green'})}
      if ( this.state.checkedTransfer ) { item.push(intl.get('TRANSFER')); colors.push({color: 'orange'})}
      if ( item.length == 1 ) 
      { // no checkbox selected
        this.setState({
          data: new Array(),
          barColors: new Array(),
          numFormat: new Array()
        });
          return;
      }
       data.push( item );
      // this.setState({ barColors: colors });

      let numFormat = new Array();
      for ( let j =1; j <= colors.length; j++ ){
        numFormat.push( {type: 'NumberFormat', column: j,  options: { pattern: '#.###'} } );
      }
      // this.setState({ numFormat: numFormat });

      let max = 31;
      if ( this.state.activeChart.type == CHART_TYPES.YEARLY_INCOME ) {
        max = 12;
      }
      for ( let i = 1; i <= max; i++ ){
        let eItem = new Array();
        eItem.push(i);
        if ( this.state.checkedTotal ) eItem.push(0);
        if ( this.state.checkedCash ) eItem.push(0);
        if ( this.state.checkedCredit ) eItem.push(0);
        if ( this.state.checkedTransfer ) eItem.push(0);
        // eItem.push("");
        data[i] = eItem;
        // data[i] = [ i, 0, 0, 0, 0];
      }
      payments.forEach(payment => {
        let pItem = new Array();
        pItem.push(payment.id);
        if ( this.state.checkedTotal ) pItem.push(payment.totalGiro);
        if ( this.state.checkedCash ) pItem.push(payment.totalCash);
        if ( this.state.checkedCredit ) pItem.push(payment.totalCredit);
        if ( this.state.checkedTransfer ) pItem.push(payment.totalTransfer);
        data [payment.id] = pItem;
          // data [payment.id] = [ payment.id, payment.totalGiro, payment.totalCash, payment.totalCredit, payment.totalTransfer];
      });
      // this.setState({ data: data });
      this.setState({
        activeChart:activeChart,
        payments: payments,
        data: data,
        barColors: colors,
        numFormat: numFormat
      });

    }
  
    handleChange = (event) =>
    {
      this.setState({ ...this.state, [event.target.name]: event.target.checked }, 
        () => {
          this.generateIncomeChartData(this.state.payments, this.state.activeChart);
        });
      // this.generateIncomeChartData(this.state.payments);
    }

    render() 
    {
      // if ( this.state.activeChart == null || this.state.activeChart.type != this.state.activeChart.type) {
      //   this.reloadData();
      // }

      console.log("RENDER - chart page - " , this.state.activeChart);

      let title = intl.get('MONTHLY_INCOME_CHART');
      if ( this.state.activeChart && this.state.activeChart.type == CHART_TYPES.YEARLY_INCOME ) {
        title = intl.get('YEARLY_INCOME_CHART');        
      }

      return (
        <div>

          {  this.state.activeChart && (this.state.activeChart.type == CHART_TYPES.MONTHLY_INCOME || this.state.activeChart.type == CHART_TYPES.YEARLY_INCOME )?
            <div  style={{marginLeft:30, marginRight:30, paddingBottom:50, height:'70%'}}>

              {this.state.activeChart.type == CHART_TYPES.MONTHLY_INCOME ? 
                <MonthSelectorBar onMonthSelected={this.onMonthSelected} ></MonthSelectorBar>
              : '' }
              {this.state.activeChart.type == CHART_TYPES.YEARLY_INCOME ? 
                <YearSelectorBar onYearSelected={this.onYearSelected} ></YearSelectorBar>
              : '' }

              <Chart
              width={'100%'}
              height={'100%'}
              style={{paddingTop:5}}
              chartType="Bar"
              loader={<div>{intl.get('LOADING')}</div>}
              data={this.state.data}
              options={{
                // Material design options
                chart: {
                  title: title,
                  //subtitle: 'Aylik Toplam Ciro 2020',
                },
                animation:{
                  duration:1000,
                  startup:true,
                  easing:'out'
                },
                series: this.state.barColors,
                vAxis: { format:'decimal'},
                hAxis: { format:'decimal'},
              }}
              formatters = {this.state.numFormat}
              // For tests
              rootProps={{ 'data-testid': '2' }}
              />
              <FormGroup row center style={{marginTop:15, justifyContent:'center'}}>
              <FormControlLabel
                  control={<Checkbox checked={this.state.checkedTotal} name="checkedTotal" color="primary"/>}
                  label={intl.get('INCOME')}
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.checkedCash} onChange={this.handleChange} name="checkedCash" color="primary"/>}
                  label={intl.get('CASH')}
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.checkedCredit} onChange={this.handleChange} name="checkedCredit" color="primary"/>}
                  label={intl.get('CREDIT_CARD')}
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.checkedTransfer} onChange={this.handleChange} name="checkedTransfer" color="primary"/>}
                  label={intl.get('TRANSFER')}
                />
              </FormGroup>
            </div>

            : ''
          }


          {  this.state.activeChart && (this.state.activeChart.type == CHART_TYPES.GENDER || 
                this.state.activeChart.type == CHART_TYPES.SOURCE) ?
            <div className={"my-pretty-chart-container"}>
              <Chart
                width={'100%'}
                height={'100%'}
                chartType="PieChart"
                loader={<div>{intl.get('LOADING')} </div>}
                data={this.state.activeChart.type == CHART_TYPES.GENDER ? this.state.genderData :this.state.sourceData }
                options={{
                  title: this.state.activeChart.type == CHART_TYPES.GENDER ? intl.get('GENDER_CHART') : intl.get('SOURCE_CHART'),
                  pieSliceText: 'label',
                  is3D : true
                }}
                rootProps={{ 'data-testid': '1' }}
              />
            </div>
            :
            ""
          }

      </div>
      );      
    }
  }
  const styles = theme => ({
    splitpane: {
      position: 'relative !important',
    },
  });
  
export default withRouter(withStyles(styles)(ChartPage));
