import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import PeopleIcon from '@material-ui/icons/People';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { setSelectedProfessional, setIsSideBarCollapsed, setActiveSetting } from '../../redux/app'
import RestManager from '/persist/RestManager';
import {ACTIVE_PAGE} from '../../pages/MainPage';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import intl from 'react-intl-universal';
import * as Constants from '/helpers/Constants'
import { Checkbox} from '@material-ui/core';
import DBManager from '../../persist/DBManager';

const styles = {
  root: {
    flexGrow: 1,
    minHeight:'22px',
  },
  grow: {
    flexGrow: 1,
    fontWeight: 400,
    fontSize: '1rem',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

@connect(({ app }) => ({
  isSideBarCollapsed : app.isSideBarCollapsed,
  isClientListVisible : app.isClientListVisible,
}), {
  setSelectedProfessional,
  setIsSideBarCollapsed,
  setActiveSetting,  
})

class MenuBar extends React.Component {
  state = {
    auth: true,
    anchorEl: null,
    currentUser:null,
    logoutDialogOpen: false,
    licenceRest:0,
    professionals:null,
    selectedProfessional:"",
    selectedProfessionalLabel:"",
  };

  constructor(props){
    super(props);
    RestManager.instance.getCurrentUser( (currentUser) =>
    {
        if ( currentUser ) {
          this.setState({currentUser : currentUser });
          this.loadProfessionalsList(currentUser);
        }
    })
    var token = RestManager.instance.getToken();
    var licenceRest = token.licenceRest;
    if ( licenceRest < 0 ) licenceRest = 0;
    this.state.licenceRest = licenceRest;
    
  }

  loadProfessionalsList = (currentUser) => 
  {
    RestManager.instance.getProfessionals((result) => {
      console.log("loadProfessionalsList users : ", result);
      if ( result && result.success == true && result.response ) {
        let professionals = result.response;
        this.setState({professionals: professionals});
        professionals.forEach(professional => {
          if ( currentUser.id === professional.user.id ) {
            this.setProfessional(professional);
          }
        });

        if ( currentUser.type == "assistant" && professionals.length > 0 ) {
          this.setProfessional(professionals[0]);
        }
  
      }
    })
  }

  onProfessionalChange = (event) => {
    let professional = event.target.value;
    this.setProfessional(professional);
  }

  setProfessional = (professional) => {
    this.setState({ anchorEl: null });
    this.setState({ selectedProfessional: professional });
    if ( professional == Constants.ALL_PROFESSIONALS ) {
      this.setState({ selectedProfessionalLabel: "Bütün Kayıtlar" });
      RestManager.instance.setSelectedProfessional(null);
      DBManager.instance.setSelectedProfessional(null);
      this.props.setSelectedProfessional(null);
    } else if ( professional == Constants.NO_PROFESSIONALS ) {
      this.setState({ selectedProfessionalLabel: "Doktor Atanmamış Kayıtlar" });
      let professional = {};
      professional.id = null;
      RestManager.instance.setSelectedProfessional(professional);
      DBManager.instance.setSelectedProfessional(professional);
      this.props.setSelectedProfessional(professional);
    } else {
      this.setState({ selectedProfessionalLabel: professional.title +" "+ professional.firstname +" "+ professional.lastname });
      RestManager.instance.setSelectedProfessional(professional);
      DBManager.instance.setSelectedProfessional(professional);
      this.props.setSelectedProfessional(professional);
    }

  }

  handleSideBarToggle = () =>{
    this.props.setIsSideBarCollapsed( ! this.props.isSideBarCollapsed );
  }

  // handleClientListToggle = () =>{
  //   this.props.setIsClientListVisible( ! this.props.isClientListVisible );
  // }

  // handleChange = event => {
  //   this.setState({ auth: event.target.checked });
  // };


  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onAccountClicked = () => {
    this.props.history.push(ACTIVE_PAGE.ACCOUNT);
    this.handleClose();
  }
  onBuyLicence = () => {
    this.props.history.push(ACTIVE_PAGE.ACCOUNT+"?tab=licence");
    this.props.setActiveSetting("licence");
  }

  handleLogout = () => {
    localStorage.removeItem("token");
    this.props.history.push(ACTIVE_PAGE.LOGIN);
    window.location.reload();
  };

  toggleConfirmDialog = () => {
    this.setState({ logoutDialogOpen: ! this.state.logoutDialogOpen });
    this.handleClose();
  }

  render() {
    const {pathname} = this.props.location;

    console.log('RENDER - MenuBar', pathname)
    const { classes } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    let isAdmin = false;
    let isAssistant = false;
    if ( this.state.currentUser ) {
      isAdmin = this.state.currentUser.isAdmin;
      isAssistant = this.state.currentUser.type=="assistant";
    }

    if ( (isAdmin || isAssistant) && this.state.professionals )
    {
      var rows = this.state.professionals.map( ( professional, i ) =>
          <MenuItem 
            value={professional}
            key={professional.id}
            onClick= {() => this.setProfessional(professional)}
          >
            {professional.title} {professional.firstname} {professional.lastname}
          </MenuItem>
      )
    } else {
      var rows = <MenuItem disabled> {this.state.selectedProfessionalLabel} </MenuItem>
    }

    return (
      <div className={classes.root}>
        <AppBar position="fixed" style={{boxShadow:'none'}}>
          <Toolbar style={{minHeight:'40px', height:'40px', backgroundColor:'#f7f7f7', color:'black', borderBottom:'1px solid #bdbdbd', borderTop:'1px solid #bdbdbd'}}>
            { ! this.props.showJustLogout ? 
              <IconButton onClick={this.handleSideBarToggle} className={classes.menuButton} color="inherit" aria-label="Menu">
                {this.props.isSideBarCollapsed ? 
                  <MenuIcon />
                  :
                  <ArrowBackIcon/>               
                }
              </IconButton>
            : "" }

            <Typography variant="h6" color="inherit" className={classes.grow}>
            { ! this.props.showJustLogout ? 
              <div>
                { pathname.includes(ACTIVE_PAGE.CLIENTS) ? intl.get('PATIENTS_VIEW'): ''}
                { pathname.includes(ACTIVE_PAGE.CALENDAR) ? intl.get('CALENDAR_VIEW')  : ''}
                { pathname.includes(ACTIVE_PAGE.REPORTS) ? intl.get('REPORTS_VIEW')  : ''}
                { pathname.includes(ACTIVE_PAGE.CHARTS) ? intl.get('CHARTS_VIEW')  : ''}
                { pathname.includes(ACTIVE_PAGE.ACCOUNT) ? intl.get('ACCOUNT_VIEW')  : ''}
                { pathname.includes(ACTIVE_PAGE.PAYMENT) ? intl.get('PAYMENT_VIEW')  : ''}
                { pathname.includes(ACTIVE_PAGE.PROFESSIONALS) ? intl.get('PROFESSIONALS_VIEW')  : ''}


                {/* { this.state.professionals && this.state.professionals.length > 0 ? 
                  <span >
                    <Select style={{ marginLeft:100, backgroundColor:'white', paddingLeft:10}}
                      labelid="doctorPicker"
                      id="doctorPicker"
                      displayEmpty
                      value={this.state.selectedProfessional}
                      onChange={this.onProfessionalChange}
                      className={classes.textField}
                    >
                      <MenuItem value="" disabled> Doktor seçiniz</MenuItem>
                      {rows}
                      <MenuItem value={Constants.ALL_PROFESSIONALS} > <em><strong>Bütün Kayıtlar</strong></em></MenuItem>
                      <MenuItem value={Constants.NO_PROFESSIONALS} > <em><strong>Doktor Atanmamış Kayıtlar</strong></em></MenuItem>
                    </Select>
                  </span>
                : "" } */}


                {/* { this.state.licenceRest < 10 &&  this.state.licenceRest > 0 ? 
                  <span style={{fontSize:'0.8em', fontWeight:300, marginLeft:40}}>
                    {"Lisansınızın bitimine " + this.state.licenceRest + " gün kaldı."} 
                  </span>
                  :""
                } 
                { this.state.licenceRest <= 0 ? 
                  <span style={{fontSize:'0.8em', fontWeight:300, marginLeft:40}}>
                    {"Lisansınızın süresi doldu."} 
                  </span>
                  :""
                } 
                { this.state.licenceRest < 8 ? 
                  <span style={{marginLeft:20}}>
                    <Button onClick={this.onBuyLicence} variant="contained"  size="small" color="primary" style={{backgroundColor:Constants.GREEN_BUTTON}}>ÜYELİK PAKETİ SATIN AL</Button>
                  </span>
                  :""
                }  */}

              </div>
              
            :
            'Praxis-24 '
            } 


            </Typography>
            {auth && (
              <div>
                <IconButton aria-owns={open ? 'menu-appbar' : undefined} aria-haspopup="true" color="inherit"
                  onClick={this.handleMenu}
                >
                <label style={{fontSize:"0.6em", marginRight:"10px"}}>
                    {this.state.selectedProfessionalLabel}
                </label>
                {/* <AccountCircle /> */}
                </IconButton>
                <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right',}} transformOrigin={{ vertical: 'top', horizontal: 'right',}}
                    open={open} onClose={this.handleClose}>
                  {(isAdmin || isAssistant) && ! this.props.showJustLogout && this.state.professionals && this.state.professionals.length > 1 ? 
                    <MenuItem value="" disabled> Doktor seçiniz</MenuItem>
                  :""}
                  {rows}
                  {(isAdmin || isAssistant) && ! this.props.showJustLogout && this.state.professionals && this.state.professionals.length > 1 ? 
                    <div>
                    <MenuItem value={Constants.ALL_PROFESSIONALS} onClick= {() => this.setProfessional(Constants.ALL_PROFESSIONALS)}> <em><strong>Bütün Kayıtlar</strong></em></MenuItem>
                    <MenuItem value={Constants.NO_PROFESSIONALS} onClick= {() => this.setProfessional(Constants.NO_PROFESSIONALS)}> <em><strong>Doktor Atanmamış Kayıtlar</strong></em></MenuItem>
                    </div>
                  :""}
                  {/* { ! this.props.showJustLogout ? 
                    <div>
                      <hr></hr>
                      <MenuItem onClick={this.onAccountClicked}>{intl.get('ACCOUNT_VIEW')}</MenuItem>
                    </div>
                  : "" } */}
                  <hr></hr>
                  <MenuItem onClick={this.toggleConfirmDialog}>{intl.get('LOGOUT')}</MenuItem>

                </Menu>
              </div>
            
              // <div>
              //   <IconButton aria-owns={open ? 'menu-appbar' : undefined} aria-haspopup="true" color="inherit"
              //     onClick={this.handleMenu}
              //   >
              //   <label style={{fontSize:"0.6em", marginRight:"10px"}}>{this.state.currentUser ? this.state.currentUser.firstname + " " + this.state.currentUser.lastname : ""}</label>
              //   <AccountCircle />
              //   </IconButton>
              //   <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right',}} transformOrigin={{ vertical: 'top', horizontal: 'right',}}
              //       open={open}onClose={this.handleClose}>
              //   { ! this.props.showJustLogout ? 
              //     <MenuItem onClick={this.onAccountClicked}>{intl.get('ACCOUNT_VIEW')}</MenuItem>
              //     : "" }
              //     <MenuItem onClick={this.toggleConfirmDialog}>{intl.get('LOGOUT')}</MenuItem>
              //   </Menu>
              // </div>
            )}





          </Toolbar>
        </AppBar>

        <ConfirmDialog
              dialogOpen={this.state.logoutDialogOpen}
              onClose={this.toggleConfirmDialog} 
              dialogTitle={intl.get('LOGOUT')}
              dialogText={intl.get('DO_YOU_WANT_TO_LOGOUT')}
              onCancel={this.toggleConfirmDialog} 
              cancelLabel={intl.get('CANCEL')}
              onConfirm={this.handleLogout} 
              confirmLabel={intl.get('LOGOUT')}
        ></ConfirmDialog>

      </div>
    );
  }
}

MenuBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(MenuBar));
// export default withRouter(withStyles(styles)(withWidth()(MyComponent)));
