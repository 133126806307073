import React, { Component } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarFooter, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import PeopleIcon from '@material-ui/icons/People';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import TableChartIcon from '@material-ui/icons/TableChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import 'react-pro-sidebar/dist/css/styles.css';
import { connect } from 'react-redux'
import { setIsSideBarCollapsed, setActiveReport, setActiveChart } from '../../redux/app'
import Tooltip from '@material-ui/core/Tooltip';
import {REPORT_TYPES} from '../../pages/ReportPage';
import {ACTIVE_PAGE} from '../../pages/MainPage';
import {CHART_TYPES} from '../../pages/ChartPage';
import { withRouter } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import intl from 'react-intl-universal';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import {
createMuiTheme,
MuiThemeProvider,
withStyles
} from "@material-ui/core/styles";
import RestManager from '../../persist/RestManager';


const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
        // color: "grey",
        // backgroundColor: "white"
      }
    }
  },
});

const styles = ({
  selectedMenu:{
    border: '1px solid lightgray',
    padding: 5,
    background: 'lightgray',
    color: 'black',
    borderRadius: 30,
  },
  subMenu:{
    margin:0,
  }
});


@connect(({ app }) => ({
    isSideBarCollapsed : app.isSideBarCollapsed,
  }), {
    setActiveReport,
    setActiveChart,
    setIsSideBarCollapsed
  })

class SideBar extends Component {

  constructor(props) {
    super(props);
    RestManager.instance.getCurrentUser( (currentUser) =>
    {
        if ( currentUser ) {
          this.state.currentUser = currentUser ;
        }
    })
  }

  state = {
    selectedMenu:ACTIVE_PAGE.CLIENTS,
    logoutDialogOpen: false,
    isSideBarOpen:false,
    currentUser:false,
  };

  onReportClicked = (pType) => {
    this.setState({selectedMenu: ACTIVE_PAGE.REPORTS});
    let report = {type:pType}
    this.props.setActiveReport(report);
    let url = ACTIVE_PAGE.REPORTS + "?type=" + pType;
    this.closeSideBar();
    this.props.history.push(url);
  }

  onChartClicked = (pType) => {
    this.setState({selectedMenu: ACTIVE_PAGE.CHARTS});
    let report = {type:pType}
    this.props.setActiveChart(report);
    let url = ACTIVE_PAGE.CHARTS + "?type=" + pType;
    this.props.history.push(url);
    this.closeSideBar();
  }

  onMenuItemClicked = (page) => {
    this.props.history.push(page);
    this.setState({selectedMenu: page});
    this.closeSideBar();
   } 

  closeSideBar = () => {
    this.props.setIsSideBarCollapsed( true );
    this.setState({isSideBarOpen:false});
  }

  onOpenSidebar= () => {
    this.props.setIsSideBarCollapsed( false );
  }

  handleLogout = () => {
    localStorage.removeItem("token");
    this.props.history.push(ACTIVE_PAGE.LOGIN);
    window.location.reload();
  };

  toggleConfirmDialog = () => {
    this.setState({ logoutDialogOpen: ! this.state.logoutDialogOpen });
  }

  render() {
    var isToggled  = ! this.props.isSideBarCollapsed;
    return (
        <div>
         {/* <MuiThemeProvider theme={theme}> */}
        <ProSidebar collapsed={this.props.isSideBarCollapsed} toggled={isToggled} className={'md'} style={{left:0, backgroundColor:'white !important'}}>

        <Menu iconShape="circle">
            {/* <MenuItem icon={<AddIcon/>} >Dashboard</MenuItem> */}
                <MenuItem onClick={() => this.onMenuItemClicked(ACTIVE_PAGE.CLIENTS)}  icon={
                    <Tooltip title={intl.get("PATIENTS")} >
                        <PeopleIcon style={ this.state.selectedMenu==ACTIVE_PAGE.CLIENTS ? styles.selectedMenu : {} } />                        
                    </Tooltip>
                } >{intl.get("PATIENTS")}</MenuItem>
            <MenuItem onClick={() => this.onMenuItemClicked(ACTIVE_PAGE.CALENDAR)}  icon={
                <Tooltip title={intl.get("CALENDAR")}>
                    <CalendarTodayIcon style={ this.state.selectedMenu==ACTIVE_PAGE.CALENDAR ? styles.selectedMenu : {} } />
                </Tooltip>
            } >{intl.get("CALENDAR")}</MenuItem>
            { this.state.currentUser.type == "professional" || this.state.currentUser.isAdmin  ? 
              <SubMenu title={intl.get('REPORTS')}
                      icon={ <TableChartIcon style={ this.state.selectedMenu==ACTIVE_PAGE.REPORTS ? styles.selectedMenu : {} } /> } >
                  <MenuItem onClick={() => this.onReportClicked(REPORT_TYPES.DAILY)} >{intl.get('DAILY_REPORT')}</MenuItem>
                  <MenuItem onClick={() => this.onReportClicked(REPORT_TYPES.MONTHLY)} >{intl.get('MONTHLY_REPORT')}</MenuItem>
                  <MenuItem onClick={() => this.onReportClicked(REPORT_TYPES.YEARLY)} >{intl.get('YEARLY_REPORT')}</MenuItem>
                  {/* <MenuItem onClick={() => this.onReportClicked('allClients')} >Bütün Hastalar</MenuItem> */}
              </SubMenu>
            :<div/>} 

            { this.state.currentUser.type == "professional" || this.state.currentUser.isAdmin  ? 
              <SubMenu  title={intl.get('CHARTS')}
                      icon={<p onClick={this.onOpenSidebar} style={styles.subMenu}> <PieChartIcon onClick={this.onOpenSidebar}  style={ this.state.selectedMenu==ACTIVE_PAGE.CHARTS ? styles.selectedMenu : {} } /></p>} >
                  <MenuItem onClick={() => this.onChartClicked(CHART_TYPES.MONTHLY_INCOME)} >{intl.get('MONTHLY_INCOME_CHART')}</MenuItem>
                  <MenuItem onClick={() => this.onChartClicked(CHART_TYPES.YEARLY_INCOME)} >{intl.get('YEARLY_INCOME_CHART')}</MenuItem>
                  <MenuItem onClick={() => this.onChartClicked(CHART_TYPES.GENDER)} >{intl.get('GENDER_CHART')}</MenuItem>
                  <MenuItem onClick={() => this.onChartClicked(CHART_TYPES.SOURCE)} >{intl.get('SOURCE_CHART')}</MenuItem>
              </SubMenu> 
            :<div/>} 

            {/* <MenuItem onClick={this.onCalendarClicked}  icon={
                <Tooltip title="Mesajlar">
                    <EmailIcon/>
                </Tooltip>
            } >Mesajlar</MenuItem> */}

            <Divider style={{backgroundColor:'gray', margin: '20 10'}}/>

            { false && (this.state.currentUser.type == "professional" || this.state.currentUser.isAdmin) ?  // disabled until forms works without error
              <MenuItem onClick={() => this.onMenuItemClicked(ACTIVE_PAGE.FORMS)}  icon={
                <Tooltip title={intl.get('FORMS_VIEW')}>
                    <FileCopyIcon style={ this.state.selectedMenu==ACTIVE_PAGE.FORMS ? styles.selectedMenu : {} } />
                </Tooltip>
              } >{intl.get('FORMS_VIEW')}</MenuItem>
             :<div/>} 

            { this.state.currentUser.type == "professional" || this.state.currentUser.isAdmin  ? 
              <MenuItem onClick={() => this.onMenuItemClicked(ACTIVE_PAGE.PROFESSIONALS)}  icon={
                  <Tooltip title={intl.get('PROFESSIONALS_VIEW')}>
                      <AccountCircleIcon style={ this.state.selectedMenu==ACTIVE_PAGE.PROFESSIONALS ? styles.selectedMenu : {} } />
                  </Tooltip>
              } >{intl.get('PROFESSIONALS_VIEW')}</MenuItem>
            :<div/>} 

            {/*
            { this.state.currentUser.type == "professional" || this.state.currentUser.isAdmin  ?
              <MenuItem onClick={ () => this.onMenuItemClicked(ACTIVE_PAGE.SECURITY) }  icon={
                  <Tooltip title={intl.get('SECURITY_VIEW')}>
                      <LockIcon style={ this.state.selectedMenu==ACTIVE_PAGE.SECURITY ? styles.selectedMenu : {} } />
                  </Tooltip>
              } >{intl.get('SECURITY_VIEW')}</MenuItem>
            :<div/>}
            */}

            <MenuItem onClick={() => this.onMenuItemClicked(ACTIVE_PAGE.ACCOUNT)}  icon={
                <Tooltip title={intl.get('ACCOUNT_VIEW')}>
                    <SettingsIcon style={ this.state.selectedMenu==ACTIVE_PAGE.ACCOUNT ? styles.selectedMenu : {} } />
                </Tooltip>
            } >{intl.get('ACCOUNT_VIEW')}</MenuItem>

            <MenuItem onClick={this.toggleConfirmDialog}  icon={
                <Tooltip title={intl.get('LOGOUT')}>
                    <PowerSettingsNewIcon/>
                </Tooltip>
            } >{intl.get('LOGOUT')}</MenuItem>


        </Menu>

        </ProSidebar>

        <ConfirmDialog
              dialogOpen={this.state.logoutDialogOpen}
              onClose={this.toggleConfirmDialog} 
              dialogTitle={intl.get('LOGOUT') + '?'} 
              dialogText={intl.get('DO_YOU_WANT_TO_LOGOUT')} 
              onCancel={this.toggleConfirmDialog} 
              cancelLabel={intl.get('CANCEL')}
              onConfirm={this.handleLogout} 
              confirmLabel={intl.get('LOGOUT')}
        ></ConfirmDialog>

        {/*  </MuiThemeProvider> */}
        </div>

    );
  }
}

export default withRouter(withStyles(styles)(SideBar));
