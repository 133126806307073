import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FormGroup, Button, IconButton } from '@material-ui/core';
import utils from '/helpers/utils';
import RefreshIcon from '@material-ui/icons/Refresh';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import intl from 'react-intl-universal';

// @connect(({ app }) => ({
//   selectedCustomer: app.selectedCustomer,
// }))

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

class DateSelectorBar extends React.Component {

    state = {
      selectedDate:utils.formatDate(new Date())
    };
    
    constructor(props){
        super(props);
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
        console.log("in handleChange : ", name, event.target.value)
    };
    
    onRefresh = () =>{
      this.props.onDateSelected(this.state.selectedDate);
    }

    onNext = () =>{
      let nDate = new Date(this.state.selectedDate);
      nDate.setDate(nDate.getDate()+1);
      this.setState({selectedDate:utils.formatDate(nDate)});
      this.props.onDateSelected(utils.formatDate(nDate));
    }

    onPrevious = () =>{
      let nDate = new Date(this.state.selectedDate);
      nDate.setDate(nDate.getDate()-1);
      this.setState({selectedDate:utils.formatDate(nDate)});
      this.props.onDateSelected(utils.formatDate(nDate));
    }

    render ()
    {
      return (
        <FormGroup row center style={{padding:15, justifyContent:'center', borderBottom: '1px solid lightgrey' }}>

          <Button variant="outlined" size="small" color="primary" style={{marginRight:30}} onClick={this.onPrevious}>
            <NavigateBeforeIcon /> {intl.get('PREVIOUS_DAY')} </Button>
            
          <TextField
            id="paymentDate"
            label={intl.get('DATE')}
            type="date"
            // defaultValue={this.state.selectedDate}
//                  className={classes.textField}
            InputLabelProps={{
                shrink: true,
            }}
            value={this.state.selectedDate}
            onChange={this.handleChange('selectedDate')}
          />
            {/* <Button size="small" color="primary" style={{marginLeft:16}} onClick={this.onRefresh}>
              <RefreshIcon /> Raporu Guncelle </Button> */}
            <IconButton onClick={this.onRefresh} style={{marginLeft:10}} color="primary" aria-label="Raporu Guncelle"> <RefreshIcon /> </IconButton>

              <Button variant="outlined" size="small" color="primary" style={{marginLeft:30}} onClick={this.onNext}>
              {intl.get('NEXT_DAY')}  <NavigateNextIcon /></Button>


        </FormGroup>



        )
    }

}

export default withStyles(styles)(DateSelectorBar);