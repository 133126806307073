import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Button, DialogActions, Toolbar} from '@material-ui/core';
import RestManager from '/persist/RestManager'
import utils from '/helpers/utils'
import intl from 'react-intl-universal';
import { setSelectedCustomer } from '../../redux/app'
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import LaunchIcon from '@material-ui/icons/Launch';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import crypt from '../../helpers/crypt';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

@connect(({ app }) => ({
  selectedCustomer: app.selectedCustomer,
}), {
  setSelectedCustomer,
})

class DocumentList extends React.Component {

    state = {
      selectedDocument:null,
      selectedIndex:-1,
      documents:null,
      addNoteMode:false,
      updateNoteMode:false,
      textAreaValue:"",
      dialogOpen:false,
    };
    
    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.reloadData();
        // this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount()
    {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
        this.reloadData();
        this.setState({addNoteMode: false, updateNoteMode:false, textAreaValue:"", selectedDocument:null});
      }
    }
  
    reloadData = () => {
      RestManager.getInstance().getDocuments(this.props.selectedCustomer.id,
        (result) => {
          // console.log("getDocuments result : ", result);
          let documents = result.response;
          if ( documents ) {
            for (let i = 0; i < documents.length; i++) {
              const element = documents[i];
              element = crypt.decryptEntity('document', element, this.props.selectedCustomer.id);
              documents[i] = element;
            }
            this.setState({ documents: documents });  
          }
        }
      );
    }
  
    onFileChange = event => {
      // Update the state
      var selFile = event.target.files[0];
      if ( selFile != this.state.selectedFile ) {
        this.setState({ selectedFile: selFile }, ()=>{
          this.onUpload();
        });  
      }
    };
    
    onUpload = () => 
    {
      if ( this.state.selectedFile ) {
        var filename = this.state.selectedFile.name ;
        // console.log("selectedFile : " ,  this.state.selectedFile);
        RestManager.getInstance().uploadFile(filename, this.state.selectedFile, this.props.selectedCustomer.id, null, (response)=>{
          this.reloadData();
        });
      }
    }

    handleClick(e) {
      this.refs.fileUploader.click();
    }

    // handleChange(event) {
    //   this.setState({value: event.target.value});
    // }
    handleChange = name => event => {
      this.setState({ [name]: event.target.value });
      // console.log("in handleChange : ", name, event.target.value)
    };

    onClicked = (index) =>
    {
      let selDocument = this.state.documents[index];
      this.setState({selectedIndex: index, selectedDocument:selDocument });
    }
  
    onDisplay = () =>
    {
      let selDocument = this.state.selectedDocument;
      if ( selDocument.documentType == 'plain') {
        this.setState({updateNoteMode:true, textAreaValue:selDocument.note});
      } else {
        RestManager.getInstance().downloadFile(this.props.selectedCustomer.id, selDocument.id, fileDec =>
          {
              var a = document.createElement("a");
              var url = window.URL.createObjectURL(fileDec);
              a.href = url;
              a.download = selDocument.documentName;
              a.click();
              window.URL.revokeObjectURL(url);
    
          });    
      }
    }

    onSaveNote = () => {
      var value = this.state.textAreaValue;
      var document = {};
      document.note = value;
      document.title = value.split('\n')[0];
      RestManager.getInstance().addNoteDocument( this.props.selectedCustomer.id, document, (response) =>
      {
          if ( response.success ) {
            this.reloadData();
            this.onCloseNote();
            this.setState({selectedIndex: -1, selectedDocument:null });
          }
      });
  
    }

    onUpdateNote = () => {
      var value = this.state.textAreaValue;
      var document = this.state.selectedDocument;
      document.note = value;
      document.title = value.split('\n')[0];
      RestManager.getInstance().updateNoteDocument( this.props.selectedCustomer.id, document, (response) =>
      {
          if ( response.success ) {
            this.reloadData();
            this.onCloseNote();
            this.setState({selectedIndex: -1, selectedDocument:null });
          }
      });
  
    }

    onDelete = () => 
    {
      RestManager.getInstance().deleteDocument( this.state.selectedDocument.id, (response) =>
      {
          if ( response.success ) {
            this.setState({selectedDocument:null});
            this.reloadData();
            this.onCloseNote();
            this.toggleConfirmDialog();
          }
      });
    }

    onAddNote = () => {
      this.setState({addNoteMode:true, textAreaValue:""});
    }

    onCloseNote = () => {
      this.setState({addNoteMode:false, updateNoteMode:false});
    }

    toggleConfirmDialog = () => {
      if ( this.state.selectedDocument || this.state.dialogOpen ) {
        this.setState({ dialogOpen: !this.state.dialogOpen });
      } 
    }
  
    render ()
    {
      const { classes} = this.props;
        
      
      if ( this.state.documents ) 
      {
        
        var rows = new Array(this.state.documents.length).fill(0).map( ( zero, i ) =>
          <div  style={{height:35}} className={this.state.selectedDocument && this.state.selectedDocument.id == this.state.documents[i].id ? 'custListItemSelected' : 'custListItem'} key={i} onClick={() => this.onClicked(i)}> 
            <div style={{marginLeft:10}}>
              <div className="column" >
              <InsertDriveFileIcon style={{fontSize:'1rem', paddingRight:20}} />
                <span >{this.state.documents[i] && this.state.documents[i].documentName ? this.state.documents[i].documentName : 
                  this.state.documents[i] && this.state.documents[i].title  ? this.state.documents[i].title  : "" } </span>
                {/* <span style={this.state.selectedDocument &&  this.state.selectedDocument.id == this.state.documents[i].id ? {} : {display: "none"} } >
                  <Button variant="outlined" size="small" color="primary" className={classes.margin} style={{ marginLeft: 30, backgroundColor:'white' }} onClick={() => this.onDisplay(i)}>Görüntüle</Button>
                </span> */}
              </div>
            </div>
          </div>
        )
        
        return (
          <div>

            <div>
              <input type="file" id="file" ref="fileUploader" onChange={this.onFileChange} style={{display: "none"}}/>

              <Toolbar style={{borderBottom:'1px solid lightgrey'}}>
                <div>
                    <Button variant="outlined" size="small" color="primary" className={classes.margin} style={{ marginRight: 6 }} onClick={this.handleClick}>
                    <AddIcon /> {intl.get('ADD_DOCUMENT')} </Button>
                      <Button variant="outlined" size="small" color="primary" className={classes.margin} style={{ marginRight: 6 }} onClick={this.onAddNote}>
                    <AddIcon /> {intl.get('ADD_NOTICE')} </Button>
                    {/* <Button variant="outlined" size="small" color="primary"  className={classes.margin} style={{ marginRight: 6 }} onClick={this.onEditNode}>
                      <EditIcon /> {intl.get('EDIT')} </Button> */}

                    <Button variant="outlined" size="small" color="primary" disabled={!this.state.selectedDocument} className={classes.margin} style={{ marginRight: 6 }} onClick={() => this.onDisplay()}>
                      <LaunchIcon/> {intl.get('VIEW_DOCUMENT')}</Button>

                    <Button variant="outlined" size="small" color="primary" disabled={!this.state.selectedDocument} className={classes.margin} onClick={this.toggleConfirmDialog}>
                      <DeleteIcon /> {intl.get('DELETE')} </Button>
                  </div>
              </Toolbar>

              {/* <h1>File Upload with Jersey</h1>                          
              <input type="file" onChange={this.onFileChange} />
              <button onClick={this.onUpload}>
                Upload!
              </button>
              <h1>Download from Jersey</h1>
              <button onClick={this.onDownload}>
                Download!
              </button> */}

            </div>
            
            { this.state.addNoteMode || this.state.updateNoteMode ? 
              <div style={{position:'relative'}}>

                <div className='row' style={{width:'750px'}}>
                  <DialogActions style={{justifyContent: 'center', background: '#dcdcdc59', borderBottom: '1px solid lightgray'}}>
                    <Button onClick={this.onCloseNote} color="primary">{intl.get('CANCEL')}</Button>
                      {this.state.updateNoteMode  ?
                        <div className={classes.wrapper}>
                          <Button onClick={this.onUpdateNote} disabled={this.state.loading} variant="contained" color="primary">{intl.get('UPDATE')}</Button>
                          {/* <CircularProgress size={24} className={classes.buttonProgress} /> */}
                        </div>
                        :
                        <div className={classes.wrapper}>
                          <Button onClick={this.onSaveNote} disabled={this.state.loading} variant="contained" color="primary">{intl.get('SAVE')}</Button>
                          {/* {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} /> } */}
                        </div>
                      }
    
                  </DialogActions>
                </div>

                <textarea
                  style={{height:300, width:730 ,  margin: 10, lineHeight: '1.5em', fontFamily: 'Roboto',  fontSize: '1em', padding: 10}}
                  value={this.state.textAreaValue}
                  onChange={this.handleChange('textAreaValue')}
                />

              </div>
            : ""}


            <div style={{position:'relative'}}>
              {rows}
            </div>

            <ConfirmDialog
              dialogOpen={this.state.dialogOpen}
              onClose={this.toggleConfirmDialog} 
              dialogTitle={intl.get("DELETE_DOCUMENT")}
              dialogText={intl.get("WANT_TO_DELETE_DOCUMENT")}
              onCancel={this.toggleConfirmDialog} 
              cancelLabel={intl.get('CANCEL')}
              onConfirm={this.onDelete} 
              confirmLabel={intl.get('DELETE')}
            ></ConfirmDialog>


          </div>
          );
      } 
      return <div style={{padding:10}} >{intl.get("LOADING_LIST")}</div>;
  


    }

}


export default withStyles(styles)(DocumentList);