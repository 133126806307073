import React, {Component} from 'react';
import Footer from '../components/Common/Footer';
import Header from '../components/Common/Header';

class NotFoundPage extends Component 
{
  constructor(props)
  {
    super(props);
  }


  render() 
  {
    return (
        <div style={{textAlign:"center"}}>
          
          <Header></Header>

          <div style={{textAlign:"center",fontSize:24, marginTop:150, color:'gray', marginBottom:50}}>
            Üzgünüz, böyle bir sayfa yok.<br></br><br></br>
          </div>

          <Footer></Footer>

        </div>
      );
    // }
  }

}


export default NotFoundPage;
