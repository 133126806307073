module.exports = [
{
  "COPYRIGHT":"© NSD Software - All rights reserved - {year}",
  "FIRSTNAME": "First Name",
  "LASTNAME": "Last Name",
  "EMAIL": "E-Mail",
  "PASSWORD": "Password",
  "CONFIRM_PASSWORD": "Confirm Password",
  "IDENTITY_NUMBER": "Identity Number",
  "PHONE":"Phone Number",
  "SUBMIT": "Submit",
  "FORGOT_PASSWORD": "Forgot my password",
  "CREATE_NEW_ACCOUNT": "Create New Account",
  "COMPANY": "Company",
  "BACK_TO_LOGIN_PAGE":"Back To Login Page",
  "CONTINUE":"Continue",
  "REGISTER":"Register",
  "WARNING":"Warning",
  "ERROR":"Error",
  "INFO":"Info",
  "MISSING_DATA":"Missing Data",
  "PLEASE_ENTER": "Please enter {missingData}.",
  "PLEASE_CONFIRM_PASSWORD":"Please confirm password.",
  "PASSWORDS_DO_NOT_MATCH":"Passwords do not match.",
  "PLEASE_WAIT":"Please wait.",
  "SYSTEM_ERROR":"System Error",
  "ADMINISTRATOR":"Administrator",

  "WRONG_EMAIL_OR_PASSWORD":"Wrong Email or Password",
  "COULD_NOT_LOGIN":"Could not login.",
  "EMAIL_SENT_SUCCESSFULLY":"E-Mail sent successfully",
  "PLEASE_CHECK_YOUR_INBOX":"Please check your inbox.",
  "EMAIL_SEND_UNSUCCESSFUL":"E-Mail could not be sent",
  "PLEASE_CONNECT_PRAXIS_TEAM":"Please connect Praxis Support Team.",
  "PLEASE_GO_BACK_TO_LOGIN":"Please go to Login Page",
  "PLEASE_LOGIN_AGAIN":"Please login again",

  "USER_WITH_THIS_EMAIL_ALREADY_EXISTS":"There is already a user with this E-Mail address.",
  "USER_CANNOT_BE_CREATED":"User can not be created.",

  "SERVER_ERROR":"Server Error",
  "COULD_NOT_CONNECT_TO_SERVER":"Could not connect to server.",
  "AUTHORIZATION_ERROR":"Authorization error",

  "ACCOUNT_CREATED":"Your account created.",
  "ACCOUNT_NOT_ACTIVE":"Account not active.",
  "ACTIVATING_ACCOUNT":"Activating your Account.",
  "ACCOUNT_ACTIVATED":"Your account has been activated.",
  "CONFIRMATION_LINK_SENT":"We have sent an email with a confirmation link to your email address.</br> If you don’t receive an email, be sure to check your spam folder.",
  "CLICK_CONFIRMATION_LINK":"In order to complete the sign-up process, please click the confirmation link in email.",
  "CAN_LOGIN_NOW":"You can now login with your credentials.",
  "RESEND_ACTIVATION_MAIL":"Resend activation email",
  "ACCOUNT_NOT_ACTIVATED":"Account could not be activated.",
  "NEW_ACTIVATION_MAIL_SENT":"New activation mail sent to you email address. </br> If you don’t receive an email, be sure to check your spam folder.",
  "WILL_SEND_ACTIVATION_CODE_PER_SMS":"We will send activation code per SMS.",

  "CRYPT_KEYWORD":"Encryption Keyword",
  "CRYPT_KEYWORD_AGAIN":"Encryption Keyword again",
  "YOUR_CRYPT_KEYWORD":"Your Encryption Keyword",
  "CREATE_NEW_CRYPT_KEYWORD":"Create new keyword",
  "KEYWORD_FOR_ENCRYPTION_TITLE":"Define Encryption Key",
  "PLEASE_READ_CAREFULLY":"We care about your sensible data. Please read carefully.",
  "PLEASE_ENTER_CRYPT_KEYWORD":"Please enter encryption keyword",
  "CRYPT_KEYWORDS_DO_NOT_MATCH":"Encryption Keywords do not match",
  "CRYPT_KEYWORD_AT_LEAST_EIGHT_CHARS":"Encryption Keyword must have at least 8 characters",
  "CONFIRM_YOUR_CRYPT_KEYWORD":"Do you confirm your encryption keyword?",
  "DO_NOT_FORGET_TO_NOTE_DOWN":"Please do not forget to note down your encryption keyword.",
  "I_CONFIRM_MY_CRYPT_KEYWORD":"I confirm my encryption keyword",

  "NEW_RECORD":"New Record",
  "SEARCH":"Seach",
  "LOADING_LIST":"Loading List...",
  "LOADING":"Loading...",

  "PATIENTS_VIEW":"Patients",
  "CALENDAR_VIEW":"Appointments",
  "REPORTS_VIEW":"Reports",
  "CHARTS_VIEW":"Charts",
  "ACCOUNT_VIEW":"My Account",
  "PAYMENT_VIEW":"Payment",
  "PROFESSIONALS_VIEW":"Doctors",
  "FORMS_VIEW":"Forms",
  "SECURITY_VIEW":"Data security / GDPR",

  "PATIENTS":"Patients",
  "CALENDAR":"Calendar",
  "REPORTS":"Reports",
  "CHARTS":"Charts",
  "DAILY_REPORT":"Daily Report",
  "MONTHLY_REPORT":"Monthly Report",
  "YEARLY_REPORT":"Yearly Report",
  "MONTHLY_INCOME_CHART":"Monthly Income ",
  "YEARLY_INCOME_CHART":"Yearly Income ",
  "GENDER_CHART":"Patient Gender",
  "SOURCE_CHART":"How did you find us",
  "MY_ACCOUNT":"My Account",
  "LOGOUT":"Logout",
  "DO_YOU_WANT_TO_LOGOUT":"Do you want to logout Praxis?",
  "DO_YOU_WANT_TO_DELETE":"Do you want to delete?",

  "OK":"OK",
  "CANCEL":"Cancel",
  "SAVE":"Save",
  "UPDATE":"Update",
  "EDIT":"Edit",
  "DELETE":"Delete",
  "NEW":"New",
  "YES":"Yes",
  "NO":"No",
  "CLOSE":"Close",
  "EXPAND":"Expand",
  "PLEASE_SELECT":"Please select",
  "SELECT":"Select",

  "PACKAGE_REST_TOTAL":"Package: Rest / Total",


  "NEW_PATIENT":"New Patient",
  "GENDER":"Gender",
  "MALE":"Male",
  "FEMALE":"Female",
  "NAME":"Name",
  "SURNAME":"Surname",
  "BIRTHDATE":"Birthdate",
  "NATIONAL_ID_NUMBER":"National ID Nummer",
  "FATHER_NAME":"Father Name",
  "SOURCE":"How did you find us?",
  "STREET":"Street",
  "CITY":"City",
  "COUNTRY":"Country",
  "COUNTY":"County",
  "POSTAL_CODE":"Postal Code",
  "AGE":"Age",

  "UNKNOWN":"Unknown",
  "WEBSITE":"Website",
  "SEARCH_ENGINE":"Search Engine",
  "FRIEND":"Friend",
  "SOCIAL_MEDIA":"Social Media",

  "APPOINTMENTS":"Appointments",
  "PACKAGE":"Package",
  "PACKAGES":"Packages",
  "ACTIVATE":"Activate",
  "DEACTIVATE":"Deactivate",

  "EXAMINATION":"Examination",
  "EXAMINATION_DATE":"Examination Date",
  "EXAMINATION_COUNT":"Examination Count",
  "PAYMENT":"Payment",
  "PAID":"Paid",
  "PAYMENT_DATE":"Payment Date",

  "WANT_TO_DELETE_PATIENT":"Do you really want to delete this patient?",
  "DELETE_PATIENT_MESSAGE":"All patient data will be deleted.",

  "FORMS":"Forms",
  "DELETE_FORM":"Delete Form",

  "DOCUMENTS":"Documents",
  "ADD_DOCUMENT":"Add Document",
  "ADD_NOTICE":"Add Notice",
  "VIEW_DOCUMENT":"View",

  "DELETE_DOCUMENT":"Delete document?",
  "WANT_TO_DELETE_DOCUMENT":"Do you really want to delete this document?",

  "PROFESSIONAL":"Doctor",
  "PROFESSIONALS":"Doctors",
  "ADD_PROFESSIONAL":"Add Doctor",
  "NEW_PROFESSIONAL_DATA":"New Doctor Data",
  "DELETE_PROFESSIONAL":"Delete Doctor?",
  "WANT_TO_DELETE_PROFESSIONAL":"Do you really want to delete this Docktor?",

  "ASSISTANT":"Assistant",
  "ASSISTANTS":"Assistants",
  "ADD_ASSISTANT":"Add Assistant",
  "NEW_ASSISTANT_DATA":"New Assistent Data",
  "DELETE_ASSISTANT":"Delete Assistant?",
  "WANT_TO_DELETE_ASSISTANT":"Do you really want to delete this Assistant?",

  "DATE_HOUR":"Date - Hour",
  "PRICE":"Price",
  "CASH":"Cash",
  "CREDIT":"Credit",
  "CREDIT_CARD":"Credit Card",
  "TRANSFER":"Transfer",
  "REST":"Rest",
  "NOTE":"Note",
  "TYPE":"Type",
  "SORT":"Sort",
  "TOTAL":"Total",
  "INCOME":"Income",
  "GROSS_INCOME":"Gross Income",
  "COLLECTED":"Collected",
  "RECEIVABLE":"Recevaible",

  "PROCESS_DATE":"Process Date",
  "NUMBER_OF_APPOINTMENTS":"Number of Appointments",
  "USED_APPOINTMENTS":"Used Appointments",
  "REST_APPOINTMENTS":"Rest Appointments",

  "TODAY":"Today",
  "BACK":"Back",
  "FORWARD":"Forward",
  "YEAR":"Year",
  "MONTH":"Month",
  "WEEK":"Week",
  "DAY":"Day",
  "AGENDA":"Agenda",
  "DATE":"Date",
  "TIME":"Time",
  "PREVIOUS_DAY":"Previous Day",
  "NEXT_DAY":"Next Day",
  "PREVIOUS_MONTH":"Previous Month",
  "NEXT_MONTH":"Next Month",
  "PREVIOUS_YEAR":"Previous Year",
  "NEXT_YEAR":"Next Year",

  "JANUARY":"January",
  "FEBRUARY":"February",
  "MARCH":"March",
  "APRIL":"April",
  "MAY":"May",
  "JUNE":"June",
  "JULY":"July",
  "AUGUST":"August",
  "SEPTEMBER":"September",
  "OCTOBER":"October",
  "NOVEMBER":"November",
  "DECEMBER":"December",

  "COMPANY_INFO":"Company Info",
  "BILL_INFO":"Billing Information",
  "CHANGE_PASSWORD":"Change Password",
  "CURRENT_PASSWORD":"Current Password",
  "NEW_PASSWORD":"New Password",
  "CONFIRM_NEW_PASSWORD":"Confirm New Password",
  "USER_SETTINGS":"User Settings",
  "USERS":"Users",
  "NEW_USER":"New User",
  "LICENCE_INFO":"Licence Information",
  "SMS_PACKAGES":"SMS Packages",
  "AGREEMENTS":"Agreements",
  "ACTIVE":"Active",
  "WANT_TO_DELETE_ACCOUNT":"I want to delete my account",
  "DELETE_ACCOUNT":"Delete My Account",
  "CONFIRM_DELETE_ACCOUNT":"Are you sure you want to delete your account? All patient data will be deleted. ",
  "CREATE_ACCOUNT_DESC":"You can create an account for your employee </br> To activate this account your employee should click the confirmation link in email we will send to his/her email address. ",
  "NEW_USER_FIRSTNAME":"New User First Name",
  "NEW_USER_LASTNAME":"New User Last Name",
  "NEW_USER_EMAIL":"New User's E-Mail Address",
  "PLEASE_FILL_THIS_FIELD":"Please fill this field",
  "CREATE_NEW_USER":"Create New User",


  "ANESTHESIOLOGY" : "Anesthesiology" ,
  "ENDOCRINOLOGY" : "Endocrinology",
  "BRAIN_SURGERY" : "Brain Surgery",
  "CARDIOLOGY" : "Cardiology",
  "CHEST_DISEASES" : "Chest Diseases",
  "CLINICAL_MICROBIOLOGY" : "Clinical Microbiology",
  "DERMATOLOGY" : "Dermatology",
  "EYE_DISEASES" : "Eye Diseases",
  "EAR_NOSE_AND_THROAT_DISEASES" : "Ear Nose and Throat Diseases",
  "FAMILY_MEDICINE" : "Family Medicine",
  "GENERAL_SURGERY" : "General Surgery",
  "GYNECOLOGY_AND_OBSTETRICS" : "Gynecology and Obstetrics",
  "INFECTIOUS_DISEASES" : "Infectious Diseases",
  "IMMUNOLOGY" : "Immunology",
  "INTERNAL_MEDICINE" : "Internal Medicine",
  "NEUROLOGY" : "Neurology",
  "NUCLEAR_MEDICINE" : "Nuclear Medicine",
  "NUTRITION_AND_DIET" : "Nutrition and Diet",
  "ONCOLOGY" : "Oncology",
  "ORAL_AND_DENTAL_DISEASES" : "Oral and Dental Diseases",
  "ORTHOPAEDICS" : "Orthopaedics",
  "PEDIATRICS" : "Pediatrics",
  "PHYSICAL_THERAPY_AND_REHABILITATION" : "Physical Therapy and Rehabilitation",
  "PLASTIC_SURGERY" : "Plastic Surgery",
  "PSYCHIATRY" : "Psychiatry",
  "PSYCHOLOGY" : "Psychology",
  "SPORTS_MEDICINE" : "Sports Medicine",
  "UROLOGY" : "Urology",
  "OTHER" : "Other",

  "CRYPT_KEY_EXPLANATION" : "We store your sensible data after encrypting it via a keyword which is defined by you. </br> We encrypt your data with AES-256 bit encryption method which is practically unbreakable. </br> Praxis-24 software developers, database administrators, company managers, employees and also the stuff of the hosting company could not access your data. </br> The only way to reach your data is to know the encrytion keyword. </br> Therefore you should not forget your encryption keyword and keep it in secure. </br> While logging first time on a new machine or after clearing the cache of the browser, you will need reenter your enryption keyword. </br> You could not change this keyword. </br> You could display your keyword on the machines you have logged in before. </br> Please define now your encrytion keyword and do not forget to notice it.",
            
    "SIMPLE": "Simple Sentence en",
    "HELLO": "Hello, {name}. Welcome to {where}!",
    "TIP": "This is <span style='color:red'>HTML</span>",
    "TIP_VAR": "This is <span style='color:red'>{message}</span>",
    "SALE_START": "Sale begins {start, date}",
    "SALE_END": "Sale ends {end, date, long}",
    "COUPON": "Coupon expires at {expires, time, medium}",
    "SALE_PRICE": "The price is {price, number, USD}",
    "PHOTO": "You have {photoNum, plural, =0 {no photos.} =1 {one photo.} other {# photos.}}",
    "MESSAGE_NOT_IN_COMPONENT": "react-intl-universal is able to internationalize message not in React.Component"
}
]  ;