import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import RestManager from '/persist/RestManager';

import queryString from 'query-string';
import Header from '../components/Common/Header';
import Footer from '../components/Common/Footer';
import { FormControl, InputLabel, Input, InputAdornment, IconButton, FormHelperText } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const styles = theme => ({
  textField: {
    width:'300px',
    margin:10
  },
  button:{
    margin:15
  },
});

class ActivationViaPassPage extends Component 
{
  constructor(props)
  {
    super(props);
    this.state={
      code:null,
      isActivating:false,
      isActivated:false,
      isActivationFailed:false,
      newPassword:"",
      confirmNewPassword:"",
      email:null,
      emailResent:false,
      emailResendFailed:false
    }  
    let params = queryString.parse(this.props.location.search)
    console.log("params = " , params.code)
    // console.log(window.location.host);
    if ( params.email ) {
      this.state.email = params.email;
    }
    if ( params.code ) {
      this.state.code = params.code;
    } 
    if ( ! params.email || ! params.code )
    {
      this.onBackToLogin();
    }
  }

  onActivate = () => 
  {
    if ( ! this.state.newPassword || ! this.state.confirmNewPassword ) {
      this.setState({changePassButtonClicked:true});
      return;
    }
    if ( this.state.newPassword  != this.state.confirmNewPassword ) {
      return;
    }

    this.state.isActivating = true;
    RestManager.getInstance().activateViaPass( this.state.code, this.state.newPassword, (result) => {
      if (result.ok == true ) {
        this.setState({ isActivated: true, isActivating: false});
      } else {
        this.setState({ isActivationFailed: true, isActivating: false });
      }
      console.log("result = ", result);
    });

  }

  onResendActivation = () => 
  {
    RestManager.getInstance().resendActivation( this.state.email, (result) => {
      this.setState({ isActivated: false, isActivating: false, isActivationFailed:false});
      if (result.ok == true ) {
        this.setState( {emailResent:true} );
        this.setState( {emailResendFailed:false} );
      } else {
        this.setState( {emailResendFailed:true} );
        this.setState( {emailResent:false} );
      }
       console.log("onResendActivation result = ", result);
    });
  }


  onBackToLogin = () => 
  {
    this.props.router.replace('/login')
  } 

  handleClickShowPassword = (password) => {
    this.setState({ [password] : ! this.state[password] });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() 
  {
    const { classes } = this.props;

    return (
      <div style={{textAlign:"center"}}>
        {/* <MuiThemeProvider> */}

          <Header></Header>

          <div style={{margin:'50 0 30 0' }}>

            { this.state.emailResent ?
                  <div style={{textAlign:"center",fontSize:24, marginTop:150, color:'gray', marginBottom:50}}>
                  Yeni aktivasyon mailiniz mail adresinize gönderildi.<br></br><br></br>
                </div>
            : <div></div> 
            }

            { this.state.emailResendFailed ?
                  <div style={{textAlign:"center",fontSize:24, marginTop:150, color:'gray', marginBottom:50}}>
                  Mail gönderimi başarısız oldu. <br></br><br></br>
                </div>
            : <div></div> 
            }

            { this.state.isActivating ?
                <div style={{textAlign:"center",fontSize:24, marginTop:100, color:'gray', marginBottom:50}}>
                  Hesabınız aktif hale getiriliyor.<br></br><br></br>
                  Lütfen bekleyiniz.           
                </div>
            : <div></div> 
            }

            { this.state.isActivated ?
                <div style={{textAlign:"center",fontSize:24, marginTop:100, color:'gray', marginBottom:50}}>
                  Hesabınız aktif hale getirildi.<br></br><br></br>
                  Giriş sayfasına dönerek email adresiniz ve şifrenizle giriş yapabilirsiniz.           
                </div>
            : <div></div> 
            }

            { this.state.isActivationFailed ?
                <div style={{textAlign:"center",fontSize:24, marginTop:100, color:'gray', marginBottom:50}}>
                  Hesabınız aktif hale getirilemedi.<br></br><br></br>
                  Aktivasyon kodu güncel olmayabilir. Lütfen aldığınız son maildeki linke tıklayınız ya da <br></br><br></br>
                  aktivasyon mailini tekrar gönder butonuna basarak işlemi tekrarlayınız.           
                </div>
            : <div></div> 
            }

            { ! this.state.isActivationFailed && ! this.state.isActivated && ! this.state.isActivating 
              && ! this.state.emailResent && ! this.state.emailResendFailed ?
              <div>

                <div style={{textAlign:"center",fontSize:24, marginTop:100, color:'gray'}}>
                        Lütfen kullanıcı şifrenizi belirleyiniz.<br></br><br></br>
                </div>

                <FormControl className={classes.textField}>
                  <InputLabel required htmlFor="new-password">Yeni Şifre</InputLabel>
                  <Input 
                    id='new-password'
                    error={this.state.newPassword == "" && this.state.changePassButtonClicked }
                    type={this.state.isNewPassVisible ? 'text' : 'password'}
                    value={this.state.newPassword}
                    onChange = {this.handleChange('newPassword')}
                    // className={classes.textField}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Şifre görünürlüğü"
                          onClick={ () => this.handleClickShowPassword('isNewPassVisible') }
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.isNewPassVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                    error={this.state.newPassword == "" && this.state.changePassButtonClicked }                  
                  >
                    {this.state.newPassword == "" && this.state.changePassButtonClicked ? 'Lütfen yeni şifrenizi giriniz. ' : '' }
                  </FormHelperText>
                </FormControl>
                  
                <br/>

                <FormControl className={classes.textField}>
                  <InputLabel required htmlFor="confirm-new-password">Yeni Şifre Tekrar</InputLabel>
                  <Input 
                    id='confirm-new-password'
                    error={ this.state.newPassword != this.state.confirmNewPassword && this.state.changePassButtonClicked}
                    type={this.state.isConfirmNewPassVisible ? 'text' : 'password'}
                    value={this.state.confirmNewPassword}
                    onChange = {this.handleChange('confirmNewPassword')}
                    // className={classes.textField}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Şifre görünürlüğü"
                          onClick={ () => this.handleClickShowPassword('isConfirmNewPassVisible') }
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.isConfirmNewPassVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                        error={ this.state.newPassword != this.state.confirmNewPassword && this.state.changePassButtonClicked}
                  >
                    {this.state.newPassword != this.state.confirmNewPassword && this.state.changePassButtonClicked ? 'Şifreler birbiri ile aynı değil.' : ''}
                  </FormHelperText>
                </FormControl>


                <div style={{textAlign:"center", marginTop:50, color:'gray', marginBottom:100}}>
                  <Button onClick={this.onActivate}  variant="contained" className={classes.button} color="primary">DEVAM</Button>
                </div>

              </div>
              : <div></div> 
            }
            { this.state.isActivationFailed || this.state.emailResendFailed ?
                <Button onClick={this.onResendActivation}  variant="outlined" style={styles.button} color="primary">AKTİVASYON MAİLİNİ TEKRAR GÖNDER</Button>
                : <div></div> 
            }
            { this.state.isActivated ?
              <Button onClick={this.onBackToLogin} variant="outlined" style={styles.button} color="primary">GİRİŞ SAYFASINA DÖN</Button>
              : <div></div> 
            }

          </div>
  
          <Footer></Footer>

        </div>
      );
    // }
  }


}

ActivationViaPassPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActivationViaPassPage);
