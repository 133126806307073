import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormGroup, Button, IconButton, InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import intl from 'react-intl-universal';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

class MonthSelectorBar extends React.Component {

    state = {
      selectedMonth:new Date().getMonth()+1,
      selectedYear:new Date().getFullYear(),
    };
    
    constructor(props){
        super(props);
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
        if ( name == 'selectedMonth') {
          this.props.onMonthSelected(event.target.value, this.state.selectedYear);
        }
        if ( name == 'selectedYear') {
          this.props.onMonthSelected(this.state.selectedMonth, event.target.value);
        }
        console.log("in handleChange : ", name, event.target.value)
    };
    
    onNext = () =>{
      let month = this.state.selectedMonth +1;
      let year = this.state.selectedYear;
      if ( month == 13 ){
        if ( year < new Date().getFullYear() ){
          month = 1;
          year++;
        } else {
          month--;
        }
      } 
      this.setState({
        selectedMonth:month, 
        selectedYear:year, 
      });
      this.props.onMonthSelected(month, year);
    }

    onPrevious = () =>{
      let month = this.state.selectedMonth - 1;
      let year = this.state.selectedYear;
      if ( month == 0 ){
        if ( year > new Date().getFullYear()-9 ){
          month = 12;
          year--;
        } else {
          month++;
        }
      } 
      this.setState({
        selectedMonth:month, 
        selectedYear:year, 
      });
      this.props.onMonthSelected(month, year);
    }

    render ()
    {
      let year = new Date().getFullYear();
      let yearItems = new Array(10).fill(0).map( ( zero, i ) =>
        <MenuItem value={year-i}>{year-i}</MenuItem>
      )
      return (
        <FormGroup row center style={{padding:15, justifyContent:'center', borderBottom: '1px solid lightgrey' }}>

          <Button variant="outlined" size="small" color="primary" style={{marginRight:30}} onClick={this.onPrevious}>
            <NavigateBeforeIcon /> {intl.get('PREVIOUS_MONTH')}  </Button>
            
            <FormControl >
              <InputLabel id="reportMonthPicker">{intl.get('MONTH')}</InputLabel>
              <Select
                labelid="reportMonthPicker"
                id="reportMonthPicker"
                value={this.state.selectedMonth}
                onChange={this.handleChange('selectedMonth')}
                style={{width:100}}
              >
                <MenuItem value={1}>{intl.get('JANUARY')}</MenuItem>
                <MenuItem value={2}>{intl.get('FEBRUARY')}</MenuItem>
                <MenuItem value={3}>{intl.get('MARCH')}</MenuItem>
                <MenuItem value={4}>{intl.get('APRIL')}</MenuItem>
                <MenuItem value={5}>{intl.get('MAY')}</MenuItem>
                <MenuItem value={6}>{intl.get('JUNE')}</MenuItem>
                <MenuItem value={7}>{intl.get('JULY')}</MenuItem>
                <MenuItem value={8}>{intl.get('AUGUST')}</MenuItem>
                <MenuItem value={9}>{intl.get('SEPTEMBER')}</MenuItem>
                <MenuItem value={10}>{intl.get('OCTOBER')}</MenuItem>
                <MenuItem value={11}>{intl.get('NOVEMBER')}</MenuItem>
                <MenuItem value={12}>{intl.get('DECEMBER')}</MenuItem>
              </Select>
            </FormControl>

            <FormControl style={{marginLeft:30}}>
              <InputLabel id="reportYearPicker">{intl.get('YEAR')}</InputLabel>
              <Select
                labelid="reportYearPicker"
                id="reportYearPicker"
                value={this.state.selectedYear}
                onChange={this.handleChange('selectedYear')}
              >
                {yearItems}
              </Select>
            </FormControl>

              <Button variant="outlined" size="small" color="primary" style={{marginLeft:30}} onClick={this.onNext}>
              {intl.get('NEXT_MONTH')}  <NavigateNextIcon /></Button>


        </FormGroup>



        )
    }

}

export default withStyles(styles)(MonthSelectorBar);