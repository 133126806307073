import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import intl from 'react-intl-universal';
import FormList from './FormList';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
});
class FormDialog extends React.Component {

  state = {
    selectedForm: null,
  }

  onItemClicked = (selForm) =>{
    this.setState({selectedForm: selForm });
  }
  
  onSelectClicked = () => {
    this.props.onSelect(this.state.selectedForm);
  }

  render() {
    return (
      <Dialog
        open={this.props.dialogOpen}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
        maxWidth='mx'
        >
          <DialogTitle id="alert-dialog-slide-title">
            Formlarım
          </DialogTitle>

          <DialogContent
            style={{height:600, width:1150}}
          >
            <FormList 
              onClose={this.props.onClose}
              onItemClicked={this.onItemClicked}
              forms={this.props.forms}
            />
          </DialogContent>


          <DialogActions style={{justifyContent:'center'}}>
            <Button onClick={this.props.onClose} color="primary">
              {intl.get('CANCEL')}
            </Button>
            <Button onClick={this.onSelectClicked} disabled={!this.state.selectedForm} variant="contained" color="primary">
              {intl.get('SELECT')}
            </Button>
          </DialogActions>

      </Dialog> 
    );
  }
}

export default withStyles(styles)(FormDialog);
