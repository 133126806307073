import React, { useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {Button, Toolbar, Menu, MenuItem, IconButton, FormControl, FormLabel, Select} from '@material-ui/core';
import RestManager from '/persist/RestManager'
import { setSelectedCustomer } from '../../redux/app'
import { connect } from 'react-redux';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import LeftIcon from '@material-ui/icons/ArrowBackIos';
import RightIcon from '@material-ui/icons/ArrowForwardIos';

import intl from 'react-intl-universal';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import utils from '/helpers/utils'
import FormDialog from './FormDialog';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import crypt from '../../helpers/crypt';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

@connect(({ app }) => ({
  selectedCustomer: app.selectedCustomer,
}), {
  setSelectedCustomer,
})

class Forms extends React.Component {

  state = {
    praxis : null,
    formViewer:null,
    formPrinter:null,
    activeForm:null,
    isPreviewMode:false,
    selectedFormData:null,
    lastSelectedFormData:null,
    selectedIndex:-1,
    lastSelectedIndex:-1,
    anchorEl: null,
    formDataArr : [],
    // forms:[],
    formDialogOpen:false,
    deleteDialogOpen:false,
    pdfViewerOpen:false,
};

  constructor (props) {
      super(props);  
      this.reloadData();
      // this.state.praxis = window(new PraxisApp());
  }    

  // componentWillMount() {
  //   this.unlisten = this.props.history.listen((location, action) => {
  //     if ( location ) {
  //       this.setFormDataFromUrl(location);
  //     }
  //   });
  // }
  // componentWillUnmount() {
  //     this.unlisten();
  // }
    
  // setFormDataFromUrl = (location) => 
  // {
  //   let search = location.search;
  //   console.log("search = " + search);
  //   let params = search.split("&");
  //   if ( params.length > 2 ) 
  //   {
  //     let res = params[1].split("=");
  //     if ( res.length > 1 & res[0] == "tab" && res[1] == "form" ) {
  //       let res = params[2].split("=");
  //       if ( res.length > 1 & res[0] == "formdata") {
  //         let id = res[1];
  //         console.log("FORMDATA ID FROM URL : ", id);
  //         for ( var i = 0; i < this.state.formDataArr.length; i++ ) {
  //           var formdata = this.state.formDataArr[i];
  //           if ( formdata.id == id ) {
  //             this.setState({selectedFormData:formdata, selectedIndex:i});
  //           }
  //         }
  //       }
  //     }
  //   }

  // }



  componentDidMount() {
//    var formViewer = window.njs_UIViewer;
    var formViewer = new DeparFramework.UIJustViewer()
    var formPrinter = window.njs_UIPrint;
    this.setState({formViewer: formViewer, formPrinter: formPrinter});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      this.reloadData();
      // this.setState({addNoteMode: false, updateNoteMode:false, textAreaValue:"", selectedDocument:null});
    }
  }

  reloadData = (callback) => {
    // RestManager.getInstance().getMyForms(
    //   (result) => {
    //     this.setState({ forms: result.response });
    //   }
    // );

    RestManager.getInstance().getAllFormDatas(this.props.selectedCustomer.id,
      (result) => {
        let formDataArr = result.response;

        // if ( formDataArr ) {
        //   for (let i = 0; i < formDataArr.length; i++) {
        //     const element = formDataArr[i];
        //     element = crypt.decryptEntity('formdata', element, this.props.selectedCustomer.id);
        //     formDataArr[i] = element;
        //   }
        // }

        this.setState({ formDataArr: formDataArr });
        // console.log("formDataArr = ", formDataArr);
        if ( formDataArr && formDataArr.length > 0 ) {
          this.setState({ selectedFormData: formDataArr[0], selectedIndex : 0 });
          this.loadFormData(formDataArr[0]);
        } else {
          this.setState({ activeForm:null, selectedFormData: null, selectedIndex : -1 });
          this.state.formViewer.clear();
        }
        if ( typeof callback == 'function' ) {
          callback();
        }
      }
    );
  }

  onNewForm = (form) => {
    var lastSelectedFormData = this.state.selectedFormData;
    var lastSelectedIndex = this.state.selectedIndex;
    this.setState({ lastSelectedFormData:lastSelectedFormData, lastSelectedIndex:lastSelectedIndex, selectedFormData: null, selectedIndex : -1 , activeForm:form}, ()=>
      {
        this.loadForm(form);
        this.onSaveFormData();
        this.toggleFormDialog();    
      });
  }

  onCancel = () =>{
    if ( this.state.lastSelectedFormData ) {
      this.setState({ selectedFormData: this.state.lastSelectedFormData, selectedIndex : this.state.lastSelectedIndex});
      this.loadFormData(this.state.lastSelectedFormData);
    } else {
      this.setState({ activeForm: null});
      this.state.formViewer.clear();
    }
  }
    
  loadForm = (form) => {
    this.state.formViewer.init("formPage_formsTab", form.formUI);
    this.handleMenuClose();
  }

  loadFormData = (formData) => {
    this.loadForm(formData.form);
    var jsonData  = "";
    if ( formData.jsonData && formData.jsonData != "" ) {
      jsonData  = JSON.parse(formData.jsonData);
    }
    this.state.formViewer.setFormData(jsonData);
  }

  onDeleteFormData = () => {
    if ( this.state.selectedFormData ) {
      RestManager.getInstance().deleteFormData( this.props.selectedCustomer.id, this.state.selectedFormData.id, (response) =>
      {
        this.reloadData();
        this.setState({ deleteDialogOpen: false });
      });

    }
  }

  onSaveFormData = () => 
  {
    var formData = this.state.formViewer.getFormData();
// console.log("formData", formData);
    if ( this.state.selectedFormData ) {
      // var newFormData = this.state.selectedFormData;
      // Object.keys(formData).forEach((key, index) => {
      //   newFormData[key] = formData[key];
      // });
      // formData.id = this.state.selectedFormData.id;

      var newFormData = this.state.selectedFormData;
      newFormData.jsonData = formData;
      // console.log("formdata = ", formData);
      RestManager.getInstance().updateFormData( this.props.selectedCustomer.id, newFormData, this.state.selectedFormData.examinationId, (response) =>
      {
        // console.log("response = ", response);
        if ( response.success ) {
          var lastSelectedIndex = this.state.selectedIndex;
          this.reloadData(()=>{
            this.onFormDataClicked(lastSelectedIndex);
          });

        }
      });
    } 
    else
    {  
      var newFormData = {};
      newFormData.jsonData = formData;
      RestManager.getInstance().addFormData( this.props.selectedCustomer.id, newFormData, this.state.activeForm.id, null, (response) =>
      {
        // console.log("response = ", response);
        if ( response.success ) {
          this.reloadData();
        }
      });
    }     

  }

  onFormDataClicked = (index) =>
  {
    let selFormData = this.state.formDataArr[index];
    this.setState({selectedIndex: index, selectedFormData:selFormData});
    this.loadFormData(selFormData);
  //   RestManager.getInstance().getFormData(this.props.selectedCustomer.id, selFormData.id, (response) =>
  //   {
  //     console.log("response = ", response);
  //     if ( response.success ) {

  //     }
  //   });

  }

  onFormDataBack = () => 
  {
    if ( this.state.selectedIndex > 0  ) {
      var index = this.state.selectedIndex - 1;
      this.onFormDataClicked(index);
    }    
  }

  onFormDataForward = () => {
    if ( this.state.selectedIndex + 1 < this.state.formDataArr.length ) {
      var index = this.state.selectedIndex + 1;
      this.onFormDataClicked(index);
    }    
  }

  onPrintForm = () => {
    if ( this.state.selectedFormData ) {
      this.setState({ pdfViewerOpen: true });

      var jsonData  = JSON.parse(this.state.selectedFormData.jsonData);
      var formUI  = JSON.parse(this.state.selectedFormData.form.formUI);

      this.state.formPrinter.createPdf(formUI, jsonData);
    }
  }

  onClosePrintPreview= () => {
      this.setState({ pdfViewerOpen: false });
      this.reloadData();
    }


  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleFormDialog = () => {
    this.setState({ formDialogOpen: ! this.state.formDialogOpen });
  }

  toggleDeleteDialog = () => {
    this.setState({ deleteDialogOpen: ! this.state.deleteDialogOpen });
  }

  render() 
  {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);



    if ( this.state.formDataArr && this.state.formDataArr.length>0) 
    {
      var formDatas = this.state.formDataArr.map( ( formData, i ) =>
        <MenuItem
          value={formData}
          key={i}
          onClick= {() => this.onFormDataClicked(i)}
        >
          {utils.humanDate(utils.formatDate(formData.insertDate))} - {formData.form.formName }
        </MenuItem>
      )
    }

    // if ( this.state.forms && this.state.forms.length>0) 
    // {
    //   var forms = this.state.forms.map( ( form, i ) =>
    //     <MenuItem
    //       value={form}
    //       key={i}
    //       onClick= {() => this.onNewForm(form)}
    //     >
    //       {form.formName}
    //     </MenuItem>
    //   )

    // }

      return (

        <div>

          { this.state.pdfViewerOpen == false ? 
            <Toolbar style={{borderBottom:'1px solid lightgrey'}}>
              <div>

              { ! this.state.activeForm  || this.state.selectedFormData ?
                <Button aria-owns={open ? 'menu-appbar' : undefined} aria-haspopup="true" variant="outlined" size="small" color="primary" className={classes.margin} style={{ marginRight: 6 }} onClick={this.toggleFormDialog}>
                  <AddIcon />Yeni Form Ekle </Button>
                :""}

                <FormDialog
                  dialogOpen={this.state.formDialogOpen}
                  onSelect={this.onNewForm}
                  onClose={this.toggleFormDialog} 
                  // forms = {this.state.forms}
                ></FormDialog>

                {/* <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'left',}} transformOrigin={{ vertical: 'top', horizontal: 'left',}}
                    open={open} onClose={this.handleMenuClose}>
                  {forms}
                </Menu> */}

                { this.state.activeForm  || this.state.selectedFormData ? 
                <Button variant="outlined" size="small" color="primary" className={classes.margin} style={{ marginRight: 6 }} onClick={this.onSaveFormData}>
                  <SaveIcon/> {intl.get("SAVE")}
                </Button>
                : "" }

                {this.state.selectedFormData ?
                  <Button variant="outlined" size="small" color="primary" className={classes.margin} style={{ marginRight: 6 }} onClick={this.toggleDeleteDialog}>
                    <DeleteIcon/>  {intl.get("DELETE")}
                  </Button>
                :
                  this.state.activeForm ? 
                    <Button onClick={this.onCancel} className={classes.margin} variant="outlined" size="small" color="secondary">İptal</Button>
                  : ""
                  
                }

                {/* <Button variant="outlined" size="small" color="primary" className={classes.margin} style={{ marginRight: 6, marginLeft:30 }} onClick={this.onPrintForm}>
                  Yazdır
                </Button> */}

              </div>
            </Toolbar>
          :
            <Toolbar>
              <Button variant="outlined" size="small" color="secondary" className={classes.margin} onClick={this.onClosePrintPreview}>
                Kapat
              </Button>
            </Toolbar>

          }

          { this.state.selectedFormData && this.state.pdfViewerOpen == false ? 
              <Toolbar style={{borderBottom:'1px solid #efefef'}}>
              <FormLabel >Formlar : </FormLabel>

              <Button variant="flat" size="small" style={{width:20, minWidth:0, padding:'6 12 6 20'}} onClick={this.onFormDataForward}>
                  <LeftIcon color="primary" /> 
                </Button>
                <FormLabel >{this.state.formDataArr.length-this.state.selectedIndex} / {this.state.formDataArr.length}</FormLabel>
                <Button variant="flat" size="small" style={{width:20, minWidth:0}} onClick={this.onFormDataBack}>
                  <RightIcon color="primary" /> 
                </Button>

              <FormControl >
                <Select style={{width:'100%', maxWidth:400}}
                  labelid="sourcePicker"
                  id="sourcePicker"
                  value={this.state.selectedFormData}
                  // onChange={this.handleChange('source')}
                  className={classes.textField}
                >
                  {formDatas}
                </Select>
              </FormControl>

              </Toolbar>
          
          :""}


          {/* {rows} */}


          {/* <div  style={{ minWidth:750, width:750, overflowX:'scroll'}} > */}
          <div>

              { this.state.pdfViewerOpen == true  ? 
                <iframe id="pdf" style={{width: '100%', height: 800}}></iframe>
                :
                <div id = "formPage_formsTab" style={{width:'100%', maxWidth:900, fontSize:'14px', margin:'0 20 500 10'}} ></div>
              }


          </div>
          {/* </div>               */}


            <ConfirmDialog
              dialogOpen={this.state.deleteDialogOpen}
              onClose={this.toggleDeleteDialog} 
              dialogTitle={intl.get('DELETE') + '?'} 
              dialogText={intl.get('DO_YOU_WANT_TO_DELETE')} 
              onCancel={this.toggleDeleteDialog} 
              cancelLabel={intl.get('CANCEL')}
              onConfirm={this.onDeleteFormData} 
              confirmLabel={intl.get('DELETE')}
            ></ConfirmDialog>

          </div>

      )
        
    }

}


export default withRouter(withStyles(styles)(Forms));