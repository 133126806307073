import React from 'react';
import { Autocomplete, Button, TextField, FormControl, Radio, RadioGroup, FormControlLabel, InputLabel, Input, DialogTitle, DialogActions, Checkbox, FormGroup, Select, MenuItem} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import utils from '/helpers/utils'
import intl from 'react-intl-universal';
import PhoneMask from '../../helpers/PhoneMask';
import MessageBox from '../Dialogs/MessageBox';
import crypt from '../../helpers/crypt';
import DBManager from '../../persist/DBManager';
import MedicalSpecialitiesList from './MedicalSpecialitiesList';
import MedicalSpecialitiesDialog from './MedicalSpecialitiesDialog';

class PersonnelForm extends React.Component {

  state = {
    personnel:
    {
      id : null,
      title: "",
      gender: "1",
      firstname: "",
      lastname: "",
      birthdate: "",
      phone: "",
      email: "",
      cryptKey:"customKey",
      customerDocumentsVisible:false,
      reportsVisible:false,
      chartsVisible:false,
      medicalSpeciality:null,
    },
    loading:false,
    alertDialogOpen:false,
    alertTitle:"",
    alertText:"",
    specialitiesDialogOpen:false,
};

  
  constructor (props) {
    super(props);
    this.updateState();  
  }    

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedPersonnel !== this.props.selectedPersonnel) {
        this.updateState();
    }
  }

  onSave = () => 
  {
    if ( this.checkValues() ) {
      this.setState({loading: true});
      const p = this.state.personnel;
      if ( p.birthdate ) {
        p.birthdate = utils.UTC2GMT(p.birthdate);
      }
      this.props.onSave(p);
    }
  }


  onUpdate = () => 
  {
    if ( this.checkValues() ) {
      this.setState({loading: true});
      const p = this.state.personnel;
      if ( p.birthdate ) {
            p.birthdate = utils.UTC2GMT(p.birthdate);
      }
      this.props.onUpdate(p);
    }
  }

  checkValues = () => {
    var alertText = "Lütfen Ad, Soyad, E-Posta bilgilerini giriniz";
    if ( this.props.isProfessional == true ) {
      var alertText = "Lütfen Ad, Soyad, E-Posta, Anahtar Kelime, Uzmanlık Alanı bilgilerini giriniz.";      
    }
    if ( ! this.props.isFirstProfessional || ! this.state.personnel.medicalSpeciality ) {
      if ( ! this.state.personnel.firstname || this.state.personnel.firstname == "" || ! this.state.personnel.lastname || this.state.personnel.lastname == ""
       || ! this.state.personnel.email || this.state.personnel.email == "" 
       || ( this.props.isProfessional == true && ! this.state.personnel.medicalSpeciality )
//       || ( this.props.isProfessional == true && ! this.props.isEditMode && this.state.personnel.cryptKey == "")
       ){
        this.setState({alertDialogOpen:true, alertText:alertText, alertTitle:'Eksik veri' });
        return false;
      }   
    }
    return true;
  }

  updateState = () =>
  {
    if ( this.props.selectedPersonnel ) {
      var np = this.state.personnel;
      for (const key in np) {
        console.log(key+ ':' +this.props.selectedPersonnel[key]);
        let value = this.props.selectedPersonnel[key];
        if ( key == "birthdate" ) {
          value = utils.formatDate(value);
        }
        np[key] = value ? value : "" ;
      }
      var user = this.props.selectedPersonnel.user;
      if ( user ) {
        np.customerDocumentsVisible = user.customerDocumentsVisible;
        np.reportsVisible = user.reportsVisible;
        np.chartsVisible = user.chartsVisible;
      }

      this.setState({personnel : np});
      console.log('this.state.personnel = ', this.state.personnel);
    } else {
      var np = this.state.personnel;
      for (const key in np) {
        np[key] = "" ;
        np.gender="1";
      }
      this.setState({personnel : np});

    }
  }
  
  onClose = () => {
    this.props.onClose();
  }

  handleDialogClose = () =>  {
    this.setState({ alertDialogOpen: false });
  }

  handleChange = name => event => {
    let newVal = event.target.value;
    if ( name == 'customerDocumentsVisible' || name == 'reportsVisible' || name == 'chartsVisible') {
      newVal = event.target.checked ;
    } 
    if (  Object.getOwnPropertyNames(this.state.personnel).includes(name) ) {
      this.setState(prevState => ({
        personnel: { ...prevState.personnel, [name]: newVal }
      }))
    } else {
      this.setState({ [name]: event.target.value });
    }
  };

  handleCheckbox = (event) =>
  {
    this.setState({ ...this.state, [event.target.name]: event.target.checked } )
  }

  setSpeciality = (medSpeciality) => {
    this.setState(prevState => ({
      personnel: { ...prevState.personnel, medicalSpeciality: medSpeciality }
    }))
    this.toggleMedSpecialitiesDialog();
  }

  toggleMedSpecialitiesDialog = () => {
    this.setState({ specialitiesDialogOpen: ! this.state.specialitiesDialogOpen });
  }

  render() 
  {    
    // Professionals List //
    // if ( ! this.props.isProfessional && this.props.professionals )
    // {
    //   var rows = new Array(this.props.professionals.length).fill(0).map( ( zero, i ) =>
    //     <div className='row' style={{margin:'0 20'}}>
    //       <FormControlLabel
    //         control={<Checkbox checked={this.state[this.props.professionals[i].id]} onChange={this.handleCheckbox} name={this.props.professionals[i].id} color="primary"/>}
    //         label={this.props.professionals[i].firstname + ' ' + this.props.professionals[i].lastname }
    //       />
    //     </div>
    //   )
    // }
    // Professionals List //


    // this.updateState();
    const { classes } = this.props;
    const { inputRef, ...other } = this.props;

    // const custCryptKey = crypt.getCustomerCryptKey("....................");
    const companyCryptKey = crypt.getCompanyCryptKey();

    return (
      <div style={{backgroundColor:'aliceblue', border:'1px solid lightgray'}}>
          { this.props.selectedPersonnel && this.props.isEditMode ? 
            <DialogTitle style={{borderBottom:'1px solid lightgray', marginBottom:'20px'}} id="form-dialog-title">{this.props.selectedPersonnel.firstname + " " + this.props.selectedPersonnel.lastname}</DialogTitle>
          :          
          <DialogTitle style={{borderBottom:'1px solid lightgray', marginBottom:'20px'}} id="form-dialog-title">{this.props.title}</DialogTitle>
          }

              <div className='row' style={{minWidth:'500px', marginLeft:'20px'}}>
                {/* <FormLabel component="legend">Cinsiyet</FormLabel> */}
                <RadioGroup
                  name="gender"
                  value={this.state.personnel.gender+""}
                  onChange={this.handleChange('gender')}
                  row
                >
                  <FormControlLabel value="1" control={<Radio selected />} label={intl.get('MALE')} />
                  <FormControlLabel value="2" control={<Radio />} label={intl.get('FEMALE')} />
                </RadioGroup>
              </div>

              <div className='row' style={{margin:'20px'}}>
                {this.props.isProfessional == true? 
                  <div className='column'  >
                    <TextField
                          id="standard-title"
                          label={'Ünvan'}
                          value={this.state.personnel.title}
                          className={classes.textField}
                          onChange={this.handleChange('title')}
                          />
                  </div>
                : ""}
                <div className='column'  >
                  <TextField
                    required
                    id="standard-firstname"
                    disabled={this.props.isFirstProfessional}
                    label={intl.get('NAME')}
                    value={this.props.isFirstProfessional ? this.props.currentUser.firstname : this.state.personnel.firstname}
                    className={classes.textField}
                    onChange={this.handleChange('firstname')}
                  />
                </div>
                <div className='column' >
                  <TextField
                    required
                    id="standard-lastname"
                    disabled={this.props.isFirstProfessional}
                    label={intl.get('SURNAME')}
                    value={this.props.isFirstProfessional ? this.props.currentUser.lastname : this.state.personnel.lastname}
                    className={classes.textField}
                    onChange={this.handleChange('lastname')}
                  />
                </div>
            </div>

            <div className='row' style={{margin:'20px'}}>
              <div className='column'  >
                <FormControl className={classes.textField}> 
                  <InputLabel htmlFor="formatted-phone">{intl.get('PHONE')}</InputLabel>
                  <Input
                    value={this.props.isFirstProfessional ? this.props.currentUser.phone : this.state.personnel.phone}
                    disabled={this.props.isFirstProfessional || this.props.isEditMode || ( this.props.selectedPersonnel && this.props.selectedPersonnel.user && this.props.selectedPersonnel.user.isAdmin) }
                    onChange={this.handleChange('phone')}
                    id="formatted-phone"
                    inputComponent={PhoneMask}
                  />  
                </FormControl>
              </div>

              <div className='column' >
                <TextField
                  required
                  id="standard-email"
                  label={intl.get('EMAIL')}
                  disabled={this.props.isFirstProfessional || this.props.isEditMode || ( this.props.selectedPersonnel && this.props.selectedPersonnel.user && this.props.selectedPersonnel.user.isAdmin) }
                  value={this.props.isFirstProfessional ? this.props.currentUser.username : this.state.personnel.email}
                  className={classes.textField}
                  onChange={this.handleChange('email')}
                  // style = {{width: 200}}
                  // margin="normal"
                />
              </div>

              <div className='column' >
                <TextField
                  id="date"
                  label={intl.get('BIRTHDATE')}
                  type="date"
                  format="dd-mm-YYYY"
                  // defaultValue="2017-05-24"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.personnel.birthdate}
                  // value="1975-08-15"
                  onChange={this.handleChange('birthdate')}
                />
              </div>

            </div>

            {this.props.isProfessional == true? 
              <div className='row' style={{margin:'20px'}}>
                <TextField
                    required
                    id="medical-psecialities"
                    label={"Uzmanlık Alanı"}
                    value={ this.state.personnel.medicalSpeciality ?  intl.get(this.state.personnel.medicalSpeciality.specialityCode) : ""}
                    className={classes.textField}
                    // onChange={this.handleChange('medicalSpeciality')}
                    onClick={ this.toggleMedSpecialitiesDialog }
                    style={{width:420}}
                />
                <MedicalSpecialitiesDialog
                  dialogOpen={this.state.specialitiesDialogOpen}
                  onSelect={this.setSpeciality}
                  onClose={this.toggleMedSpecialitiesDialog} 
                ></MedicalSpecialitiesDialog>
              </div>
            :""}



            { this.props.isProfessional != true ? 
            
              <div>

                {/* <DialogTitle style={{borderBottom:'1px solid lightgray', marginBottom:'20px'}} id="title-permissions">İzinler</DialogTitle> */}
                {/* <div className='row' style={{margin:'40 20 0 20'}}>
                  <FormControlLabel
                    control={<Checkbox checked={this.state.personnel.customerDocumentsVisible} onChange={this.handleChange('customerDocumentsVisible')} name="customerDocumentsVisible" color="primary"/>}
                    label={'Hasta/Danışan dokümanlarını görüntüleyebilir'}
                  />
                </div> */}
                
                {/* <div style={{borderBottom:'1px solid lightgrey', marginBottom:30}}>                  
                  {rows}
                </div> */}

                {/* <div className='row' style={{margin:'0 20'}}>
                  <FormControlLabel
                    control={<Checkbox checked={this.state.personnel.reportsVisible} onChange={this.handleChange('reportsVisible')} name="reportsVisible" color="primary"/>}
                    label={'Raporları görüntüleyebilir'}
                  />
                </div>
                <div className='row' style={{margin:'0 20'}}>
                  <FormControlLabel
                    control={<Checkbox checked={this.state.personnel.chartsVisible} onChange={this.handleChange('chartsVisible')} name="chartsVisible" color="primary"/>}
                    label={'Grafikleri görüntüleyebilir'}
                  />
                </div> */}
              </div>
              :''}


            {/*
            { this.props.isFirstProfessional != true && ! this.props.isEditMode  && this.props.isProfessional == true ?
              <div className={classes.infoLabel} style={{margin:'30 20'}} >
                  <span>
                      {intl.get("CRYPT_KEYWORD")}: 
                    </span>
                    <span>
                      <Select style={{ marginLeft:10, backgroundColor:'white', width:350}}
                          labelid="cryptKeyPicker"
                          id="cryptKeyPicker"
                          displayEmpty
                          value={this.state.personnel.cryptKey}
                          onChange={this.handleChange('cryptKey')}
                          className={classes.textField}
                        >
                          <MenuItem value="" disabled> {intl.get("PLEASE_SELECT")} </MenuItem>
                          <MenuItem value={'companyCryptKey'} > {companyCryptKey}</MenuItem>
                          <MenuItem value={'customKey'} > <em>{intl.get("CREATE_NEW_CRYPT_KEYWORD")}</em></MenuItem>
                      </Select>
                    </span>
                    <br></br>
                    <br></br><br></br>
              </div>
            : ""}
            */}


            { this.props.isProfessional == true ? 
              this.props.isFirstProfessional == true ? 
                <div className={classes.infoLabel} style={{margin:'30 20'}}>
                  İlk Doktor girişiniz mevcut hesabınız ile ilişkilendirilecektir.<br></br>
                  Mevcut Hasta/Danışan kayıtları bu doktor altına alınacaktır.
                </div>
                :
                <div>

                </div>              
            :
              ! this.props.isEditMode ?
                <div className={classes.infoLabel} style={{margin:'30 20' }}>
                  Asistanınızın Praxis-24'e giriş yapabilmesi için yeni bir kullanıcı oluşturulacaktır. <br></br>
                  Asistanınız E-Posta adresine göndereceğimiz link'e tıklayarak hesabını aktif hale getirmelidir.<br></br>

                </div>
              : ""
            }


            <div className='row' style={{width:'670px', margin:'20px'}}>
              <DialogActions  style={{ justifyContent:'center'}}>
                <Button onClick={this.onClose} color="primary">{intl.get('CANCEL')}</Button>
                {this.props.selectedPersonnel && this.props.isEditMode ?
                  <Button onClick={this.onUpdate} disabled={this.state.loading} variant="contained" color="primary">{intl.get('UPDATE')}</Button>
                  :
                  <Button onClick={this.onSave} disabled={this.state.loading} variant="contained" color="primary">{intl.get('SAVE')}</Button>
                }
              </DialogActions>
            </div>
      
            <MessageBox
              alertDialogOpen={this.state.alertDialogOpen}
              onClose={this.handleDialogClose} 
              alertTitle={this.state.alertTitle} 
              alertText={this.state.alertText} >
            </MessageBox>

      </div>
    );
  }
}

const styles = theme => ({
  textField: {
    marginRight: 20,
    width: 200,
  },
  infoLabel: {
    fontSize: '1.2em',
  }

});
export default withStyles(styles)(PersonnelForm);
