import {
	eventName,
	underscoredToCamelCase,
	DEFAULT_REDUX_EVENT_NAMING,
	generateReduxNamespace,
	generateReduxEventName
} from './naming'

import {
	RESULT_ACTION_PROPERTY,
	ERROR_ACTION_PROPERTY
} from './middleware/asynchronous'

// Sometimes modules for one project are imported from another project directory.
// For example, Redux actions from one project may be imported into another project.
// In such cases `Counter` is not enough because Redux event names would collide
// because actions from the first project use different `node_modules` and therefore
// use different `react-website` modules having their own `Counter`.
// In such cases it's mandatory to pass Redux event name to `.action()` and `.simpleAction()`.
// Otherwise there will be Redux event name collisions.
// Importing packages from two different `node_modules` is not a good practice.

// Deprecated. Use `new ReduxModule()` instead.
export function createReduxModule(namespace, settings)
{
	return new ReduxModule(namespace, settings)
}

export default class ReduxModule
{
	handlers = {}
	registered_state_properties = []

	constructor(namespace = generateReduxNamespace(counter.next()), settings = {})
	{
		this.namespace = namespace

		this.settings = {
			reduxEventNaming: settings.reduxEventNaming || DEFAULT_REDUX_EVENT_NAMING,
			reduxPropertyNaming: settings.reduxPropertyNaming || underscoredToCamelCase
		}
	}

	replace(event, handler)
	{
		if (!Array.isArray(handler))
		{
			handler = [handler]
		}

		this.handlers[event] = handler
	}

	on(namespace, event, handler)
	{
		if (typeof event === 'function')
		{
			handler = event
			event = namespace
			namespace = undefined
		}
		else
		{
			// Use "success" event name.
			event = this.settings.reduxEventNaming(eventName(namespace, event))[1]
		}

		if (!this.handlers[event])
		{
			this.handlers[event] = []
		}

		this.handlers[event].push(handler)
	}

	action(event, action, result, options)
	{
		if (event && typeof event !== 'string') {
			options = result
			result = action
			action = event
			event = undefined
		}

		// Autogenerate `event` name.
		if (!event) {
			event = generateReduxEventName(counter.next())
		}

		options = options || {}

		if (typeof action !== 'function') {
			throw new Error('[react-website] One must pass an `action()` argument (the second one) to Redux module action creator: `reduxModule(event, action, result, options = {})`.')
		}

		return create_action(event, action, result, options, this)
	}

	simpleAction(event, action, result, options)
	{
		if (event && typeof event !== 'string') {
			options = result
			result = action
			action = event
			event = undefined
		}
		// `action` argument is currently a useless one:
		// taking arguments and converting them into an object.
		// This feature substitutes a missing `action` argument with a passthrough.
		// Deprecated: remove `action` argument in some future major version.
		if (action) {
			if (typeof result !== 'string' && typeof result !== 'function') {
				options = result
				result = action
				action = object => object
			}
		}
		options = options || {}
		options.sync = true
		return this.action(event, action, result, options)
	}

	// Returns Redux action creator for resetting error.
	resetError(event)
	{
		const
		[
			pending_event_name,
			success_event_name,
			error_event_name
		]
		= this.settings.reduxEventNaming(event)

		// Redux "action creator"
		return () =>
		({
			type  : eventName(this.namespace, error_event_name),
			error : undefined
		})
	}

	// Deprecated.
	getProperties = (state) =>
	{
		const properties = {}

		for (const property_name of this.registered_state_properties)
		{
			properties[property_name] = state[property_name]
		}

		return properties
	}

	createReducer(initialState = {}) {
		// Applies a handler based on the Redux action `type`.
		// (is copy & paste'd for all action response handlers)
		return (state = initialState, event = {}) => {
			try {
				for (const handler of (this.handlers[event.type] || [])) {
					state = handler(state, this.getEventData(event))
				}
				return state
			} catch (error) {
				// For some strange reason Redux didn't report
				// these errors to the console, hence the manual `console.error`.
				console.error(error)
				throw error
			}
		}
	}

	reducer(initialState = {}) {
		const reducer = this.createReducer(initialState)
		// The event names list is used for autogenerated
		// event names conflict detection.
		// See `checkForAutogeneratedEventNameCollision()`.
		reducer._react_website_events = Object.keys(this.handlers)
		return reducer
	}

	getEventData(event) {
		if (Object.prototype.hasOwnProperty.call(event, RESULT_ACTION_PROPERTY)) {
			return event[RESULT_ACTION_PROPERTY]
		} else if (event[ERROR_ACTION_PROPERTY] !== undefined) {
			return event[ERROR_ACTION_PROPERTY]
		} else {
			return event
		}
	}

	add_state_properties() {
		this.registered_state_properties.push.apply(this.registered_state_properties, arguments)
	}

	// Public alias.
	properties() {
		this.add_state_properties.apply(this, arguments)
	}

	// Public alias.
	property() {
		this.add_state_properties.apply(this, arguments)
	}
}

// Returns Redux action creator.
function create_action(event, action, result, options, redux)
{
	const namespace = redux.namespace

	const {
		sync,
		cancelPrevious
	} = options

	// If `result` is a property name,
	// then add that property to `connectXxx()`.
	if (typeof result === 'string') {
		redux.add_state_properties(result)
	}
	// If `result` is an object of property getters,
	// then add those properties to `connectXxx()`.
	else if (typeof result === 'object') {
		redux.add_state_properties(...Object.keys(result))
	}

	// Default "on result" handler is a reducer that does nothing.
	// Actually, I guess it should throw an error instead.
	// Deprecated: throw an error in some future major version
	// instead of using a "no op" reducer.
	result = result || (state => state)

	// Synchronous action
	if (sync)
	{
		// Reducer
		redux.on(eventName(namespace, event), get_action_value_reducer(result))

		// Redux "action creator"
		return (...parameters) =>
		({
			type : eventName(namespace, event),
			[RESULT_ACTION_PROPERTY] : action.apply(this, parameters)
		})
	}

	// Asynchronous action

	// Add Redux reducers handling events:
	//
	//   * pending
	//   * success
	//   * error
	//
	add_asynchronous_action_reducers(redux, namespace, event, get_action_value_reducer(result))

	// Redux "action creator"
	return (...parameters) =>
	({
		event   : eventName(namespace, event),
		// `dispatch` and `getState` arguments are deprecated
		// and will be removed in some future major version release.
		promise : (http, dispatch, getState) =>
		{
			if (redux.v2) {
				// For gradual migration from version "2.x" syntax.
				return action.apply(this, [{ http, dispatch, getState }].concat(parameters))
			}
			return action.apply(this, parameters)(http)
		},
		cancelPrevious
	})
}

// Adds handlers for:
//
//   * pending
//   * done
//   * failed
//   * reset error
//
function add_asynchronous_action_reducers(redux, namespace, event, result_reducer)
{
	const
	[
		pending_event_name,
		success_event_name,
		error_event_name
	]
	= redux.settings.reduxEventNaming(event)

	const pending_property_name = redux.settings.reduxPropertyNaming(pending_event_name)
	const error_property_name   = redux.settings.reduxPropertyNaming(error_event_name)

	// This info will be used in `storeConnector`
	redux.add_state_properties(pending_property_name, error_property_name)

	// When Promise is created: reset result variable, clear `error`, set `pending` flag.
	redux.on(eventName(namespace, pending_event_name), (state) =>
	{
		const new_state =
		{
			...state,
			// Set `pending` flag
			[pending_property_name]: true
		}

		// Clear `error`
		if (redux.v2) {
			// For gradual migration from version "2.x" syntax.
			new_state[error_property_name] = undefined
		} else {
			delete new_state[error_property_name]
		}

		return new_state
	})

	// When Promise succeeds: clear `pending` flag, set result variable.
	redux.on(eventName(namespace, success_event_name), (state, result) =>
	{
		const new_state = result_reducer(state, result)

		// Clear `pending` flag
		if (redux.v2) {
			// For gradual migration from version "2.x" syntax.
			new_state[pending_property_name] = false
		} else {
			delete new_state[pending_property_name]
		}

		return new_state
	})

	// When Promise fails, clear `pending` flag and set `error`.
	// Can also clear `error` when no `error` is passed as part of an action.
	redux.on(eventName(namespace, error_event_name), (state, error) =>
	{
		const new_state =
		{
			...state,
			[error_property_name] : error
		}

		// Clear `pending` flag
		if (redux.v2) {
			// For gradual migration from version "2.x" syntax.
			new_state[pending_property_name] = false
		} else {
			delete new_state[pending_property_name]
			// `resetError()`
			if (!error) {
				delete new_state[error_property_name]
			}
		}

		return new_state
	})
}

// Returns a function
function get_action_value_reducer(reducer)
{
	// If `reducer` is a property name,
	// then the reducer will write action value
	// to that property of Redux state.
	if (typeof reducer === 'string')
	{
		return (state, value) =>
		({
			...state,
			[reducer]: value
		})
	}

	// If `reducer` is an object of property getters
	// then those properties will be added to Redux state.
	if (typeof reducer === 'object')
	{
		return (state, value) =>
		{
			const updated_properties = {}

			for (const property of Object.keys(reducer))
			{
				updated_properties[property] = reducer[property](value)

				// Don't know why did I previously write it like:
				// updated_properties =
				// {
				// 	...updated_properties,
				// 	...reducer[property](value)
				// }
			}

			return {
				...state,
				...updated_properties
			}
		}
	}

	// Otherwise `reducer` is `(state, value) => ...`
	return reducer
}

class Counter
{
	counter = 0

	next()
	{
		if (this.counter < MAX_SAFE_INTEGER) {
			this.counter++
		} else {
			this.counter = 1
		}
		return this.counter
	}
}

const counter = new Counter()

// `MAX_SAFE_INTEGER` is not supported by older browsers
const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || Math.pow(2, 53) - 1
