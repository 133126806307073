import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide } from '@material-ui/core/';
import intl from 'react-intl-universal';

class MessageBox extends React.Component {

    constructor(props){
        super(props);
    }
      
    render ()
    {
      return (
        <Dialog
          open={this.props.alertDialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.props.onClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          maxWidth='md'
        >
          <DialogTitle id="alert-dialog-slide-title">
            {this.props.alertTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {this.props.alertText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose} color="primary">
              {intl.get('CLOSE')}
            </Button>
          </DialogActions>
        </Dialog>
        )
    }

}

function Transition(props) {
    return <Slide direction="up" {...props} />;
  }

export default MessageBox;