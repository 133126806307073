import React, { Component } from 'react';
//import './App.css';
import { BrowserRouter as Router } from 'react-router-dom'

import intl from 'react-intl-universal';
import _ from 'lodash';
import de_locale from '../../public/locales/de-DE.json';
import en_locale from '../../public/locales/en-US.json';
import tr_locale from '../../public/locales/tr-TR.json';
import MainPage from './MainPage';
import LoginPage from './LoginPage';
import ActivationPage from './ActivationPage';
import ActivationViaPassPage from './ActivationViaPassPage';
import PasswordResetPage from './PasswordResetPage';
import NotFoundPage from './NotFoundPage';
import ConsentPage from './ConsentPage';

const SUPPOER_LOCALES = [
  {
    name: 'English',
    value: 'en-US'
  },
  {
    name: 'Türkçe',
    value: 'tr-TR'
  },
  {
    name: 'Deutsch',
    value: 'de-DE'
  }
];
class App extends Component 
{
  state = { initDone: false };

  constructor(props){
    super(props);
    this.loadLocales();
  }

  componentDidMount() {
    // this.loadLocales();
  }

  loadLocales() {
    let currentLocale = intl.determineLocale({
      urlLocaleKey: 'lang',
      cookieLocaleKey: 'lang'
    });

    console.log("currentLocale = " , currentLocale);

    if (!_.find(SUPPOER_LOCALES, { value: currentLocale })) {
      currentLocale = 'en-US';
    }


    // !!!! TEST !!! //
    // currentLocale = 'en-US';
    // currentLocale = 'de-DE';
    // !!!! TEST !!! //

    // set to turkish for now
    // currentLocale = 'tr-TR';

    let localData = null;
    let prefix = 'tr';
    if ( currentLocale == 'tr-TR') {
     localData = tr_locale[0];
    } else if ( currentLocale == 'de-DE') {
      prefix = 'de';
     localData = de_locale[0];
    } else if ( currentLocale == 'en-US') {
      prefix = 'en';
     localData = en_locale[0];
    }
    // localData = `${prefix}_locale`;
    // console.log('localData : ', localData);
    intl.init({
      currentLocale,
      locales: {
        [currentLocale]: localData
      }
    }).then(() => {
    this.setState({ initDone: true });
    });

  }


  useEffect = () => {
    const depar = window.DeparFramework;
    if (depar && depar.UIBuilder) {
      const newFormBuilder = new depar.UIBuilder();
      console.log('UIBuilder instance created');
      newFormBuilder.setLocale('EN'); // Varsayılan dil
      newFormBuilder.init("formBasePage");
      setFormBuilder(newFormBuilder);
    } else {
      console.error('DeparFramework or UIBuilder not found');
    }
  }

  renderLocaleSelector() {
    return (
      <select onChange={this.onSelectLocale} defaultValue="">
        <option value="" disabled>
          Change Language
        </option>
        {SUPPOER_LOCALES.map(locale => (
          <option key={locale.value} value={locale.value}>
            {locale.name}
          </option>
        ))}
      </select>
    );
  }

  showLogin = () => {
    this.props.router.replace('/login')
  }

  showClients = () => {
    this.props.router.replace('/clients')
  }

  render() 
  {
    const {pathname} = this.props.location;
    var token = localStorage.getItem('token');
    // if ( ! token ) {
    //   return (<div> {this.state.initDone && this.showLogin()}</div>)
    // } else {
    //   return (<div> { this.state.initDone && this.showClients()}</div>)
    // }

    if ( pathname.includes('activationViaPass') ){
      return (<ActivationViaPassPage router={this.props.router} location={this.props.location} />);
    } 
    else if ( pathname.includes('activation') ){
      return (<ActivationPage router={this.props.router} location={this.props.location} />);
    } 
    else if ( pathname.includes('passwordReset') ){
      return (<PasswordResetPage router={this.props.router} location={this.props.location} />);
    } 
    else if ( pathname.includes('consent') ){
      return (<ConsentPage router={this.props.router} location={this.props.location} />);
    } 


    if ( ! token ) {
      return (<LoginPage router={this.props.router}/>);
    }
    else if ( pathname.includes('login') ){ // in first if case token checked, if token go to Mainpage
      return (<LoginPage router={this.props.router}/>);
    } 
    else if ( pathname.includes('clients') || pathname.includes('calendar') || pathname.includes('reports')
        || pathname.includes('charts') || pathname.includes('account') 
        || pathname.includes('/') ){
      return (<MainPage router={this.props.router}/>);
    } 
    else {
      return (<NotFoundPage router={this.props.router}/>);
    }

    // <Route path="*" Component={ NotFoundPage }/>



  }
}

export default App;