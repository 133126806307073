import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import DialogActions from '@material-ui/core/DialogActions';
import RestManager from '/persist/RestManager'
import utils from '/helpers/utils'
import PaymentBar from '../Selector/PaymentBar';
import MessageBox from '../Dialogs/MessageBox';
import intl from 'react-intl-universal';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  priceField:{
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 80,
  }
});



class NewPackage extends React.Component {

    state = {
        id:"",
        examCount: "",
        paymentDate:new Date(),
        paymentId:"",
        price:"",
        paidCash:"",
        paidCredit:"",
        paidTransfer:"",
        restAmount:"",
        alertDialogOpen:false,
        alertTitle:"",
        alertText:"",
    };
    
    constructor(props){
        super(props);
    }
    componentDidMount()
    {
      const {selectedItem} = this.props;
      if ( selectedItem ) {
        let newState = this.createData(selectedItem.id, selectedItem.examCount, utils.formatDate(selectedItem.payment.paymentDate), 
          selectedItem.payment.id, selectedItem.payment.price, selectedItem.payment.paidCash, 
          selectedItem.payment.paidCredit, selectedItem.payment.paidTransfer, selectedItem.payment.restAmount)
        this.setState(newState, ()=>console.log("new state:" + this.state));
        }        
    }

    createData(id, examCount, paymentDate, paymentId, price, paidCash, paidCredit, paidTransfer, restAmount) {
      return { id, examCount, paymentDate, paymentId, price, paidCash, paidCredit, paidTransfer, restAmount };
    }

    onSave = () => 
    {
      if ( ! this.checkInputs() ) return;

      RestManager.getInstance().addPackage( 
        this.props.customer.id,
        {
          'examCount':this.state.examCount, 
          'payment':{
            'paymentDate':new Date(this.state.paymentDate), 
            'price': this.state.price ? this.state.price : 0, 
            'paidCash':this.state.paidCash  ? this.state.paidCash : 0, 
            'paidCredit':this.state.paidCredit  ? this.state.paidCredit : 0, 
            'paidTransfer':this.state.paidTransfer ? this.state.paidTransfer : 0, 
            'restAmount':this.state.restAmount ? this.state.restAmount : 0, 
          }
        }, 
        (result) => {
          this.props.onSaved(result);
        }
      )
    }

    onUpdate = () => 
    {
      if ( ! this.checkInputs() ) return;

      RestManager.getInstance().updatePackage( 
        this.props.customer.id,
        {
          'id':this.state.id,
          'examCount':this.state.examCount, 
          'payment':{
            'id':this.state.paymentId,
            'paymentDate':new Date(this.state.paymentDate), 
            'price': this.state.price ? this.state.price : 0, 
            'paidCash':this.state.paidCash  ? this.state.paidCash : 0, 
            'paidCredit':this.state.paidCredit  ? this.state.paidCredit : 0, 
            'paidTransfer':this.state.paidTransfer ? this.state.paidTransfer : 0, 
            'restAmount':this.state.restAmount ? this.state.restAmount : 0, 
          }
        }, 
        (result) => {
          this.props.onUpdated(result);
        }
      )
    }

    checkInputs = () => 
    {
      if (  ! this.state.examCount || ! this.state.paymentDate ) {
        this.setState({
          alertTitle:'Eksik Veri',
          alertText:'Lütfen Görüşme sayısını ve ödeme tarihini girin.',
          alertDialogOpen:true,
        });
        return false;
      }
      return true;
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
        console.log("in handleChange : ", name, event.target.value)
    };
    
    paymentValueChanged = (name, value) => {
      this.setState({ [name]: value });
    }

    handleDialogClose = () =>
    {
      this.setState({ alertDialogOpen: false });
    }

    render ()
    {
        const { classes } = this.props;
        return (
        <div>
            <div className='row' style={{marginBottom:'20px'}}>

                <div className='column' >
                    <TextField
                    label={intl.get('EXAMINATION_COUNT')}
                    value={this.state.examCount}
                    className={classes.textField}
                    onChange={this.handleChange('examCount')}
                    style = {{width: 120}}
                    // margin="normal"
                    />
                </div>
            </div>

            <PaymentBar valueChanged={this.paymentValueChanged} pState={this.state} ></PaymentBar>

            <div className='row' style={{width:'750px'}}>
              <DialogActions style={{justifyContent: 'center',  background: '#dcdcdc59', borderBottom: '1px solid lightgray', padding:10}}>
        <Button onClick={this.props.onClose} color="primary">{intl.get('CANCEL')}</Button>
                {this.props.selectedItem  ?
                  <Button onClick={this.onUpdate} variant="contained" color="primary">{intl.get('UPDATE')}</Button>
                  :
                <Button onClick={this.onSave} variant="contained" color="primary">{intl.get('SAVE')}</Button>
                }
              </DialogActions>
            </div>

            <MessageBox
              alertDialogOpen={this.state.alertDialogOpen}
              onClose={this.handleDialogClose} 
              alertTitle={this.state.alertTitle} 
              alertText={this.state.alertText} >
            </MessageBox>

        </div>
        )
    }

}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        // thousandSeparator
        // prefix="$"
      />
    );
  }
  NewPackage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewPackage);