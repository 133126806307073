import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import RestManager from '/persist/RestManager';
import utils from '/helpers/utils';
import DateSelectorBar from '../components/Selector/DateSelectorBar';
import MonthSelectorBar from '../components/Selector/MonthSelectorBar';
import YearSelectorBar from '../components/Selector/YearSelectorBar';
import {withRouter} from 'react-router-dom';
import intl from 'react-intl-universal';
import queryString from 'query-string';

export const REPORT_TYPES = {
  DAILY: 'daily',
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
}

function createData(paymentDate, price, paidCash, paidCredit, paidTransfer, restAmount, type) {
  return { paymentDate, price, paidCash, paidCredit, paidTransfer, restAmount, type };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class ReportTableHead extends React.Component 
{

  state = {
    rows : [
      { id: 'paymentDate', numeric: false, disablePadding: true, label: intl.get('DATE_HOUR') },
      { id: 'price', numeric: true, disablePadding: true, label: intl.get('PRICE') },
      { id: 'paidCash', numeric: true, disablePadding: true, label: intl.get('CASH') },
      { id: 'paidCredit', numeric: true, disablePadding: true, label: intl.get('CREDIT') },
      { id: 'paidTransfer', numeric: true, disablePadding: true, label: intl.get('TRANSFER') },
      { id: 'restAmount', numeric: true, disablePadding: true, label: intl.get('REST') },
      { id: 'type', numeric: false, disablePadding: false, label: intl.get('TYPE') },
    ]
  }

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          {this.state.rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'center'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title={intl.get('SORT')}
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

ReportTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const styles = (theme => ({
  root: {
    flexGrow: 1,
    borderBottom:'2px solid lightgray',
  },
  table: {
    minWidth: 520,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  header: {
    minHeight: '50px',
  },
  values: {
    backgroundColor: '#f5f5f5',
    //paddingTop:'10px',
    paddingBottom:'20px'
  },
  label: {
    padding: 10,
    textAlign: 'right',
    // backgroundColor:'white',
    // color:'gray'
  },
  value: {
    padding: 10,
    textAlign: 'left',
    borderBottom: '1px solid gray',
  },
}));

@connect(({ app }) => ({
  activeReport: app.activeReport,
  selectedProfessional: app.selectedProfessional,
}))

class ReportPage extends React.Component 
{
  state = {
    activeReport:{type:REPORT_TYPES.DAILY, date:new Date()},
    order: 'asc',
    orderBy: 'examDate',
    selected: [],
    page: 0,
    rowsPerPage: 25,
    customer:null,
    payments:null,
    data:[],
    totalExamCount:0,
    totalPackageCount:0,
    totalAmount:0,
    totalRest:0,
    totalPaidCash:0,
    totalPaidCredit:0,
    totalPaidTransfer:0,
    
  };

  constructor(props){
    super(props);
    let actRep = this.props.activeReport;
    if ( ! actRep ) {
      actRep = this.state.activeReport;
    }
    this.setSelectedReportFromUrl()
    //this.reloadData(actRep);
    console.log("in PaymentReport constructor");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.activeReport !== this.props.activeReport) {
      console.log('componentDidUpdate ReportPage - !!!')
      this.setSelectedReportFromUrl();
    }
    if (prevProps.selectedProfessional !== this.props.selectedProfessional) {
      this.setSelectedReportFromUrl();
    }
  }

  setSelectedReportFromUrl()
  {
    let params = queryString.parse(this.props.location.search)
    if ( params.type ) {
      this.state.activeReport = {type:params.type};
      this.reloadData(this.state.activeReport);
    }

    // let search = this.props.location.search;
    // console.log("search = " + search);
    // let res = search.split("=");
    // if ( res.length > 1 ) {
    //   this.state.activeReport = {type:res[1]};
    //   this.reloadData(this.state.activeReport);
    // }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   console.log('componentDidUpdate - !!!')
  //   if (prevProps.activeReport !== this.props.activeReport) {
  //     // this.setReportDates(this.props.activeReport);
  //     this.reloadData(this.props.activeReport);
  //       // Do whatever you want
  //   }
  // }

  onDateSelected = (selectedDate) => {
    console.log("in onDateSelected : ", selectedDate);
    let report = this.state.activeReport;
    report.date = selectedDate;
    this.reloadData(report);
  }

  onMonthSelected = (selectedMonth, selectedYear) => {
    console.log("in onMonthSelected : ", selectedMonth, selectedYear);
    let report = this.state.activeReport;
    report.date = new Date(selectedYear, selectedMonth-1, 10);
    this.reloadData(report);
  }

  onYearSelected = (selectedYear) => {
    console.log("in onYearSelected : ", selectedYear);
    let report = this.state.activeReport;
    report.date = new Date(selectedYear, 1, 10);
    this.reloadData(report);
  }

  reloadData = (activeReport) => 
  {
    if ( ! activeReport ) {
      return;
    }
    let startEndInMilli = null;
    let day = new Date();
    if ( activeReport.date ) {
      day = new Date(activeReport.date);
    } else {
      activeReport.date = day;
    }
    if ( activeReport.type == REPORT_TYPES.DAILY ) {
      startEndInMilli = utils.getStartEndOfDayInMilli(day);
    }
    else if (activeReport.type == REPORT_TYPES.MONTHLY ) {
      startEndInMilli = utils.getStartEndOfMonthInMilli(day);
    }
    else if (activeReport.type == REPORT_TYPES.YEARLY ) {
      startEndInMilli = utils.getStartEndOfYearInMilli(day);
    }
    
    RestManager.getInstance().getPayments(startEndInMilli.start, startEndInMilli.end, 
        (result) => {
          if ( ! result.response ) 
            return;
          console.log("getPayments result : ", result);
          let payments = result.response;
          let data = [];
          let totalAmount=0, totalPaidCash=0, totalPaidCredit=0, totalPaidTransfer=0, totalRest=0, totalExamCount = 0, totalPackageCount = 0;
          for ( let i = 0 ; i < payments.length; i++ ) {
            let payment = payments[i];
            totalAmount += +payment.price;
            totalPaidCash += +payment.paidCash;
            totalPaidCredit += +payment.paidCredit;
            totalPaidTransfer += +payment.paidTransfer;
            totalRest += +payment.restAmount;
            if ( payment.type == 1 ) {
              totalExamCount++;
            } else {
              totalPackageCount++;
            }
            data.push(createData(payment.paymentDate, payment.price, payment.paidCash, payment.paidCredit, payment.paidTransfer, payment.restAmount, payment.type));
          }
          this.setState ({
            data:data,
            payments: payments, 
            totalExamCount: totalExamCount,
            totalPackageCount: totalPackageCount,
            totalAmount: totalAmount,
            totalRest: totalRest,
            totalPaidCash: totalPaidCash,
            totalPaidCredit: totalPaidCredit,
            totalPaidTransfer: totalPaidTransfer,
            activeReport : activeReport,
          });
      }
    );

  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };


  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() 
  {
    // if ( this.state.activeReport == null || this.state.activeReport.type != this.props.activeReport.type ) {
    //   this.reloadData();
    // }
    console.log("RENDER - PaymentTable - state: ", this.state);
    console.log("state activeReport = ", this.state.activeReport);
    console.log("props activeReport = ", this.props.activeReport);
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div >
        <div className={classes.root}>

          {/* <div className={classes.header}> </div> */}
          {this.state.activeReport.type == REPORT_TYPES.DAILY ? 
            <DateSelectorBar onDateSelected={this.onDateSelected} ></DateSelectorBar>
          : '' }
          {this.state.activeReport.type == REPORT_TYPES.MONTHLY ? 
            <MonthSelectorBar onMonthSelected={this.onMonthSelected} ></MonthSelectorBar>
          : '' }
          {this.state.activeReport.type == REPORT_TYPES.YEARLY ? 
            <YearSelectorBar onYearSelected={this.onYearSelected} ></YearSelectorBar>
          : '' }

          <div className={classes.values}>

          <div style={{heigth:'16px', textAlign:'center', background:'rgb(63 81 181 / 81%)', color:'white', padding:'5px'}}>
              { this.state.activeReport && this.state.activeReport.date && this.state.activeReport.type == REPORT_TYPES.DAILY ? 
                utils.humanDate(this.state.activeReport.date) + ' - ' + intl.get('DAILY_REPORT') : '' }
              { this.state.activeReport && this.state.activeReport.date && this.state.activeReport.type == REPORT_TYPES.MONTHLY ? 
                (this.state.activeReport.date.getMonth()+1) + " - " + this.state.activeReport.date.getFullYear() + ' - ' + intl.get('MONTHLY_REPORT'): '' }
              { this.state.activeReport && this.state.activeReport.date && this.state.activeReport.type == REPORT_TYPES.YEARLY ? 
                this.state.activeReport.date.getFullYear() + ' - ' + intl.get('YEARLY_REPORT') : '' }
          </div>

            <Grid container spacing={3}>
              <Grid item xs={6} sm={2}>
                <div className={classes.label}> {intl.get('EXAMINATION')} : </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.value}>{this.state.totalExamCount} </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.label}>{intl.get('GROSS_INCOME')} : </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.value}>{this.state.totalAmount} </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.label}> {intl.get('CASH')} : </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.value}>{this.state.totalPaidCash} </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={2}>
                <div className={classes.label}> {intl.get('PACKAGE')} : </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.value}>{this.state.totalPackageCount} </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.label}>{intl.get('COLLECTED')} : </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.value}>{this.state.totalPaidCash+this.state.totalPaidCredit+this.state.totalPaidTransfer} </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.label}>{intl.get('CREDIT_CARD')} : </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.value}>{this.state.totalPaidCredit} </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid item xs={6} sm={2}>
                <div className={classes.label}>{intl.get('TOTAL')} : </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.value}>{this.state.totalExamCount+this.state.totalPackageCount} </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.label}>{intl.get('RECEIVABLE')} :</div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.value}>{this.state.totalRest} </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.label}>{intl.get('TRANSFER')} :</div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.value}>{this.state.totalPaidTransfer} </div>
              </Grid>
            </Grid>

          </div>
          
        </div>


        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <ReportTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      // role="checkbox"
                      // aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      // selected={isSelected}
                    >
                      <TableCell component="th" scope="row" align="center" padding="none">
                        {utils.formatDate(n.paymentDate, ".", true, true)}
                      </TableCell>
                      <TableCell align="right" padding="none">{n.price}</TableCell>
                      <TableCell align="right" padding="none" >{n.paidCash}</TableCell>
                      <TableCell align="right" padding="none" >{n.paidCredit}</TableCell>
                      <TableCell align="right" padding="none" >{n.paidTransfer}</TableCell>
                      <TableCell align="right" padding="none" >{n.restAmount}</TableCell>
                      <TableCell align="center" padding="none" >
                        { n.type ==1 ? "Muayene Ücreti" : "Paket Satışı"  }
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}


  // const mapStateToProps = (app) => ({ 
  //   activeReport: app.activeReport,
  // });
  ReportPage.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  // export default (withStyles(styles),connect(mapStateToProps))(ReportPage);
export default withRouter(withStyles(styles)(ReportPage));
