import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {TextField, Button, DialogActions, CircularProgress, FormControlLabel, RadioGroup, Radio} from '@material-ui/core';
import RestManager from '/persist/RestManager';
import utils from '/helpers/utils';
import { connect } from 'react-redux';
import PaymentBar from '../Selector/PaymentBar';
import MessageBox from '../Dialogs/MessageBox';
import intl from 'react-intl-universal';
import { Checkbox } from '@material-ui/core';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  priceField:{
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 80,
  },
  wrapper: {
      margin: theme.spacing.unit,
      position: 'relative',
    },
});

// const [loading, setLoading] = React.useState(false);

@connect(({ app }) => ({
  selectedCustomer: app.selectedCustomer,
}))

class NewExamination extends React.Component {

    state = {
        id:"",
        examType: 2,
        examDate: new Date(),
        examNote: "",
        paymentDate: new Date(),
        paymentId:"",
        price:"",
        paidCash:"",
        paidCredit:"",
        paidTransfer:"",
        restAmount:"",
        loading:false,
        alertDialogOpen:false,
        alertTitle:"",
        alertText:"",
        isControlExam:false,
        autoExamType:"1",
        autoExamDay1:true,
        autoExamDay2:true,
        autoExamDay3:true,
        autoExamDay4:true,
        autoExamDay5:true,
        autoExamDay6:false,
        autoExamDay0:false,
        autoExamDates:[],
    };
    
    constructor(props){
        super(props);
    }
    componentDidMount()
    {
      const {selectedExam} = this.props;
      if ( selectedExam ) {
        let newState;
        if ( selectedExam.payment ) {
          newState = this.createData(selectedExam.id, selectedExam.examType, utils.formatDate(selectedExam.examDate, "-", false, true, "T"), 
          selectedExam.examNote, utils.formatDate(selectedExam.payment.paymentDate), selectedExam.payment.id, 
          selectedExam.payment.price, selectedExam.payment.paidCash, selectedExam.payment.paidCredit, 
          selectedExam.payment.paidTransfer, selectedExam.payment.restAmount)
        } else {
          newState = this.createDataForPackage(selectedExam.id, selectedExam.examType, utils.formatDate(selectedExam.examDate, "-", false, true, "T"), selectedExam.examNote)
        }
        this.setState(newState, ()=>console.log("new state:" + this.state));
        if ( selectedExam.examType == 3 ) {
          this.state.isControlExam = true;
        }
      }        
    }

    createData(id, examType, examDate, examNote, paymentDate, paymentId, price, paidCash, paidCredit, paidTransfer, restAmount) {
      return { id, examType, examDate, examNote, paymentDate, paymentId, price, paidCash, paidCredit, paidTransfer, restAmount };
    }
    createDataForPackage(id, examType, examDate, examNote) {
      return { id, examType, examDate, examNote};
    }

    onSave = (examDate, callback) => 
    {
      if ( ! this.checkInputs() ) return;

      this.setState({ loading: true });
      let paymentDate = null;
      if ( this.state.paymentDate && this.state.paymentDate != "" ) {
        paymentDate = utils.UTC2GMT(utils.formatDate(this.state.paymentDate));
      }
      if ( ! examDate ) {
        examDate = this.state.examDate;
      }
      RestManager.getInstance().addExamination( 
        this.props.customer.id,
        {
          'examType': this.state.examType, 
          'examDate':new Date(utils.formatDate(examDate, "-", false, true, "T")), 
          'examNote':this.state.examNote, 
          'payment':{
            'paymentDate':paymentDate,
            'price': this.state.price ? this.state.price : 0, 
            'paidCash':this.state.paidCash  ? this.state.paidCash : 0, 
            'paidCredit':this.state.paidCredit  ? this.state.paidCredit : 0, 
            'paidTransfer':this.state.paidTransfer ? this.state.paidTransfer : 0, 
            'restAmount':this.state.restAmount ? this.state.restAmount : 0, 
          }
        }, 
        (result) => {
          if ( callback ) {
            callback(result);
          } else {
            this.props.onSaved(result);
            this.setState({ loading: false });  
          }
        }
      )
    }

    onSaveMultiple = (arrNewExamDates, count = 0 ) => 
    {
      if ( arrNewExamDates && arrNewExamDates.length > 0  ) {
        if ( count < arrNewExamDates.length ){
          this.onSave(arrNewExamDates[count], (result)=>{
            if ( count == arrNewExamDates.length -1 ) {
              this.props.onSaved(result, arrNewExamDates.length);
              this.setState({ loading: false });  
            } else {
              this.onSaveMultiple(arrNewExamDates, ++count)
            }
          })
        }  
      }
      else {
        this.onSave();
      }
    }

    onUpdate = () => 
    {
      if ( ! this.checkInputs(true) ) return;

      let paymentDate = null;
      if ( this.state.paymentDate && this.state.paymentDate != "" ) {
        paymentDate = utils.UTC2GMT(utils.formatDate(this.state.paymentDate));
      }
      RestManager.getInstance().updateExamination( 
        this.props.customer.id,
        {
          'id':this.state.id,
          'examType': this.state.examType, 
          'examDate':utils.UTC2GMT(utils.formatDate(this.state.examDate, "-", false, true, "T")), 
          'examNote':this.state.examNote, 
          'payment':{
            'id':this.state.paymentId,
            'paymentDate':paymentDate,
            'price': this.state.price ? this.state.price : 0, 
            'paidCash':this.state.paidCash  ? this.state.paidCash : 0, 
            'paidCredit':this.state.paidCredit  ? this.state.paidCredit : 0, 
            'paidTransfer':this.state.paidTransfer ? this.state.paidTransfer : 0, 
            'restAmount':this.state.restAmount ? this.state.restAmount : 0, 
          }
        }, 
        (result) => {
          this.props.onUpdated(result);
        }
      )
    }

    onControlExam = () =>{

    }

    checkInputs = (isUpdate) => {
      if ( ! this.state.examDate || (isUpdate && this.state.examType==0 && ! this.state.paymentDate )) {
        this.setState({
          alertTitle:'Eksik Veri',
          alertText: isUpdate ? 'Lütfen görüşme tarihini ve ödeme tarihini girin.' : 'Lütfen görüşme tarihini girin.',
          alertDialogOpen:true,
        });
        return false;
      }
      return true;
    }

    handleChange = name => event => {
      if ( event.target.type == "checkbox" ) {
        this.setState({ [name]: event.target.checked });
        if ( name == "isControlExam" ){
          this.setState({ examType : event.target.checked == true ? 3 : 2 });
        }
      } else {
        this.setState({ [name]: event.target.value });
      }
    };

    paymentValueChanged = (name, value) => {
      this.setState({ [name]: value });
    }

    handleDialogClose = () =>
    {
      this.setState({ alertDialogOpen: false });
    }

    render ()
    {

        const { classes, selectedCustomer, selectedExam } = this.props;
        let restExamCount = selectedCustomer.examCount - selectedCustomer.usedExams;
        console.log("selectedExam = ", selectedExam) ;
        console.log("restExamCount = ", restExamCount) ;


        var allExamDates = [];
        var allStrExamDates = [];
        if ( this.state.isAutoExam ){
            var examDate = new Date(utils.formatDate(this.state.examDate, "-", false, true, "T"));
            var examDay = examDate.getDay(); // 0 - 6  Sun - Sat
            var count =  0;
            for ( var i = 0; i < restExamCount; i++ ) 
            {

              if ( this.state.autoExamType == "1" ) // daily}
              {                  
                if ( eval("this.state.autoExamDay" + examDay) || count == 0 ) {
                  var strNewDate = utils.humanDate(examDate, true);
                  allStrExamDates.push( <div>{i+1} - {strNewDate}</div>) ;
                  allExamDates.push(new Date(examDate.getTime()));
                } else {
                  i--;
                }
                examDay++;
                if ( examDay > 6 ) examDay = 0;
                examDate.setDate(examDate.getDate() + 1);
                count++;
                if ( count > 7 && allStrExamDates.length == 1  ) {
                  break;
                }  
              } else { // weekly
                var strNewDate = utils.humanDate(examDate, true);
                allStrExamDates.push( <div>{i+1} - {strNewDate}</div>) ;
                allExamDates.push(new Date(examDate.getTime()));
                examDate.setDate(examDate.getDate() + 7);
              }    
            }
        }
        // if ( allExamDates.length > 1 )
        // {
        //   this.setNewExams(allExamDates);
        // }  
  
        return (
        <div>
            <div className='row' style={{marginBottom:'20px'}}>
                <div className='column' >
                    <TextField
                    id="datetime-local"
                    label={intl.get('EXAMINATION_DATE')}
                    type="datetime-local"
                    value={utils.formatDate(this.state.examDate, "-", false, true, "T")}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={this.handleChange('examDate')}
                    />
                </div>
                {/* { ! selectedExam && restExamCount > 0 ? 
                  <div className='column' style={{marginLeft:40, paddingTop:6}}>
                    <FormControlLabel
                      control={<Checkbox checked={this.state.isAutoExam} onChange={this.handleChange('isAutoExam')} color="primary" />}
                      label="Otomatik Randevu Olustur"
                    />
                  </div>
                  :""} */}

                { ! selectedExam && restExamCount == 0 ? 
                  <div className='column' style={{marginLeft:80, paddingTop:6}}>
                    <FormControlLabel
                      control={<Checkbox checked={this.state.isControlExam} onChange={this.handleChange('isControlExam')} color="primary" />}
                      label="Kontrol Muayenesi"
                    />
                  </div>
                :""}

            </div>


            { this.state.isAutoExam == true  ? 
              <div>
                <div className='row' style={{ marginLeft:'260px'}}>
                  <RadioGroup
                    name="autoExamType"
                    value={this.state.autoExamType+""}
                    onChange={this.handleChange('autoExamType')}
                    row
                  >
                    <FormControlLabel value="1" control={<Radio />} label='Gunluk' />
                    <FormControlLabel value="2" control={<Radio />} label='Haftalik' />
                  </RadioGroup>
                </div>

                { this.state.autoExamType == '1' ?
                  <div className='row' style={{marginBottom:'10px', marginLeft:'260px'}}>
                    <FormControlLabel
                          control={<Checkbox checked={this.state.autoExamDay1} onChange={this.handleChange('autoExamDay1')} color="primary" />}
                          label="Pzt"
                      />
                      <FormControlLabel
                          control={<Checkbox checked={this.state.autoExamDay2} onChange={this.handleChange('autoExamDay2')} color="primary" />}
                          label="Sal"
                      />
                      <FormControlLabel
                          control={<Checkbox checked={this.state.autoExamDay3} onChange={this.handleChange('autoExamDay3')} color="primary" />}
                          label="Çar"
                      />
                      <FormControlLabel
                          control={<Checkbox checked={this.state.autoExamDay4} onChange={this.handleChange('autoExamDay4')} color="primary" />}
                          label="Per"
                      />
                      <FormControlLabel
                          control={<Checkbox checked={this.state.autoExamDay5} onChange={this.handleChange('autoExamDay5')} color="primary" />}
                          label="Cum"
                      />
                      <FormControlLabel
                          control={<Checkbox checked={this.state.autoExamDay6} onChange={this.handleChange('autoExamDay6')} color="primary" />}
                          label="Cmt"
                      />
                      <FormControlLabel
                          control={<Checkbox checked={this.state.autoExamDay0} onChange={this.handleChange('autoExamDay0')} color="primary" />}
                          label="Paz"
                      />
                  </div>      
                :""} 
                  <div className='row' style={{marginBottom:'10px', marginLeft:'260px'}}>
                    {allStrExamDates}
                  </div>
              </div>
              : ""
              }

            <div className='row' style={{marginBottom:'20px'}}>
                <div className='column' >
                    <TextField
                    label={intl.get('NOTE')}
                    value={this.state.examNote}
                    className={classes.textField}
                    onChange={this.handleChange('examNote')}
                    style = {{width: 770}}
                    // margin="normal"
                    />
                </div>
            </div>

            { (!selectedExam && restExamCount > 0) || this.state.examType == 1 || this.state.isControlExam? 
              '' :
            <PaymentBar valueChanged={this.paymentValueChanged} pState={this.state} ></PaymentBar>
            }


            <div className='row' style={{width:'790px'}}>
              <DialogActions style={{justifyContent: 'center', background: '#dcdcdc59', borderBottom: '1px solid lightgray'}}>
                <Button onClick={this.props.onClose} color="primary">{intl.get('CANCEL')}</Button>
                  {this.props.selectedExam  ?
                    <div className={classes.wrapper}>
                      <Button onClick={this.onUpdate} disabled={this.state.loading} variant="contained" color="primary">{intl.get('UPDATE')}</Button>
                      {/* <CircularProgress size={24} className={classes.buttonProgress} /> */}
                    </div>
                    :
                    <div className={classes.wrapper}>
                      <Button onClick={ () => this.onSaveMultiple(allExamDates)} disabled={this.state.loading} variant="contained" color="primary">{intl.get('SAVE')}</Button>
                      {/* {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} /> } */}
                    </div>
                  }
 
              </DialogActions>
            </div>


            <MessageBox
              alertDialogOpen={this.state.alertDialogOpen}
              onClose={this.handleDialogClose} 
              alertTitle={this.state.alertTitle} 
              alertText={this.state.alertText} >
            </MessageBox>

        </div>
        )
    }

}

NewExamination.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewExamination);