import React, {Component} from 'react';
//import './App.css'
import SplitPane from 'react-split-pane'
import { connect } from 'react-redux'
import { setCustomers, setSelectedCustomer, setIsClientListVisible, setIsEditMode, setIsNewMode } from '../redux/app'
import RestManager from '/persist/RestManager'
import CustomerList from '../components/Customer/CustomerList'
import CustomerDetails from '../components/Customer/CustomerDetails'
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import * as Constants from '/helpers/Constants'
import { Button, Fab } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

@connect(({ app }) => ({
  customers : app.customers,
  selectedCustomer: app.selectedCustomer,
  selectedProfessional: app.selectedProfessional,
  isClientListVisible : app.isClientListVisible,
}), {
  setCustomers,
  setSelectedCustomer,
  setIsEditMode,
  setIsNewMode,
  setIsClientListVisible
})

class CustomerPage extends Component 
{
  state = {
    splitDividerPos : '240px',
    activeTab : "exam",
  };

    constructor (props) {
        super(props);
        RestManager.getInstance().getAllCustomers( (result) => {
          this.onCustomersRetrieved(result)
        });
    }    
    
    onCustomersRetrieved = (result) => 
    {
      if ( result.success == true ) {
        console.log("in onCustomersRetrieved 1")
        const { setCustomers } = this.props
        setCustomers(result.response);
        console.log("in onCustomersRetrieved 2")

        if ( ! this.props.selectedCustomer ) {
          this.setSelectedCustomerFromUrl(this.props.history.location);
        }

      }
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) 
    {
      if (prevProps.selectedProfessional !== this.props.selectedProfessional) {
        RestManager.getInstance().getAllCustomers( (result) => {
          this.onCustomersRetrieved(result)
        });
      }

      // const customerPageMainDiv = document.getElementById('customerPageMainDiv');
      // Constants.onresize(customerPageMainDiv, this.onResize);

    }
    // componentDidMount()
    // {
    //   var customerPageMainDivWidth = customerPageMainDiv.offsetWidth;
    //   if ( customerPageMainDivWidth < 900 ){
    //     this.props.setIsClientListVisible(false);
    //   } else {
    //     this.props.setIsClientListVisible (true);
    //   }
    // }
  
    componentWillMount() {
      this.unlisten = this.props.history.listen((location, action) => {
        if ( location ) {
          this.setSelectedCustomerFromUrl(location);
        }
        console.log("CustomerPage -> on route change");
      });
    }
    componentWillUnmount() {
        this.unlisten();
    }
      
    setSelectedCustomerFromUrl = (location) => 
    {
      let search = location.search;
      console.log("search = " + search);
      let params = search.split("&");
      if ( params.length > 0 ) 
      {
        let res = params[0].split("=");
        if ( res.length > 1 ) {
          let id = res[1];
          RestManager.getInstance().getCustomer(id, (result) => {
            
            if ( this.props.selectedCustomer ) {
              let customers = this.props.customers;
              let selPos = customers.indexOf(this.props.selectedCustomer);
              customers[selPos] = result.response;
              this.props.setCustomers(customers);
          
            }
  
            this.props.setSelectedCustomer(result.response);
            this.props.setIsEditMode(false);
            this.props.setIsNewMode(false);
  
          });
        }

        if ( params.length > 1 ) {
          let res = params[1].split("=");
          if ( res.length > 1 && res[0] == "tab") {
            this.setState({activeTab : res[1]});
          }  
        }
          
      }
    }
  
    handleClientListToggle = () =>{
      this.props.setIsClientListVisible( ! this.props.isClientListVisible );
    }
  
    render() 
    {
      const { classes, isClientListVisible} = this.props;
console.log("isClientListVisible : ",  isClientListVisible);
        return (
          <div id='customerPageMainDiv'>
            <SplitPane split="vertical" className={classes.splitpane} 
              minSize={isClientListVisible ? this.state.splitDividerPos : 0 } 
              defaultSize={isClientListVisible ? this.state.splitDividerPos : 0} 
              size={isClientListVisible ? this.state.splitDividerPos : 0} 
            >
              <div id='customerList' >
                <div className="customerList">
                  <CustomerList/>

                  {isClientListVisible ?
                    <Button onClick={this.handleClientListToggle} variant="contained" color="primary" className={classes.rightToggle}>
                      <ArrowBackIosIcon />
                    </Button>
                    : ""
                  }                    
                </div>
              </div>
              <div className="customerList" >
                  <CustomerDetails style={{height:'100%'}} activeTab={this.state.activeTab}/>
                  {! isClientListVisible ?
                    <Button  onClick={this.handleClientListToggle}  variant="contained" color="primary" className={classes.leftToggle}>
                      <ArrowForwardIosIcon />
                    </Button>
                  : ""
                  }                    

              </div>
            </SplitPane>
          </div>
        )
    }
  }
  const styles = theme => ({
    splitpane: {
      position: 'relative !important',
    },
    rightToggle:{
      position:'absolute', 
      bottom:40, 
      right:'-2px',
      minWidth: '34px',
      width: '34px',
      borderBottomLeftRadius: '22px',
      borderTopLeftRadius: '22px',
    },
    leftToggle:{
      position:'absolute', 
      bottom:40, 
      left:'-2px',
      minWidth: '34px',
      width: '34px',
      borderBottomRightRadius: '22px',
      borderTopRightRadius: '22px',
    }
  });
  
export default withRouter(withStyles(styles)(CustomerPage));
