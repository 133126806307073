import React, {Component} from 'react';
import SearchField from 'react-search-field';
import { withStyles } from '@material-ui/core/styles';
import { Button, MenuItem, Select } from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import intl from 'react-intl-universal';
import utils from '../../helpers/utils';
import male from '/images/male50.png';
import female from '/images/female50.png';
import crypt from '../../helpers/crypt';
import RestManager from '../../persist/RestManager';
import SplitPane from 'react-split-pane';
import { white } from 'material-ui/styles/colors';
import { connect } from 'react-redux'

const styles = () => ({
  packageBox: {
    float: 'right',
    border: '1px solid #41b955',
    borderTop: '10px solid #41b955',
    marginRight: 10,
    padding: 3,
    minWidth: 40,
    fontSize: '0.9em',
    textAlign: 'center',
    backgroundColor:'white',
    color:'black',
  },
  searchBox: {
    position: 'absolute',
    background: 'white',
    zIndex: 9,
    width: '100%',
  },
  // listItem:{
  //   height: 26,
  //   backgroundColor: "#fbfbfb",
  //   paddingLeft: 8,
  //   paddingTop: 6,
  //   borderBottom: "1px solid lightgray",
  //   cursor: "pointer",
  //   "&:hover": {
  //     backgroundColor: "#efefef",
  //   },
  // },
  // listItemSelected:{
  //   height: 26,
  //   backgroundColor: "#3a95e4",
  //   color: "white",
  //   paddingLeft: 8,
  //   paddingTop: 6,
  //   borderBottom: "1px solid lightgray",
  //   cursor: "pointer",
  // },
  splitpane: {
    position: 'relative !important',
 
  },

});

@connect(({ app }) => ({
  selectedProfessional: app.selectedProfessional,
}))

class FormList extends Component 
{
  state = {
    forms:[],
    commonForms:[],
    filteredForms: [],
    filteredCommonForms:[],
    selectedForm: null,
    selectedCommonForm: null,
    searchText:"",
    formViewer:null,
  }

  constructor (props) {
    super(props);  
    this.reloadData();
    var formViewer = window.njs_UIViewer;
    this.state.formViewer = formViewer;

  }    


  reloadData = () => 
  {
    RestManager.getInstance().getMyForms(
      (result) => {
        if (result.success == true ) {
          this.setState({ forms: result.response });
        }
      }
    );
    var medicalSpeciality = null;
    if ( this.props.selectedProfessional ) {
      if ( this.props.selectedProfessional.medicalSpeciality ) {
        medicalSpeciality = this.props.selectedProfessional.medicalSpeciality.specialityCode;
      }
    }
    RestManager.getInstance().getCommonForms( medicalSpeciality,
      (result) => {
        if (result.success == true ) {
          this.setState({ commonForms: result.response });
        }
      }
    );
  }

  onMyFormClicked = (index) =>
  {
    const { filteredForms } = this.state;
    let selForm = filteredForms[index];
    this.setState({selectedForm: selForm });
    this.state.formViewer.init("formPreview", selForm.formUI);
    this.props.onItemClicked(selForm);
  }

  onCommonFormClicked = (index) =>
  {
    const { filteredCommonForms } = this.state;
    let selForm = filteredCommonForms[index];
    this.setState({selectedForm: selForm });
    this.state.formViewer.init("formPreview", selForm.formUI);
    this.props.onItemClicked(selForm);
  }

  onSearch = (searchText) => {
    this.setState({searchText:searchText});
  }

  onNewClient = () => {
    this.props.setIsNewMode(true);
  }

  filterForms = (form) => {
    const { searchText } = this.state;
    let name = form.formName.toLowerCase() +  (form.medicalSpeciality ? intl.get(form.medicalSpeciality).toLowerCase() : "" ) ;
    let lcSearchText = searchText.toLowerCase();
    let isOk =  name.indexOf(lcSearchText) > -1;
    return isOk;
  }

  render(){

    const { classes} = this.props;
    const {forms, commonForms, selectedForm, searchText } = this.state;
console.log( "IN FORMLIST render : ", this.state.forms);
    if ( forms || commonForms ) 
    {
      this.state.filteredForms = forms;
      this.state.filteredCommonForms = commonForms;
      if ( searchText ) {
        this.state.filteredForms = forms.filter(this.filterForms);
        this.state.filteredCommonForms = commonForms.filter(this.filterForms);
      } 
      
      var rows = this.state.filteredForms.map( ( filteredForm, i ) =>
        <div className={selectedForm && selectedForm.id == filteredForm.id ? "custListItemSelected" : "custListItem"} key={i} onClick={() => this.onMyFormClicked(i)}> 
          <div className="column">
            <div>{filteredForm.formName}</div>
          </div>
        </div>
      )
      
      var commonRows = this.state.filteredCommonForms.map( ( filteredForm, i ) =>
        <div className={selectedForm && selectedForm.id == filteredForm.id ? "custListItemSelected" : "custListItem"} 
            key={i} 
            onClick={() => this.onCommonFormClicked(i)}
            style={{height:52}}
        > 
          <div className="column" >
            <div>{filteredForm.formName}</div>
            { filteredForm.medicalSpeciality ? 
              <div>({intl.get(filteredForm.medicalSpeciality)})</div>
            : "(Tüm branşlar)" }
          </div>
        </div>
      )

      return (
        
        <div style={{ border:'1px solid #efefef'}}>

            <SplitPane split="vertical" className={classes.splitpane} minSize={240} defaultSize={400} >
              <div style={{height:'100%', overflowY:'scroll'}}>
                <div className={classes.searchBox} style={{display:this.props.isPreviewVisible == false ? 'none':'block' }}>
                  <SearchField classNames='custSearchField' placeholder={intl.get("SEARCH")} onChange={this.onSearch} />
                </div>
                <div style={{position:'relative', top:this.props.isPreviewVisible == false ? 0 : 40 }}>
                  <div className="commonFormsLabel">
                    Formlarım
                  </div>
                  {rows}
                  <div>
                    <div className="commonFormsLabel">
                      Diğer Formlar
                    </div>
                    {commonRows}
                  </div>
                </div>
              </div>

              <div style={{height:'100%', margin:'0 10', overflowY:'scroll', display:this.props.isPreviewVisible == false ? 'none':'block' }}>
                <div id="overlay">
                  <div id="overlayText">
                    Önizleme
                  </div>
                </div>
                <div id="formPreview" style={{width:'750px'}}>
                </div>
              </div>

            </SplitPane>

        </div>
        );
    } 
    return <div style={{padding:10}} >{intl.get("LOADING_LIST")}</div>;
  }  

}
  
export default withRouter(withStyles(styles)(FormList));
