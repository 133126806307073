// Converts `UNDERSCORED_NAMES` to `camelCasedNames`.
// E.g. event `GET_USERS_ERROR` => state.`getUsersError`.
export function underscoredToCamelCase(string)
{
	return string.split('_')
		.map((word, i) =>
		{
			let firstLetter = word.slice(0, 1)
			const rest      = word.slice(1)

			if (i === 0)
			{
				firstLetter = firstLetter.toLowerCase()
			}
			else
			{
				firstLetter = firstLetter.toUpperCase()
			}

			return firstLetter + rest.toLowerCase()
		})
		.join('')
}

// Converts `namespace` and `event` into a namespaced event name
export function eventName(namespace, event)
{
	return namespace ? `${namespace}: ${event}` : event
}

export const DEFAULT_REDUX_EVENT_NAMING = (event) =>
([
	`${event}_PENDING`,
	`${event}_SUCCESS`,
	`${event}_ERROR`
])

export function generateReduxNamespace(counter) {
	return `REACT_WEBSITE_${counter}`
}

export function generateReduxEventName(counter) {
	return `REACT_WEBSITE_ACTION_${counter}`
}

export function isAutogeneratedReduxEventName(name) {
	// Matches "REACT_WEBSITE_1: REACT_WEBSITE_ACTION_2_PENDING".
	// Matches "REACT_WEBSITE_1: REACT_WEBSITE_ACTION_2".
	// Matches "SLIDESHOW: REACT_WEBSITE_ACTION_3".
	// Matches "SLIDESHOW: REACT_WEBSITE_ACTION_3_ENDING".
	return name.indexOf('REACT_WEBSITE_') >= 0
}

// Checks for `ReduxModule` autogenerated event names conflicts.
export function checkForAutogeneratedEventNameCollision(reducers) {
	const reduxModuleAutogeneratedEventNames = {}
	for (const reducerName of Object.keys(reducers)) {
		if (reducers[reducerName]._react_website_events) {
			const autogeneratedReduxEventNames = []
			for (const eventName of reducers[reducerName]._react_website_events) {
				if (isAutogeneratedReduxEventName(eventName)) {
					for (const _reducerName of Object.keys(reduxModuleAutogeneratedEventNames)) {
						if (reduxModuleAutogeneratedEventNames[_reducerName].indexOf(eventName) >= 0) {
							throw new Error(`"ReduxModule" autogenerated action "type" collision detected for reducers "${_reducerName}" and "${reducerName}" and action "type" "${eventName}". Specify an explicit namespace for any of the two reducers to resolve the conflict.`)
						}
					}
					autogeneratedReduxEventNames.push(eventName)
				}
			}
			reduxModuleAutogeneratedEventNames[reducerName] = autogeneratedReduxEventNames
		}
	}
}