import React from 'react';
import male from '/images/male50.png';
import female from '/images/female50.png';
import maleDoctor from '/images/maleDoctor.png';
import femaleDoctor from '/images/femaleDoctor.png';
import maleAssistant from '/images/assistantMale100.png';
import femaleAssistant from '/images/assistantFemale100.png';
import crypt from '../../helpers/crypt';
import classNames from 'classnames';
import { IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import intl from 'react-intl-universal';


class PersonnelList extends React.Component 
{

  state = {
    selectedPersonnel:null,
    cryptKeyVisible:false,
  };

  constructor (props) {
    super(props);  
  }    
  
  onClicked = (index) =>
  {
    let selPersonnel = this.props.personnels[index];
    this.setState({selectedPersonnel:selPersonnel });
    this.props.onClicked(selPersonnel);
  }

  toggleCryptKey = (i) =>{
    this.setState({ [i] : ! this.state[i]});
  }

  render ()
  {
    const companyCryptKey = crypt.getCompanyCryptKey();

    if ( this.props.personnels )
    {
      var rows = this.props.personnels.map( ( personnel, i ) =>
        <div className={this.state.selectedPersonnel && this.state.selectedPersonnel.id == personnel.id ? 'custListItemSelected2' : 'custListItem'} 
          style={{height:90}} key={i} onClick={() => this.onClicked(i)}> 
          {this.props.isProfessional ? 
          <img className='avatar' src={personnel.gender == 2 ? femaleDoctor : maleDoctor } alt="Avatar" height="80" width="80"></img>
          :
          <img className='avatar' src={personnel.gender == 2 ? femaleAssistant : maleAssistant } alt="Avatar" height="80" width="80"></img>
          }
          <div style={{fontSize:'1.1em', lineHeight:'1.6em', paddingLeft:90}}>
            <div className="column">
              <div><strong>{personnel.title} {personnel.firstname} {personnel.lastname}</strong></div>
              { personnel.medicalSpeciality ? <div>({intl.get(personnel.medicalSpeciality.specialityCode)})</div> : ""}
              <div> {personnel.phone} / {personnel.email}</div>
            </div>
              { personnel.user && personnel.user.isAdmin ?
                <div className="column" style={{marginLeft:20, background: 'mediumseagreen', color: 'white', padding: '4px 15px'}}>
                <strong>{intl.get("ADMINISTRATOR")}</strong>
              </div>
          :
              // personnel.user && ! this.props.isProfessional ?
              //   <div className="column" style={{marginLeft:20}}>
              //     <div >
              //       {personnel.user.customerDocumentsVisible ? <div>&#9989; Hasta/Danışan dokümanlarını görüntüleyebilir</div>: <div>&#10060; Hasta/Danışan dokümanlarını  görüntüleyemez</div>}
              //       {personnel.user.reportsVisible ? <div>&#9989; Raporları görüntüleyebilir</div>: <div>&#10060; Raporları görüntüleyemez</div>}
              //       {personnel.user.chartsVisible ? <div>&#9989; Grafikleri görüntüleyebilir</div>: <div>&#10060; Grafikleri görüntüleyemez</div>}
              //     </div>
                    
              //   </div>
              // :''
              ""
          }

          {/*
          {this.props.isKeyVisible ?
            <div>
              <div className='crypt-key'>
                { this.state[i] ? 
                  personnel.user.originalCryptKey == true ? companyCryptKey : crypt.getUserCryptKey(personnel.user.id) 
                :
                  '**********'
                }
                <span>
                  <IconButton style={{padding:0, marginLeft:10, color:'white', cursor:'pointer'}}
                    onClick={ () => this.toggleCryptKey(i) }  >
                    {this.state[i] ? <Visibility /> : <VisibilityOff />}
                  </IconButton>

                </span>

              </div>
              <div style={{float:'right', padding: 5, lineHeight: '20px'}} >
                {intl.get("CRYPT_KEYWORD")}:
              </div>
            </div>
          :""}
          */}

          </div>
        </div>
      )
    }
    return (
      <div style={{position:'relative', borderTop:'1px solid lightgrey'}}>
        {rows}
      </div>
    )
  }

}

export default PersonnelList;