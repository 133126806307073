import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import RestManager from '/persist/RestManager'
import BigCalendar from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'moment/locale/tr'
import moment from 'moment';
import utils from '/helpers/utils';
import intl from 'react-intl-universal';

let counter = 0;
function createData(title, start, end) {
  counter += 1;
  return { id: counter, title, start, end };
}

@connect(({ app }) => ({
  selectedCustomer: app.selectedCustomer,
}))
class Calendar extends React.Component 
{
  state = {
    customer:null,
    examinations:null,
    data:[],
  };

  constructor(props){
    super(props);
    this.reloadData();
  }

  reloadData = () => 
  {
    const { selectedCustomer} = this.props;
    RestManager.getInstance().getExaminations(selectedCustomer.id, 
      (result) => {
        // console.log("getExaminations result : ", result);
        let examinations = result.response;
        this.state.examinations = examinations;
        let data = [];
        for ( let i = 0 ; i < examinations.length; i++ ) {
          let exam = examinations[i];
          // let startDate = utils.UTC2GMT(exam.examDate);
          // let endDate = utils.UTC2GMT(exam.examDate);
          let startDate = exam.examDate;
          let endDate = exam.examDate;
          data.push(createData(utils.getHours(exam.examDate.toString()) + "  " + exam.examNote, startDate, endDate ));
        }
        if ( this.state.customer != selectedCustomer ) {
          this.state.customer = selectedCustomer;
          this.state.selected = [];
          counter = 0;
        } 
        this.state.data = data;
        this.setState({ data: data });
      }
    );
  }

  render() 
  {
    const { selectedCustomer} = this.props;
    const { examinations, data } = this.state;
    if ( this.state.customer != selectedCustomer ) {
      this.reloadData();
    }
    let allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k])
    moment.locale(intl.getInitOptions().currentLocale);
    const localizer = BigCalendar.momentLocalizer(moment)
    return (
      <div>
      <BigCalendar style={{margin:20}}
        events={data}
        // views={allViews}
        // views={['month', 'week', 'day', 'agenda']}
        views={['month']}
        step={60}
        showMultiDayTimes
        // max={dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours')}
        defaultDate={new Date()}
        localizer={localizer}
        messages={{'today': intl.get('TODAY'), "previous":'< '+intl.get('BACK'), "next":intl.get('FORWARD')+" >", "month":intl.get('MONTH'), 
        "week":intl.get('WEEK'), "work_week":"Working Week Days", "day":intl.get('DAY'), "agenda":intl.get('AGENDA'),
        "date":intl.get('DATE'), "time":intl.get('TIME'), "event":intl.get('EXAMINATION')}}
      />
      </div>
    )

  }
}

Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (Calendar);
