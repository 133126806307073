module.exports = [
{
  "COPYRIGHT":"© Palm IT Solutions - Bütün Hakları Saklıdır - {year}",
  "FIRSTNAME": "Ad",
  "LASTNAME": "Soyad",
  "EMAIL": "E-Posta",
  "PASSWORD": "Şifre",
  "CONFIRM_PASSWORD": "Şifre Tekrar",
  "IDENTITY_NUMBER": "Kimlik Numarası",
  "PHONE":"Telefon Numarası",
  "SUBMIT": "Giriş",
  "FORGOT_PASSWORD": "ŞİFREMİ UNUTTUM",
  "CREATE_NEW_ACCOUNT": "YENİ HESAP OLUŞTUR",
  "COMPANY": "Firma Adı",
  "BACK_TO_LOGIN_PAGE":"GİRİŞ EKRANINA GERİ DÖN",
  "CONTINUE":"Devam",
  "REGISTER":"Kayıt Ol",
  "WARNING":"Uyarı",
  "ERROR":"Hata",
  "INFO":"Bilgi",
  "MISSING_DATA":"Eksik Veri",
  "PLEASE_ENTER": "Lütfen {missingData} verisini giriniz.",
  "PLEASE_CONFIRM_PASSWORD":"Lütfen şifreyi tekrar girin.",
  "PASSWORDS_DO_NOT_MATCH":"Şifreler birbiri ile aynı değil.",
  "PLEASE_WAIT":"Lütfen bekleyiniz.",
  "SYSTEM_ERROR":"Sistem hatası",
  "ADMINISTRATOR":"Yönetici",

  "WRONG_EMAIL_OR_PASSWORD":"Yanlış Mail adresi ya da şifre",
  "COULD_NOT_LOGIN":"Giriş yapılamıyor.",
  "EMAIL_SENT_SUCCESSFULLY":"E-Posta gönderimi başarılı",
  "PLEASE_CHECK_YOUR_INBOX":"Lütfen E-Posta kutunuzu kontrol ediniz.",
  "EMAIL_SEND_UNSUCCESSFUL":"E-Posta gönderimi başarısız",
  "PLEASE_CONNECT_PRAXIS_TEAM":"Lütfen Praxis Destek ile iletişime geçin.",
  "PLEASE_GO_BACK_TO_LOGIN":"Lütfen giriş sayfasına gidin.",
  "PLEASE_LOGIN_AGAIN":"Lütfen tekrar giriş yapınız",

  "USER_WITH_THIS_EMAIL_ALREADY_EXISTS":"Bu Mail adresi veya telefon numarası ile kayıtlı bir kullanıcı var. ",
  "USER_CANNOT_BE_CREATED":"Kullanıcı oluşturulamadı.",

  "SERVER_ERROR":"Sunucu hatası",
  "COULD_NOT_CONNECT_TO_SERVER":"Sunucuya bağlanamıyor.",
  "AUTHORIZATION_ERROR":"Yetki hatası",

  "ACCOUNT_CREATED":"Hesabınız oluşturuldu.",
  "ACCOUNT_NOT_ACTIVE":"Hesabınız aktif değil.",
  "ACTIVATING_ACCOUNT":"Hesabınız aktif hale getiriliyor.",
  "ACCOUNT_ACTIVATED":"Hesabınız aktif hale getirildi.",
  "CONFIRMATION_LINK_SENT":"Aktivasyon Linki verdiğiniz mail adresine gönderildi. ",
  "CLICK_CONFIRMATION_LINK":"Hesabınızı aktif hale getirmek için lütfen size gönderdiğimiz maildeki linke tıklayınız.",
  "CAN_LOGIN_NOW":"Giriş sayfasına dönerek email adresiniz ve şifrenizle giriş yapabilirsiniz.",
  "RESEND_ACTIVATION_MAIL":"AKTİVASYON MAİLİNİ TEKRAR GÖNDER",
  "ACCOUNT_NOT_ACTIVATED":"Hesabınız aktif hale getirilemedi.",
  "NEW_ACTIVATION_MAIL_SENT":"Yeni aktivasyon mailiniz mail adresinize gönderildi.",
  "WILL_SEND_ACTIVATION_CODE_PER_SMS":"Telefonunuza doğrulama kodu gönderilecektir.",

  "CRYPT_KEYWORD":"Anahtar Kelime",
  "CRYPT_KEYWORD_AGAIN":"Anahtar Kelime tekrar",
  "YOUR_CRYPT_KEYWORD":"Anahtar Kelimeniz",
  "CREATE_NEW_CRYPT_KEYWORD":"Yeni bir anahtar kelime oluştur",
  "KEYWORD_FOR_ENCRYPTION_TITLE":"Veri şifreleme için anahtar kelime girişi",
  "PLEASE_READ_CAREFULLY":"Verilerinizin gizliliğini önemsiyoruz. Bilgilendirmeleri lütfen dikkatlice okuyunuz.",
  "PLEASE_ENTER_CRYPT_KEYWORD":"Lütfen anahtar kelimenizi giriniz",
  "CRYPT_KEYWORDS_DO_NOT_MATCH":"Anahtar kelimeler birbiri ile aynı değil",
  "CRYPT_KEYWORD_AT_LEAST_EIGHT_CHARS":"Lütfen en az 8 karakterli bir kelime seçiniz",
  "CONFIRM_YOUR_CRYPT_KEYWORD":"Anahtar kelimenizi onaylıyor musunuz?",
  "DO_NOT_FORGET_TO_NOTE_DOWN":"Anahtar kelimenizi güvenli bir yere not etmeyi unutmayın.",
  "I_CONFIRM_MY_CRYPT_KEYWORD":"Anahtar kelimemi onaylıyorum",

  "NEW_RECORD":"YENİ KAYIT",
  "SEARCH":"Ara",
  "LOADING_LIST":"Liste yükleniyor...",
  "LOADING":"Yükleniyor...",

  "PATIENTS_VIEW":"Hastalar / Danışanlar",
  "CALENDAR_VIEW":"Randevular",
  "REPORTS_VIEW":"Raporlar",
  "CHARTS_VIEW":"Grafikler",
  "ACCOUNT_VIEW":"Hesabım",
  "PAYMENT_VIEW":"Ödeme",
  "PROFESSIONALS_VIEW":"Doktorlar",
  "FORMS_VIEW":"Formlar",
  "SECURITY_VIEW":"Veri Güvenliği / KVKK",

  "PATIENTS":"Hastalar / Danışanlar",
  "CALENDAR":"Takvim",
  "REPORTS":"Raporlar - Tablolar",
  "CHARTS":"Grafikler",
  "DAILY_REPORT":"Günlük Rapor",
  "MONTHLY_REPORT":"Aylık Rapor",
  "YEARLY_REPORT":"Yıllık Rapor",
  "MONTHLY_INCOME_CHART":"Aylık Ciro Grafiği",
  "YEARLY_INCOME_CHART":"Yıllık Ciro Grafiği",
  "GENDER_CHART":"Hasta Cinsiyet Grafiği",
  "SOURCE_CHART":"Hasta Yönlendirme Grafiği",
  "MY_ACCOUNT":"Kullanıcı Bilgileri",
  "LOGOUT":"Çıkış",
  "DO_YOU_WANT_TO_LOGOUT":"Praxis'ten çıkmak istiyor musunuz?",
  "DO_YOU_WANT_TO_DELETE":"Silmek istiyor musunuz?",

  "OK":"Tamam",
  "CANCEL":"İptal",
  "SAVE":"Kaydet",
  "UPDATE":"Güncelle",
  "EDIT":"Düzenle",
  "DELETE":"Sil",
  "NEW":"Yeni",
  "YES":"Evet",
  "NO":"Hayır",
  "CLOSE":"Tamam",
  "EXPAND":"Genişlet",
  "PLEASE_SELECT":"Lütfen Seçiniz",
  "SELECT":"Seç",

  "PACKAGE_REST_TOTAL":"Paket: Kalan / Toplam",

  "NEW_PATIENT":"Yeni Hasta / Danışan",
  "GENDER":"Cinsiyet",
  "MALE":"Erkek",
  "FEMALE":"Kadın",
  "NAME":"Ad",
  "SURNAME":"Soyad",
  "BIRTHDATE":"Doğum Tarihi",
  "NATIONAL_ID_NUMBER":"TC Kimlik No",
  "FATHER_NAME":"Baba Adi",
  "SOURCE":"Bize nasıl ulaştığı?",
  "STREET":"Sokak",
  "CITY":"Şehir",
  "COUNTRY":"Ülke",
  "COUNTY":"İlçe",
  "POSTAL_CODE":"Posta Kodu",
  "AGE":"Yaş",

  "UNKNOWN":"Bilinmeyen",
  "WEBSITE":"Web Sitesi",
  "SEARCH_ENGINE":"Arama Motoru",
  "FRIEND":"Arkadaş",
  "SOCIAL_MEDIA":"Sosyal Medya",

  "APPOINTMENTS":"Muayeneler",
  "PACKAGE":"Paket",
  "PACKAGES":"Paketler",
  "ACTIVATE":"Aktive Et",
  "DEACTIVATE":"Deaktive Et",

  "EXAMINATION":"Muayeneler",
  "EXAMINATION_DATE":"Muayene Tarihi",
  "EXAMINATION_COUNT":"Muayene Sayisi",
  "PAYMENT":"Ödeme",
  "PAID":"Ödenen",
  "PAYMENT_DATE":"Ödeme Tarihi",

  "WANT_TO_DELETE_PATIENT":"Bu kayıdı silmek istediğinize emin misiniz?",
  "DELETE_PATIENT_MESSAGE":"Randevu bilgileri, paket bilgileri, ödeme bilgileri ve hastaya ait diğer bütün veriler silinecektir.",

  "FORMS":"Formlar",
  "DELETE_FORM":"Formu Sil",


  "DOCUMENTS":"Dokümanlar",
  "ADD_DOCUMENT":"Belge Ekle",
  "ADD_NOTICE":"Not Ekle",
  "VIEW_DOCUMENT":"Görüntüle",

  "DELETE_DOCUMENT":"Dokümanı sil?",
  "WANT_TO_DELETE_DOCUMENT":"Seçili dokümanı silmek istiyor musunuz?",

  "PROFESSIONAL":"Doktor",
  "PROFESSIONALS":"Doktorlar",
  "ADD_PROFESSIONAL":"Doktor ekle",
  "NEW_PROFESSIONAL_DATA":"Yeni Doktor Bilgileri",
  "DELETE_PROFESSIONAL":"Doktoru sil?",
  "WANT_TO_DELETE_PROFESSIONAL":"Seçili doktoru silmek istiyor musunuz?",

  "ASSISTANT":"Asistan",
  "ASSISTANTS":"Asistanlar",
  "ADD_ASSISTANT":"Asistan ekle",
  "NEW_ASSISTANT_DATA":"Yeni Asistan Bilgileri",
  "DELETE_ASSISTANT":"Asistanı sil?",
  "WANT_TO_DELETE_ASSISTANT":"Seçili assistanı silmek istiyor musunuz?",

  "DATE_HOUR":"Tarih - Saat",
  "PRICE":"Ücret",
  "CASH":"Nakit",
  "CREDIT":"Kredi",
  "CREDIT_CARD":"Kredi Kartı",
  "TRANSFER":"Transfer",
  "REST":"Kalan",
  "NOTE":"Not",
  "TYPE":"Tip",
  "SORT":"Sırala",
  "TOTAL":"Toplam",
  "INCOME":"Ciro",
  "GROSS_INCOME":"Toplam Ciro",
  "COLLECTED":"Tahsil edilen",
  "RECEIVABLE":"Tahsil edilecek",

  "PROCESS_DATE":"İşlem Tarihi",
  "NUMBER_OF_APPOINTMENTS":"Görüşme Sayısı",
  "USED_APPOINTMENTS":"Yapılan Görüşme",
  "REST_APPOINTMENTS":"Kalan Görüşme",
  
  "TODAY":"Bugün",
  "BACK":"Geri",
  "FORWARD":"İleri",
  "YEAR":"Yıl",
  "MONTH":"Ay",
  "WEEK":"Hafta",
  "DAY":"Gün",
  "AGENDA":"Ajanda",
  "DATE":"Tarih",
  "TIME":"Zaman",
  "PREVIOUS_DAY":"Önceki Gün",
  "NEXT_DAY":"Sonraki Gün",
  "PREVIOUS_MONTH":"Önceki Ay",
  "NEXT_MONTH":"Sonraki Ay",
  "PREVIOUS_YEAR":"Önceki Yıl",
  "NEXT_YEAR":"Sonraki Yıl",
  
  "JANUARY":"Ocak",
  "FEBRUARY":"Şubat",
  "MARCH":"Mart",
  "APRIL":"Nisan",
  "MAY":"Mayıs",
  "JUNE":"Haziran",
  "JULY":"Temmuz",
  "AUGUST":"Ağustos",
  "SEPTEMBER":"Eylül",
  "OCTOBER":"Ekim",
  "NOVEMBER":"Kasım",
  "DECEMBER":"Aralık",

  "COMPANY_INFO":"Şirket Bilgilerl",
  "BILL_INFO":"Fatura Bilgilerl",
  "CHANGE_PASSWORD":"Şifre Değişikliği",
  "CURRENT_PASSWORD":"Güncel Şifre",
  "NEW_PASSWORD":"Yeni Şifre",
  "CONFIRM_NEW_PASSWORD":"Yeni Şifre Tekrar",
  "USER_SETTINGS":"Kullanıcı Ayarları",
  "USERS":"Kullanıcılar",
  "NEW_USER":"Yeni Kullanıcı",
  "LICENCE_INFO":"Lisans Bilgileri ve Üyelik Paketleri",
  "SMS_PACKAGES":"SMS Paketleri",
  "AGREEMENTS":"Sözleşmeler",
  "ACTIVE":"Aktif",
  "WANT_TO_DELETE_ACCOUNT":"Hesabımı silmek istiyorum",
  "DELETE_ACCOUNT":"Hesabımı sil",
  "CONFIRM_DELETE_ACCOUNT":"Hesabımı silmek istediğinize emin misiniz? Şirketinize ait bütün hasta kayıt bilgileri silinecektir. ",
  "CREATE_ACCOUNT_DESC":"Burada şirket çalışanlarınız için yeni hesap oluşturabilirsiniz. </br> Çalışanınız e-posta adresine gelecek link'e tıklayarak hesabını aktif hale getirmelidir.",
  "NEW_USER_FIRSTNAME":"Yeni kullanıcının Adı",
  "NEW_USER_LASTNAME":"Yeni kullanıcının Soyadı",
  "NEW_USER_EMAIL":"Yeni kullanıcıya ait E-Posta adresi",
  "PLEASE_FILL_THIS_FIELD":"Lütfen bu alanı doldurunuz",
  "CREATE_NEW_USER":"Yeni Kullanıcı Oluştur",


  "ANESTHESIOLOGY" : "Anesteziyoloji" ,
  "ENDOCRINOLOGY" : "Endokrinoloji",
  "BRAIN_SURGERY" : "Beyin ve Sinir Cerrahisi",
  "CARDIOLOGY" : "Kardiyoloji",
  "CHEST_DISEASES" : "Göğüs Hastalıkları",
  "CLINICAL_MICROBIOLOGY" : "Klinik Mikrobiyoloji",
  "DERMATOLOGY" : "Dermatoloji",
  "EYE_DISEASES" : "Göz Hastalıkları",
  "EAR_NOSE_AND_THROAT_DISEASES" : "Kulak Burun Boğaz",
  "FAMILY_MEDICINE" : "Aile Hekimliği",
  "GENERAL_SURGERY" : "Genel Cerrahi",
  "GYNECOLOGY_AND_OBSTETRICS" : "Kadın Hastalıkları ve Doğum",
  "INFECTIOUS_DISEASES" : "Enfeksiyon hastalıkları",
  "IMMUNOLOGY" : "İmmünoloji",
  "INTERNAL_MEDICINE" : "Dahiliye",
  "NEUROLOGY" : "Nöroloji",
  "NUCLEAR_MEDICINE" : "Nükleer Tıp",
  "NUTRITION_AND_DIET" : "Beslenme ve Diyetetik",
  "ONCOLOGY" : "Onkoloji",
  "ORAL_AND_DENTAL_DISEASES" : "Ağız ve Diş Sağlığı",
  "ORTHOPAEDICS" : "Ortopedi",
  "PEDIATRICS" : "Pediatri",
  "PHYSICAL_THERAPY_AND_REHABILITATION" : "Fizyoterapi Ve Rehabilitasyon",
  "PLASTIC_SURGERY" : "Plastik Cerrahi",
  "PSYCHIATRY" : "Psikiyatri",
  "PSYCHOLOGY" : "Psikoloji",
  "SPORTS_MEDICINE" : "Spor Hekimliği",
  "UROLOGY" : "Üroloji",
  "OTHER" : "Diğer",


"CRYPT_KEY_EXPLANATION" : "Hassas verileriniz sizin belirleyeceğiniz bir anahtar kelime ile şifrelendikten sonra veritabanlarımıza kaydediliyor. </br> Verilerinizi kırılması pratikte mümkün olmayan AES-256 bit kriptolama yönetimi ile şifreliyoruz. </br> Praxis-24 yazılım geliştiricileri, veritabanı uzmanları, şirket çalışanları ve yöneticileri, sunucularımızın bulunduğu veri merkezlerinin çalışanları dahil hiç kimse şifrelenmiş verilerinize ulaşamaz. </br> Verilerinize ulaşmanın tek yolu anahtar kelimeyi bilmektir. </br> Bu yüzden biraz sonra belirleyeceğiniz anahtar kelimenizi kesinlikle unutmamanız gerekiyor. </br> Yeni bir bilgisayarda oturum açtığınızda veya mevcut kullandığınız aygıtın tarayıcısının cache geçmişini temizlediğinizde bu anahtar kelimeyi tekrar girmeniz gerekecektir. </br> Anahtar kelimenizi sonradan değiştiremezsiniz. </br> Kullanmakta olduğunuz aygıtlarda istediğiniz zaman anahtar kelimenizi görüntüleyebilirsiniz. </br> Lütfen şimdi anahtar kelimenizi belirleyiniz ve bu kelimeyi muhakkak bir yere not ediniz.",




"SIMPLE": "Simple Sentence tr",
    "HELLO": "Hello, {name}. Welcome to {where}!",
    "TIP": "This is <span style='color:red'>HTML</span>",
    "TIP_VAR": "This is <span style='color:red'>{message}</span>",
    "SALE_START": "Sale begins {start, date}",
    "SALE_END": "Sale ends {end, date, long}",
    "COUPON": "Coupon expires at {expires, time, medium}",
    "SALE_PRICE": "The price is {price, number, USD}",
    "PHOTO": "You have {photoNum, plural, =0 {no photos.} =1 {one photo.} other {# photos.}}",
    "MESSAGE_NOT_IN_COMPONENT": "react-intl-universal is able to internationalize message not in React.Component"
}
]  ;