import React, {Component} from 'react';
import { connect } from 'react-redux';
import utils from '/helpers/utils'
import { IoIosCall, IoIosMail, IoIosReturnRight, IoIosHome } from "react-icons/io";
import { FaBirthdayCake } from "react-icons/fa";
import { IconContext } from "react-icons";
import {Fab, Link, Button, TextField, AppBar, Tabs, Tab, Typography, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton} from '@material-ui/core/';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CustomerForm from '../../components/Customer/CustomerForm'
import { setIsEditMode, setIsNewMode, setSelectedCustomer, setCustomers } from '../../redux/app'
import ExaminationList from '../Examination/ExaminationList'
import PackageTable from '../Package/PackageTable'
import RestManager from '/persist/RestManager'
import Calendar from '../../../src/components/Calendar/Calendar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as Constants from '/helpers/Constants'
import intl from 'react-intl-universal';
import male from '/images/male50.png';
import female from '/images/female50.png';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import DocumentList from '../Document/DocumentList';
import crypt from '../../helpers/crypt';
import Forms from '../Form/Forms';
import NSnackBar from '../Common/NSnackBar';

  const defaultTheme = createMuiTheme();
  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
        }
      }
    }
  });
  
function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingTop: 1 , width:'98%'}}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({

  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    height: '44px',
    minHeight: '44px',
    paddingBottom: '4px',
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    // backgroundColor: '#1890ff',
  },
  tabRoot: {
    color:'black',
    // textTransform: 'initial',
    minWidth: 60,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      // color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      // color: '#40a9ff',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit ,
  },

  // root: {
  //   textTransform: 'none',
  //   backgroundColor: theme.palette.background.paper,
  //   minWidth: 70,
  // },
  packageBoxHeader: {
    background: '#41b955',
    color: 'white',
    padding: '1px 10px 1px 10px',
    fontSize: '0.9em',
    fontWeight:'bold'
  },
  packageBoxLabel: {
    background: 'white',
    border:'1px solid #41b955',
    padding: 4,
    fontSize: '1.1em',
  },
});

@connect(({ app }) => ({
  selectedCustomer: app.selectedCustomer,
  isEditMode: app.isEditMode,
  isNewMode: app.isNewMode,
}), {
  setIsEditMode,
  setIsNewMode,
  setSelectedCustomer,
  setCustomers
})

class CustomerDetails extends Component 
{
  constructor (props) {
    super(props);
    RestManager.instance.getCurrentUser( (currentUser) =>
    {
        if ( currentUser ) {
          this.state.currentUser = currentUser;
        }
    })
  }    

  state = {
    value: 0,
    dialogOpen:false,
    custDetailVisible:false,
    cryptKeyVisible:false,
    consentCode:null,
    isSnackOpen: false,
    snackMessage:'',
    snackVariant:'',
    consentApproved:null,
    currentUser:null,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      console.log(" CustomerDetails CustomerDetails CustomerDetails");
      if ( this.props.selectedCustomer ) {
        this.setState({consentApproved: this.props.selectedCustomer.consentApproved});
      }
      if (  this.props.activeTab == "form" ) {
        this.setState({value: 1});
      }
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  onEditClient = () => {
    const { setIsEditMode} = this.props;
    setIsEditMode(true);
  }
  
  onExpand = () => {
    this.setState({custDetailVisible: !this.state.custDetailVisible});
  }

  openConfirmDialog = () => {
    this.setState({dialogOpen: !this.state.dialogOpen });
  }

  onDeleteClient = () => 
  {
    const { selectedCustomer, setSelectedCustomer } = this.props;

    RestManager.getInstance().deleteCustomer( selectedCustomer.id, (result) => {
      if ( result.success == true ) {
        setSelectedCustomer(null);
        setIsEditMode(true);
        this.reloadCustomers();
        this.setState({dialogOpen: false});
      }
    })  
  }

  onCustomerFormClose= () => {
    const { setIsEditMode, setIsNewMode} = this.props;
    setIsEditMode(false);
    setIsNewMode(false);
  }

  reloadCustomers () {
    RestManager.getInstance().getAllCustomers( (result) => {
      const { setCustomers } = this.props;
      setCustomers(result.response);
    });
  };

  toggleCryptKey = () =>{
    this.setState({cryptKeyVisible: ! this.state.cryptKeyVisible});
  }

  sendConsentSMS = () => {
    RestManager.getInstance().sendConsentSMS( this.props.selectedCustomer.id, (result) => {
      console.log(result);
      if ( result.success == true ) {
        this.setState({snackMessage:'SMS gönerildi.' , snackVariant:'success', isSnackOpen:true});
        this.setState({consentApproved:false});
      } else {
        this.setState({snackMessage:'SMS gönerilemedi.' , snackVariant:'error', isSnackOpen:true});
      }
    });
  }

  resendConsentSMS = () => {
    RestManager.getInstance().resendConsentSMS( this.props.selectedCustomer.id, (result) => {
      console.log(result);
      if ( result.success == true ) {
        this.setState({snackMessage:'SMS gönerilemedi.' , snackVariant:'error', isSnackOpen:true});
      } else {
        this.setState({snackMessage:'SMS gönerilemedi.' , snackVariant:'error', isSnackOpen:true});
      }
    });
  }

  saveConsentCode = () => {
    RestManager.getInstance().saveConsentCode( this.props.selectedCustomer.id, this.state.consentCode, (result) => {
      console.log(result);
      if ( result.success == true ) {
        this.setState({consentApproved:true});
      }  else if ( result.success == false && result.status == 5042 ){
        this.setState({snackMessage:'Hatalı Kod' , snackVariant:'error', isSnackOpen:true});
      }
    });
  }

  handleChange2 = name => event => {
    this.setState({ [name]: event.target.value });
  };

  onSnackClosed = () => {
    this.setState({ isSnackOpen:false });
  }

  render()
  {
    // if ( this.props.selectedCustomer ) {
    //   console.log("this.props.selectedCustomer.consentApproved : " + this.props.selectedCustomer.consentApproved);
    // }

    const { classes } = this.props;
    const { value, currentUser } = this.state;
    var isAssistant = currentUser.type == "assistant";
    // let allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k])
    // moment.locale('tr');
    // const localizer = BigCalendar.momentLocalizer(moment)

    const { selectedCustomer , isEditMode, isNewMode} = this.props;
        
    if ( selectedCustomer && selectedCustomer.id && isEditMode == false && isNewMode == false) 
    {
      let custCryptKey = crypt.getCustomerCryptKey(selectedCustomer.id);
      var lblAge = "";
      if ( selectedCustomer.birthdate != "" &&  selectedCustomer.birthdate != 'Invalid Date' ) {
        lblAge = ' ( '+intl.get('AGE')+': ' + utils.getAge(selectedCustomer.birthdate) + " )";
      }

        return (
          <div className="custDetailsMainDiv">

            <div className='custDetailHeader' > 
                <img className='avatar' src={selectedCustomer.gender == 2 ? female : male} alt="Avatar" height="50" width="50"></img>
                <div className="custDetailLabel">
                  <div className="column">{selectedCustomer.firstName} {selectedCustomer.lastName}</div>
                  <div className="custAgeLabel column">{lblAge}</div>
                  
                  <MuiThemeProvider theme={theme}>
                    <div style={{float:'right', marginRight:'15px', marginTop:'10px'}} >
                      <Tooltip title={intl.get('EXPAND')} >
                        <Fab size='small' onClick={this.onExpand}>
                          {this.state.custDetailVisible ? <ExpandLessIcon/> : <ExpandMoreIcon/>} 
                        </Fab>
                      </Tooltip>
                    </div>
                    <div style={{float:'right', marginRight:'15px', marginTop:'10px'}} >
                      <Tooltip title={intl.get("DELETE")} >
                        <Fab size='small' onClick={this.openConfirmDialog}>
                          <DeleteIcon />
                        </Fab>
                      </Tooltip>  
                    </div>
                    <div style={{float:'right', marginRight:'15px', marginTop:'10px'}} >
                      <Tooltip title={intl.get("EDIT")} >
                        <Fab size='small' color="primary" onClick={this.onEditClient}>
                          <EditIcon />
                        </Fab>
                      </Tooltip>
                    </div>
                  </MuiThemeProvider>

                </div>
            </div>

            <div style={details}>

              <div className="row rowPadding" style={{paddingLeft:'60px'}}>
                <div className="column">

                  <div className="row">
                    <div className="column">
                      <IconContext.Provider value={{ className: "miniIcons" }}> <IoIosCall/> </IconContext.Provider>
                    </div >
                    <div className="column" style={hLabel} >{selectedCustomer.phone ? selectedCustomer.phone : "-"} </div>
                    {/*
                    <div className="column" style={{paddingLeft:40}}>
                      { typeof this.state.consentApproved == "undefined" || this.state.consentApproved == null ?
                        <Link onClick={this.sendConsentSMS} style={{cursor:'pointer'}}>( Acik Riza Onay SMS'i gonder )</Link>
                        : 
                        this.state.consentApproved == false ?
                          <div>
                            <div className="column" style={hLabel} > Onay Kodu:</div>
                            <input type="number" value={this.state.consentCode} onChange={this.handleChange2('consentCode')} style={{width:70, border:'none', borderBottom:'1px solid lightgray', marginLeft:10}} />
                            <Link onClick={this.saveConsentCode} style={{cursor:'pointer'}}>Kaydet</Link>
                            <Link onClick={this.resendConsentSMS} style={{cursor:'pointer'}}>  / (Tekrar SMS gonder)</Link>
                          </div>
                        : 
                          <div>
                            <div className="column" style={{color:'seagreen'}} > &#10003; Açık Rıza Onaylandı</div>
                          </div>


                      }
                    </div>
                    */}

                  </div>

                  { selectedCustomer.email ?
                    <div className="row" style={{marginTop:'8px'}}>
                      <div className="column">
                        <IconContext.Provider value={{ className: "miniIcons" }}> <IoIosMail/> </IconContext.Provider>
                      </div >
                      <div className="column" style={hLabel} >{selectedCustomer.email ? selectedCustomer.email : "-"} </div>
                    </div>
                  :''}
                </div>
                { selectedCustomer.examCount - selectedCustomer.usedExams > 0 ?
                  <div className="column" style={{textAlign:'center', marginLeft:30}}>
                    <div className="row" className={classes.packageBoxHeader} >{intl.get('PACKAGE_REST_TOTAL')} </div>
                    <div className="row" style={hLabel} className={classes.packageBoxLabel}> {selectedCustomer.examCount-selectedCustomer.usedExams} / {selectedCustomer.examCount}</div>
                  </div >
                :''}
              </div>

              { this.state.custDetailVisible ? 
                <div>
                  <div className="row rowPadding" style={{paddingLeft:'60px'}}>
                    { selectedCustomer.address && (selectedCustomer.address.street || selectedCustomer.address.postalCode || selectedCustomer.address.county || selectedCustomer.address.city ) ? 
                      <div>
                        <div className="column">
                          <IconContext.Provider value={{ className: "miniIcons" }}> <IoIosHome/> </IconContext.Provider>
                        </div >
                        <div className="column"  >
                          <div className="row" style={hLabel} >{selectedCustomer.address.street} &nbsp;</div>
                          <div className="row">
                            <div className="column" style={hLabel} >{selectedCustomer.address.postalCode} </div>
                            <div className="column" style={hLabel} >{selectedCustomer.address.county} </div>
                            <div className="column" style={hLabel} >{selectedCustomer.address.city} </div>
                          </div>
                        </div>
                      </div> 
                    : '' }

                    { selectedCustomer.source ?
                      <div>
                        <div className="column" style={bigSpace}>
                          <IconContext.Provider value={{ className: "miniIcons" }}> <IoIosReturnRight/> </IconContext.Provider>
                        </div >
                        <div className="column" style={hLabel} >{  intl.get(Constants.SOURCE_ITEMS[selectedCustomer.source])} </div>
                      </div>
                    :""}

                    { selectedCustomer.birthdate != "" &&  selectedCustomer.birthdate != 'Invalid Date' ?
                    <div>
                      <div className="column" style={bigSpace}>
                        <IconContext.Provider value={{ className: "miniIcons" }}> <FaBirthdayCake/> </IconContext.Provider>
                      </div >
                      <div className="column" style={hLabel} > { utils.formatDate(selectedCustomer.birthdate, ".", true)  }  </div>
                    </div>
                    : "" }
                  </div>

                  <hr style={{margin:'10 50 0 50'}}></hr>

                  {/*
                  <div className="row rowPadding" style={{marginTop:'8px'}}>
                    <div className='crypt-key'>
                      { this.state.cryptKeyVisible ?
                        <div className="column" >{custCryptKey} </div>
                        :
                        '********'
                      }
                      <span>
                        <IconButton style={{padding:0, marginLeft:10, color:'white', cursor:'pointer'}}
                          onClick={ this.toggleCryptKey }
                        >
                          {this.state.cryptKeyVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </span>
                    </div>
                    <div className="column" style={{float:'right', padding:5, lineHeight:'20px'}} >{intl.get("CRYPT_KEYWORD")}:  </div>
                  </div>
                  */}
                
                </div>
              : '' }


            </div>

            <div className="row"> {/* // Examination, Package ,... tab panel */}

              {/* <Tabs>
                <div label="Muayene">
                  <ExaminationTable ></ExaminationTable>
                </div>
                <div label="Paket">
                  <PackageTable ></PackageTable>
                </div>
                <div label="Takvim" >
                  <Calendar></Calendar>
                </div>
              </Tabs> */}

              <div className={classes.root}>
                <AppBar position="static" color="default" className={classes.root}>
                  <Tabs
                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                    value={value}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    style={{minWidth: '60px !important'}}
                  >
                    <Tab label={intl.get('APPOINTMENTS')}  classes={{ root: classes.tabRoot, selected: classes.tabSelected }}/>
                    {! isAssistant && <Tab label={intl.get('DOCUMENTS')} classes={{ root: classes.tabRoot, selected: classes.tabSelected }}/>}
                    <Tab label={intl.get('CALENDAR')}  classes={{ root: classes.tabRoot, selected: classes.tabSelected }}/>
                    <Tab label={intl.get('PACKAGES')} classes={{ root: classes.tabRoot, selected: classes.tabSelected }}/>
                    {/* <Tab label="DOSYALAR"  />
                    <Tab label="MESAJLAR"  /> */}
                  </Tabs>
                </AppBar>
                {value === 0 && <TabContainer ><ExaminationList ></ExaminationList></TabContainer>}
                {value === 1 && ! isAssistant && <TabContainer ><DocumentList ></DocumentList></TabContainer>}
                {value === 1 && isAssistant && <TabContainer><Calendar></Calendar></TabContainer>}
                {value === 2 && isAssistant && <TabContainer><PackageTable ></PackageTable></TabContainer>}
                {value === 2 && <TabContainer><Calendar></Calendar></TabContainer>}
                {value === 3 && <TabContainer><PackageTable ></PackageTable></TabContainer>}
              </div>

            </div>


            <Dialog
              open={this.state.dialogOpen}
              onClose={this.openConfirmDialog }
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{intl.get('WANT_TO_DELETE_PATIENT')}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {intl.get('DELETE_PATIENT_MESSAGE')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={ this.openConfirmDialog } color="primary">
                  {intl.get("CANCEL")}
                </Button>
                <Button onClick={this.onDeleteClient} color="primary" autoFocus>
                  {intl.get("DELETE")}
                </Button>
              </DialogActions>
            </Dialog>

            <NSnackBar open={this.state.isSnackOpen}  variant={this.state.snackVariant} 
                  message={this.state.snackMessage}  onClose={this.onSnackClosed}>  </NSnackBar>



          </div>
        )
    } 
    return (
      <CustomerForm onClose={this.onCustomerFormClose} selectedCustomer={selectedCustomer}/>
    );
  }  
  
}

const hLabel = {
  paddingLeft : '6px',
  lineHeight: '20px'

}
const bigSpace = {
  paddingLeft : '30px'
}

const details = {
  // backgroundColor : 'rgb(249, 252, 255)',
  borderBottom: '1px solid lightgray',
  backgroundColor: '#f9f9f9',
  // minHeight:87
}

CustomerDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(CustomerDetails);
