import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import RestManager from '/persist/RestManager';

import queryString from 'query-string';
import Header from '../components/Common/Header';
import Footer from '../components/Common/Footer';
import { TextField, Button } from '@material-ui/core';
import MessageBox from '../components/Dialogs/MessageBox';
import { ACTIVE_PAGE } from './MainPage';
import {withRouter} from 'react-router-dom';

const styles = {
  textField: {
    width:'300px',
    margin:10
  },
  button:{
    margin:15
  }
};

class PasswordResetPage extends Component 
{
  constructor(props)
  {
    super(props);
    this.state={
      email:null,
      code:null,
      isCodeValid:false,
      password:null,
      confirmPassword:null,

      alertDialogOpen:false,
      alertText:'',
      alertTitle:'',
      backToLogin: false,

      loading: false,
  
    }  

    let params = queryString.parse(this.props.location.search)
    console.log("params = " , params.code)
    // console.log(window.location.host);
    if ( params.email && params.code ) {
      this.state.email = params.email;
      this.state.code = params.code;
    }
    else 
    {
      this.props.router.replace('/login')
    }
  }

  onResetPassword = () => 
  {
    this.setState({ alertTitle: "Eksik veri"});
    if ( ! this.state.password ) {
      this.setState({ alertText: "Lütfen Yeni Şifreyi girin." });
      this.setState({ alertDialogOpen: true });
      return;
    } else if ( ! this.state.confirmPassword ) {
      this.setState({ alertText: "Lütfen Yeni Şifreyi Tekrar girin." });
      this.setState({ alertDialogOpen: true });
      return;
    } else if ( this.state.password != this.state.confirmPassword ) {
      this.setState({ alertTitle: "Hatalı veri"});
      this.setState({ alertText: "Şifreler birbiri ile aynı değil." });
      this.setState({ alertDialogOpen: true });
      return;
    }

    this.setState({ loading: true});
    RestManager.getInstance().resetPassword( this.state.email, this.state.code, this.state.password , (result) => {
      this.setState({ loading: false});
      if (result.ok == true ) {
        this.setState({ alertTitle: "Şifre değiştirme başarılı", alertDialogOpen: true });
        this.setState({ alertText: "Yeni şifrenizle giriş yapabilirsiniz." });
      }
      else if ( result.status == 5021 ) { // PASS_RESET_CODE_NOT_CORRECT
        this.setState({ alertTitle: "Hatalı şifre yenileme Link'i", alertDialogOpen: true });
        this.setState({ alertText: "Eğer birden fazla şifre yenileme isteği gönderdi iseniz, E-Posta'nızdaki son maildeki linke tıklayınız. Ya da tekrar şifre yenileme isteğinde bulununuz." });
      } 
      else if ( result.status == 5022 ) { // PASS_RESET_CODE_NULL
        this.setState({ alertTitle: "Şifre yenileme Link'i artık geçerli değil", alertDialogOpen: true });
        this.setState({ alertText: "Lütfen tekrar şifre yenileme isteği gönderin." });
      }
      
      if ( result instanceof Error ) {
        this.setState({ alertTitle: "Sunucu Hatası", alertDialogOpen: true });
        this.setState({ alertText: "Sunucu bağlantı hatası. Lütfen Praxis Destek ile iletişime geçin." });
      } else {
        this.setState({ backToLogin: true});
      }
      console.log("result = ", result);

    });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };
  
  handleDialogClose = () =>
  {
    this.setState({ alertDialogOpen: false });
    if ( this.state.backToLogin ) {
      this.onBackToLogin();
    }
  }

  onBackToLogin = () => {
    this.props.history.push(ACTIVE_PAGE.LOGIN);
    window.location.reload();
    //    this.props.router.replace(ACTIVE_PAGE.LOGIN)

  }

  render() 
  {
    return (
        <div style={{textAlign:"center"}}>
          {/* <MuiThemeProvider> */}
  
            <Header></Header>

              <div style={{textAlign:"center",fontSize:24, marginTop:100, color:'gray', marginBottom:50}}>
                Lütfen yeni şifrenizi giriniz<br></br><br></br>

                  <TextField
                      type="password"
                      label="Yeni Şifre"
                      onChange = {this.handleChange('password')}
                      style={styles.textField} 
                  />
                <br/>
                  <TextField
                    type="password"
                    label="Yeni Şifre Tekrar"
                    onChange = {this.handleChange('confirmPassword')}
                    style={styles.textField} 
                  />

              </div>

              <div style={{textAlign:"center", marginTop:30, color:'gray', marginBottom:20}}>
                  <Button onClick={this.onResetPassword} disabled={this.state.loading} variant="contained" style={styles.button} color="primary">ŞİFREYİ GÜNCELLE</Button>
              </div>
                
              <div className="row">
                    <Button onClick={this.onBackToLogin} size="small" color="primary" >GİRİŞ EKRANINA GERİ DÖN </Button>
              </div>


              <Footer></Footer>

              <MessageBox
                alertDialogOpen={this.state.alertDialogOpen}
                onClose={this.handleDialogClose} 
                alertTitle={this.state.alertTitle} 
                alertText={this.state.alertText} >
              </MessageBox>

        </div>
      );
    // }
  }


}

PasswordResetPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(PasswordResetPage));
