import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import RestManager from '/persist/RestManager';
import queryString from 'query-string';
import Header from '../components/Common/Header';
import Footer from '../components/Common/Footer';
import intl from 'react-intl-universal';

const styles = {
  button:{
    margin:15
  }
};

class ActivationPage extends Component 
{
  constructor(props)
  {
    super(props);
    this.state={
      isActivating:false,
      isActivated:false,
      isActivationFailed:false,
      statusCheckEmail:false,
      statusWaitingForActivation:false,
      email:null,
      emailResent:false,
      emailResendFailed:false
    }  
    let params = queryString.parse(this.props.location.search)
    console.log("params = " , params.code)
    // console.log(window.location.host);
    if ( params.email ) {
      this.state.email = params.email;
    }
    if ( params.code ) {
      this.state.isActivating = true;
      RestManager.getInstance().activate( params.code, false, (result) => {
        if (result.ok == true ) {
          this.setState({ isActivated: true, isActivating: false});
        } else {
          this.setState({ isActivationFailed: true, isActivating: false });
        }
        console.log("result = ", result);
      });
    } 
    else if (params.status == "checkemail") {
      this.state.statusCheckEmail = true;
      //this.setState({ statusCheckEmail: true });
    }
    else if (params.status == "waiting") {
      this.state.statusWaitingForActivation =  true ;
    }
    else 
    {
      this.props.router.replace('/login')
    }
  }

  onBackToLogin = () => 
  {
    this.props.router.replace('/login')
    window.location.reload(false);
  } 

  onResendActivation = () => {
    RestManager.getInstance().resendActivation( this.state.email, (result) => {
      if (result.ok == true ) {
        this.setState( {emailResent:true} );
        this.setState( {emailResendFailed:false} );
      } else {
        this.setState( {emailResendFailed:true} );
        this.setState( {emailResent:false} );
      }
       console.log("onResendActivation result = ", result);
    });

  }

  render() 
  {
    return (
        <div style={{textAlign:"center"}}>
          {/* <MuiThemeProvider> */}
  
            <Header></Header>

            { (this.state.emailResent || this.state.emailResendFailed ) ?
              <div>
                { this.state.emailResent ?
                      <div style={{textAlign:"center",fontSize:24, marginTop:150, color:'gray', marginBottom:50}}>
                      {intl.getHTML('NEW_ACTIVATION_MAIL_SENT')}<br></br><br></br>
                    </div>
                : <div></div> 
                }

                { this.state.emailResendFailed ?
                      <div style={{textAlign:"center",fontSize:24, marginTop:150, color:'gray', marginBottom:50}}>
                      {intl.get('EMAIL_SEND_UNSUCCESSFUL')} <br></br><br></br>
                    </div>
                : <div></div> 
                }
              </div>
              :
              <div>
                { this.state.statusCheckEmail ?
                    <div style={{textAlign:"center",fontSize:24, marginTop:150, color:'gray', marginBottom:50}}>
                      {intl.get('ACCOUNT_CREATED')}<br></br><br></br>
                      {intl.getHTML('CONFIRMATION_LINK_SENT')}<br></br><br></br>
                      {intl.getHTML('CLICK_CONFIRMATION_LINK')}
                    </div>
                : <div></div> 
                }

                { this.state.statusWaitingForActivation ?
                    <div style={{textAlign:"center",fontSize:24, marginTop:150, color:'gray', marginBottom:50}}>
                      {intl.get('ACCOUNT_NOT_ACTIVE')} <br></br><br></br>
                      {intl.getHTML('CLICK_CONFIRMATION_LINK')}          
                    </div>
                : <div></div> 
                }

                { this.state.isActivating ?
                    <div style={{textAlign:"center",fontSize:24, marginTop:150, color:'gray', marginBottom:50}}>
                      {intl.get('ACTIVATING_ACCOUNT')}<br></br><br></br>
                      {intl.get('PLEASE_WAIT')}           
                    </div>
                : <div></div> 
                }

                { this.state.isActivated ?
                    <div style={{textAlign:"center",fontSize:24, marginTop:150, color:'gray', marginBottom:50}}>
                      {intl.get('ACCOUNT_ACTIVATED')}<br></br><br></br>
                      {intl.get('CAN_LOGIN_NOW')}          
                    </div>
                : <div></div> 
                }

                { this.state.isActivationFailed ?
                    <div style={{textAlign:"center",fontSize:24, marginTop:150, color:'gray', marginBottom:50}}>
                      {intl.get('ACCOUNT_NOT_ACTIVATED')}<br></br><br></br>
                      Aktivasyon kodu güncel olmayabilir. Lütfen aldığınız son maildeki linke tıklayınız ya da <br></br><br></br>
                      aktivasyon mailini tekrar gönder butonuna basarak işlemi tekrarlayınız.           
                    </div>
                : <div></div> 
                }

              </div>
            }



              <div style={{textAlign:"center", marginTop:50, color:'gray', marginBottom:100}}>
                { ( this.state.emailResendFailed == true || this.state.statusWaitingForActivation == true || this.state.isActivationFailed == true ) ? 
                  <Button onClick={this.onResendActivation}  variant="outlined" style={styles.button} color="primary">{intl.get('RESEND_ACTIVATION_MAIL')}</Button>
                :
                  <div></div>
                }
                <Button onClick={this.onBackToLogin} variant="outlined" style={styles.button} color="primary">{intl.get('BACK_TO_LOGIN_PAGE')}</Button>
              </div>
              
  
          <Footer></Footer>

        </div>
      );
    // }
  }


}

ActivationPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActivationPage);
