import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormGroup, Button, IconButton, InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import intl from 'react-intl-universal';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

class YearSelectorBar extends React.Component {

    state = {
      selectedYear:new Date().getFullYear(),
    };
    
    constructor(props){
        super(props);
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
        if ( name == 'selectedYear') {
          this.props.onYearSelected(event.target.value);
        }
        console.log("in handleChange : ", name, event.target.value)
    };
    
    onNext = () =>{
      let year = this.state.selectedYear;
      if ( year < new Date().getFullYear() ){
        year++;
      }
      this.setState({
        selectedYear:year, 
      });
      this.props.onYearSelected(year);
    }

    onPrevious = () =>{
      let year = this.state.selectedYear;
      if ( year > new Date().getFullYear()-9 ){
        year--;
      }
      this.setState({
        selectedYear:year, 
      });
      this.props.onYearSelected(year);
    }

    render ()
    {
      let year = new Date().getFullYear();
      let yearItems = new Array(10).fill(0).map( ( zero, i ) =>
        <MenuItem value={year-i}>{year-i}</MenuItem>
      )
      return (
        <FormGroup row center style={{padding:15, justifyContent:'center', borderBottom: '1px solid lightgrey' }}>

          <Button variant="outlined" size="small" color="primary" style={{marginRight:30}} onClick={this.onPrevious}>
            <NavigateBeforeIcon /> {intl.get('PREVIOUS_YEAR')}  </Button>
            
            <FormControl style={{marginLeft:30}}>
              <InputLabel id="reportYearPicker">{intl.get('YEAR')}</InputLabel>
              <Select
                labelid="reportYearPicker"
                id="reportYearPicker"
                value={this.state.selectedYear}
                onChange={this.handleChange('selectedYear')}
              >
                {yearItems}
              </Select>
            </FormControl>

              <Button variant="outlined" size="small" color="primary" style={{marginLeft:30}} onClick={this.onNext}>
              {intl.get('NEXT_YEAR')}  <NavigateNextIcon /></Button>


        </FormGroup>



        )
    }

}

export default withStyles(styles)(YearSelectorBar);