import DBManager from '../persist/DBManager';
import sha256 from 'sha256';

const utils = {
    formatDate: function(date, separator = "-", isYearLast = false, isWithTime = false, timeSeparator=" - ")
    { // returns YYYY-MM-DD
        if ( date ) {
            date = (date+"").replace("[UTC]", "");
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
    
            // return [year, month, day].join('-');
            if ( ! separator ) {
                separator = "-";
            }
            var txtTime = "";
            if ( isWithTime ) {
                var hour = d.getHours()+"";
                var min = d.getMinutes()+"";
                if ( hour.length == 1 ) hour = "0" + hour;
                if ( min.length == 1 ) min = "0" + min;
                txtTime = timeSeparator + hour + ":" + min;
            }
            if ( isYearLast == true ) {
                return [day, month, year].join(separator) + txtTime;
            }
            return [year, month, day].join(separator) + txtTime;
        }
        return "";
    },
    humanDate:function(date, isWithTime = false)
    { // returns dd.mm.yyyy
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        if ( day < 10 ) day = '0' + day;
        if ( month < 10 ) month = '0' + month;
        var txtTime = "";
        if ( isWithTime ) {
            var hour = d.getHours()+"";
            var min = d.getMinutes()+"";
            if ( hour.length == 1 ) hour = "0" + hour;
            if ( min.length == 1 ) min = "0" + min;
            txtTime = " / " + hour + ":" + min;
        }
        return day+"."+month+"."+year+txtTime;
    },
    getHours: function(date)
    { // returns HH:MM
        if ( date ) {
            date = date.replace("[UTC]", "");
            var d = new Date(date);
            var hour = d.getHours()+"";
            var min = d.getMinutes()+"";
            if ( hour.length == 1 ) hour = "0" + hour;
            if ( min.length == 1 ) min = "0" + min;
            var txtTime = hour + ":" + min;
            return txtTime;
        }
        return "";
    },
    getStartEndOfDayInMilli: function(day)
    { // gets a day and returns start and end of that day in milliseconds
        if ( day ) {
            let start = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0,0,0,0);
            let end = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 23,59,59,99);
            console.log('start and end = ', start, end)
            let startMilli = start.getTime(); // in milliseconds
            let endMilli = end.getTime(); // in milliseconds
            return {start : startMilli, end : endMilli};
        }
        return null;
    },
    getStartEndOfWeekInMilli: function(day)
    { // gets a day and returns start and end of that day in milliseconds
        if ( day ) {            
            let start = new Date(day.getTime());
            start.setDate(start.getDate() - ( day.getDay() -1 ) );
            let end = new Date(day.getTime());
            end.setDate(day.getDate() + ( 7 - day.getDay()) );
            start = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0,0,0,0);
            end = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23,59,59,99);
            console.log('start and end = ', start, end)
            let startMilli = start.getTime(); // in milliseconds
            let endMilli = end.getTime(); // in milliseconds
            return {start : startMilli, end : endMilli};
        }
        return null;
    },
    getStartEndOfMonthInMilli: function(day)
    { // gets a day and returns start and end of the month of that day in milliseconds
        if ( day ) {
            let start = new Date(day.getFullYear(), day.getMonth(), 1, 0,0,0,0);
            let end = new Date(day.getFullYear(), day.getMonth()+1, 1, 0,0,0,0);
            console.log('start and end = ', start, end)
            let startMilli = start.getTime(); // in milliseconds
            let endMilli = end.getTime(); // in milliseconds
            return {start : startMilli, end : endMilli};
        }
        return null;
    },
    getStartEndOfYearInMilli: function(day)
    { // gets a day and returns start and end of the year of that day in milliseconds
        if ( day ) {
            let start = new Date(day.getFullYear(), 0, 1, 0,0,0,0);
            let end = new Date(day.getFullYear()+1, 0, 1, 0,0,0,0);
            console.log('start and end = ', start, end)
            let startMilli = start.getTime(); // in milliseconds
            let endMilli = end.getTime(); // in milliseconds
            return {start : startMilli, end : endMilli};
        }
        return null;
    },
    // getTimeZoneDate: function(date){
    //     console.log("getNoTimeZoneDate before: ", date);
    //     var d = new Date(date);
    //     var month = '' + (d.getMonth() + 1);
    //     var day = '' + d.getDate();
    //     var year = d.getFullYear();
    //     if (month.length < 2) month = '0' + month;
    //     if (day.length < 2) day = '0' + day;
    //     var hour = d.getHours()+"";
    //     var min = d.getMinutes()+"";
    //     if ( hour.length == 1 ) hour = "0" + hour;
    //     if ( min.length == 1 ) min = "0" + min;
    //     // 2013-02-14T21:00:00.000Z
    //     var nDate = year + "-" + month + "-" + day +"T"+hour+":"+min+":00Z";
    //     console.log("getNoTimeZoneDate after: ", nDate);
    //     return nDate;
    // },
    getAge: function(date)
    {
        if ( date ) {
            var d = new Date();
            var thisYear = d.getFullYear();
            var thisMonth = '' + (d.getMonth() + 1);
    
            date = (date+"").replace("[UTC]", "");
            d = new Date(date);
            var year = d.getFullYear();
            var month = '' + (d.getMonth() + 1);
    
            var age = thisYear - year;
            if ( thisMonth < month ) {
                age = age -1;
            }
            return age;
        }
        return "";
    },
    UTC2GMT: function (txtUTCDate) {
        // console.log("UTC2GMT before: " + txtUTCDate);
        let nDate = new Date(txtUTCDate.replace("[UTC]", ""));
        // console.log("UTC2GMT after: " + nDate);
        return nDate;
    },
    convertServerDate: function (sDate) {
        return this.UTC2GMT(sDate);
    },
    // formatServerDate: function(customer)
    // {
    //     if ( customer.birthdate ) {
    //         customer.birthdate = customer.birthdate.replace("[UTC]", "");
    //         console.log('birthdate = ', customer.birthdate);
    //         return customer;
    //     }
    //     return customer;
    // },
    parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(window.atob(base64));
    },
    checkEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(email) ) {
            return true;
        }
        else {
            return false;
        }

    },
    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    },
    utf8_to_b64( str ) {
        try {
            return window.btoa(unescape(encodeURIComponent( str )));
        } catch (error) {        }
    },
    b64_to_utf8( str ) {
        try {
            return decodeURIComponent(escape(window.atob( str )));            
        } catch (error) {        }
    },
    
    // generateCryptKey(cryptKey){
    //     // const shaKey = sha256(cryptKey);
    //     // var finalCryptKey = shaKey.substring(0, 8);
    //     // return finalCryptKey;
    //     return cryptKey;
    // },
    // getCryptKeyCheck(cryptKey = DBManager.getInstance().getCryptKey()){
    //     // return this.encrypt("praxis24");
    //     // var cryptKey = DBManager.getInstance().getCryptKey();
    //     const shaKey = sha256(cryptKey);
    //     return shaKey;
    // },
    // getCustomerCryptKey(customerId)
    // {
    //     console.log("getCustomerCryptKey", customerId);
    //     DBManager.getInstance().getCryptKey((cryptKey)=>{
    //         console.log("getCustomerCryptKey", cryptKey);
    //         var customerKey = cryptKey.substring(0, 4) + customerId.substring(4, 8) ;
    //         console.log("getCustomerCryptKey customerKey = ", customerKey)
    //         // var encryptedCustomerKey = sha256(customerKey).substring(0, 8);
    //         // console.log("getCustomerCryptKey encryptedCustomerKey = ", encryptedCustomerKey)

    //     })
    // },
    // getPersonnelCryptKey(personnelId)
    // {
    //     DBManager.getInstance().getCryptKey((cryptKey)=>{
    //         var key = cryptKey.substring(0, 4) + customerId.substring(4, 8) ;
    //         // var encryptedCustomerKey = sha256(customerKey).substring(0, 8);
    //         // console.log("getCustomerCryptKey encryptedCustomerKey = ", encryptedCustomerKey)

    //     })
    // },
    // encrypt(text) {
    //     var cryptKey = DBManager.getInstance().getCryptKey();
    //     var AES = require('crypto-js/AES');
    //     var ciphertext = AES.encrypt(text, cryptKey).toString();
    //     console.log("ciphertext : " + ciphertext);
    //     return ciphertext;
    // },
    // encryptFile(file, callback) 
    // {
    //     // var file = input.files[0];
    //     var reader = new FileReader();
    //     var key = DBManager.getInstance().getCryptKey();
    //     reader.onload = () => {
    //         // var key = "1234567887654321";
    //         var CryptoJS = require('crypto-js');
    //         var wordArray = CryptoJS.lib.WordArray.create(reader.result);           // Convert: ArrayBuffer -> WordArray
    //         var encrypted = CryptoJS.AES.encrypt(wordArray, key).toString();        // Encryption: I: WordArray -> O: -> Base64 encoded string (OpenSSL-format)
    
    //         var fileEnc = new Blob([encrypted]);                                    // Create blob from string
    //         callback(fileEnc);
    //         // var a = document.createElement("a");
    //         // var url = window.URL.createObjectURL(fileEnc);
    //         // var filename = file.name + ".enc";
    //         // a.href = url;
    //         // a.download = filename;
    //         // a.click();
    //         // window.URL.revokeObjectURL(url);
    //     };
    //     reader.readAsArrayBuffer(file);
    // },
    // decrypt(ciphertext) {
    //     var cryptKey = DBManager.getInstance().getCryptKey();
    //     var AES = require('crypto-js/AES');
    //     var enc = require('crypto-js/enc-Utf8');
    //     var bytes  = AES.decrypt(ciphertext, cryptKey);
    //     var originalText = "";
    //     try {
    //         originalText = bytes.toString(enc);
    //     } catch (error) {
    //         console.error("Wrong key for decrption.");
    //     }
    //     console.log("originalText : " + originalText); 
    //     return originalText;
    // },
    // decryptFile(value, callback) 
    // {
    //     var key = DBManager.getInstance().getCryptKey();
    //     var AES = require('crypto-js/AES');
    //     var decrypted = AES.decrypt(value, key);               // Decryption: I: Base64 encoded string (OpenSSL-format) -> O: WordArray
    //     var typedArray = this.convertWordArrayToUint8Array(decrypted);               // Convert: WordArray -> typed array

    //     var fileDec = new Blob([typedArray]);                                   // Create blob from typed array
    //     callback(fileDec);
    //     // var a = document.createElement("a");
    //     // var url = window.URL.createObjectURL(fileDec);
    //     // // var filename = file.name.substr(0, file.name.length - 4) + ".dec";
    //     // a.href = url;
    //     // a.download = filename;
    //     // a.click();
    //     // window.URL.revokeObjectURL(url);

    // },
    // convertWordArrayToUint8Array(wordArray) {
    //     var arrayOfWords = wordArray.hasOwnProperty("words") ? wordArray.words : [];
    //     var length = wordArray.hasOwnProperty("sigBytes") ? wordArray.sigBytes : arrayOfWords.length * 4;
    //     var uInt8Array = new Uint8Array(length), index=0, word, i;
    //     for (i=0; i<length; i++) {
    //         word = arrayOfWords[i];
    //         uInt8Array[index++] = word >> 24;
    //         uInt8Array[index++] = (word >> 16) & 0xff;
    //         uInt8Array[index++] = (word >> 8) & 0xff;
    //         uInt8Array[index++] = word & 0xff;
    //     }
    //     return uInt8Array;
    // },
    // encryptCustomer(customer) {
    //     // customer.firstNameCr = this.encrypt(customer.firstName);
    //     // customer.firstName = "";
    //     // customer.lastNameCr = this.encrypt(customer.lastName);
    //     // customer.lastName = "";
    //     customer.genderCr = this.encrypt(customer.gender+"");
    //     customer.gender = 0;
    //     // customer.phoneCr = this.encrypt(customer.phone);
    //     // customer.phone = null;
    //     customer.emailCr = this.encrypt(customer.email);
    //     customer.email = "";
    //     customer.birthdateCr = this.encrypt(customer.birthdate.toString());
    //     customer.birthdate = null;
    //     return customer;
    // },
    // encryptPayment(payment) {
    //     payment.priceCr = this.encrypt(payment.price);
    //     payment.price = 0;
    //     payment.paidCashCr = this.encrypt(payment.paidCash);
    //     payment.paidCash = 0;
    //     payment.paidCreditCr = this.encrypt(payment.paidCredit);
    //     payment.paidCredit = 0;
    //     payment.paidTransferCr = this.encrypt(payment.paidTransfer);
    //     payment.paidTransfer = 0;
    //     payment.restAmountCr = this.encrypt(payment.restAmount);
    //     payment.restAmount = 0;
    //     return payment;
    // },
    // encryptDocument(document) {
    //     document.noteCr = this.encrypt(document.note);
    //     document.note = "";
    //     document.titleCr = this.encrypt(document.title);
    //     document.title = "";
    //     return document;
    // },
    // decryptPayment(payment) {
    //     payment.price = +this.decrypt(payment.priceCr);
    //     payment.paidCash = +this.decrypt(payment.paidCashCr);
    //     payment.paidCredit = +this.decrypt(payment.paidCreditCr);
    //     payment.paidTransfer = +this.decrypt(payment.paidTransferCr);
    //     payment.restAmount = +this.decrypt(payment.restAmountCr);
    //     return payment;
    // },
    // decryptExamPayments(exams) 
    // {
    //     var Constants = require('/helpers/Constants');
    //     // var columns = ["price", "paidCash", "paidCredit", "paidTransfer", "restAmount"]
    //     for ( var i = 0; i < exams.length; i++ ) 
    //     {
    //         Constants.PAYMENT_COLUMNS.forEach((col)=>{
    //             var colCr = col + Constants.CRYPT_SUFFIX ; 
    //             if ( exams[i].payment && exams[i].payment[colCr] ) {
    //                 var orgText = this.decrypt(exams[i].payment[colCr]);
    //                 this.isNumeric(orgText) ? exams[i].payment[col] = orgText : 0;
    //             }
    //         });
    //     }
    //     return exams;
    // },
    // decryptEntity(tablename, entity)
    // {
    //     if ( tablename == 'customer' ) {
    //         // if ( entity.firstNameCr ) entity.firstName = this.decrypt(entity.firstNameCr);
    //         // if ( entity.lastNameCr ) entity.lastName = this.decrypt(entity.lastNameCr);
    //         if ( entity.genderCr ) entity.gender = this.decrypt(entity.genderCr);
    //         if ( entity.birthdateCr ) entity.birthdate = this.decrypt(entity.birthdateCr);
    //         // if ( entity.phoneCr ) entity.phone = this.decrypt(entity.phoneCr);
    //         if ( entity.emailCr ) entity.email = this.decrypt(entity.emailCr);
    //     } 
    //     else if ( tablename == 'payment' ) {
    //         if ( entity.priceCr ) entity.price = this.decrypt(entity.priceCr);
    //         if ( entity.paidCashCr ) entity.paidCash = this.decrypt(entity.paidCashCr);
    //         if ( entity.paidCreditCr ) entity.paidCredit = this.decrypt(entity.paidCreditCr);
    //         if ( entity.paidTransferCr ) entity.paidTransfer = this.decrypt(entity.paidTransferCr);
    //         if ( entity.restAmountCr ) entity.restAmount = this.decrypt(entity.restAmountCr);
    //     }
    //     else if ( tablename == 'document' ) {
    //         if ( entity.noteCr ) entity.note = this.decrypt(entity.noteCr);
    //         if ( entity.titleCr ) entity.title = this.decrypt(entity.titleCr);
    //     }
    //     return entity;    
    // },
    formatPhoneNumber(phoneNumberString) 
    {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '')
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return null
      },
      
      
}

export default utils;