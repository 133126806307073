import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide, Input, FormHelperText} from '@material-ui/core/';
import intl from 'react-intl-universal';
// import MaskedInput from 'react-text-mask';


const styles = {
  textField: {
    textAlign: 'center',
    fontSize: '1.2em',
    height: '40px',
    width: '190px',
    color: 'darkblue',
    letterSpacing: '20px',

  }
};


class CodeBox extends React.Component 
{
  
  constructor(props) {
    super(props);
    // Create a ref to store the textInput DOM element
    this.textInput = React.createRef();
  }

  state = {
    code:"",
    regexp : /^[0-9\b]+$/
  }

  onHandleCodeChange = (e) => {
    let code = e.target.value;

    // if value is not blank, then test the regex
    if (code === '' || ( this.state.regexp.test(code) && code.length <= 6) ) {
        this.setState({ [e.target.name]: code })
    }
};
  render ()
  {
    return (
      <Dialog
        open={this.props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-slide-title">
          {'Lütfen telefonunuza gelen 6 haneli kodu giriniz'}
        </DialogTitle>
        <DialogContent style={{textAlign:'center'}}>
          <DialogContentText id="alert-dialog-slide-description">

            <Input style={styles.textField} 
              name='code'
              ref={this.textInput}
              value={this.state.code}
              onChange = {this.onHandleCodeChange}
              id="formatted-phone"
              autoFocus={true}
              error={this.state.code != "" && this.props.isWrongCode }
              aria-describedby="regcode-helper-text"
            />  
            <FormHelperText style={{textAlign:'center'}} id="regcode-helper-text">{this.state.code != "" && this.props.isWrongCode ? 'Hatalı Kod.' : ''}</FormHelperText>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose()} color="primary">
              {intl.get('CANCEL')}
            </Button>
          <Button onClick={() => this.state.code != "" ? this.props.onClose(this.state.code): "" } variant="contained" color="primary">
              {intl.get('OK')}
          </Button>
        </DialogActions>
      </Dialog>
      )
  }

}

// function CodeMask (props)
// {
//     const { inputRef, ...other } = {...props};
//     return (
//       <MaskedInput style={{textAlign:'center'}}
//         {...other}
//         ref={ref => {
//           inputRef(ref ? ref.inputElement : null);
//         }}
//         mask={[ /\d/,  /\d/, /\d/, /\d/, /\d/, /\d/]}
//         // mask={[ /\d/, '-', /\d/, '-', /\d/, '-', /\d/, '-', /\d/, '-', /\d/]}
//         // placeholderChar={'\u2000'}
//         placeholderChar={'_'}
//         showMask
//         autoFocus
//       />
//     );
// }

function Transition(props) {
    return <Slide direction="up" {...props} />;
  }

export default CodeBox