import React, {Component, useEffect} from 'react';
import RestManager from '/persist/RestManager'
import { withStyles } from '@material-ui/core/styles';
import 'moment/locale/tr'
import moment from 'moment';
import utils from '/helpers/utils';
import { ACTIVE_PAGE } from './MainPage';
import { withRouter } from 'react-router-dom';
import intl from 'react-intl-universal';
import SplitPane from 'react-split-pane';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import classNames from 'classnames';
import useScript from '../helpers/hooks/useScript';
import { Button , FormLabel, Toolbar, FormControl, Select, MenuItem, TextField, Menu} from '@material-ui/core';
import DBManager from '../persist/DBManager';
import LeftIcon from '@material-ui/icons/ArrowBackIos';
import RightIcon from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { NavigationFullscreen } from 'material-ui/svg-icons';
import FormDialog from '../components/Form/FormDialog';
import MessageBox from '../components/Dialogs/MessageBox';
import ConfirmDialog from '../components/Dialogs/ConfirmDialog';


class FormPage extends Component 
{
  state = {
    praxis : null,
    formBuilder:null,
    forms:[],
    activeForm:null,
    selectedIndex:-1,
    // isPreviewMode:false,
    newFormMode:false,
    // formName:"",
    anchorEl: null,
    anchorElNewForm: null,
    formDialogOpen:false,
    alertDialogOpen:false,
    alertTitle:"",
    alertText:"",
    deleteDialogOpen:false,
};

  constructor (props) {
      super(props);  
      this.reloadData();
      // this.state.praxis = window(new PraxisApp());
  }    

  componentDidMount() {
//    var formBuilder = window.praxisFormBuilder;
//    var depar = DeparFramework;
    var formBuilder;

    var depar = window.DeparFramework;
    if (depar && depar.UIBuilder) {
        var formBuilder = new depar.UIBuilder();
        console.log('UIBuilder instance created');
    } else {
        console.error('DeparFramework or UIBuilder not found');
    }
//    var formBuilder = new DeparFramework.UIBuilder();
//    console.log("formBuilder = ", formBuilder);
    if (typeof formBuilder !== 'undefined') {
        formBuilder.setLocale(intl.getInitOptions?.().currentLocale ?? 'EN');
        formBuilder.init("formBasePage");
        // formBuilder.setLocale("DE");
        this.setState({formBuilder: formBuilder});
    }

    // RestManager.getInstance().getForms((result)=>{
    //   console.log("forms 123= ", result);
    //   var form = result.response[0];
    //   this.setState({activeForm:form});
    // });

  }

  reloadData = () => {
    RestManager.getInstance().getMyForms(
      (result) => {
        this.state.formBuilder.reinitPage()
        var forms = result.response;
        this.setState({ forms: forms });
        if ( forms && forms[0]) {
          this.onLoadForm(forms[0]);
          this.setState({selectedIndex: 0, activeForm:forms[0]});
        } else {
          this.setState({selectedIndex: -1, activeForm:null});
        }
      }
    );

  }

  onLoadForm = (form) => 
  {
      RestManager.getInstance().isFormUsed( form.id,
        (result) => {
          var isUsed = result.response;
          this.state.activeForm.isFormUsed = isUsed;
          var formName = form.formName;
          // if ( form.formVersion ) {
          //   formName = formName + " - v" + form.formVersion;
          // }
          // this.state.formBuilder.loadForm(form.formUI, formName, isUsed);
          var doAssignDBColumns = true;
          if ( form.formName == "KVKK" ) {
            doAssignDBColumns = false;
          }
          this.state.formBuilder.loadForm(form.formUI, formName, doAssignDBColumns);
          console.log("isFormUsed result = ", result);
        }
      );
    }

  onNewForm = (template) => {
    this.setState({selectedIndex: -1, activeForm:null, newFormMode:true});
    this.state.formBuilder.reinitPage();
    if ( template ) {
      this.state.formBuilder.loadForm(template.formUI, "", true);
    }
    this.handleNewFormMenuClose();
    this.setState({ formDialogOpen: false });
  }

  onCancel = () => {
    this.setState({newFormMode:false});
    this.state.formBuilder.reinitPage();
  }

  onSaveForm = () => {
    var formjson = this.state.formBuilder.getFormJSON();
    var formName = this.state.formBuilder.getFormName();
    if ( formName == "" ) {
      this.setState({alertDialogOpen:true, alertText:'Lütfen form adı giriniz.', alertTitle:intl.get('MISSING_DATA') });
      return;
    }
    var form = {formName:formName,  formUI:formjson, formVersion:1}
    RestManager.getInstance().addForm(form, ()=>{
      this.reloadData();
      this.setState({newFormMode:false});
    });
    console.log("form json = ", formjson);
  }

  onUpdateForm = () => {
    var formjson = this.state.formBuilder.getFormJSON();
    var formName = this.state.formBuilder.getFormName();    
    this.state.activeForm.formUI = formjson;
    this.state.activeForm.formName = formName;
    if ( this.state.activeForm.isFormUsed && this.state.formBuilder.isModelChanged() == true ) {
      var form = this.state.activeForm;
      form.parentFormId = form.id;
      var formVersion = form.formVersion;
      form.formVersion = +formVersion+1;      
      RestManager.getInstance().addForm(form, ()=>{});  
    } else {
      console.log("active form = ", this.state.activeForm);
      RestManager.getInstance().updateForm(this.state.activeForm, ()=>{});
    }
  }

  onDeleteForm = () => {
    console.log("on delete active form : ", this.state);
    RestManager.getInstance().deleteForm(this.state.activeForm.id, ()=>{
      this.reloadData();  
      this.setState({ deleteDialogOpen: false });
    });
  }

  // onSwitchPreviewMode = () => {
  //   this.state.formBuilder.setPreviewMode(! this.state.isPreviewMode);
  //   this.setState({isPreviewMode:!this.state.isPreviewMode});
  // }

  onFormValues = () => {
    var arrFormValues = this.state.formBuilder.getFormData();
    
  }

  onFormSelected = (index) =>
  {
    let selForm = this.state.forms[index];
    this.setState({selectedIndex: index, activeForm:selForm});
    this.onLoadForm(selForm);
    this.handleMenuClose();
  }

  onFormBack = () => 
  {
    if ( this.state.selectedIndex > 0  ) {
      var index = this.state.selectedIndex - 1;
      this.onFormSelected(index);
    }    
  }

  onFormForward = () => {
    if ( this.state.selectedIndex + 1 < this.state.forms.length ) {
      var index = this.state.selectedIndex + 1;
      this.onFormSelected(index);
    }    
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNewFormMenu = event => {
    this.setState({ anchorElNewForm: event.currentTarget });
  };

  handleNewFormMenuClose = () => {
    this.setState({ anchorElNewForm: null });
  };

  toggleFormDialog = () => {
    this.setState({ formDialogOpen: ! this.state.formDialogOpen });
  }

  handleMessageBoxClose = () => {
    this.setState({alertDialogOpen:false });
  }

  toggleDeleteDialog = () => {
    this.setState({ deleteDialogOpen: ! this.state.deleteDialogOpen });
  }

  render() 
  {
    const { classes } = this.props;
    const { anchorEl, anchorElNewForm } = this.state;
    const open = Boolean(anchorEl);
    const openNewForm = Boolean(anchorElNewForm);
    // window.test1010();
    // let praxis = window.praxis();

    if ( this.state.forms && this.state.forms.length>0) 
    {
      var forms = this.state.forms.map( ( form, i ) =>
        <MenuItem
          value={form}
          key={i}
          onClick= {() => this.onFormSelected(i)}
        >
          {form.formVersion ? form.formName + " - v" + form.formVersion : form.formName}
        </MenuItem>
      )

    }

      return (

        // <div className='row'>
        // {/* <SplitPane split="vertical" className={classes.splitpane} minSize={300} defaultSize={1000} > */}
        //   <div className='column'>

          

          <div >

            <Toolbar style={{borderBottom:'1px solid lightgrey'}}>

              { ! this.state.newFormMode ? 
                <div>
                  <Button aria-owns={openNewForm ? 'menu-newForm' : undefined} aria-haspopup="true" variant="outlined" 
                    size="small" color="primary" className={classes.margin} onClick={this.handleNewFormMenu}>
                    <AddIcon />Yeni Form Olustur 
                  </Button>
                  <Menu id="menu-newForm" anchorEl={anchorElNewForm} anchorOrigin={{ vertical: 'top', horizontal: 'left',}} transformOrigin={{ vertical: 'top', horizontal: 'left',}}
                      open={openNewForm} onClose={this.handleNewFormMenuClose}>
                      <MenuItem onClick= {() => this.onNewForm()}  >
                        Boş Form
                      </MenuItem>
                      <MenuItem onClick= {() => this.toggleFormDialog()}  >
                        Altlık Form Seç
                      </MenuItem>
                  </Menu>
                </div>
              :""}

              <FormDialog
                dialogOpen={this.state.formDialogOpen}
                onSelect={this.onNewForm}
                onClose={this.toggleFormDialog} 
                // forms = {this.state.forms}
              ></FormDialog>

                { this.state.newFormMode ? 
                  <div>
                    <Button onClick={this.onSaveForm} className={classes.margin} variant="outlined" size="small" color="primary"><SaveIcon/>&nbsp;Formu Kaydet</Button>
                    <Button onClick={this.onCancel} className={classes.margin} variant="outlined" size="small" color="secondary">İptal</Button>
                  </div>
                  :
                    <div> 
                      { forms ? 
                        <div>
                          <Button aria-owns={open ? 'menu-choseForm' : undefined} aria-haspopup="true" variant="outlined" size="small" color="primary" className={classes.margin} style={{ marginRight: 6 }} onClick={this.handleMenu}>
                            <DescriptionOutlinedIcon/>&nbsp;Form Seç </Button>
                          <Menu id="menu-choseForm" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'left',}} transformOrigin={{ vertical: 'top', horizontal: 'left',}}
                              open={open} onClose={this.handleMenuClose}>
                            {forms}
                          </Menu>
                        </div>
                      : "" }
                    </div>
                }

                {this.state.activeForm ?  
                  <div>
                    <Button onClick={this.onUpdateForm} className={classes.margin} variant="outlined" size="small" color="primary"><SaveIcon/>&nbsp;Formu Guncelle</Button>
                    <Button onClick={this.toggleDeleteDialog} className={classes.margin} variant="outlined" color="primary"><DeleteIcon/>&nbsp;Formu Sil</Button>
                  </div>
                : ""}


                {/* <Button onClick={this.onFormValues} className={classes.margin} variant="outlined" size="small" color="primary" style={{marginLeft:300}}>Form Verileri</Button> */}

            </Toolbar>

            {/* <Toolbar style={{borderBottom:'1px solid #efefef'}}>
            {this.state.newFormMode ? 
              <div>
                <FormLabel style={{lineHeight:"30px"}}>Form Adı : </FormLabel>
                <TextField style={{width:580, marginRight:30}} 
                  value={this.state.formName} 
                  onChange={this.handleChange('formName')}
                ></TextField>
              </div>
            :
              <div>
                <FormLabel >Formlar : </FormLabel>
                <FormControl >
                  <Select style={{width:580}}
                    labelid="sourcePicker"
                    id="sourcePicker"
                    value={this.state.activeForm}
                    // onChange={this.handleChange('source')}
                    className={classes.textField}
                  >
                    {forms}
                  </Select>
                </FormControl>

                <Button variant="flat" size="small" onClick={this.onFormBack}>
                  <LeftIcon color="primary" /> 
                </Button>
                <FormLabel >{this.state.selectedIndex+1} / {this.state.forms.length}</FormLabel>
                <Button variant="flat" size="small" onClick={this.onFormForward}>
                  <RightIcon color="primary" /> 
                </Button>
              </div>
            }
            </Toolbar> */}

            {/* {this.state.activeForm || this.state.newFormMode  ?   */}
              <div id = "formBasePage" style ={{width:1200, display: this.state.activeForm || this.state.newFormMode  ? 'block' : 'none'}}>  </div>
            {/* : ""} */}

              <MessageBox
                alertDialogOpen={this.state.alertDialogOpen}
                onClose={this.handleMessageBoxClose} 
                alertTitle={this.state.alertTitle} 
                alertText={this.state.alertText} >
              </MessageBox>

              <ConfirmDialog
                dialogOpen={this.state.deleteDialogOpen}
                onClose={this.toggleDeleteDialog} 
                dialogTitle={intl.get('DELETE') + '?'} 
                dialogText={intl.get('DO_YOU_WANT_TO_DELETE')} 
                onCancel={this.toggleDeleteDialog} 
                cancelLabel={intl.get('CANCEL')}
                onConfirm={this.onDeleteForm} 
                confirmLabel={intl.get('DELETE')}
              ></ConfirmDialog>

          </div>
          
      //     </div>
      //     <div  className={classNames(classes.widgets, 'column')}  >
      //         <FormWidgets/>
      //     </div>
      //   {/* </SplitPane> */}
      // </div>

      )
        
    }
  }
  const styles = theme => ({
    widgets: {
      position: 'relative',
      border: '1px solid gray',
      padding: '20px',
      height: 'calc(100vh - 190px)',
      marginTop:'40px',
      width: '200px',
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    },
    margin:{
      marginRight:6,
    }
  });
  
export default withRouter (withStyles(styles)(FormPage));
