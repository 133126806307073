import React, {Component} from 'react';
//import './App.css'
import CustomerPage from './CustomerPage'
import RestManager from '/persist/RestManager'
import MenuBar from '../components/Bars/MenuBar'
import SideBar from '../components/Bars/SideBar'
// import BottomBar from '../components/Bars/BottomBar'
import { withStyles } from '@material-ui/core/styles';
import CalendarPage from './CalendarPage';
import ReportPage from './ReportPage';
import ChartPage from './ChartPage';
import AccountPage from './AccountPage';
import PaymentPage from './PaymentPage';
import {withRouter} from 'react-router-dom';
import { Dialog, DialogTitle, TextField, Button, FormLabel, AppBar } from '@material-ui/core';
import intl from 'react-intl-universal';
import CryptKeyPage from './CryptKeyPage';
import DBManager from '../persist/DBManager';
import PersonnelPage from './PersonnelPage';
import SecurityPage from './SecurityPage';
import crypt from '../helpers/crypt';
import FormPage from './FormPage';
import { connect } from 'react-redux'

export const ACTIVE_PAGE = {
  LOGIN: '/login',
  CLIENTS: '/clients',
  CALENDAR: '/calendar',
  REPORTS: '/reports',
  CHARTS: '/charts',
  ACCOUNT: '/account',  
  PAYMENT: '/payment',
  PROFESSIONALS: '/professionals',
  FORMS: '/forms',
  SECURITY: '/security',
  };

@connect(({ app }) => ({
  selectedProfessional: app.selectedProfessional,
}))
  
class MainPage extends Component 
{
    constructor (props) {
        super(props);
        var CryptoJS = require('crypto-js');
        // console.log("var hash = CryptoJS.SHA1;", CryptoJS.SHA256("w6fFnd178c").toString());
        // console.log("CONSTRUCTOR: Main Page ")
        if ( RestManager.getInstance().getToken().success == false ) {
          console.log('TOKEN expired, go to login.');
          this.state.dialogOpen = true;
//          this.props.history.push('/login')
        }
        if ( ! this.props.location.pathname || this.props.location.pathname == "/") {
          //this.props.history.push( ACTIVE_PAGE.CLIENTS );
        }
        RestManager.getInstance().getCurrentUser((currentUser)=>{
          // let currentUser = result.response;
          this.state.currentUser = currentUser;

          DBManager.getInstance().initDBForUser(currentUser, () =>{
            this.checkCryptKey();  
          });
        })  
    }    

    state = {
      currentUser:null,
      cryptKey:"",
      isCryptKeySet:null,
      email:'',
      password:'',
      dialogOpen: false,
      loading:false,
      alertText:'',
      isAlertLabelVisible: false,
      cryptKeyDialogOpen:false,
      loadingCrypt:false,
      cryptAlertText:'',
      isCryptAlertLabelVisible: false,
    };
  
    componentDidUpdate(){
      if ( ! this.state.dialogOpen ) {
        this.checkToken();
      }
      console.log("componentDidUpdate: Main Page ")

    }

    checkToken(){
      let resp = RestManager.getInstance().getToken();
      if ( resp.status == RestManager.TOKEN_STATUS.EXPIRED || resp.status == RestManager.TOKEN_STATUS.NO_TOKEN){
        this.setState({ dialogOpen : true });
      }
      else if ( resp.status == RestManager.TOKEN_STATUS.OK ) {
        if ( ! this.props.location.pathname || this.props.location.pathname == "/") {
          this.props.history.push( ACTIVE_PAGE.CLIENTS );
        }
      } 
    }

    checkCryptKey()
    {  
      RestManager.getInstance().getCompany((result => {
        if ( result.success == true ) {
          var company = result.response;

          if ( company.cryptKeySet == true ) 
          {
            try {
              DBManager.getInstance().getCryptKey((cryptKey)=>{
                if ( !cryptKey ) {
                  if ( this.state.currentUser.originalCryptKey == true ) {
                    this.setState({ cryptKey : "praxis24" });
                  } else {
                    let mainCryptKey = crypt.generateMainCryptKey("praxis24");
                    let userCryptKey = mainCryptKey.substring(0, 5) + this.state.currentUser.id.substring(5, 10)
                    this.setState({ cryptKey : userCryptKey });
                  }
                  this.onSubmitCryptKey();
//                  this.setState({ cryptKeyDialogOpen : true });
                }
              });
            } catch (err) { // do logout
              localStorage.removeItem("token");
              // window.location.reload(false);
            }
          } else {
              RestManager.getInstance().setCompanyCryptKey("praxis24", ()=>{
                window.location.reload();
              });
          }
          this.setState({ isCryptKeySet : company.cryptKeySet });

        }
      }));
    }

    openConfirmDialog = () => {
      this.setState({ dialogOpen: !this.state.dialogOpen });
    }
  
    handleChange = name => event => {
      this.setState({ [name]: event.target.value });
    };
    
    onSubmit = () => 
    {
      this.setState({ isAlertLabelVisible: false });
      if ( ! this.state.email ) {
        this.setState({ alertText: intl.get('PLEASE_ENTER', {missingData: intl.get('EMAIL')}) });
        this.setState({ isAlertLabelVisible: true });
        return;
      } else if ( ! this.state.password ) {
        this.setState({ alertText: intl.get('PLEASE_ENTER', {missingData: intl.get('PASSWORD')}) });
        this.setState({ isAlertLabelVisible: true });
        return;
      }

      RestManager.getInstance().login( this.state.email, this.state.password, (result) => 
      {
        this.setState({ isAlertLabelVisible: true });
        if ( result.success == true ) 
        {
          this.setState({ loading: false, dialogOpen: false });
          window.location.reload();
        }  
        else if (result.status == '401') {
          this.setState({ alertText: intl.get('WRONG_EMAIL_OR_PASSWORD') });
        }  
        else {
          this.setState({ alertTitle: intl.get('SYSTEM_ERROR') + intl.get('PLEASE_GO_BACK_TO_LOGIN') });
        }
      });
    }
    
    onBackToLogin = () => 
    {
      localStorage.removeItem("token");
      this.props.history.push(ACTIVE_PAGE.LOGIN);
      window.location.reload();  
    } 
    
    onSubmitCryptKey = () => 
    {
      if ( this.state.currentUser ) 
      {
        let cryptKey = this.state.cryptKey;
        if ( this.state.currentUser.originalCryptKey == true ) {
          cryptKey = crypt.generateMainCryptKey(cryptKey);
        }
        RestManager.getInstance().checkCryptedKey(cryptKey, (result) => {
          if ( result.success == true ){
            var isCryptedKeyCorrect = result.response;
            if ( isCryptedKeyCorrect ) {
              RestManager.getInstance().setUserCryptKey(cryptKey, ()=>{
                window.location.reload();
              });  
            } else {
              this.setState({ cryptAlertText: "Anahtar Kelime hatalı", isCryptAlertLabelVisible: true });
            }
  
          }
        })
  
      }
    }

    render() 
    {
      const { classes} = this.props;
      console.log("RENDER - MAIN PAGE");
      const {pathname} = this.props.location;
        
      return (
          <div >
            
            { this.state.isCryptKeySet == true ?
              <MenuBar/>
            : 
              <MenuBar showJustLogout={true}/>
            } 

            <div className={classes.app}>

              { this.state.isCryptKeySet == true ?
                <div className={classes.sidebar}>
                  <SideBar/>
                </div>
              : '' } 
              
              {/* { ! this.state.dialogOpen && this.state.isCryptKeySet == true && this.props.selectedProfessional ? */}
              { ! this.state.dialogOpen && this.state.isCryptKeySet == true ?
                <div style={{ marginTop:18, width:'100%', zIndex:1 }}>
                  {pathname.includes(ACTIVE_PAGE.CLIENTS) ? <CustomerPage/> : ''}
                  {pathname.includes(ACTIVE_PAGE.CALENDAR) ? <CalendarPage/> : ''}
                  {pathname.includes(ACTIVE_PAGE.REPORTS) ? <ReportPage/> : ''}
                  {pathname.includes(ACTIVE_PAGE.CHARTS) ? <ChartPage/> : ''}
                  {pathname.includes(ACTIVE_PAGE.ACCOUNT) ? <AccountPage/> : ''}
                  {pathname.includes(ACTIVE_PAGE.PAYMENT) ? <PaymentPage/> : ''}
                  {pathname.includes(ACTIVE_PAGE.PROFESSIONALS) ? <PersonnelPage/> : ''}
                  {pathname.includes(ACTIVE_PAGE.FORMS) ? <FormPage/> : ''}
                  {pathname.includes(ACTIVE_PAGE.SECURITY) ? <SecurityPage/> : ''}
                </div>
                : ""
              } 

              { /* this.state.isCryptKeySet == false ?
                <div style={{ marginTop:18, width:'100%', zIndex:1 }}>
                  <CryptKeyPage/>
                </div>
                : '' */
              }

              {/* { ! this.state.dialogOpen && this.state.isCryptKeySet == true && ! this.props.selectedProfessional ?
                <div style={{ marginTop:18, width:'100%', zIndex:1 }}>
                  <PersonnelPage/>
                </div>
              : "" } */}

              {/* <BottomBar/> */}

            </div>

    

            <Dialog
              open={this.state.dialogOpen}
              onClose={this.openConfirmDialog }
              aria-labelledby="login-dialog"
            >
              <DialogTitle id="login-dialog" style={{borderBottom:'1px solid lightgray'}} > {intl.get('PLEASE_LOGIN_AGAIN')} </DialogTitle>
              <div style={{padding:'10 20 10 20'}}>
                <TextField
                      // hintText="Enter your Email"
                      label="E-Mail"
                      onChange = {this.handleChange('email')}
                      style={styles.textField} 
                  />
                  <br/>
                  <TextField
                      type="password"
                      label="Password"
                      onChange = {this.handleChange('password')}
                      style={styles.textField} 
                  />
                  <br/>
                    {this.state.isAlertLabelVisible ? 
                      <div style={{textAlign:'center', color:'red', padding:10}}>
                        <FormLabel style={{color:'red'}}>{this.state.alertText}</FormLabel>
                      </div>
                      : ''
                    }

                  <div style={{textAlign:'center'}}>
                    <Button onClick={this.onSubmit} disabled={this.state.loading} variant="contained" style={styles.button} color="primary">{intl.get('SUBMIT')}</Button>
                  </div>
                  <div className="row" style={{textAlign:'center'}}>
                  <Button onClick={this.onBackToLogin} size="small" color="primary" >{intl.get('BACK_TO_LOGIN_PAGE')}</Button>
                  </div>


              </div>
            </Dialog>




            <Dialog
              open={this.state.cryptKeyDialogOpen}
              onClose={this.openCryptKeyDialog }
              aria-labelledby="cryptKey-dialog"
            >
              <DialogTitle id="cryptKey-dialog" style={{borderBottom:'1px solid lightgray'}} > Veri Şifreleme Anahtar Kelimenizi giriniz </DialogTitle>
              <div style={{padding:'10 20 10 20'}}>
                <TextField
                      label="Anahtar Kelimeniz"
                      onChange = {this.handleChange('cryptKey')}
                      style={styles.textField} 
                  />
                  <br/>
                  <br/>
                    {this.state.isCryptAlertLabelVisible ? 
                      <div style={{textAlign:'center', color:'red', padding:10}}>
                        <FormLabel style={{color:'red'}}>{this.state.cryptAlertText}</FormLabel>
                      </div>
                      : ''
                    }

                  <div style={{textAlign:'center'}}>
                    <Button onClick={this.onBackToLogin} disabled={this.state.loadingCrypt} style={styles.button} color="primary">{intl.get('CANCEL')}</Button>
                    <Button onClick={this.onSubmitCryptKey} disabled={this.state.loadingCrypt} variant="contained" style={styles.button} color="primary">{intl.get('SUBMIT')}</Button>
                  </div>
                  {/* <div className="row" style={{textAlign:'center'}}>
                  <Button onClick={this.onBackToLogin} size="small" color="primary" >{intl.get('BACK_TO_LOGIN_PAGE')}</Button>
                  </div> */}


              </div>
            </Dialog>


        </div>
      )
    }
  }
  const styles = ({
    splitpane: {
      position: 'relative !important',
    },
    app:{
      display: 'flex',
      position: 'relative',
      height: '100%'
    },
    sidebar: {
      marginTop:18,
    },
    textField: {
      minWidth:250,
      margin:10
    },
    button:{
      margin:25
    }
  
  });
  
export default withRouter(withStyles(styles)(MainPage));
