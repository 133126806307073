import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {  InputLabel } from '@material-ui/core';
import utils from '/helpers/utils';
import NumberFormat from 'react-number-format';
import intl from 'react-intl-universal';

class PaymentBar extends React.Component {

    state = {


      // 'payment':{
      //   'id':this.state.paymentId,
      //   'paymentDate':paymentDate,
      //   'price': this.state.price ? this.state.price : 0, 
      //   'paidCash':this.state.paidCash  ? this.state.paidCash : 0, 
      //   'paidCredit':this.state.paidCredit  ? this.state.paidCredit : 0, 
      //   'paidTransfer':this.state.paidTransfer ? this.state.paidTransfer : 0, 
      //   'restAmount':this.state.restAmount ? this.state.restAmount : 0, 
      // }


    };
    
    constructor(props){
        super(props);
    }
    
    handleChange = name => event => 
    { // state will be handled in parent component, just do the calculation here
      let price = ( name == 'price' ? event.target.value : this.props.pState.price );
      let paidCash = ( name == 'paidCash' ? event.target.value : this.props.pState.paidCash );
      let paidCredit = ( name == 'paidCredit' ? event.target.value : this.props.pState.paidCredit );
      let paidTransfer = ( name == 'paidTransfer' ? event.target.value : this.props.pState.paidTransfer );
      let rest =  Number(price) - (Number(paidCash) + Number(paidCredit) + Number(paidTransfer) );
      this.props.valueChanged('restAmount', rest);

      this.props.valueChanged([name], event.target.value);

      console.log("in handleChange : ", name, event.target.value)
    };

    render ()
    {
      const { classes} = this.props;
console.log("this.props.pState.paymentDate = ", this.props.pState.paymentDate)
      return (

        <div className='row' >

          <div className='column' >
              <TextField
                  id="paymentDate"
                  label={intl.get('PAYMENT_DATE')}
                  type="date"
                  // defaultValue="2017-05-24"
                  defaultValue={utils.formatDate(new Date())}
                  className={classes.textField}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  value={utils.formatDate(this.props.pState.paymentDate)}
                  onChange={this.handleChange('paymentDate')}
              />
          </div>
            <div className='column' >
                <TextField
                    className={classes.priceField}
                    label={intl.get('PRICE')}
                    value={this.props.pState.price}
                    onChange={this.handleChange('price')}
                    id="formatted-numberformat-input"
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                />
          </div>
          <div className='column' >
              <InputLabel style={{lineHeight:'63px', marginLeft:'10px', color:'black'}}>{intl.get('PAID')} : </InputLabel>
          </div>
          <div className='column' >
                <TextField
                    className={classes.priceField}
                    label={intl.get('CASH')}
                    value={this.props.pState.paidCash}
                    onChange={this.handleChange('paidCash')}
                    id="formatted-numberformat-input"
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                />
          </div>
          <div className='column' >
                <TextField
                    className={classes.priceField}
                    label={intl.get('CREDIT_CARD')}
                    value={this.props.pState.paidCredit}
                    onChange={this.handleChange('paidCredit')}
                    id="formatted-numberformat-input"
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                />
          </div>
          <div className='column' >
                <TextField
                    className={classes.priceField}
                    label={intl.get('TRANSFER')}
                    value={this.props.pState.paidTransfer}
                    onChange={this.handleChange('paidTransfer')}
                    id="formatted-numberformat-input"
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                />
          </div>
          <div className='column' >
            <InputLabel style={{lineHeight:'63px', color:'black'}}>&#8594;</InputLabel>
          </div>

          <div className='column' >
                <TextField
                    disabled
                    className={classes.priceField}
                    label={intl.get('REST')}
                    value={this.props.pState.restAmount}
                    onChange={this.handleChange('restAmount')}
                    id="formatted-numberformat-input"
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                />
          </div>
        </div>

        )
    }

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      // thousandSeparator
      // prefix="$"
    />
  );
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  priceField:{
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 80,
  }
});

PaymentBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PaymentBar);