import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide } from '@material-ui/core/';

class ConfirmDialog extends React.Component {
    
    constructor(props){
        super(props);
    }
      
    render ()
    {
      return (

        <Dialog
          open={this.props.dialogOpen}
          onClose={this.props.onClose }
          TransitionComponent={Transition}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.dialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={ this.props.onCancel } color="primary">
              {this.props.cancelLabel}
            </Button>
            <Button onClick={this.props.onConfirm} color="primary" variant="contained"  autoFocus>
              {this.props.confirmLabel}
            </Button>
          </DialogActions>
        </Dialog>
      )


    }

}

function Transition(props) {
    return <Slide direction="up" {...props} />;
  }

export default ConfirmDialog;