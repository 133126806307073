import React from 'react';
import { Button, TextField, FormControl, Radio, RadioGroup, FormControlLabel, InputLabel, Select, MenuItem, Input, DialogTitle, DialogActions} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { withStyles } from '@material-ui/core/styles';
import RestManager from '/persist/RestManager'
import { connect } from 'react-redux'
import { setCustomers, setSelectedCustomer } from '../../redux/app'
import utils from '/helpers/utils'
import * as Constants from '/helpers/Constants'
import intl from 'react-intl-universal';
import PhoneMask from '../../helpers/PhoneMask';
import crypt from '../../helpers/crypt';
import MessageBox from '../Dialogs/MessageBox';
import maleDoctor from '/images/maleDoctor.png';
import femaleDoctor from '/images/femaleDoctor.png';


@connect(({ app }) => ({
  isEditMode : app.isEditMode,
  isNewMode : app.isNewMode,
  customers : app.customers,
  selectedProfessional: app.selectedProfessional,
}), {
  setCustomers,
  setSelectedCustomer
})


class CustomerForm extends React.Component {

  state = {
    gender: 0,
    firstName: "",
    lastName: "",
    birthdate: "",
    phone: "",
    email: "",
    nationalID: "",
    fatherName: "",
    source: 0,
    city: "",
    county: "",
    postalCode: "",
    street: "",
    loading:false,
    alertDialogOpen:false,
    alertTitle:"",
    alertText:"",
    currentLocale:"",
};

  constructor (props) {
    super(props);
  
    if ( this.props.selectedCustomer && this.props.isEditMode) {
        for (var key in this.props.selectedCustomer) {
            this.updateState(key, this.props.selectedCustomer[key]);
          }
    } 

    let currentLocale = intl.determineLocale({
      urlLocaleKey: 'lang',
      cookieLocaleKey: 'lang'
    });
    this.state.currentLocale = currentLocale;

    // else if (this.props.isNewMode) {
      // for (var key in this.state) {
      //   this.updateState(key, this.state[key]);
    // }
    // }
}    

  onSave = () => 
  {
    this.setState({loading: true});
    RestManager.getInstance().addCustomer( 
      {
      'gender':this.state.gender, 
      'firstName':this.state.firstName, 
      'lastName':this.state.lastName, 
      'birthdate':utils.UTC2GMT(this.state.birthdate), 
      'phone':this.state.phone, 
      'email':this.state.email, 
      'nationalID':this.state.nationalID, 
      'fatherName':this.state.fatherName, 
      'source':this.state.source,
      'address':{
          'city':this.state.city, 
          'county':this.state.county, 
          'postalCode':this.state.postalCode, 
          'street':this.state.street
          }
      }, 
      (result) => {
        console.log("ON NEW CUStOmeT", result);
        if ( result.success == false && result.status == 5052) {
          this.setState({
            alertTitle:'Lisansınızın süresi doldu',
            alertText: 'Yeni hasta girişi yapabilmek için lütfen lisansınızı güncelleyiniz.',
            alertDialogOpen:true,
          });  
        }
        this.setState({loading: false});
        this.reloadCustomers();
        this.props.setSelectedCustomer(result.response);
      }
    )
    this.onClose();
  }


  onUpdate = () => 
  {
    this.setState({loading: true});
    let customer = this.props.selectedCustomer;
    customer.gender = this.state.gender;
    customer.firstName = this.state.firstName;
    customer.lastName = this.state.lastName;
    customer.birthdate = utils.UTC2GMT(this.state.birthdate);
    customer.phone = this.state.phone;
    customer.email = this.state.email;
    customer.nationalID = this.state.nationalID;
    customer.fatherName = this.state.fatherName;
    customer.source = this.state.source;
    customer.professionalId = this.props.selectedCustomer.professionalId;
    customer.address = {};
    customer.address.city = this.state.city;
    customer.address.county = this.state.county;
    customer.address.postalCode = this.state.postalCode;
    customer.address.street = this.state.street;

    RestManager.getInstance().updateCustomer( 
      customer, 
      (result) => {
        this.setState({loading: false});

        this.reloadCustomers(()=>{
          let nCustomer = result.response;
          nCustomer.examCount = customer.examCount;
          nCustomer.usedExams = customer.usedExams;
  
          this.props.setSelectedCustomer(crypt.decryptEntity("customer", nCustomer ));
          // let customers = this.props.customers;
          // let selPos = customers.indexOf(nCustomer);
          // customers[selPos] = nCustomer;
          // this.props.setCustomers(customers);
  
        });



      }
    )
    this.onClose();
  }
  
  reloadCustomers (callback) {
    RestManager.getInstance().getAllCustomers( (result) => {
      const { setCustomers } = this.props;
      setCustomers(result.response);
      if ( typeof callback == "function" ) {
        callback();
      }
    });
  };

  onClose = () => {
    this.props.onClose();
  }

  updateState = (name, value) => 
  {
    (name === "gender" ) ? this.state.gender = value : "";
    (name === "firstName" ) ? this.state.firstName = value : "";
    (name === "lastName" ) ? this.state.lastName = value : "";
    (name === "birthdate" ) ? this.state.birthdate = utils.formatDate(value) : "";
    (name === "phone" ) ? this.state.phone = value : "";
    (name === "email" ) ? this.state.email = value : "";
    (name === "nationalID" ) ? this.state.nationalID = value : "";
    (name === "fatherName" ) ? this.state.fatherName = value : "";
    (name === "source" ) ? this.state.source = value : "";
    if ( name == "address") {
      this.state.city = value.city;
      this.state.county = value.county;
      this.state.postalCode = value.postalCode;
      this.state.street = value.street;
    }
    
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleDialogClose = () =>
  {
    this.setState({ alertDialogOpen: false });
  }

  render() {
    const { classes } = this.props;
    const { inputRef, ...other } = this.props;
    console.log("IN RENDER");
    return (
      <div>
        { this.props.selectedProfessional == null ?
          <div style={{textAlign:'center', marginTop:'200px'}}> Hasta kaydı oluşturabilmek için lutfen önce bir doktor tanimlayin
              <div  style={{ marginTop:'20px'}}>
                <a href="/professionals"  >
                  <img style={{marginRight:'20px' }} src={femaleDoctor } height="80" width="80"></img>
                  <img  style={{textAlign:'center'}} src={maleDoctor } height="80" width="80"></img>
                </a>
              </div>

              <div style={{paddingTop:'20px'}} >
                <a href="/professionals"  >Doktor giriş asayfasına gidin</a>
              </div>
          </div>
          :
          <div >

            {/* <Dialog
            maxWidth="md"
            open={true}
            onClose={this.onClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick={true}
            > */}

            { this.props.selectedCustomer && this.props.isEditMode ? 
              <DialogTitle style={{borderBottom:'1px solid lightgray', marginBottom:'20px'}} id="form-dialog-title">{this.props.selectedCustomer.firstName + " " + this.props.selectedCustomer.lastName}</DialogTitle>
            :          
            <DialogTitle style={{borderBottom:'1px solid lightgray', marginBottom:'20px'}} id="form-dialog-title">{intl.get('NEW_PATIENT')}</DialogTitle>
            }

            {/* <form className={classes.form} noValidate> */}

                <div className='row' style={{minWidth:'500px', marginLeft:'20px'}}>
                  {/* <FormLabel component="legend">Cinsiyet</FormLabel> */}
                  <RadioGroup
                    name="gender"
                    value={this.state.gender+""}
                    onChange={this.handleChange('gender')}
                    row
                  >
                    <FormControlLabel value="1" control={<Radio />} label={intl.get('MALE')} />
                    <FormControlLabel value="2" control={<Radio />} label={intl.get('FEMALE')} />
                  </RadioGroup>
                </div>

                <div className='row' style={{margin:'20px'}}>
                  <div className='column'  >
                    <TextField
                          id="standard-name"
                          label={intl.get('NAME')}
                          value={this.state.firstName}
                          className={classes.textField}
                          onChange={this.handleChange('firstName')}
                          // margin="normal"
                    />
                  </div>
                  <div className='column' >
                    <TextField
                      id="standard-name"
                      label={intl.get('SURNAME')}
                      value={this.state.lastName}
                      className={classes.textField}
                      onChange={this.handleChange('lastName')}
                      // margin="normal"
                    />
                </div>
                <div className='column' >
                  <TextField
                    id="date"
                    label={intl.get('BIRTHDATE')}
                    type="date"
                    format="dd-mm-YYYY"
                    // defaultValue="2017-05-24"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.birthdate}
                    // value="1975-08-15"
                    onChange={this.handleChange('birthdate')}
                  />
                </div>
              </div>

              <div className='row' style={{margin:'20px'}}>
                  <div className='column'  >
                    <TextField
                          id="standard-name"
                          label={intl.get('NATIONAL_ID_NUMBER')}
                          value={this.state.nationalID}
                          className={classes.textField}
                          onChange={this.handleChange('nationalID')}
                          // margin="normal"
                    />
                  </div>
                  { this.state.currentLocale == "tr-TR" ? 
                    <div className='column' >
                      <TextField
                        id="standard-name"
                        label={intl.get('FATHER_NAME')}
                        value={this.state.fatherName}
                        className={classes.textField}
                        onChange={this.handleChange('fatherName')}
                      />
                  </div>
                :""}
            </div>


              <div className='row' style={{margin:'20px'}}>
                <div className='column'  >
                  <FormControl className={classes.textField}>
                    <InputLabel htmlFor="formatted-text-mask-input">{intl.get('PHONE')}</InputLabel>
                    <Input
                      value={this.state.phone}
                      onChange={this.handleChange('phone')}
                      id="formatted-text-mask-input"
                      inputComponent={PhoneMask}
                    />  
                  </FormControl>
                </div>

                <div className='column' >
                  <TextField
                    id="standard-email"
                    label={intl.get('EMAIL')}
                    value={this.state.email}
                    className={classes.textField}
                    onChange={this.handleChange('email')}
                    // style = {{width: 200}}
                    // margin="normal"
                  />
                </div>

                <div className='column' >
                  <FormControl >
                    <InputLabel id="sourcePicker">{intl.get('SOURCE')}</InputLabel>
                    <Select
                      labelid="sourcePicker"
                      id="sourcePicker"
                      value={this.state.source}
                      onChange={this.handleChange('source')}
                      className={classes.textField}
                    >
                      <MenuItem value={0}></MenuItem>
                      <MenuItem value={1}>{intl.get(Constants.SOURCE_ITEMS[1])}</MenuItem>
                      <MenuItem value={2}>{intl.get(Constants.SOURCE_ITEMS[2])}</MenuItem>
                      <MenuItem value={3}>{intl.get(Constants.SOURCE_ITEMS[3])}</MenuItem>
                      <MenuItem value={4}>{intl.get(Constants.SOURCE_ITEMS[4])}</MenuItem>
                    </Select>
                  </FormControl>

                </div>

              </div>

              <div className='row' style={{margin:'20px'}}>
                <TextField
                    id="standard-street"
                    label={intl.get('STREET')}
                    value={this.state.street}
                    className={classes.textField}
                    onChange={this.handleChange('street')}
                    style = {{width: 640}}
                  />
              </div>


              <div className='row' style={{margin:'20px'}}>
                <div className='column'  >
                  <TextField
                    id="standard-city"
                    label={intl.get('CITY')}
                    value={this.state.city}
                    className={classes.textField}
                    onChange={this.handleChange('city')}
                  />
                </div>
                {/* <div className='column' style={{ marginRight:'20px'}} >
                  <TextField
                    id="standard-county"
                    label="Bölge"
                    value={this.state.county}
                    className={classes.textField}
                    onChange={this.handleChange('county')}
                  />
                </div> */}
                <div className='column' >
                  <TextField
                    id="standard-postalCode"
                    label={intl.get('POSTAL_CODE')}
                    value={this.state.postalCode}
                    className={classes.textField}
                    onChange={this.handleChange('postalCode')}
                    // style = {{width: 145}}
                  />
                </div>
              </div>



              <div className='row' style={{width:'670px', marginTop:'20px'}}>
                <DialogActions>
                  {this.props.selectedCustomer ?
                    <Button onClick={this.onClose} color="primary">{intl.get('CANCEL')}</Button>
                    : "" }
                  {this.props.selectedCustomer && this.props.isEditMode ?
                  <Button onClick={this.onUpdate} disabled={this.state.loading} variant="contained" color="primary">{intl.get('UPDATE')}</Button>
                  :
                  <Button onClick={this.onSave} disabled={this.state.loading} variant="contained" color="primary">{intl.get('SAVE')}</Button>
                  }
                </DialogActions>
              </div>
          
            {/* </Dialog> */}
              <MessageBox
                alertDialogOpen={this.state.alertDialogOpen}
                onClose={this.handleDialogClose} 
                alertTitle={this.state.alertTitle} 
                alertText={this.state.alertText} >
              </MessageBox>

          </div>
        }
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginRight: 20,
    width: 200,
  },
});
export default withStyles(styles)(CustomerForm);
