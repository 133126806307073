import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import RestManager from '/persist/RestManager'
import { connect } from 'react-redux';

import intl from 'react-intl-universal';
import utils from '/helpers/utils'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

@connect(({ app }) => ({
  selectedCustomer: app.selectedCustomer,
}), {
})

class ConsentPage extends React.Component {

  state = {
    consentText : "",
  };

  constructor (props) {
      super(props);  
      this.loadConsentText();
  }    

  // componentDidMount() {
  //   var formViewer = window.njs_UIViewer;
  //   this.setState({formViewer: formViewer});
  // }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
  //     this.loadConsentText();
  //   }
  // }

  loadConsentText = () => {

    let search = this.props.location.search;
    console.log("search = " + search);
    let res = search.split("=");
    if ( res.length > 1 ) 
    {
      let id = res[1];
      RestManager.getInstance().getConsentText(id,
        (result) => {
          console.log("result = ", result);
          let consentText = result.response;
          // let consentText = result.response.replace(/(?:\\n)/g, '\n');;
          console.log("consentText = ", consentText);
          this.setState({consentText: consentText});
        }
      );

    }
  }
    
  render() 
  {
    const { consentText } = this.state;

      return (
          <div>
            <div id = "formPage" style={{width:750, margin:'40 20 500 30', whiteSpace: 'break-spaces'}} >
              <p>
                {consentText}
              </p>
            </div>
          </div>
      )
        
    }

}


export default withStyles(styles)(ConsentPage);