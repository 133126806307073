import React, {Component} from 'react';
import { AppBar, Toolbar, Typography, Link } from '@material-ui/core';

class Header extends Component 
{
  render() 
  {
    return (
      <AppBar position="static">
        <Toolbar style={{minHeight:'50px', backgroundColor:'white', color:'black'}}>
          <Typography variant="h6"  >
          <Link href="/" style={{color:"black"}}>
            Praxis - 24
          </Link>
          </Typography>
        </Toolbar>
      </AppBar>
      );
  }

}


export default Header;
