export const setSelectedProfessional = (professional) => ({ type: 'SET_SELECTED_PROFESSIONAL', professional })
export const setCustomers = (customers) => ({ type: 'SET_CUSTOMERS', customers })
export const setSelectedCustomer = (customer) => ({ type: 'SET_SELECTED_CUSTOMER', customer })
export const setIsEditMode = (isEditMode) => ({ type: 'SET_IS_EDIT_MODE', isEditMode })
export const setIsNewMode = (isNewMode) => ({ type: 'SET_IS_NEW_MODE', isNewMode })
export const setToken = (token) => ({ type: 'SET_TOKEN', token })
export const setIsSideBarCollapsed = (isSideBarCollapsed) => ({ type: 'SET_IS_SIDEBAR_COLLAPSED', isSideBarCollapsed })
export const setIsClientListVisible = (isClientListVisible) => ({ type: 'SET_IS_CLIENT_LIST_VISIBLE', isClientListVisible })
export const setActiveReport = (activeReport) => ({ type: 'SET_ACTIVE_REPORT', activeReport })
export const setActiveChart = (activeChart) => ({ type: 'SET_ACTIVE_CHART', activeChart })
export const setActiveSetting = (activeSetting) => ({ type: 'SET_ACTIVE_SETTING', activeSetting })
// export const setPaymentToken = (paymentToken) => ({ type: 'SET_PAYMENT_TOKEN', paymentToken })


const initialState = {
	selectedProfessional: null,
    customers : null,
	selectedCustomer: null,
	isEditMode: false,
	isNewMode: false,
	token: null,
	isSideBarCollapsed: true,
	isClientListVisible: true,
	activeReport: null,
	activeChart: null,
	activeSetting: null,
	// paymentToken:  null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'SET_SELECTED_PROFESSIONAL':
			return {
				...state,
				selectedProfessional: action.professional
			}
		case 'SET_CUSTOMERS':
			return {
				...state,
				customers: action.customers
			}
		case 'SET_SELECTED_CUSTOMER':
			return {
				...state,
				selectedCustomer: action.customer
			}
		case 'SET_IS_EDIT_MODE':
			return {
				...state,
				isEditMode: action.isEditMode
		}
		case 'SET_IS_NEW_MODE':
			return {
				...state,
				isNewMode: action.isNewMode
		}
		case 'SET_IS_SIDEBAR_COLLAPSED':
			return {
				...state,
				isSideBarCollapsed: action.isSideBarCollapsed
		}
		case 'SET_IS_CLIENT_LIST_VISIBLE':
			return {
				...state,
				isClientListVisible: action.isClientListVisible
		}
		case 'SET_TOKEN':
			return {
				...state,
				token: action.token
		}
		case 'SET_ACTIVE_REPORT':
			return {
				...state,
				activeReport: action.activeReport
		}
		case 'SET_ACTIVE_CHART':
			return {
				...state,
				activeChart: action.activeChart
		}
		case 'SET_ACTIVE_SETTING':
			return {
				...state,
				activeSetting: action.activeSetting
		}
		// case 'SET_PAYMENT_TOKEN':
		// 	return {
		// 		...state,
		// 		paymentToken: action.paymentToken
		// }
		default:
			return state
	}
}