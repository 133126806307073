import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TextField, DialogActions, Button, Radio, RadioGroup, InputAdornment, IconButton, Input, InputLabel, FormControl, FormControlLabel, FormHelperText, Select, MenuItem } from '@material-ui/core';
import RestManager from '/persist/RestManager';
import NSnackBar from '../components/Common/NSnackBar';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import intl from 'react-intl-universal';
import ConfirmDialog from '../components/Dialogs/ConfirmDialog';
import { withRouter } from 'react-router-dom';
import {ACTIVE_PAGE} from './MainPage';
import utils from '/helpers/utils'
import queryString from 'query-string';
import * as Constants from '/helpers/Constants'
import { connect } from 'react-redux';
import { setActiveSetting } from '/redux/app'
import MessageBox from '../components/Dialogs/MessageBox';
import PhoneMask from '../helpers/PhoneMask';
import DBManager from '../persist/DBManager';
import iyzicoLogo from '/images/logo-iyzico.png';

const PANELS = {
  CRYPT_KEY: 'crypt_key',
  USER_PROFILE: 'user_profile',
  COMPANY_INFO: 'company_info',
  LICENCE: 'licence',
  SMS_PACKAGE: 'sms_package',
  CHANGE_PASSWORD: 'change_password',
  COMPANY_PROFILE: 'company_profile',
  USERS: 'users',
  NEW_USER: 'new_user',
  AGREEMENTS: 'agreements',
  };

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionBar: {
    // background: '#f8faff94',
    background: '#3f51b596',
    color: 'white',
    borderBottom: '1px solid white',
    // borderTop: '1px solid white', 
  },
  expansionPanel: {
    margin: '0 !important', 
  },
  textField: {
    width:'300px',
    margin:10
  },
  button:{
    margin:15
  },
  buttonBar: {
    justifyContent: 'center', 
    // borderBottom: '1px solid lightgray', 
    padding:10,
  },
  infoLabel: {
    fontSize: '1.1em',
    color:'gray',
  }

});

@connect(({ app }) => ({
  activeSetting: app.activeSetting,
}), {
  setActiveSetting,  
})

class AccountPage extends React.Component {
  state = {
    activeSetting: PANELS.USER_PROFILE,
    expanded: null,
    user:{},
    newUser:{},
    company:{address:{}},
    currentPassword: '',
    newPassword:'',
    confirmNewPassword: '',
    isSnackOpen: false,
    snackMessage:'',
    snackVariant:'',
    changePassButtonClicked:false,
    changeUPButtonClicked:false,
    changeCIButtonClicked:false,
    newUserButtonClicked:false,
    isCurrentPassVisible:false,
    isNewPassVisible:false,
    isConfirmNewPassVisible:false,
    users:null,
    wantToDeleteCompany:false,
    currentPassForDelete:'',
    isPassForDeleteVisible:false,
    deleteCompanyClicked:false,
    confirmDeleteDialogOpen:false,
    licence:null,
    licencePackage:3,
    newLicenceProfCount:1,
    smsInfo:null,
    smsPackage:1,
    alertDialogOpen:false,
    alertTitle:"",
    alertText:"",
    professionals:null,

    // paymentStatus:"",
  };

  constructor (props) {
    super(props);
    this.loadUser(true, (currentUser)=>{
      this.state.user = currentUser;
    });
    this.setSelectedTabFromUrl();
  }    

  componentDidUpdate(prevProps, prevState, snapshot) 
  {
   if (prevProps.activeSetting !== this.props.activeSetting) {
      console.log('componentDidUpdate AccountPage - !!!')
      this.setSelectedTabFromUrl();
   }
  }

  setSelectedTabFromUrl()
  {
    let params = queryString.parse(this.props.location.search)
    if ( params.tab ) {
      this.state.expanded = params.tab;
      this.onPanelChanged( params.tab);
    }
    if ( params.paymentStatus == "success" ) {
      this.state.alertTitle = 'Ödeme başarılı';
      this.state.alertText = 'Ödemeniz başarı ile gerçekleşti.';
      this.state.alertDialogOpen = true;
    } else if ( params.paymentStatus == "fail" ) {
      this.state.alertTitle = 'Ödeme başarısız';
      this.state.alertText = 'Ödemeniz gerçekleşmemiştir. Hata: '+params.errorMessage;
      this.state.alertDialogOpen = true;
    }

  }

  onSaveUP = () => {
    console.log(this.state.user.phone );
    console.log(this.state.user.phone === "+  -   -       ");
    if ( ! this.state.user.firstname || ! this.state.user.lastname || ! this.state.user.identityNumber || this.state.user.phone === "+  -   -       ") {
      this.setState({changeUPButtonClicked:true});
      return;
    }
    RestManager.instance.updateUser(this.state.user, (result) =>
    {
      console.log(" onSaveUP :", result );
      if ( result && result.success ) {
          this.setState({snackMessage:'Bigiler başarıyla güncellendi.' , snackVariant:'success', isSnackOpen:true});
      } else {
        this.setState({snackMessage:'Güncelleme başarısız.' , snackVariant:'error', isSnackOpen:true});
      }
    });
  }

  onSaveCompInfo = () => {
    if ( ! this.state.company.companyName || ! this.state.company.taxNumber || ! this.state.company.taxOffice || 
      ! this.state.company.address.city || ! this.state.company.address.street  ) {
      this.setState({changeCIButtonClicked:true});
      return;
    }
    RestManager.instance.updateCompany(this.state.company, (result) =>
    {
      console.log(" onSaveCompInfo :", result );
      if ( result && result.success ) {
          this.setState({snackMessage:'Bigiler başarıyla güncellendi.' , snackVariant:'success', isSnackOpen:true});
      } else {
        this.setState({snackMessage:'Güncelleme başarısız.' , snackVariant:'error', isSnackOpen:true});
      }
    });
  }

  onChangePass = () => {
    console.log(this.state.currentPassword + " , " + this.state.newPassword );
    if ( ! this.state.currentPassword || ! this.state.newPassword || ! this.state.confirmNewPassword ) {
      this.setState({changePassButtonClicked:true});
      return;
    }
    if ( this.state.newPassword  != this.state.confirmNewPassword ) {
      return;
    }
//    changePassButtonClicked:false,
    RestManager.instance.changeUserPass(this.state.user.id, this.state.currentPassword, this.state.newPassword, (result) =>
    {
      console.log(" onChangePass :", result );
      if ( result && result.success ) {
          this.loadUser(true);
          this.setState({snackMessage:'Bigiler başarıyla güncellendi.' , snackVariant:'success', isSnackOpen:true});
          this.setState({currentPassword:"", newPassword:"", confirmNewPassword:"", changePassButtonClicked:false});
      } else if ( result.status == '5023' ) {
        this.setState({snackMessage:'Güncel şifreniz hatalı' , snackVariant:'error', isSnackOpen:true});
      } else {
        this.setState({snackMessage:'Güncelleme başarısız.' , snackVariant:'error', isSnackOpen:true});
      }
    });
  }

  // onCreateUser = () => {
  //   this.setState({newUserButtonClicked:true});
  //   if ( this.state.newUser.username && this.state.newUser.firstname && this.state.newUser.lastname){
  //     let newUser = this.state.newUser;
  //     // newUser.company = this.state.user.company;
  //     console.log('new User :', newUser);
  //     RestManager.instance.addUserToCompany(newUser, (result) => {
  //       if ( result.success == false ) {
  //         if ( result.status == 5011 ) {
  //           this.setState({snackMessage:'Bu mail adresine sahip bir kullanıcı var.' , snackVariant:'error', isSnackOpen:true});
  //         } else if ( result.status == 5053 ) {
  //           this.setState({snackMessage:'Lisansınızın yeterli değil. \nYeni kullanıcı için lütfen lisans satın alınız.' , snackVariant:'info', isSnackOpen:true});
  //         } else {
  //           this.setState({snackMessage:'Kullanıcı oluşturulurken bir hata oluştu. \nLütfen Praxis Destek ekibi ile iletişime geçin.' , snackVariant:'error', isSnackOpen:true});
  //         }
  //       } else {
  //         this.setState({snackMessage:'Kullanıcı başarıyla oluşturuldu.' , snackVariant:'success', isSnackOpen:true});
  //         this.setState({newUser : {usename:"", firstname:"", lastname:""}, newUserButtonClicked:false });

  //       }
  //       console.log('onCreateUser : ', result);
  //     });
  //   }

  // }

  onWantToDeleteCompany = () => {
    this.setState({wantToDeleteCompany:true});
  }

  onConfirmDeleteCompany  = () => 
  {
    this.setState({deleteCompanyClicked:true});
    if ( ! this.state.currentPassForDelete ) {
      return;
    }
    this.setState({ confirmDeleteDialogOpen: true}); 
  }  

  onDeleteCompany  = () => 
  {
    let companyId = this.state.user.company.id;
    // let userId = this.state.user.id;
    RestManager.instance.deleteCompany(companyId, this.state.currentPassForDelete, (result) => 
    {
      if ( result && result.success ) {
        this.setState({snackMessage:'Kullanici silindi.' , snackVariant:'success', isSnackOpen:true});
        localStorage.removeItem("token");
        this.props.history.push(ACTIVE_PAGE.LOGIN);
        window.location.reload();
      } else if ( result.status == '5023' ) {
        this.setState({snackMessage:'Güncel şifreniz hatalı' , snackVariant:'error', isSnackOpen:true});
      } else {
        this.setState({snackMessage:'Islem başarısız.' , snackVariant:'error', isSnackOpen:true});
      }
      console.log('onDeleteUser : ', result);
    });

  }

  onSnackClosed = () => {
    console.log(' in onSnackClosed ');
    this.setState({ isSnackOpen:false });
  }

  toggleConfirmDialog = () => {
    this.setState({ confirmDeleteDialogOpen: ! this.state.confirmDeleteDialogOpen });
  }

  showPanel = (panel) =>  
  {
    this.setState({  expanded: panel });
    this.props.history.push("?tab="+panel);
    this.props.setActiveSetting(panel);
    this.onPanelChanged(panel);
  };


  handlePanelChange = panel => (event, expanded) => 
  {
    this.setState({
      expanded: expanded ? panel : false,
    });
    if ( expanded ) {
      this.props.history.push("?tab="+panel);
      this.props.setActiveSetting(panel);
      this.onPanelChanged(panel);
    }
  };

  onPanelChanged = (panel) => 
  {
    if ( panel == PANELS.USER_PROFILE || panel == PANELS.CHANGE_PASSWORD || panel == PANELS.NEW_USER ){
      this.loadUser();
    }
    else if ( panel == PANELS.USERS ) {
      this.loadUserList();
    }
    else if ( panel == PANELS.LICENCE ) {
      this.loadUser();
      this.loadCompany();
      this.loadLicence();
      this.loadProfessionals();
    }
    else if ( panel == PANELS.SMS_PACKAGE ) {
      this.loadUser();
      this.loadCompany();
      this.loadSMSInfo();
    }
    else if ( panel == PANELS.COMPANY_INFO ) {
      this.loadCompany();
    }

  }

  handleUPChange = name => event => {
    let newVal = event.target.value;
    this.setState(prevState => ({
      user: { ...prevState.user, [name]: newVal }
    }))
  };
  
  handleCIChange = name => event => {
    let newVal = event.target.value;
    if ( name == 'companyName' || name == 'taxNumber' || name == 'taxOffice' ) {
      this.setState(prevState => ({
        company: { ...prevState.company, [name]: newVal }
      }))
    } else {
      this.setState(prevState => ({
        company: {   ...prevState.company  ,              
            address: { ...prevState.company.address, [name]: newVal }
        }
      })) 
    }
  };
  
  handleNewUserChange = name => event => {
    let newVal = event.target.value;
    this.setState(prevState => ({
      newUser: { ...prevState.newUser, [name]: newVal }
    }))
  };
  
  handleClickShowPassword = (password) => {
    this.setState({ [password] : ! this.state[password] });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };
  
  handleDialogClose = () =>
  {
    this.setState({ alertDialogOpen: false });
  }

  loadUser(reload = false, callback) 
  {
    if ( ! this.state.user.firstname || reload ) // if not loaded already
    {
      RestManager.instance.getCurrentUser( (currentUser) =>
      {
        if ( currentUser ) {
          // this.setState({user: currentUser});
          if(typeof callback==="function") {
            callback(currentUser);
          }  
        }
      });
    } else {
      if ( callback ) {
        callback(this.state.user);
      }
    }
  }

  loadCompany(reload = false, callback) 
  {
    if ( ! this.state.company.companyName || reload ) // if not loaded already
    {
      RestManager.instance.getCompany( (result) =>
      {
        if ( result && result.success && result.response ) {
          let company = result.response;
          if ( ! company.address ) {
            company.address = {};
          }
          company.address.country = "Türkiye";
          this.setState({company: company});
          if(typeof callback==="function") {
            callback(company);
          }
        }
      });
    } else {
      if ( callback ) {
        callback(this.state.company);
      }
    }
  }

  loadUserList = () => {
    this.loadUser(false, (user) => {
      RestManager.instance.getUsers((result) => {
        console.log("loadUserList users : ", result);
        if ( result && result.success && result.response ) {
          this.setState({users: result.response});
        }
      })
    });
  }

  loadLicence = () => {
    this.loadUser(false, (user) => {
      RestManager.instance.getLicence((result) => {
        console.log("licence : ", result);
        if ( result && result.success && result.response ) {
          this.setState({licence: result.response, newLicenceProfCount:result.response.userCount});
        }
      })
    });
  }

  loadSMSInfo = () => {
    this.loadUser(false, (user) => {
      RestManager.instance.getSMSInfo((result) => {
        console.log("sms info : ", result);
        if ( result && result.success && result.response ) {
          this.setState({smsInfo: result.response});
        }
      })
    });
  }

    loadProfessionals = () => {
      RestManager.instance.getProfessionals((result) => {
        console.log("loadProfessionalsList users : ", result);
        if ( result && result.success == true && result.response ) {
          this.setState({professionals: result.response});
          if (result.response) {
            let professionalCount = result.response.length;
            this.setState({newLicenceProfCount:professionalCount});
          }

        }

      })
    }


  doPayment = (packageType) => 
  {
    console.log("this.state = ", this.state);
    if ( ! this.state.user.firstname || ! this.state.user.lastname || ! this.state.user.identityNumber || ! this.state.user.phone  ) {
        this.setState({
          alertTitle:'Eksik Veri',
          alertText: "Lütfen 'Kullanıcı Bilgilerini' eksiksiz giriniz.",
          alertDialogOpen:true,
        });
			return null;
    }
    if ( ! this.state.company.companyName || ! this.state.company.taxNumber || ! this.state.company.taxOffice 
      || ! this.state.company.address.city || ! this.state.company.address.country || ! this.state.company.address.street  ) {
      this.setState({
        alertTitle:'Eksik Veri',
        alertText: "Lütfen 'Şirket Bilgilerini' eksiksiz giriniz.",
        alertDialogOpen:true,
      });
      return null;
    }
  
    var scriptId = "iyzicoScript";
    if (document.contains(document.getElementById(scriptId))) {
      document.getElementById(scriptId).remove();
    }
    let itemCount = 1;
    if ( packageType.startsWith('licence')){
      itemCount = this.state.newLicenceProfCount;
    }
    RestManager.instance.getPaymentPopup(packageType, itemCount, (result) => {
      if ( result.success ) {
        console.log("script : ", result);
        var checkoutFormContent = result.response.checkoutFormContent;
        var iyziToken = result.response.iyziToken;
        
        RestManager.instance.setIyziToken(iyziToken, itemCount, (result) => {
          if ( result.success ) 
          {
            var script = checkoutFormContent.substring(checkoutFormContent.indexOf(">")+1, checkoutFormContent.indexOf("</script>"));
            console.log("cleaned result", script);
      
            var s = document.createElement('script');
            s.setAttribute('type', 'text/javascript');
            s.innerHTML = "iyziInit = undefined;  " + script;
            s.id=scriptId;
            document.getElementsByTagName('head')[0].appendChild(s);
          }
        })
        
      } else {
        if ( result.status == 5051 ){
          console.log("ITEM COUNT LESS THEN PERSONNEL COUNT");
          this.setState({
            alertTitle : 'Daktor sayısını kontrol ediniz.',
            alertText : 'Lisans için seçilen doktor sayısı kayıtlı doktor sayısının altında.',
            alertDialogOpen : true,
          });  
    
        }
      }

    })

  }
 
  render() {
    const { classes } = this.props;
    const { expanded } = this.state;

    // var licenceRest = RestManager.instance.getToken().licenceRest;
    var licenceRest = 0;
    if ( this.state.licence ) {
      licenceRest = this.state.licence.licenceRest;
    }

    var usersList = null;
    if ( this.state.users ) {
      usersList = new Array(this.state.users.length).fill(0).map( ( zero, i ) =>
      ! this.state.users[i].isAdmin ?
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{this.state.users[i].firstname} {this.state.users[i].lastname}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <div className="row">{intl.get('EMAIL')} : {this.state.users[i].username} </div>
              <div className="row">{intl.get('ACTIVE')} : {this.state.users[i].isActive ? intl.get('YES') : intl.get('NO') } </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        :<div></div> 
      )
    }


    let userLicenceCountItems = [];
    for (var i = this.state.newLicenceProfCount; i <= 20; i++) {
      userLicenceCountItems.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    return (
      <div className={classes.root}>

        {/*
        <ExpansionPanel expanded={expanded === PANELS.CRYPT_KEY} onChange={this.handlePanelChange(PANELS.CRYPT_KEY)} className={classes.expansionPanel}>
          <ExpansionPanelSummary className={classes.expansionBar} expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>}>
            <Typography className={classes.heading}>{intl.get("CRYPT_KEYWORD")}</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <div style={{width:700}}>
                <div className={classes.infoLabel} style={{marginTop:20, marginBottom:10}}>
                  {intl.get("YOUR_CRYPT_KEYWORD")}:
                </div>
                {this.state.user.originalCryptKey ? utils.b64_to_utf8(DBManager.getInstance().getCryptKey()) :  DBManager.getInstance().getCryptKey()}
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        */}


        <ExpansionPanel expanded={expanded === PANELS.USER_PROFILE} onChange={this.handlePanelChange(PANELS.USER_PROFILE)} className={classes.expansionPanel}>
          <ExpansionPanelSummary className={classes.expansionBar} expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>}>
            <Typography className={classes.heading}>{intl.get('MY_ACCOUNT')}</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>

            <div style={{margin:'0 0 30 0' }}>

              {/* <TextField required
                label={intl.get('COMPANY')}
                disabled={!this.state.user.isAdmin}
                error={this.state.user.company.companyName == "" && this.state.changeUPButtonClicked }
                helperText={this.state.user.company.companyName == "" && this.state.changeUPButtonClicked ? 'Lütfen şirket adını giriniz. ' : '' }
                value={this.state.user.company.companyName || '' }
                onChange = {this.handleUPChange('companyName')}
                className={classes.textField}
              />
              <br/> */}
              <TextField required
                error={this.state.user.firstname == "" && this.state.changeUPButtonClicked }
                helperText={this.state.user.firstname == "" && this.state.changeUPButtonClicked ? 'Lütfen adınızı giriniz. ' : '' }
                label={intl.get('FIRSTNAME')}
                value={this.state.user.firstname || '' }
                onChange = {this.handleUPChange('firstname')}
                className={classes.textField}
              />
              <br/>
              <TextField required
                error={this.state.user.lastname == "" && this.state.changeUPButtonClicked }
                helperText={this.state.user.lastname == "" && this.state.changeUPButtonClicked ? 'Lütfen soyadınızı giriniz. ' : '' }
                label={intl.get('LASTNAME')}
                value={this.state.user.lastname || '' }
                onChange = {this.handleUPChange('lastname')}
                className={classes.textField}
              />
              <br/>
              <TextField required
                error={ ! this.state.user.identityNumber && this.state.changeUPButtonClicked }
                helperText={ ! this.state.user.identityNumber  && this.state.changeUPButtonClicked ? 'Lütfen kimlik numaranızı giriniz. ' : '' }
                label={intl.get('IDENTITY_NUMBER')}
                value={this.state.user.identityNumber || '' }
                onChange = {this.handleUPChange('identityNumber')}
                className={classes.textField}
              />
              <br/>
                <FormControl className={classes.textField}>
                  <InputLabel required htmlFor="formatted-phone">{intl.get('PHONE')}</InputLabel>
                  <Input 
                    value={this.state.user.phone}
                    onChange={this.handleUPChange('phone')}
                    id="formatted-phone"
                    inputComponent={PhoneMask}
                    // error={this.state.user.phone === "+  -   -       " && this.state.changeUPButtonClicked }
                  />  
                </FormControl>

              <br/>

              <TextField
                label={intl.get('EMAIL')}
                disabled
                value={this.state.user.username || '' }
                onChange = {this.handleUPChange('email')}
                className={classes.textField}
              />


              <div className='row' >
                <DialogActions className={classes.buttonBar}>
                    <Button onClick={this.onSaveUP} variant="contained" color="primary">{intl.get('SAVE')}</Button>
                </DialogActions>
              </div>

              { this.state.user.isAdmin ?
                <div>
                  <div className='row' style={{margin:'50 0 0 0'}}>
                    <Button onClick={this.onWantToDeleteCompany} size='small' disableRipple >{intl.get('WANT_TO_DELETE_ACCOUNT')}</Button>
                  </div>
                  { this.state.wantToDeleteCompany ?
                    <FormControl className={classNames(classes.textField)}>
                    <InputLabel required htmlFor="passwordForDel">{intl.get('PASSWORD')}</InputLabel>
                    <Input 
                      id='passwordForDel'
                      error={this.state.currentPassForDelete == "" && this.state.wantToDeleteCompany && this.state.deleteCompanyClicked}
                      type={this.state.isPassForDeleteVisible ? 'text' : 'password'}
                      value={this.state.currentPassForDelete}
                      onChange = {this.handleChange('currentPassForDelete')}
                      // className={classes.textField}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Şifre görünürlüğü"
                            onClick={ () => this.handleClickShowPassword('isPassForDeleteVisible') }
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {this.state.isPassForDeleteVisible ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText
                      error={this.state.currentPassForDelete == "" && this.state.wantToDeleteCompany && this.state.deleteCompanyClicked}                  
                    >
                      {this.state.currentPassForDelete == "" && this.state.wantToDeleteCompany && this.state.deleteCompanyClicked? 'Lütfen şifrenizi giriniz. ' : '' }
                    </FormHelperText>

                    <Button onClick={this.onConfirmDeleteCompany} variant="contained" color="secondary" style={{marginTop:20}}>{intl.get('DELETE_ACCOUNT')}</Button>

                  </FormControl>
                  :<div></div>
                  }
                </div>
                  :<div></div>
                }
            </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        { this.state.user.isAdmin  ?
          <ExpansionPanel expanded={expanded === PANELS.COMPANY_INFO} onChange={this.handlePanelChange(PANELS.COMPANY_INFO)} className={classes.expansionPanel}>
            <ExpansionPanelSummary className={classes.expansionBar} expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>}>
              <Typography className={classes.heading}>{intl.get('COMPANY_INFO')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>

                <div style={{width:700}}>

                <div className={classes.infoLabel} style={{marginTop:20, marginBottom:10}}>
                  {intl.getHTML('CREATE_ACCOUNT_DESC')}
                  Firma Bilgileri -  Bu bilgiler aynı zamanda faturanızda kullanacağımız bilgilerdir. 
                </div>

                <div className='row' style={{ margin:'0px 20px 0'}}>
                      <div className='column'  >
                        <TextField required
                          label={intl.get('COMPANY')}
                          error={ ! this.state.company.companyName && this.state.changeCIButtonClicked }
                          helperText={ ! this.state.company.companyName && this.state.changeCIButtonClicked ? 'Lütfen şirket adını giriniz. ' : '' }
                          value={this.state.company.companyName || '' }
                          onChange = {this.handleCIChange('companyName')}
                          className={classes.textField}
                          style = {{width: 620}}
                        />
                      </div>
                  </div>


                  <div className='row' style={{margin:'0px 20px'}}>
                    <div className='column'  >
                      <TextField required
                            id="standard-name"
                            label="Vergi Numarası"
                            error={ ! this.state.company.taxNumber && this.state.changeCIButtonClicked }
                            helperText={! this.state.company.taxNumber  && this.state.changeCIButtonClicked ? 'Lütfen vergi numarasını giriniz. ' : '' }
                            value={this.state.company.taxNumber || ''}
                            className={classes.textField}
                            onChange={this.handleCIChange('taxNumber')}
                      />
                    </div>
                    <div className='column' >
                      <TextField required
                        id="standard-name" 
                        label="Vergi Dairesi"
                        error={ ! this.state.company.taxOffice && this.state.changeCIButtonClicked }
                        helperText={ ! this.state.company.taxOffice && this.state.changeCIButtonClicked ? 'Lütfen vergi dairesini giriniz. ' : '' }
                        value={this.state.company.taxOffice || ''}
                        className={classes.textField}
                        onChange={this.handleCIChange('taxOffice')}
                      />
                    </div>
                  </div>

                  <div className='row' style={{margin:'0 20px'}}>
                      <div className='column'  >
                        <TextField
                              id="standard-name"
                              label="Ülke"
                              value={this.state.company.address.country || ''}
                              disabled="true"
                              className={classes.textField}
                              onChange={this.handleCIChange('country')}
                        />
                      </div>
                      <div className='column' >
                        <TextField required
                          id="standard-name"
                          label={intl.get('CITY')}
                          error={ ! this.state.company.address.city && this.state.changeCIButtonClicked }
                          helperText={! this.state.company.address.city && this.state.changeCIButtonClicked ? 'Lütfen şehir giriniz. ' : '' }
                          value={this.state.company.address.city || ''}
                          className={classes.textField}
                          onChange={this.handleCIChange('city')}
                        />
                    </div>
                  </div>

                  <div className='row' style={{margin:'0 20px'}}>
                      <div className='column'  >
                        <TextField
                              id="standard-name"
                              label={intl.get('COUNTY')}
                              value={this.state.company.address.county || ''}
                              className={classes.textField}
                              onChange={this.handleCIChange('county')}
                        />
                      </div>
                      <div className='column' >
                        <TextField
                          id="standard-name"
                          label={intl.get('POSTAL_CODE')}
                          value={this.state.company.address.postalCode || ''}
                          className={classes.textField}
                          onChange={this.handleCIChange('postalCode')}
                        />
                    </div>
                  </div>

                  <div className='row' style={{ margin:'0 20px'}}>
                      <div className='column'  >
                        <TextField required
                              id="standard-name"
                              label="Adres"
                              error={ ! this.state.company.address.street && this.state.changeCIButtonClicked }
                              helperText={ ! this.state.company.address.street && this.state.changeCIButtonClicked ? 'Lütfen adresi giriniz. ' : '' }
                              value={this.state.company.address.street || ''}
                              className={classes.textField}
                              onChange={this.handleCIChange('street')}
                              style = {{width: 620}}
                        />
                      </div>
                  </div>

                  <div className='row' >
                    <DialogActions className={classes.buttonBar}>
                        <Button onClick={this.onSaveCompInfo} variant="contained" color="primary">{intl.get('SAVE')}</Button>
                    </DialogActions>
                  </div>


                </div>

              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        :<div></div>}

        { this.state.user.isAdmin  ?
          <ExpansionPanel expanded={expanded === PANELS.LICENCE} onChange={this.handlePanelChange(PANELS.LICENCE)} className={classes.expansionPanel}>
            <ExpansionPanelSummary className={classes.expansionBar} expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>}>
              <Typography className={classes.heading}>{intl.get('LICENCE_INFO')} </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                {this.state.licence ? 
                  <div className={classes.infoLabel}  style={{marginTop:20, marginBottom:10}}>
                      Lisansınızın başlangıç tarihi: {utils.humanDate(utils.UTC2GMT(this.state.licence.licenceStartDate))} - bitiş tarihi: {utils.humanDate(utils.UTC2GMT(this.state.licence.licenceEndDate))}
                      { this.state.licence && this.state.licence.licenceType != 0 ?
                        <div >
                          Lisansınız dahilinde sisteme ekleyebileceğiniz kullanıcı sayısı:
                          {'\u00a0\u00a0'}{this.state.licence.userCount}   
                        </div>
                      :"" }
                      <br></br>
                      { licenceRest >= 30 ? 
                        "Lisansınızın süresinin dolmasina 1 aydan kısa bir süre kaldığında yeni üyelik paketi satın alabilirsiniz."  : "" 
                      }
                      { licenceRest <= 0 ? 
                        "Lisansınızın süresi bitmiştir. Aşağıdaki üyelik paketlerinden birini seçerek Praxis-24'ü kullanmaya devam edebilirsiniz."  : "" 
                      }
                      { licenceRest < 30 && licenceRest > 0 && this.state.licence.licenceType != 0 ? 
                        "Lisansınızın bitmesine 1 aydan kısa bir süre kaldı. Aşağıdaki üyelik paketlerden birini seçerek Praxis-24'ü kullanmaya devam edebilirsiniz." 
                        : ""
                      }
                      { licenceRest > 0 && this.state.licence.licenceType == 0 ?
                        "Aşağıdaki üyelik paketlerinden birini seçerek Siz de Praxis-24 ailesinin bir üyesi olun." : ""
                      }
                      <br></br><br></br>

                      {/* { this.state.paymentStatus == "success" ? "Ödemeniz başarı ile gerçekleşti." : ""}
                      { this.state.paymentStatus == "fail" ? "Ödeme başarısız." : ""} */}
                  </div>
                :""}



                { licenceRest < 30 ?
                <div>
                  <div className={classes.infoLabel}>
                    Devam etmeden önce lütfen  <a style={{color:'mediumblue', cursor: 'pointer'}}  onClick={ () => this.showPanel(PANELS.USER_PROFILE) }> Kullanıcı Bilgilerinizi </a> ve 
                    <a style={{color:'mediumblue', cursor: 'pointer'}} onClick={ () => this.showPanel(PANELS.COMPANY_INFO) } > Şirket Bilgilerinizi </a>  kontrol ediniz. Eksik bilgileri tamamlayınız.
                  </div>
                  <br></br><br></br>
                  <strong>ÜYELİK PAKETLERİ</strong>
                  <br></br><br></br>
                  <div className='row' style={{backgroundColor:'gainsboro', padding:'8 20', }}>
                    <div className='column' style={{height:30, lineHeight:'30px', marginRight:20}}>
                      Doktor Sayısı Seçiniz: 
                    </div>
                    <div className='column' style={{textAlign:'center'}}>
                      <FormControl >
                        {/* <InputLabel id="newLicenceProfCount">Kullanıcı Sayısı</InputLabel> */}
                        <Select
                          labelid="newLicenceProfCount" id="newLicenceProfCount"
                          value={this.state.newLicenceProfCount}
                          onChange={this.handleChange('newLicenceProfCount')}
                          style={{width:100}}
                        >
                        {userLicenceCountItems}
                          
                        </Select>
                      </FormControl>
                    </div>

                  </div>
                  <hr></hr>
                  <RadioGroup
                    name="licencePackage"
                    value={this.state.licencePackage+""}
                    onChange={this.handleChange('licencePackage')}
                    column
                  >
                    <FormControlLabel value="1" style={{marginLeft:10}} control={<Radio />} label={'Aylık Üyelik: 150 TL + KDV ' } />
                    <div style={{marginLeft:50}}>
                        { this.state.newLicenceProfCount + ' Kullanıcı Ücreti = ' + this.state.newLicenceProfCount * 150 +' TL + KDV '}
                     </div>

                    <div style={{marginLeft:50}}>
                      { this.state.licence && licenceRest > 0 ? 
                          "Yeni üyelik paketi başlangıç tarihi: " + utils.humanDate(utils.UTC2GMT(this.state.licence.licenceEndDate))  
                          : 
                          "Yeni üyelik paketi başlangıç tarihi: " + utils.humanDate(new Date())  
                      }
                    </div>
                    <div style={{marginLeft:50, marginTop:10, paddingBottom:10, marginBottom:10, borderBottom:'1px solid gray'}} >
                      <Button onClick={()=>this.doPayment('licence-month')} variant="contained" color="primary" style={{backgroundColor:Constants.GREEN_BUTTON, visibility:this.state.licencePackage != "1" ? 'hidden':'visible'}}>1 AYLIK ÜYELİK SATIN AL</Button>
                    </div>
                    <FormControlLabel value="2" style={{marginLeft:10}} control={<Radio />} label={'6 Aylık Üyelik: 750 TL + KDV' } />
                    <div style={{marginLeft:50}}>
                        {  this.state.newLicenceProfCount + ' Kullanıcı Ücreti = ' + this.state.newLicenceProfCount * 750 +' TL + KDV'}
                     </div>
                    <div style={{marginLeft:50}}>
                      { this.state.licence && licenceRest > 0 ? 
                          "Yeni üyelik paketi başlangıç tarihi: " + utils.humanDate(utils.UTC2GMT(this.state.licence.licenceEndDate))  
                          : 
                          "Yeni üyelik paketi başlangıç tarihi: " + utils.humanDate(new Date())  
                      }
                    </div>
                    <div style={{marginLeft:50, marginTop:10, paddingBottom:10, marginBottom:10, borderBottom:'1px solid gray'}} >                  
                      <Button onClick={()=>this.doPayment('licence-halfyear')} variant="contained" color="primary" style={{backgroundColor:Constants.GREEN_BUTTON, visibility:this.state.licencePackage != "2" ? 'hidden':'visible'}}>6 AYLIK ÜYELİK SATIN AL</Button>
                    </div>
                    <FormControlLabel value="3" style={{marginLeft:10}}control={<Radio />} label={'1 Yıllık Üyelik: 1250 TL + KDV  ' } />
                    <div style={{marginLeft:50}}>
                        {  this.state.newLicenceProfCount + ' Kullanıcı Ücreti = ' + this.state.newLicenceProfCount * 1250 +' TL + KDV ' }
                     </div>

                    <div style={{marginLeft:50}}>
                      { this.state.licence && licenceRest > 0 ? 
                          "Yeni üyelik paketi başlangıç tarihi: " + utils.humanDate(utils.UTC2GMT(this.state.licence.licenceEndDate))  
                          : 
                          "Yeni üyelik paketi başlangıç tarihi: " + utils.humanDate(new Date())  
                      }
                    </div>
                    <div style={{marginLeft:50, marginTop:10, marginBottom:10, paddingBottom:10, borderBottom:'1px solid gray'}} >
                      <Button onClick={()=>this.doPayment('licence-year')} variant="contained" color="primary" style={{backgroundColor:Constants.GREEN_BUTTON, visibility:this.state.licencePackage != "3" ? 'hidden':'visible'}}>1 YILLIK ÜYELİK SATIN AL</Button>
                    </div>
                  </RadioGroup>

                  {/* <div style={{marginLeft:30, marginTop:50, marginBottom:10, textAlign:'center'}} > */}
                    <div id="iyzipay-checkout-form" class="popup" style={{ visibility:'hidden', maxHeight:0}}></div>
                    {/* <div id="iyzipay-checkout-form" class="responsive"></div> */}
                  {/* </div> */}

                  <div style={{marginLeft:30, marginTop:50, marginBottom:10, textAlign:'center'}} >
                    <img src={iyzicoLogo} width="700" ></img>
                  </div>

                  <div style={{marginLeft:30, marginTop:60, marginBottom:100, textAlign:'center'}} >
                    <span > <a href='https://nsdsoft.com/kullanici-sozlesmesi/' target="_blank" >Kulllanıcı Sözleşmesi</a> </span>
                    <span style={{marginLeft:40}}> <a href='https://nsdsoft.com/gizlilik-politikasi/' target="_blank" >Gizlilik ve Kişisel Verilerin Korunması Politikası</a> </span>
                  </div>
                </div>
                :""}

                </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        :<div></div>}

        { this.state.user.isAdmin  ?
          <ExpansionPanel expanded={expanded === PANELS.SMS_PACKAGE} onChange={this.handlePanelChange(PANELS.SMS_PACKAGE)} className={classes.expansionPanel}>
            <ExpansionPanelSummary className={classes.expansionBar} expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>}>
              <Typography className={classes.heading}>{intl.get('SMS_PACKAGES')} </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
              <br></br> 
                {  this.state.smsInfo && this.state.smsInfo.total > 0 ?
                  "MEVCUT KULLANILABİLİR SMS SAYISI: " + (this.state.smsInfo.total-this.state.smsInfo.used)
                :""} 
                <div className={classes.infoLabel}  style={{marginTop:20, marginBottom:10}}>
                    Aşağıdaki SMS paketlerinden birini seçerek Hasta/Danışanlarınıza bilgilendirme ve hatırlatma mesajları gönderebilirsiniz.
                    <br></br>
                    Devam etmeden önce lütfen  <a style={{color:'mediumblue', cursor: 'pointer'}}  onClick={ () => this.showPanel(PANELS.USER_PROFILE) }> Kullanıcı Bilgilerinizi </a> ve 
                    <a style={{color:'mediumblue', cursor: 'pointer'}} onClick={ () => this.showPanel(PANELS.COMPANY_INFO) } > Şirket Bilgilerinizi </a>  kontrol ediniz. Eksik bilgileri tamamlayınız.
                </div>
                <div>
                  <div className={classes.infoLabel}>
                  </div>
                  <br></br>
                  SMS PAKETLERİ
                  <br></br>
                  <hr></hr>
                  <RadioGroup
                    name="smsPackage"
                    value={this.state.smsPackage+""}
                    onChange={this.handleChange('smsPackage')}
                    column
                  >
                    <FormControlLabel value="1" style={{marginLeft:10}} control={<Radio />} label={'1000 SMS - 350 TL + KDV '} />
                    <div style={{marginLeft:50}}>
                    </div>
                    <div style={{marginLeft:50, marginTop:10, paddingBottom:10, marginBottom:10, borderBottom:'1px solid gray'}} >
                      <Button onClick={()=>this.doPayment('sms-1000')} variant="contained" color="primary" style={{backgroundColor:Constants.GREEN_BUTTON, visibility:this.state.smsPackage != "1" ? 'hidden':'visible'}}>1000 SMS SATIN AL</Button>
                    </div>
                    <FormControlLabel value="2" style={{marginLeft:10}} control={<Radio />} label={'2500 SMS - 800 TL + KDV '} />
                    <div style={{marginLeft:50, marginTop:10, paddingBottom:10, marginBottom:10, borderBottom:'1px solid gray'}} >
                      <Button onClick={()=>this.doPayment('sms-2500')} variant="contained" color="primary" style={{backgroundColor:Constants.GREEN_BUTTON, visibility:this.state.smsPackage != "2" ? 'hidden':'visible'}}>2500 SMS SATIN AL</Button>
                    </div>
                    <FormControlLabel value="3" style={{marginLeft:10}}control={<Radio />} label={'5000 SMS - 1500 TL + KDV '} />
                    <div style={{marginLeft:50, marginTop:10, marginBottom:10, paddingBottom:10, borderBottom:'1px solid gray'}} >
                      <Button onClick={()=>this.doPayment('sms-5000')} variant="contained" color="primary" style={{backgroundColor:Constants.GREEN_BUTTON, visibility:this.state.smsPackage != "3" ? 'hidden':'visible'}}>5000 SMS SATIN AL</Button>
                    </div>
                  </RadioGroup>

                  {/* <div style={{marginLeft:30, marginTop:50, marginBottom:10, textAlign:'center'}} > */}
                    <div id="iyzipay-checkout-form" class="popup" style={{ visibility:'hidden', maxHeight:0}}></div>
                    {/* <div id="iyzipay-checkout-form" class="responsive"></div> */}
                  {/* </div> */}

                  <div style={{marginLeft:30, marginTop:50, marginBottom:10, textAlign:'center'}} >
                    <img src={iyzicoLogo} width="700" ></img>
                  </div>

                  <div style={{marginLeft:30, marginTop:60, marginBottom:100, textAlign:'center'}} >
                    <span > <a href='https://nsdsoft.com/kullanici-sozlesmesi/' target="_blank" >Kulllanıcı Sözleşmesi</a> </span>
                    <span style={{marginLeft:40}}> <a href='https://nsdsoft.com/gizlilik-politikasi/' target="_blank" >Gizlilik ve Kişisel Verilerin Korunması Politikası</a> </span>
                  </div>
                </div>

                </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        :<div></div>}


        <ExpansionPanel expanded={expanded === PANELS.CHANGE_PASSWORD} onChange={this.handlePanelChange(PANELS.CHANGE_PASSWORD)} className={classes.expansionPanel} >
          <ExpansionPanelSummary className={classes.expansionBar} expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>}>
            <Typography className={classes.heading}>{intl.get('CHANGE_PASSWORD')}</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>

              <div style={{margin:'0 0 30 0' }}>
                <FormControl className={classNames(classes.textField)}>
                  <InputLabel required htmlFor="current-password">{intl.get('CURRENT_PASSWORD')}</InputLabel>
                  <Input 
                    id='current-password'
                    error={this.state.currentPassword == "" && this.state.changePassButtonClicked }
                    type={this.state.isCurrentPassVisible ? 'text' : 'password'}
                    value={this.state.currentPassword}
                    onChange = {this.handleChange('currentPassword')}
                    // className={classes.textField}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Şifre görünürlüğü"
                          onClick={ () => this.handleClickShowPassword('isCurrentPassVisible') }
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.isCurrentPassVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                    error={this.state.currentPassword == "" && this.state.changePassButtonClicked }
                  >
                    {this.state.currentPassword == "" && this.state.changePassButtonClicked ? intl.get('PLEASE_FILL_THIS_FIELD') : '' }
                  </FormHelperText>
                </FormControl>

                <br/>

                <FormControl className={classNames(classes.textField)}>
                  <InputLabel required htmlFor="new-password">{intl.get('NEW_PASSWORD')}</InputLabel>
                  <Input 
                    id='new-password'
                    error={this.state.newPassword == "" && this.state.changePassButtonClicked }
                    type={this.state.isNewPassVisible ? 'text' : 'password'}
                    value={this.state.newPassword}
                    onChange = {this.handleChange('newPassword')}
                    // className={classes.textField}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Şifre görünürlüğü"
                          onClick={ () => this.handleClickShowPassword('isNewPassVisible') }
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.isNewPassVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                    error={this.state.newPassword == "" && this.state.changePassButtonClicked }                  
                  >
                    {this.state.newPassword == "" && this.state.changePassButtonClicked ? intl.get('PLEASE_FILL_THIS_FIELD') : '' }
                  </FormHelperText>
                </FormControl>


                {/* <TextField required
                    error={this.state.newPassword == "" && this.state.changePassButtonClicked }
                    helperText= {this.state.newPassword == "" && this.state.changePassButtonClicked ? 'Lütfen yeni şifrenizi giriniz.' : ''}
                    type="password"
                    label="Yeni Şifre"
                    onChange = {this.handleChange('newPassword')}
                    className={classes.textField}
                  /> */}
               
                <br/>

                <FormControl className={classNames(classes.textField)}>
                  <InputLabel required htmlFor="confirm-new-password">{intl.get('CONFIRM_NEW_PASSWORD')}</InputLabel>
                  <Input 
                    id='confirm-new-password'
                    error={ this.state.newPassword != this.state.confirmNewPassword && this.state.changePassButtonClicked}
                    type={this.state.isConfirmNewPassVisible ? 'text' : 'password'}
                    value={this.state.confirmNewPassword}
                    onChange = {this.handleChange('confirmNewPassword')}
                    // className={classes.textField}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Şifre görünürlüğü"
                          onClick={ () => this.handleClickShowPassword('isConfirmNewPassVisible') }
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.isConfirmNewPassVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                        error={ this.state.newPassword != this.state.confirmNewPassword && this.state.changePassButtonClicked}
                  >
                    {this.state.newPassword != this.state.confirmNewPassword && this.state.changePassButtonClicked ? intl.get('PASSWORDS_DO_NOT_MATCH') : ''}
                  </FormHelperText>
                </FormControl>

                {/* <TextField required
                    error={ this.state.newPassword != this.state.confirmNewPassword && this.state.changePassButtonClicked}
                    helperText={this.state.newPassword != this.state.confirmNewPassword && this.state.changePassButtonClicked ? 
                                'Şifreler birbiri ile aynı değil.' : ''}
                    type="password"
                    label="Yeni Şifre Tekrar"
                    onChange = {this.handleChange('confirmNewPassword')}
                   className={classes.textField}
                /> */}
              </div>

              <div className='row' >
                <DialogActions className={classes.buttonBar} >
                    <Button onClick={this.onChangePass} variant="contained" color="primary">{intl.get('SAVE')}</Button>
                </DialogActions>
              </div>

            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/* <ExpansionPanel expanded={expanded === PANELS.COMPANY_PROFILE} onChange={this.handlePanelChange(PANELS.COMPANY_PROFILE)} className={classes.expansionPanel}>
          <ExpansionPanelSummary className={classes.expansionBar} expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>}>
            <Typography className={classes.heading}>ŞİRKET PROFİLİ</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar
              diam eros in elit. Pellentesque convallis laoreet laoreet.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel> */}

        {/* { this.state.user.isAdmin ?
          <ExpansionPanel expanded={expanded === PANELS.NEW_USER} onChange={this.handlePanelChange(PANELS.NEW_USER)} className={classes.expansionPanel}>
            <ExpansionPanelSummary className={classes.expansionBar} expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>}>
              <Typography className={classes.heading}>{intl.get('NEW_USER')} </Typography>
              <Typography className={classes.secondaryHeading}></Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>

                <div className={classes.infoLabel} style={{marginTop:20, marginBottom:10}}>
                  {intl.getHTML('CREATE_ACCOUNT_DESC')}
                </div>

                <div> 
                  <TextField required
                    error={ ! this.state.newUser.firstname && this.state.newUserButtonClicked }
                    helperText={ ! this.state.newUser.firstname && this.state.newUserButtonClicked ? intl.get('PLEASE_FILL_THIS_FIELD') : '' }
                    label={intl.get('NEW_USER_FIRSTNAME')}
                    value={this.state.newUser.firstname || '' }
                    onChange = {this.handleNewUserChange('firstname')}
                    className={classes.textField}
                  />
                  <br/>
                  <TextField required
                    error={ ! this.state.newUser.lastname && this.state.newUserButtonClicked }
                    helperText={ ! this.state.newUser.lastname && this.state.newUserButtonClicked ? intl.get('PLEASE_FILL_THIS_FIELD') : '' }
                    label={intl.get('NEW_USER_LASTNAME')}
                    value={this.state.newUser.lastname || '' }
                    onChange = {this.handleNewUserChange('lastname')}
                    className={classes.textField}
                  />
                  <br/>
                  <TextField required
                    style={{width:400}}
                    error={ ! this.state.newUser.username && this.state.newUserButtonClicked }
                    helperText={ ! this.state.newUser.username && this.state.newUserButtonClicked ? intl.get('PLEASE_FILL_THIS_FIELD'):''}
                    label={intl.get('NEW_USER_EMAIL')}
                    value={this.state.newUser.username || '' }
                    onChange = {this.handleNewUserChange('username')}
                    className={classes.textField}
                  />

              </div>

                <div className='row' >
                  <DialogActions className={classes.buttonBar} >
                      <Button onClick={this.onCreateUser} variant="contained" color="primary">{intl.get('CREATE_NEW_USER')}</Button>
                  </DialogActions>

                </div>

              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        :<div></div>} */}


        {/* { this.state.user.isAdmin  ?
          <ExpansionPanel expanded={expanded === PANELS.USERS} onChange={this.handlePanelChange(PANELS.USERS)} className={classes.expansionPanel}>
            <ExpansionPanelSummary className={classes.expansionBar} expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>}>
              <Typography className={classes.heading}>{intl.get('USERS')}</Typography>
              <Typography className={classes.secondaryHeading}></Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>

                <div style={{width:700}}>
                  { this.state.users && this.state.users.length > 1 ? usersList 
                  :
                  <div className={classes.infoLabel} style={{marginTop:20, marginBottom:10}}>
                    Henüz kullanıcı oluşturulmamış. <br></br>
                    <a style={{color:'mediumblue', cursor: 'pointer'}} onClick={ () => this.showPanel(PANELS.NEW_USER) }>Yeni kullanıcı oluşturmak için tıklayınız.</a>
                  </div>
                  } 
                </div>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        :<div></div>} */}

          
          {/* <ExpansionPanel expanded={expanded === PANELS.AGREEMENTS} onChange={this.handlePanelChange(PANELS.AGREEMENTS)} className={classes.expansionPanel}>
            <ExpansionPanelSummary className={classes.expansionBar} expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>}>
              <Typography className={classes.heading}>{intl.get('AGREEMENTS')}</Typography>
              <Typography className={classes.secondaryHeading}></Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>

                <div style={{marginLeft:20, marginTop:50, marginBottom:50}} >
                    - <a href='https://nsdsoft.com/kullanici-sozlesmesi/' target="_blank" > KULLANICI SÖZLEŞMESİ</a>
                  </div>
                <div style={{marginLeft:20, marginBottom:50}} >
                  - <a href='https://nsdsoft.com/gizlilik-politikasi/' target="_blank" > GİZLİLİK ve KİŞİSEL VERİLERİN KORUNMASI POLİTİKASI</a> 
                </div>

              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel> */}

          {/* <div id="paymentHiddenDiv" style={{ display: this.state.isPaymentHiddenDivVisible ? "block":"none" , border:'1px solid gray'}}></div> */}
          


        <NSnackBar open={this.state.isSnackOpen}  variant={this.state.snackVariant} 
                  message={this.state.snackMessage}  onClose={this.onSnackClosed}>  </NSnackBar>

        <ConfirmDialog
              dialogOpen={this.state.confirmDeleteDialogOpen}
              onClose={this.toggleConfirmDialog}
              dialogTitle={intl.get('DELETE_ACCOUNT')} 
              dialogText={intl.get('CONFIRM_DELETE_ACCOUNT')}
              onCancel={this.toggleConfirmDialog}
              cancelLabel={intl.get('CANCEL')}
              onConfirm={this.onDeleteCompany} 
              confirmLabel={intl.get('DELETE_ACCOUNT')}
        ></ConfirmDialog>

        <MessageBox
          alertDialogOpen={this.state.alertDialogOpen}
          onClose={this.handleDialogClose} 
          alertTitle={this.state.alertTitle} 
          alertText={this.state.alertText} >
        </MessageBox>

      </div>
    );
  }
}

AccountPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(AccountPage));
