import {Component} from 'react';
import sha256 from 'sha256';
import jwt_decode from 'jwt-decode';
import intl from 'react-intl-universal';
import crypt from '../helpers/crypt';
import DBManager from './DBManager';
  

class RestManager extends Component
{
    static instance = null;
    loginTrial = 0;
    registerTrial = 0;

    static TOKEN_STATUS = {
        EXPIRED: 'Token Expired',
        // NEEDS_UPDATE: 'Token needs update',
        NO_TOKEN: 'Token does not exists',
        OK: 'Token Ok',
    }
    
    state = {
    }
    constructor(props) {
        super(props);
        // this.baseUrl  = "http://192.168.1.20:8080/praxisRestApi/";
//        this.baseUrl  = "http://localhost:8080/praxisRestApi/";
        this.baseUrl  = "https://api.praxis-24.com/praxisRestApi/";
        this.webApiUrl  = this.baseUrl+"webapi/";
        this.selectedProfessional = null;
        this.currentUser = null;
    }

    static getInstance() 
    {
        if (this.instance == null) {
            this.instance = new RestManager();
            // this.instance.testBackupServer();
        }
        return this.instance;
    }

    setSelectedProfessional(professional){
        this.selectedProfessional = professional;
    }

    // checkToken()
    // {
    //     let resp = this.getToken();
    //     if ( resp.status == RestManager.TOKEN_STATUS.EXPIRED || resp.status == RestManager.TOKEN_STATUS.NO_TOKEN){
    //         window.location.reload();
    //     }
    // }
  
    login(username, password, callback)
    {
        const encryptedPass = sha256(password);        
        hashedCredentials = new Buffer.from(username+':'+encryptedPass).toString('base64');
        this.loginTrial = 0 ;
        this.loginWithHash(hashedCredentials, callback);
    }

    loginWithHash(hashedCredentials, callback)
    {
        this.loginTrial = this.loginTrial+1 ;
        dbinstance = this;
        const auth = 'Basic '+hashedCredentials;
        fetch(this.webApiUrl+'user/login', {
            method: 'POST',
            headers: {
                Authorization: auth,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            console.log("login response: ", response)
            if ( response.status == 200) {
                response.text().then((token)=>{
                    localStorage.setItem('token', token);
                    localStorage.setItem('hashedCredentials', hashedCredentials);
                    localStorage.setItem('tokenCreatedAtSec', new Date().getTime()/1000);
                    if(typeof callback==="function") {
                        callback({success:true, token:token});
                    }
                    });
            } else {
                callback({success:false, status:response.status});
            }
        })
        .catch((error) => {
            if ( this.loginTrial < 10 ) {
                console.log("Could not connect to server. Retrying to reconnect. Trial " + this.loginTrial, error);
                setTimeout(function (){
                    dbinstance.loginWithHash(hashedCredentials, callback);
                }, 1000); 
            } else {
                callback({success:false , fetchError:true});
            }
        });

    }


    register(user, callback) 
    {
        this.getUserInfo((userInfo) => 
        {
            this.registerTrial = this.registerTrial + 1 ;
            dbinstance = this;
    
            console.log("IP = " + userInfo.ip);
            user.ipAddress = userInfo.ip;

            // encrypt password
            const encryptedPass = sha256(user.password);        
            user.password = encryptedPass;        

            fetch(this.webApiUrl+'user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': intl.getInitOptions().currentLocale
                },
                body: JSON.stringify(
                    user
                ),
            })
            .then((response) => {
                console.log("register response: ", response)
                callback(response);
            })
            .catch((error) => {
                if ( this.registerTrial < 10 ) {
                    console.log("Could not connect to server. Retrying to reconnect. Trial " + this.registerTrial, error);
                    setTimeout(function (){
                        dbinstance.register(user, callback);
                    }, 1000); 
                } else {
                    callback({success:false , fetchError:true});
                }
            });
        });
    }

    sendPasswordResetMail(email, callback) 
    {
        fetch(this.webApiUrl+'user/forgotPassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain',
                'Accept-Language': intl.getInitOptions().currentLocale
            },
            body: 
                email
            ,
        })
        .then((result) => {
            console.log("sendPasswordResetMail response: ", result)
            callback(result);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    resetPassword(email, code, password, callback)
    {
        const encryptedPass = sha256(password);        

        fetch(this.webApiUrl+'user/resetPassword?email='+email+'&code='+code+'&pass='+encryptedPass, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((result) => {
            console.log("resetPassword response: ", result)
            callback(result);
        })
        .catch((error) => {
            console.error(error);
            callback(error);
        });
    }

    activate(code, viaSms = false, callback) 
    {
        console.log("RESTMANAGER - activate")
        var url = this.webApiUrl+'user/activate';
        if ( viaSms ==  true) url += "?type=sms" 
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain',
            },
            body: 
                code
            ,
        })
        .then((response) => {
            console.log("activate response: ", response)
            callback(response);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    activateViaPass(code, password, callback) 
    {
        let encryptedPass = sha256(password);        

        console.log("RESTMANAGER - activate")
        fetch(this.webApiUrl+'user/activateViaPass?pass='+encryptedPass, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain',
            },
            body: 
                code
            ,
        })
        .then((response) => {
            console.log("activateViaPass response: ", response)
            callback(response);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    resendActivation(email, callback) 
    {
        console.log("RESTMANAGER - resendActivation")
        fetch(this.webApiUrl+'user/activate/resend', {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain',
                'Accept-Language': intl.getInitOptions().currentLocale
            },
            body: 
                email
            ,
        })
        .then((response) => {
            console.log("resend email response: ", response)
            callback(response);
        })
        .catch((error) => {
            console.error(error);
        });
        
    }

    getUserInfo(callback)
    {
        console.log("RESTMANAGER - getUserInfo")
        var userInfo = {};
        fetch('https://www.cloudflare.com/cdn-cgi/trace')
        .then(function(response) {
            return response.text();
        }).then(function(data) {
            var arrData = data.split("\n");
            console.log(arrData);
            for ( var i = 0; i < arrData.length; i++ ) {
                var arrLine = arrData[i].split("=");
                userInfo[arrLine[0]] = arrLine[1];
            }
            console.log("ip = " + userInfo.ip);
            callback(userInfo);
            // console.log(data); // this will be a string
        })
        .catch((error) => {
            callback("");
        });
    }

    // getCommonRestCall (url, callback) 
    // {
    //     console.log("RESTMANAGER - getCommonRestCall")
    //     var resp = this.getToken();
    //     // if ( resp.success == false ) callback(resp);
    //     if ( resp.status == RestManager.TOKEN_STATUS.NEEDS_UPDATE ) {
    //         this.updateToken( (result) => {
    //             let auth  = 'Bearer '+result.token;
    //             this.getCommonRestCall2(url, callback, auth);
    //         });
    //     } 
    //     else if ( resp.status == RestManager.TOKEN_STATUS.EXPIRED ) {
    //         callback(resp);
    //     } else if ( resp.status == RestManager.TOKEN_STATUS.OK ) {
    //         let auth  = 'Bearer '+resp.token;
    //         this.getCommonRestCall2(url, callback, auth);
    //     }
    // }

    getCommonRestCall (url, callback) 
    {
        console.log("RESTMANAGER - getCommonRestCall")
        var resp = this.getToken();
        if ( resp.success == false ) callback(resp);
        let auth  = 'Bearer '+resp.token;

        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: auth,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            if ( response.ok == false ) {
                if ( response.status == '401' ) {
                    callback({success:false, status:RestManager.TOKEN_STATUS.EXPIRED});
                } else {
                    callback({success:false, status:response.status});
                }
            } else {
                return response.json();
            }
        })
        .then((responseJson) => {
            if ( typeof responseJson !== 'undefined'  ) {
                console.log("getCommonRestCall resp: ", responseJson)
                if(typeof callback==="function") {
                    callback({success:true, response:responseJson});
                }
            }
        })
        .catch((error) => {
            console.error(error);
            callback({success:false, response:error});
        });

    }

    addCommonRestCall (url, object, callback) 
    {
        console.log("RESTMANAGER - addCommonRestCall")
        var resp = this.getToken();
        if ( resp.success == false ) callback(resp);
        let auth  = 'Bearer '+resp.token;
        var cryptKeyCheck = crypt.getCryptKeyCheck();

        fetch(url, {
        method: 'POST',
        headers: {
            Authorization: auth,
            'Content-Type': 'application/json',
            'Accept-Language': intl.getInitOptions().currentLocale,
            'Crypt-Key-Check': cryptKeyCheck,
        },
        body: JSON.stringify(
            object
        ),
        })
        .then((response) => {
            if ( response.ok == false ) {
                if ( response.status == '401' ) {
                    callback({success:false, status:RestManager.TOKEN_STATUS.EXPIRED});
                } else {
                    callback({success:false, status:response.status});
                }
            } else {
                var contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    callback({success:true}); 
                }
            }
        })
        .then((responseJson) => {
            if(typeof callback==="function" && responseJson ) {
                callback({success:true, response:responseJson});
            }
        })
        .catch((error) => {
            console.error(error);
            callback({success:false, response:error});
        });
    }

    updateCommonRestCall(url, object, callback) 
    {
        console.log("RESTMANAGER - updateCommonRestCall")
        var resp = this.getToken();
        if ( resp.success == false ) callback(resp);
        let auth  = 'Bearer '+resp.token;
        var cryptKeyCheck = crypt.getCryptKeyCheck();

        fetch(url, {
        method: 'PUT',
        headers: {
            Authorization: auth,
            'Content-Type': 'application/json',
            'Crypt-Key-Check': cryptKeyCheck,
        },
        body: JSON.stringify(
            object
        ),
        })
        .then((response) => {
            if ( response.ok == false ) {
                if ( response.status == '401' ) {
                    callback({success:false, status:RestManager.TOKEN_STATUS.EXPIRED});
                } else {
                    callback({success:false, status:response.status});
                }
            } else {
                return response.json();
            }
        })
        .then((responseJson) => {
            if(typeof callback==="function" && responseJson) {
                callback({success:true, response:responseJson});
            }
        })
        .catch((error) => {
            console.error(error);
            callback({success:false, response:error});
        });
    }

    deleteCommonRestCall(url, callback) 
    {
        console.log("RESTMANAGER - deleteCommonRestCall")
        var resp = this.getToken();
        if ( resp.success == false ) callback(resp);
        let auth  = 'Bearer '+resp.token;
        var cryptKeyCheck = crypt.getCryptKeyCheck();

        fetch(url, {
        method: 'DELETE',
        headers: {
            Authorization: auth,
            'Content-Type': 'application/json',
            'Crypt-Key-Check': cryptKeyCheck,
        }
        })
        .then((response) => {
            if(typeof callback==="function") {
                callback({success:true});
            }
        })
        .catch((error) => {
            console.error(error);
            callback({success:false, response:error});
        });
    }

    
    uploadFile (filename, file, customerId, examId, callback) 
    {
        crypt.encryptFile(customerId, file, (encFile) => 
        {
            const formData  = new FormData();
            formData.append('file', encFile);
            formData.append('filename', filename);
            formData.append('examId', examId);

            url = this.webApiUrl+'customer/'+customerId+'/document/upload';
            console.log("RESTMANAGER - uploadFile")
            var resp = this.getToken();
            if ( resp.success == false ) callback(resp);
            let auth  = 'Bearer '+resp.token;
            var cryptKeyCheck = crypt.getCryptKeyCheck();

            fetch(url, {
            method: 'POST',
            headers: {
                Authorization: auth,
                'Crypt-Key-Check': cryptKeyCheck,
            },
            body: 
                formData
            })
            .then((response) => {
                console.log("UploadFile response = ", response);
                callback({success:true, response:response});
            })
            .catch((error) => {
                console.error(error);
                callback({success:false, response:error});
            });

        });
    }

    downloadFile (customerId, documentId, callback) 
    {
        url = this.webApiUrl+'document/download?documentId='+documentId;
        console.log("RESTMANAGER - downloadFile")
        var resp = this.getToken();
        if ( resp.success == false ) callback(resp);
        let auth  = 'Bearer '+resp.token;

        fetch(url, {
        method: 'GET',
        headers: {
            Authorization: auth,
            // 'Content-Type': 'multipart/form-data',
        }})
        .then((response) => {
            response.arrayBuffer()
            .then(function(buffer) {
                // console.log("Download File resopnse ", buffer);
                var uint8array = new Uint8Array(buffer);
                var string = new TextDecoder("utf-8").decode(uint8array);
                crypt.decryptFile(customerId, string, callback);
            });
        })
        .catch((error) => {
            console.error(error);
            callback({success:false, response:error});
        });
    }

    getDocuments(customerId,callback) 
    {
        // let url = this.webApiUrl+'document?customerId='+customerId;
        let url = this.webApiUrl+'customer/'+customerId+'/document'
        this.getCommonRestCall(url, callback);
    }

    addNoteDocument(customerId, document, callback) 
    {
        document = crypt.encryptDocument(document, customerId);
        let url = this.webApiUrl+'customer/'+customerId+'/document/addNote'
        this.addCommonRestCall(url, document, callback)
    }

    updateNoteDocument(customerId, document, callback) 
    {
        document = crypt.encryptDocument(document, customerId);
        let url = this.webApiUrl+'customer/'+customerId+'/document'
        this.updateCommonRestCall(url, document, callback)
    }

    deleteDocument(id, callback) 
    {
        let url = this.webApiUrl+'document/'+id;
        this.deleteCommonRestCall(url , callback);
    }

    getMedicalSpecialties(callback)
    {
        let url = this.webApiUrl+'medicalspeciality';
        this.getCommonRestCall(url, callback);
    }

    getCommonForms(medicalSpeciality, callback) 
    {
        let url = this.webApiUrl+'form/common/'+medicalSpeciality;
        this.getCommonRestCall(url, callback);
    }

    getMyForms(callback)
    {
        let url = this.webApiUrl+'form';
        this.getCommonRestCall(url, callback);
    }

    getForm(id, callback) 
    {
        let url = this.webApiUrl+'form/'+id;
        this.getCommonRestCall(url , callback);
    }

    addForm(form, callback) 
    {
        let url = this.webApiUrl+'form';
        this.addCommonRestCall(url, form, callback)
    }

    updateForm(form, callback) 
    {
        let url = this.webApiUrl+'form';
        this.updateCommonRestCall(url, form, callback)
    }

    deleteForm(id, callback) 
    {
        let url = this.webApiUrl+'form/'+id;
        this.deleteCommonRestCall(url , callback);
    }
    isFormUsed(formId,callback) 
    {
        let url = this.webApiUrl+'form/isUsed/'+formId
        this.getCommonRestCall(url, callback);
    }
// GDPR //
    saveOrUpdateGDPRData(gdprData, callback) {
        let url = this.webApiUrl+'gdprdata';
        this.addCommonRestCall(url, gdprData, callback)
    }
    getGDPRData(callback) 
    {
        let url = this.webApiUrl+'gdprdata';
        this.getCommonRestCall(url , callback);
    }
    getConsentText (id, callback) 
    { // get consent text without authentication
        fetch(this.webApiUrl+'gdprdata/consentText/'+id, {
            method: 'GET',
        })
        .then((response) => {
            return response.text();
        })
        .then((responseText) => {
            if ( typeof responseText !== 'undefined'  ) {
                if(typeof callback==="function") {
                    callback({success:true, response:responseText});
                }
            }
        })
        .catch((error) => {
            callback({success:false, response:error});
        });

    }
    sendConsentSMS(customerId, callback)
    {
        let url = this.webApiUrl+'consent/sms/'+customerId;
        this.addCommonRestCall(url, null, callback)
    }
    resendConsentSMS(customerId, callback)
    {
        let url = this.webApiUrl+'consent/smsresend/'+customerId;
        this.addCommonRestCall(url, null, callback)
    }
    saveConsentCode(customerId, code, callback)
    {
        let url = this.webApiUrl+'consent/savecode/'+customerId+"/"+code;
        this.addCommonRestCall(url, null, callback)
    }
 
// Form Data //
    getAllFormDatas(customerId,callback) 
    {
        // DBManager.getInstance().getAllFormDatas(customerId, callback);
        let url = this.webApiUrl+'customer/'+customerId+'/formdata'
        this.getCommonRestCall(url, (result)=>
        {
            result.response = crypt.decryptEntities('formdata', result.response, customerId)
            callback(result);
        });

    }

    getAllFormDatasExamIsSet(customerId,callback) 
    {
        // DBManager.getInstance().getAllFormDatas(customerId, callback);
        let url = this.webApiUrl+'customer/'+customerId+'/formdata/examIsSet'
        this.getCommonRestCall(url, (result)=>
        {
            result.response = crypt.decryptEntities('formdata', result.response, customerId)
            callback(result);
        });
    }

    getFormData(customerId, formDataId, callback) 
    {
        let url = this.webApiUrl+'customer/'+customerId+'/formdata/'+formDataId;
        this.getCommonRestCall(url, callback);
        this.getCommonRestCall(url, (result)=>
        {
            result.response = crypt.decryptEntity('formdata', result.response, customerId)
            callback(result);
        });
    }

    addFormData(customerId, formdata, formId, examId, callback) 
    {
        // document = crypt.encryptDocument(document, customerId);
        formdata = crypt.encryptFormdata(formdata, customerId);

        let url = this.webApiUrl+'customer/'+customerId+'/formdata/'+formId+"/"+examId;
        this.addCommonRestCall(url, formdata, callback);
    }

    updateFormData(customerId, formdata, examId, callback) 
    {
        // document = crypt.encryptDocument(document, customerId);
        formdata = crypt.encryptFormdata(formdata, customerId);
        let url = this.webApiUrl+'customer/'+customerId+'/formdata/'+examId;
        this.updateCommonRestCall(url, formdata, callback);
    }
    deleteFormData(customerId, id, callback) 
    {
        let url = this.webApiUrl+'customer/'+customerId+'/formdata/'+id;
        this.deleteCommonRestCall(url , callback);
    }
// Customer //
    getAllCustomers(callback) 
    {         console.log("RESTMANAGER - getAllCustomers")
        DBManager.getInstance().getCustomers(callback);
        // let url = this.webApiUrl+'customer';
        // this.getCommonRestCall(url, callback);
    }

    getCustomer(id, callback) 
    {         console.log("RESTMANAGER - getCustomer")
        DBManager.getInstance().getCustomer(id, callback);
        // let url = this.webApiUrl+'customer/'+id;
        // this.getCommonRestCall(url, callback);
        // console.log("Selectted Professional asdas= " , this.selectedProfessional);
    }

    addCustomer(customer, callback) 
    {
        customer = crypt.encryptCustomer(customer);
        let url = this.webApiUrl+'customer';
        if ( this.selectedProfessional ) {
            url = this.webApiUrl+'customer?professionalId='+this.selectedProfessional.id;
        }
        this.addCommonRestCall(url, customer, callback)
    }

    updateCustomer(customer, callback) 
    {
        customer = crypt.encryptCustomer(customer);
        let url = this.webApiUrl+'customer';
        this.updateCommonRestCall(url, customer, callback)
    }

    deleteCustomer(id, callback) 
    {
        let url = this.webApiUrl+'customer/'+id;
        this.deleteCommonRestCall(url , callback);
    }

    getCustomerSum(type, callback) 
    {
        console.log("RESTMANAGER - getCustomerSum")
        if ( type == 'gender' ) {
            DBManager.getInstance().getGenderSum(callback);
        } else if ( type == 'source' ){
            DBManager.getInstance().getSourceSum(callback);
        }
        // let url = this.webApiUrl+'customer/gendersum';
        // if ( type == 'source' ) {
        //     url = this.webApiUrl+'customer/sourcesum';
        // }
        // this.getCommonRestCall(url, callback);
    }
    
    getAllExaminations(startDate, endDate, callback) 
    {
        return this.getExaminations(null, callback, startDate, endDate);
    }

    getExaminations(customerId, callback, startDate, endDate) 
    {
        console.log("RESTMANAGER - getExaminations")
        if ( customerId == null ) {
            DBManager.getInstance().getExaminationsBetweenDates(startDate, endDate, callback);
        } else {
            DBManager.getInstance().getExaminations(customerId, callback);
        }
        // let url = this.webApiUrl+'customer/'+customerId+'/examination';
        // if ( customerId == null ) {
        //     url = this.webApiUrl+'examination?startDate='+startDate+'&endDate='+endDate;
        // }
        // this.getCommonRestCall(url, (result) => {
        //     console.log(" in getExaminations : ", result)
        //     // result.response = crypt.decryptExamPayments(result.response);
        //     callback(result);
        // });
    }

    addExamination(customerId, examination, callback) 
    {
        examination.payment = crypt.encryptPayment(examination.payment, customerId);
        let url = this.webApiUrl+'customer/'+customerId+'/examination';
        this.addCommonRestCall(url, examination, callback)
    }

    updateExamination(customerId, examination, callback) 
    {
        examination.payment = crypt.encryptPayment(examination.payment, customerId);
        let url = this.webApiUrl+'customer/'+customerId+'/examination';
        this.updateCommonRestCall(url, examination, callback)
    }

    deleteExamination(customerId, id, addToPackage, callback) 
    {
        let url = this.webApiUrl+'customer/'+customerId+'/examination/'+id;
        if ( addToPackage ) {
            url += '?addToPackage=true';
        }
        this.deleteCommonRestCall(url , callback);
    }

    getPayments(iStartDate, iEndDate, callback) 
    {
        DBManager.getInstance().getPayments(iStartDate, iEndDate, callback);
        // let url = this.webApiUrl+'payment?startDate='+iStartDate+'&endDate='+iEndDate;
        // this.getCommonRestCall(url, callback);
    }

    getPriceSum(month, year, callback) 
    {
        DBManager.getInstance().getPriceSum(month, year, callback);
        // let url = this.webApiUrl+'payment/pricesum?month='+month+'&year='+year;
        // this.getCommonRestCall(url, callback);
    }

    getPackages(customerId, callback) 
    {
        DBManager.getInstance().getPackages(customerId, callback);
        // let url = this.webApiUrl+'customer/'+customerId+'/package';
        // this.getCommonRestCall(url, callback);
    }

    addPackage(customerId, ppackage, callback) 
    {
        ppackage.payment = crypt.encryptPayment(ppackage.payment, customerId);
        let url = this.webApiUrl+'customer/'+customerId+'/package';
        this.addCommonRestCall(url, ppackage, callback)
    }

    updatePackage(customerId, ppackage, callback) 
    {
        ppackage.payment = crypt.encryptPayment(ppackage.payment, customerId);
        let url = this.webApiUrl+'customer/'+customerId+'/package';
        this.updateCommonRestCall(url, ppackage, callback)
    }

    activatePackage(customerId, packageId, isActive, callback) 
    {
        let url = this.webApiUrl+'customer/'+customerId+'/package/'+packageId+'/'+isActive;
        this.updateCommonRestCall(url, '', callback)
    }

    deletePackage(customerId, id, callback) 
    {
        let url = this.webApiUrl+'customer/'+customerId+'/package/'+id;
        this.deleteCommonRestCall(url , callback);
    }

    getProfessionals(callback)
    {
        let url = this.webApiUrl+'professional';
        this.getCommonRestCall(url, callback);
    }

    addProfessional(professional, user, callback) 
    {
        let url = this.webApiUrl+'professional';
        if ( user ) {
            url += '?userId='+user.id;
        }        
        this.addCommonRestCall(url, professional, callback)
    }

    updateProfessional(professional, callback) 
    {
        let url = this.webApiUrl+'professional';
        this.updateCommonRestCall(url, professional, callback)
    }

    deleteProfessional(id, callback) 
    {
        let url = this.webApiUrl+'professional/'+id;
        this.deleteCommonRestCall(url , callback);
    }

    getAssistants(callback)
    {
        let url = this.webApiUrl+'assistant';
        this.getCommonRestCall(url, callback);
    }

    addAssistant(assistant, user, callback) 
    {
        let url = this.webApiUrl+'assistant';
        if ( user ) {
            url += '?userId='+user.id;
        }        
        this.addCommonRestCall(url, assistant, callback)
    }

    updateAssistant(assistant, callback) 
    {
        let url = this.webApiUrl+'assistant';
        this.updateCommonRestCall(url, assistant, callback)
    }

    deleteAssistant(id, callback) 
    {
        let url = this.webApiUrl+'assistant/'+id;
        this.deleteCommonRestCall(url , callback);
    }

    getUsers(callback)
    {
        let url = this.webApiUrl+'user';
        this.getCommonRestCall(url, callback);
    }

    addUserToCompany(user, callback) {
        let url = this.webApiUrl+'user/addToCompany';
        if ( user.cryptKeyCheck ) {
            url += '?cryptKeyCheck='+user.cryptKeyCheck;
        }
        this.addCommonRestCall(url, user, callback)
    }

    getCurrentUser(callback) 
    {
        console.log(" this.currentUser = " , this.currentUser);
        if ( this.currentUser ==  null ) {
            let url = this.webApiUrl+'user/currentUser';
            this.getCommonRestCall(url, 
                (result) =>
                {
                    if ( result && result.response ) {
                        this.currentUser = result.response;
                        callback(this.currentUser);
                    }
                }
            );    
        } else {
            callback(this.currentUser);
        }
    }

    updateUser(user, callback) 
    {
        let url = this.webApiUrl+'user';
        if ( user.cryptKeyCheck ) {
            url += '?cryptKeyCheck='+user.cryptKeyCheck;
        }
        this.updateCommonRestCall(url, user, callback)
    }

    getCompany(callback) 
    {
        let url = this.webApiUrl+'company/currentCompany';
        this.getCommonRestCall(url, callback);
    }

    updateCompany(company, callback) 
    {
        let url = this.webApiUrl+'company';
        this.updateCommonRestCall(url, company, callback)
    }

    setCompanyCryptKey(cryptKey, callback)
    {
        let mainCryptKey = crypt.generateMainCryptKey(cryptKey);
        DBManager.getInstance().setCryptKey(mainCryptKey, ()=>{
            var cryptKeyCheck = crypt.getCryptKeyCheck(mainCryptKey);
            let url = this.webApiUrl+'company/initCrypt/'+cryptKeyCheck;
            this.updateCommonRestCall(url, {}, ()=>{
                url = this.webApiUrl+'user/initCrypt/'+cryptKeyCheck;
                this.updateCommonRestCall(url, {}, callback)    
            });    
        })
    }

    setUserCryptKey(cryptKey, callback)
    {
        DBManager.getInstance().setCryptKey(cryptKey, ()=>{
            var cryptKeyCheck = crypt.getCryptKeyCheck(cryptKey);
            url = this.webApiUrl+'user/initCrypt/'+cryptKeyCheck;
            this.updateCommonRestCall(url, {}, callback)    
        })
    }

    checkCryptedKey(cryptedKey, callback)
    {
        var cryptKeyCheck = crypt.getCryptKeyCheck(cryptedKey);
        console.log("cryptKeyCheck = " + cryptKeyCheck);
        let url = this.webApiUrl+'user/cryptKeyCheck/'+cryptKeyCheck;
        this.getCommonRestCall(url, callback)
    }

    changeUserPass(userid, currentPass, newPass, callback) 
    {
        let encryptedCurPass = sha256(currentPass);        
        let encryptedNewPass = sha256(newPass);        

        let url = this.webApiUrl+'user/'+userid+'?currentPass='+encryptedCurPass+'&newPass='+encryptedNewPass;
        this.updateCommonRestCall(url, '' , callback)
    }

    deleteCompany(companyId, currentPass, callback) 
    {
        let encryptedCurPass = sha256(currentPass);        

        let url = this.webApiUrl+'company/'+companyId+'?currentPass='+encryptedCurPass;
        this.deleteCommonRestCall(url , callback);
    }

    getLicence(callback) 
    {
        let url = this.webApiUrl+'licence';
        this.getCommonRestCall(url, callback);
    }

    getSMSInfo(callback) 
    {
        let url = this.webApiUrl+'smsInfo';
        this.getCommonRestCall(url, callback);
    }

    getPaymentPopup(packageType, itemCount, callback) 
    {
        this.getUserInfo((userInfo) => 
        {
            let url = this.webApiUrl+'payment/formContent?packageType='+packageType+'&&itemCount='+itemCount;
            if ( userInfo.ip ) {
                url = this.webApiUrl+'payment/formContent?packageType='+packageType+'&&itemCount='+itemCount+"&&ip="+userInfo.ip;
            }
            this.getCommonRestCall(url, callback);
        });

    }

    setIyziToken(iyziToken, itemCount, callback)
    {
        let url = this.webApiUrl+'payment/init?iyziToken='+iyziToken+'&&itemCount='+itemCount;
        this.addCommonRestCall(url, null, callback)
    }

    getPaymentStatus(callback) 
    {
        this.getUserInfo((userInfo) => 
        {
            let url = this.webApiUrl+'payment/status';
            if ( userInfo.ip ) {
                url = this.webApiUrl+'payment/status?ip='+userInfo.ip;
            }
            this.getCommonRestCall(url, callback);
        });
    }

    getEntitiesForSync(entity, startDate, size, callback) 
    {
        console.log("RESTMANAGER - getEntitiesForSync")
        let url = this.webApiUrl+entity+'/sync?startDate='+startDate+'&size='+size;
        this.getCommonRestCall(url, (result) => {
            console.log(" in getEntitiesForSync : ", result)
            // result.response = crypt.decryptExamPayments(result.response);
            callback(result);
        });
    }

    getToken()
    {   
        var token = localStorage.getItem('token');

        if ( token ) {
            let parsed = this.parseJwt(token);
            let totalLifeSec = parsed.exp - parsed.iat;
            var tokenCreatedAtSec = localStorage.getItem('tokenCreatedAtSec');
            let currentSec = new Date().getTime() / 1000;
            let tokenUsedSec = currentSec - tokenCreatedAtSec;
            console.log(" tokenUsedSec = " + tokenUsedSec );
            console.log("licenceRest = " + parsed.licenceRest);
            var licenceRest = parsed.licenceRest;
            if ( tokenUsedSec > totalLifeSec ) {
                localStorage.removeItem('token');
                localStorage.removeItem('hashedCredentials');
                localStorage.removeItem('tokenCreatedAtSec');
                return {success:false, status:RestManager.TOKEN_STATUS.EXPIRED};
            }
            else if ( tokenUsedSec > totalLifeSec - 600 ) {
                // return {success:false, status:RestManager.TOKEN_STATUS.NEEDS_UPDATE};
                this.updateToken( ()=>{} );
            } 
            return {success:true, status:RestManager.TOKEN_STATUS.OK, token:token, licenceRest:licenceRest};
        }

        return {success:false, status:RestManager.TOKEN_STATUS.NO_TOKEN};
    }

    updateToken(callback)
    {
        console.log('updating tokem...');
        var hashedCredentials = localStorage.getItem('hashedCredentials');

        if ( hashedCredentials ) {
            this.loginTrial = 0 ;
            this.loginWithHash(hashedCredentials, 
                (response) => {
                    console.log('token generated');
                    if ( callback ) {
                        callback(response);
                    }
                });
        }

    }

    parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        var parsed = JSON.parse(jsonPayload);
        // var expDate = this.toDateTime(parsed.exp);
        return parsed;
    };

};


export default RestManager;