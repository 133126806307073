import React, {Component} from 'react';
import intl from 'react-intl-universal';

class Footer extends Component 
{
  render() 
  {
    return (
        <div style={{textAlign:"center"}}>

            <div className="row" style={{borderBottom:'1px solid lightgray', marginTop:50}}></div>
            <div style={{textAlign:"center",marginTop:50, color:'gray', marginBottom:50}}>
              {intl.get('COPYRIGHT', {year: new Date().getFullYear()} ) } 
            </div>

        </div>
      );
  }

}


export default Footer;
