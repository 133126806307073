import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import RestManager from '../persist/RestManager';
import { Button, FormControl, FormHelperText, Input, InputLabel, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ConfirmDialog from '../components/Dialogs/ConfirmDialog';
import intl from 'react-intl-universal';

const styles = {
  textField: {
    width:'300px',
    margin:10
  },
  button:{
    margin:15
  }
};


@connect(({ app }) => ({
  paymentToken: app.paymentToken,
}))


class CryptKeyPage extends Component 
{
    constructor (props) {
        super(props);
    }

    state = {
      cryptKey:"",
      confirmCryptKey:"",
      dialogOpen:false,
      alertTitle:intl.get("CONFIRM_YOUR_CRYPT_KEYWORD"),
      alertText:intl.get("DO_NOT_FORGET_TO_NOTE_DOWN"),
      submitButtonClicked:false,
      keyLengthWarning:false,
  };
  
    handleChange = name => event => {
      this.setState({ [name]: event.target.value });
    };
    
    toggleConfirmDialog = () => {
      this.setState({ submitButtonClicked: true });
      if ( this.state.cryptKey != "" && this.state.cryptKey == this.state.confirmCryptKey && this.state.cryptKey.length > 7 ) {
        this.setState({ dialogOpen: ! this.state.dialogOpen });
      } else {
        this.setState({keyLengthWarning: true});
      }
    }
  
    setCryptKey = () => 
    {
      if ( this.state.cryptKey.length < 8 ){
        return;
      }
      RestManager.getInstance().setCompanyCryptKey(this.state.cryptKey, ()=>{
        window.location.reload();
      });
    }
  
  
    render() 
    {
        return (
          <div>

            <div style={{ padding:'40 20 0 50', color:'royalblue'}}>
                <h1>{intl.get("KEYWORD_FOR_ENCRYPTION_TITLE")} </h1>
            </div>
            <div style={{ padding:'0 20 0 50', color:'gray'}}>
                <h2>{intl.get("PLEASE_READ_CAREFULLY")} </h2>
            </div>

            <div style={{fontSize:'1.4em', padding:'0 20 20 50', color:'gray', lineHeight: '2em'}}>
              {intl.getHTML('CRYPT_KEY_EXPLANATION')}
              
              {/* - Verilerinizin gizliliğini önemsiyoruz.
              <br></br>- Hassas verileriniz sizin belirleyeceğiniz bir anahtar kelime ile şifrelendikten sonra veritabanlarımıza kaydediliyor.
              <br></br>- Verilerinizi kırılması pratikte mümkün olmayan AES-256 bit kriptolama yönetimi ile şifreliyoruz. 
              <br></br>- Praxis-24 yazılım geliştiricileri, veritabanı uzmanları, şirket çalışanları ve yöneticileri, sunucularımızın bulunduğu veri merkezlerinin çalışanları dahil hiç kimse şifrelenmiş verilerinize ulaşamaz.
              <br></br>- Verilerinize ulaşmanın tek yolu anahtar kelimeyi bilmektir.
              <br></br>- Bu yüzden biraz sonra belirleyeceğiniz anahtar kelimenizi kesinlikle unutmamanız gerekiyor. 
              <br></br>- Yeni bir bilgisayarda oturum açtığınızda veya mevcut kullandığınız aygıtın tarayıcısının cache geçmişini temizlediğinizde bu anahtar kelimeyi tekrar girmeniz gerekecektir.
              <br></br>- Anahtar kelimenizi sonradan değiştiremezsiniz.
              <br></br>- Kullanmakta olduğunuz aygıtlarda istediğiniz zaman anahtar kelimenizi görüntüleyebilirsiniz.
              <br></br>- Lütfen şimdi anahtar kelimenizi belirleyiniz ve bu kelimeyi muhakkak bir yere not ediniz.  */}
            </div>


            <div style={{textAlign:"center",fontSize:24, marginTop:30, color:'gray', marginBottom:50}}>
                {intl.get("PLEASE_ENTER_CRYPT_KEYWORD")}<br></br>

                <FormControl style={styles.textField} >
                  <InputLabel required htmlFor="cryptKey">{intl.get("CRYPT_KEYWORD")}</InputLabel>
                  <Input
                      onChange = {this.handleChange('cryptKey')}
                      // style={styles.textField} 
                      error={ (this.state.cryptKey == "" || this.state.cryptKey != this.state.confirmCryptKey) && this.state.submitButtonClicked }
                  />
                  <FormHelperText error={(this.state.cryptKey == "" || this.state.cryptKey != this.state.confirmCryptKey) && this.state.submitButtonClicked } >
                    { (this.state.cryptKey == "" || this.state.cryptKey != this.state.confirmCryptKey) && this.state.submitButtonClicked ? intl.get("CRYPT_KEYWORDS_DO_NOT_MATCH"):'' }
                  </FormHelperText>
                </FormControl>

                <br/>

                <FormControl style={styles.textField} >
                  <InputLabel required htmlFor="confirmCryptKey">{intl.get("CRYPT_KEYWORD_AGAIN")}</InputLabel>
                  <Input
                      onChange = {this.handleChange('confirmCryptKey')}
                      // style={styles.textField} 
                      error={ (this.state.confirmCryptKey == "" || this.state.cryptKey != this.state.confirmCryptKey) && this.state.submitButtonClicked }
                  />
                  <FormHelperText error={(this.state.confirmCryptKey == "" || this.state.cryptKey != this.state.confirmCryptKey) && this.state.submitButtonClicked } >
                    { (this.state.confirmCryptKey == "" || this.state.cryptKey != this.state.confirmCryptKey) && this.state.submitButtonClicked ? intl.get("CRYPT_KEYWORDS_DO_NOT_MATCH"):''  }
                  </FormHelperText>
                </FormControl>

                <div>{this.state.keyLengthWarning ? intl.get("CRYPT_KEYWORD_AT_LEAST_EIGHT_CHARS") : '' }</div>
                <div className="row" style={{margin:20, minHeight:300}} >
                    <Button onClick={this.toggleConfirmDialog} variant="contained"  color='primary' >{intl.get("CONTINUE")}</Button>
                </div>

              </div>


              <ConfirmDialog
                dialogOpen={this.state.dialogOpen}
                onClose={this.toggleConfirmDialog} 
                dialogTitle={this.state.alertTitle} 
                dialogText={this.state.alertText}
                onCancel={this.toggleConfirmDialog} 
                cancelLabel={intl.get('CANCEL')}
                onConfirm={this.setCryptKey} 
                confirmLabel={intl.get("I_CONFIRM_MY_CRYPT_KEYWORD")}
              ></ConfirmDialog>


          </div>

        )
    }
  }
  
export default withRouter(withStyles(styles)(CryptKeyPage));
