import React, {Component} from 'react';
import SearchField from 'react-search-field';
import { withStyles } from '@material-ui/core/styles';
import {Autocomplete, Button, MenuItem, Select } from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import intl from 'react-intl-universal';
import utils from '../../helpers/utils';
import male from '/images/male50.png';
import female from '/images/female50.png';
import crypt from '../../helpers/crypt';
import RestManager from '../../persist/RestManager';

const styles = () => ({
  // packageBox: {
  //   float: 'right',
  //   border: '1px solid #41b955',
  //   borderTop: '10px solid #41b955',
  //   marginRight: 10,
  //   padding: 3,
  //   minWidth: 40,
  //   fontSize: '0.9em',
  //   textAlign: 'center',
  //   backgroundColor:'white',
  //   color:'black',
  // },
  searchBox: {
    position: 'absolute',
    background: 'white',
    zIndex: 9,
    width: '500px',
    marginTop:'-10px'
  },
  listItem:{
    height: 26,
    backgroundColor: "#fbfbfb",
    paddingLeft: 8,
    paddingTop: 6,
    borderBottom: "1px solid lightgray",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#efefef",
    },
  }
});

class MedicalSpecialitiesList extends Component 
{
  state = {
    specialities:[],
    filteredSpecialities: [],
    selectedSpeciality: null,
    searchText:"",
    selectedIndex:-1,
  }

  constructor (props) {
    super(props);  
    this.reloadData();
  }    


  reloadData = () => 
  {
    RestManager.getInstance().getMedicalSpecialties(
      (result) => {
        this.setState({specialities:result.response});
        // this.state.specialities = result.response;
        console.log("specialities = ", this.state.specialities);
      }
    );
  }

  onClicked = (index) =>
  {

    const { filteredSpecialities } = this.state;
    let selSpeciality = filteredSpecialities[index];
    this.props.onSelect(selSpeciality);

    // // crypt.getSpecialityCryptKey(selSpeciality.id);
    // this.setState({selectedIndex: index });
    // this.props.history.push('/clients?id='+selSpeciality.id);
  }

  onSearch = (searchText) => {
    this.setState({searchText:searchText});
  }

  filterSpecialities = (speciality) => {
    // console.log("in speciality: " + speciality);
    const { searchText } = this.state;
    let name = intl.get(speciality.specialityCode).toLowerCase();
    // let lastName = speciality.lastName.toLowerCase();
    let lcSearchText = searchText.toLowerCase();
    let isOk =  name.indexOf(lcSearchText) > -1;
    return isOk;
  }

  render(){

    const { classes} = this.props;
    const { specialities, selectedSpeciality, searchText } = this.state;
    if ( specialities ) 
    {
      this.state.filteredSpecialities = specialities;
      if ( searchText ) {
        this.state.filteredSpecialities = specialities.filter(this.filterSpecialities);
      } 
      
      var rows = this.state.filteredSpecialities.map( ( filteredSpeciality, i ) =>
        <div className={selectedSpeciality && selectedSpeciality.id == filteredSpeciality.id ? 'custListItemSelected' : classes.listItem} key={i} onClick={() => this.onClicked(i)}> 
          <div className="column">
            <div>{intl.get(filteredSpeciality.specialityCode)}</div>
          </div>
        </div>
      )
      
      return (
        
        <div style={{width:500, height:500, border:'1px solid #efefef'}}>
          <div className={classes.searchBox}>
            <SearchField classNames='custSearchField' placeholder={intl.get("SEARCH")} onChange={this.onSearch} />
          </div>
          <div style={{position:'relative', top:40}}>
            {rows}
          </div>
        </div>
        );
    } 
    return <div style={{padding:10}} >{intl.get("LOADING_LIST")}</div>;
  }  

}
  
export default withRouter(withStyles(styles)(MedicalSpecialitiesList));
