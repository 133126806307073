import React, {Component} from 'react';
import { connect } from 'react-redux'
import RestManager from '/persist/RestManager'
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import { Button, Toolbar } from '@material-ui/core';
import PersonnelFormProfessional from '../components/Personnel/PersonnelForm';
import PersonnelListProfessional from '../components/Personnel/PersonnelList';
import PersonnelFormAssistant from '../components/Personnel/PersonnelForm';
import PersonnelListAssistant from '../components/Personnel/PersonnelList';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import utils from '/helpers/utils'
import ConfirmDialog from '../components/Dialogs/ConfirmDialog';
import intl from 'react-intl-universal';
import MessageBox from '../components/Dialogs/MessageBox';
import crypt from '../helpers/crypt';
import { setSelectedProfessional } from '../redux/app'

@connect(({ app }) => ({
  selectedProfessional: app.selectedProfessional,
}), {
  setSelectedProfessional,
})

class PersonnelPage extends Component 
{
    constructor (props) {
        super(props);
        this.loadUser((currentUser)=>{
          this.state.currentUser = currentUser;
          this.loadLicence();
          this.loadProfessionalsList();
          this.loadAssistantsList();
        });
    }    

    state = {
      currentUser:null,
      licence:null,
      professionals:null,
      assistants:null,
      selectedProfessional:null,
      selectedAssistant:null,
      isProfessionalFormOpen:false,
      isProfessionalEditMode:false,
      isAssistantFormOpen:false,
      isAssistantEditMode:false,
      deleteProfessionalDialogOpen:false,
      deleteAssistantDialogOpen:false,
      alertDialogOpen:false,
      alertTitle:"",
      alertText:"",
      };

    loadUser = (callback) =>
    {
        RestManager.instance.getCurrentUser( (currentUser) =>
        {
          if (  currentUser && typeof callback==="function" ) {
            callback(currentUser);
            // this.setState({currentUser: currentUser});
          }
        });
    }
  
    loadLicence = () => 
    {
      RestManager.instance.getLicence((result) => {
        console.log("licence : ", result);
        if ( result && result.success && result.response ) {
          this.setState({licence: result.response});
        }
      })
    }

    loadProfessionalsList = () => {
      RestManager.instance.getProfessionals((result) => {
        console.log("loadProfessionalsList users : ", result);
        if ( result && result.success == true && result.response ) {
          this.setState({professionals: result.response});
        }
      })
    }
  
    loadAssistantsList = () => {
      RestManager.instance.getAssistants((result) => {
        console.log("loadAssistantsList users : ", result);
        if ( result && result.success == true && result.response ) {
          this.setState({assistants: result.response});
        }
      })
    }
  

    onPersonnelSave = (personnel, isProfessional) => 
    {
      let user = null;
      // let updateCustomers = false;
      if ( isProfessional && this.state.professionals && this.state.professionals.length == 0 ){
        // this is the first user, assign to professional
        user = this.state.currentUser;
        personnel.firstname = user.firstname;
        personnel.lastname = user.lastname;
        personnel.email = user.username;
        personnel.phone = user.phone;

        this.addProfessional(personnel, user);
      } 
      else {
        user = {};
        user.firstname = personnel.firstname;
        user.lastname = personnel.lastname;
        user.username = personnel.email;
        user.phone = personnel.phone;
        // user.customerDocumentsVisible = true;
        // user.reportsVisible = true;
        // user.chartsVisible = true;  
        if ( personnel.cryptKey == 'companyCryptKey' ) {
          let cryptKeyCheck = crypt.getCryptKeyCheck();
          user.cryptKeyCheck = cryptKeyCheck;
          user.originalCryptKey = true;
        }
        if ( isProfessional ) {
          user.type = "professional"
        } else {
          user.type = "assistant"
        }
        this.createUser(user, (result)=>
        {
          if ( result.success == true ) 
          {
            let newUser = result.response;
            if ( newUser.originalCryptKey == false ){
              let cryptKey = crypt.getUserCryptKey(newUser.id);
              let cryptKeyCheck = crypt.getCryptKeyCheck(cryptKey);
              newUser.cryptKeyCheck = cryptKeyCheck;
              RestManager.getInstance().updateUser(newUser, ()=>{});
            }
            if ( isProfessional ) {
              this.addProfessional(personnel, newUser);
            } else {
              this.addAssistant(personnel, newUser);
            }

          } else {
            this.onProfessionalFormClose();
            this.onAssistantFormClose();
          }
        })
  
      }
    }


    onProfessionalSave = (professional) => 
    {
      this.onPersonnelSave(professional, true);
      return;
    }

    addProfessional= (professional, user)=>
    {
      RestManager.getInstance().addProfessional( 
        professional,
        user,
        (result) => {
          if ( result.success ) {
            if ( ! this.props.selectedProfessional ) {
              this.props.setSelectedProfessional(professional);
              window.location.reload();
            }
            this.onProfessionalFormClose();
          }
        }
      )  
    }
  

    addAssistant= (assistant, user)=>
    {
      RestManager.getInstance().addAssistant( 
        assistant,
        user,
        (result) => {
          if ( result.success ) {
            this.onAssistantFormClose();
          }
        }
      )  
    }

    onAssistantSave = (assistant) => 
    {
      this.onPersonnelSave(assistant, false);
      return;
    }

    createUser = (newUser, callback) => 
    {
        RestManager.instance.addUserToCompany(newUser, (result) => {
          if ( result.success == false ) {
            if ( result.status == 5011 ) {
              this.setState({alertDialogOpen:true, alertText:'Bu mail adresine / cep telefonu numarasına sahip bir kullanıcı var.', alertTitle:'Kullanıcı Oluşturulamadı' });
            } else if ( result.status == 5053 ) {
              this.setState({alertDialogOpen:true, alertText:'Lisansınız yeterli değil. \nYeni kullanıcı için lütfen lisans satın alınız.' , alertTitle:'Kullanıcı Oluşturulamadı' });
            } else {
              this.setState({alertDialogOpen:true, alertText:'Kullanıcı oluşturulurken bir hata oluştu. \nLütfen Praxis Destek ekibi ile iletişime geçin.', alertTitle:'Kullanıcı Oluşturulamadı' });
            }
            callback(result);
          } else {            
            callback(result);
          }
        });  
    }
  
    onProfessionalUpdate = (professional) => 
    {
      let user = this.state.selectedProfessional.user;
      user.firstname = professional.firstname;
      user.lastname = professional.lastname;
      user.username = professional.email;
      user.phone = professional.phone;
      RestManager.instance.updateUser(user, (result) =>
      {
        if ( result.success ) 
        {
          RestManager.getInstance().updateProfessional( 
            professional, 
            (result) => {
              if ( result.success ) {
                this.setState({selectedProfessional:null});
                this.loadProfessionalsList();
                this.onProfessionalFormClose();
              }
            }
          )
        }
      });
    }
    
    onAssistantUpdate = (assistant) => 
    {
      let user = this.state.selectedAssistant.user;
      console.log("USER = ", user);
      user.firstname = assistant.firstname;
      user.lastname = assistant.lastname;
      user.username = assistant.email;
      user.phone = assistant.phone;
      // user.customerDocumentsVisible = assistant.customerDocumentsVisible;
      // user.reportsVisible = assistant.reportsVisible;
      // user.chartsVisible = assistant.chartsVisible;

      RestManager.instance.updateUser(user, (result) =>
      {
        if ( result.success ) 
        {
            RestManager.getInstance().updateAssistant( 
              assistant, 
              (result) => {
                if ( result.success ) {
                  this.setState({selectedAssistant:null});
                  this.loadAssistantsList();
                  this.onAssistantFormClose();
                }
              }
            )

        }
      });
    }
    
    onNewProfessional = () => {
      this.setState({isProfessionalFormOpen:true});
      this.setState({isProfessionalEditMode:false});
      this.setState({selectedProfessional:null});
    }

    onEditProfessional = () => {
      this.setState({isProfessionalFormOpen:true});
      this.setState({isProfessionalEditMode:true});
    }

    onDeleteProfessional = () => {
      RestManager.getInstance().deleteProfessional( 
        this.state.selectedProfessional.id, 
        (result) => {
          if ( result.success ) {
            this.setState({selectedProfessional:null});
            this.onProfessionalFormClose();
            this.toggleDeleteProfessionalDialog();
            window.location.reload();
          }
        }
      )
    }

    onNewAssistant = () => {
      this.setState({isAssistantFormOpen:true});
      this.setState({isAssistantEditMode:false});
      this.setState({selectedAssistant:null});
    }

    onEditAssistant = () => {
      this.setState({isAssistantFormOpen:true});
      this.setState({isAssistantEditMode:true});
    }

    onDeleteAssistant = () => {
      RestManager.getInstance().deleteAssistant( 
        this.state.selectedAssistant.id, 
        (result) => {
          if ( result.success ) {
            this.setState({selectedAssistant:null});
            this.onAssistantFormClose();
            this.toggleDeleteAssistantDialog();
          }
        }
      )
    }

    onProfessionalFormClose = () => {
      this.loadProfessionalsList();
      this.setState({isProfessionalFormOpen:false});
      this.setState({isProfessionalEditMode:false});
    }

    onAssistantFormClose = () => {
      this.loadAssistantsList();
      this.setState({isAssistantFormOpen:false});
      this.setState({isAssistantEditMode:false});
    }

    onProfessionalSelected = (p) =>{
      p.birthdate = utils.formatDate(p.birthdate);
      this.setState({selectedProfessional:p});

      this.setState({isProfessionalEditMode:false});
      this.setState({isProfessionalFormOpen:false});
      this.setState({isAssistantEditMode:false});
      this.setState({isAssistantFormOpen:false});
    }
    
    onAssistantSelected = (p) =>{
      p.birthdate = utils.formatDate(p.birthdate);
      this.setState({selectedAssistant:p});

      this.setState({isProfessionalEditMode:false});
      this.setState({isProfessionalFormOpen:false});
      this.setState({isAssistantEditMode:false});
      this.setState({isAssistantFormOpen:false});
    }
    
    toggleDeleteAssistantDialog = () => {
      if ( this.state.selectedAssistant || this.state.deleteAssistantDialogOpen ) {
        this.setState({ deleteAssistantDialogOpen: !this.state.deleteAssistantDialogOpen });
      } 
    }

    toggleDeleteProfessionalDialog = () => {
      if ( this.state.selectedProfessional || this.state.deleteProfessionalDialogOpen ) {
        this.setState({ deleteProfessionalDialogOpen: !this.state.deleteProfessionalDialogOpen });
      } 
    }

    handleDialogClose = () =>  {
      this.setState({ alertDialogOpen: false });
    }
  
    render() 
    {
      const { classes} = this.props;
      // const {}
      var isFirstProfessional = false;
      if ( this.state.professionals && this.state.professionals.length == 0){
        isFirstProfessional = true;
      }
      var isAdmin = false;
      if ( this.state.currentUser && this.state.currentUser.isAdmin ) {
        isAdmin = true;
      }

      return (

          <div >
            

            {/* { isFirstProfessional && ! this.state.isProfessionalFormOpen && ! this.state.isAssistantFormOpen ?
              <div style={{textAlign:'center', backgroundColor:'aliceblue', margin:20, border:'1px solid lightgray', width:650, height:200}}>

                <h2>  Lütfen kullanıcınız için bir Doktor ya da Asistan girişi yapınız. </h2>
                <Button variant="outlined" onClick={this.onNewProfessional}  color="primary" 
                  style={{margin:10, backgroundColor:'white'}}>Doktorum ve adıma kayıt açmak istiyorum</Button>
                <Button variant="outlined" onClick={this.onNewAssistant}   color="primary" 
                  style={{margin:10, backgroundColor:'white'}}>Asistanım ve adıma kayıt açmak istiyorum</Button>


              </div>
            : ""}  */}

            <Toolbar style={{backgroundColor:'whitesmoke'}}>
              <h1 style={{marginRight:30}}> {intl.get("PROFESSIONALS")} </h1>
              <Button variant="outlined" onClick={this.onNewProfessional} disabled={!isAdmin || this.state.isProfessionalEditMode  }  color="primary" 
                style={{marginRight:10, backgroundColor:'white'}}><AddIcon /> {intl.get("ADD_PROFESSIONAL")} </Button>
              <Button  
                onClick={this.onEditProfessional} 
                disabled={!isAdmin ||  !this.state.selectedProfessional || ( this.state.isProfessionalFormOpen && ! this.state.isProfessionalEditMode )} 
                variant="outlined" color="primary" style={{marginRight:10, backgroundColor:'white'}} ><EditIcon />{intl.get("EDIT")}</Button>
              <Button 
                onClick={this.toggleDeleteProfessionalDialog}
                disabled={!isAdmin ||  !this.state.selectedProfessional} 
                variant="outlined" color="primary"style={{backgroundColor:'white'}} ><DeleteIcon />{intl.get("DELETE")}</Button>
            </Toolbar>
            {this.state.isProfessionalFormOpen == true ? 
              <PersonnelFormProfessional title={intl.get("NEW_PROFESSIONAL_DATA")} 
                onClose={this.onProfessionalFormClose} 
                isProfessional={true}
                selectedPersonnel={this.state.selectedProfessional} 
                isEditMode={this.state.isProfessionalEditMode} 
                onSave={this.onProfessionalSave}
                onUpdate={this.onProfessionalUpdate}
                isFirstProfessional={isFirstProfessional}
                currentUser={this.state.currentUser}
              />
            :''}
            <PersonnelListProfessional
              personnels={this.state.professionals} 
              onClicked={this.onProfessionalSelected}
              isProfessional={true}
              isKeyVisible={isAdmin}
            />

            <div style={{marginTop:60, borderTop:'6px solid lightgray'}}>
              <Toolbar style={{backgroundColor:'whitesmoke'}}>
                <h1 style={{marginRight:30}}> {intl.get("ASSISTANTS")} </h1>
                <Button variant="outlined" onClick={this.onNewAssistant} disabled={!isAdmin || this.state.isAssistantEditMode}  color="primary" 
                  style={{marginRight:10, backgroundColor:'white'}}><AddIcon />{intl.get("ADD_ASSISTANT")} </Button>
                <Button 
                  onClick={this.onEditAssistant}
                  disabled={!isAdmin || !this.state.selectedAssistant || ( this.state.isAssistantFormOpen && ! this.state.isAssistantEditMode )} 
                  variant="outlined" color="primary"style={{marginRight:10, backgroundColor:'white'}} ><EditIcon />{intl.get("EDIT")}</Button>
                <Button 
                  onClick={this.toggleDeleteAssistantDialog}
                  disabled={!isAdmin || !this.state.selectedAssistant} 
                  variant="outlined" color="primary"style={{backgroundColor:'white'}} ><DeleteIcon />{intl.get("DELETE")}</Button>

              </Toolbar>
              {this.state.isAssistantFormOpen == true ? 
                <PersonnelFormAssistant title={intl.get("NEW_ASSISTANT_DATA")} 
                  onClose={this.onAssistantFormClose} 
                  selectedPersonnel={this.state.selectedAssistant} 
                  isEditMode={this.state.isAssistantEditMode} 
                  onSave={this.onAssistantSave}
                  onUpdate={this.onAssistantUpdate}
                  professionals={this.state.professionals}
                  />
              :''}
              <PersonnelListAssistant
                personnels={this.state.assistants} 
                onClicked={this.onAssistantSelected}
                isKeyVisible={isAdmin}
              />
            </div>



            <ConfirmDialog
              dialogOpen={this.state.deleteProfessionalDialogOpen}
              onClose={this.toggleDeleteProfessionalDialog} 
              dialogTitle={intl.get("DELETE_PROFESSIONAL")}
              dialogText={intl.get("WANT_TO_DELETE_PROFESSIONAL")}
              onCancel={this.toggleDeleteProfessionalDialog} 
              cancelLabel={intl.get('CANCEL')}
              onConfirm={this.onDeleteProfessional} 
              confirmLabel={intl.get('DELETE')}
            ></ConfirmDialog>

            <ConfirmDialog
              dialogOpen={this.state.deleteAssistantDialogOpen}
              onClose={this.toggleDeleteAssistantDialog} 
              dialogTitle={intl.get("DELETE_ASSISTANT")}
              dialogText={intl.get("WANT_TO_DELETE_ASSISTANT")}
              onCancel={this.toggleDeleteAssistantDialog} 
              cancelLabel={intl.get('CANCEL')}
              onConfirm={this.onDeleteAssistant} 
              confirmLabel={intl.get('DELETE')}
            ></ConfirmDialog>

            <MessageBox
              alertDialogOpen={this.state.alertDialogOpen}
              onClose={this.handleDialogClose} 
              alertTitle={this.state.alertTitle} 
              alertText={this.state.alertText} >
            </MessageBox>


          </div>
        )
    }
  }
  
export default withRouter(PersonnelPage);
