import DBManager from '../persist/DBManager';
import sha256 from 'sha256';
import utils from './utils';

const crypt = 
{
    generateMainCryptKey(cryptKey)
    { // companies main key which is set by admin
        let hashed = new Buffer.from(cryptKey).toString('base64');
        // var finalCryptKey = "@!p" + hashed + "#x!";
        return hashed;
    },
    // cleanUpCryptKey(cryptKey)
    // { // this is just for main company key
    //     if ( cryptKey.startsWith("@!p") ) {
    //         cryptKey = cryptKey.replace("@!p", "").replace("#x!", "");
    //         return cryptKey;
    //     }
    // },
    getCryptKeyCheck(cryptKey = DBManager.getInstance().getCryptKey()){
        // var cryptKey = this.getUserCryptKey(userId);
        const shaKey = sha256(cryptKey);
        return shaKey;
    },
    getCompanyCryptKey(){
        let companyCryptKey = utils.b64_to_utf8(DBManager.getInstance().getCryptKey());
        return companyCryptKey;
    },
    getCustomerMainCryptKey()
    {
        var cryptKey = DBManager.getInstance().getCryptKey();
        return cryptKey.substring(0, 5) + cryptKey.substring(0, 5) ;        
    },
    getCustomerCryptKey(customerId)
    {
        var cryptKey = DBManager.getInstance().getCryptKey();
        return cryptKey.substring(0, 5) + customerId.substring(5, 10) ;        
    },
    getUserCryptKey(userId)
    {
        var cryptKey = DBManager.getInstance().getCryptKey();
        return cryptKey.substring(0, 5) + userId.substring(5, 10) ;
    },
    encryptText(cryptKey, text) {
        // var cryptKey = this.getCustomerCryptKey(customerId);
        var AES = require('crypto-js/AES');
        var ciphertext = AES.encrypt(text, cryptKey).toString();
        console.log("ciphertext : " + ciphertext);
        return ciphertext;
    },
    encryptFile(customerId, file, callback) 
    {
        // var file = input.files[0];
        var reader = new FileReader();
        var cryptKey = this.getCustomerCryptKey(customerId);
        reader.onload = () => {
            // var key = "1234567887654321";
            var CryptoJS = require('crypto-js');
            var wordArray = CryptoJS.lib.WordArray.create(reader.result);           // Convert: ArrayBuffer -> WordArray
            var encrypted = CryptoJS.AES.encrypt(wordArray, cryptKey).toString();        // Encryption: I: WordArray -> O: -> Base64 encoded string (OpenSSL-format)
    
            var fileEnc = new Blob([encrypted]);                                    // Create blob from string
            callback(fileEnc);
            // var a = document.createElement("a");
            // var url = window.URL.createObjectURL(fileEnc);
            // var filename = file.name + ".enc";
            // a.href = url;
            // a.download = filename;
            // a.click();
            // window.URL.revokeObjectURL(url);
        };
        reader.readAsArrayBuffer(file);
    },
    decryptText(cryptKey, ciphertext) {
        var AES = require('crypto-js/AES');
        var enc = require('crypto-js/enc-Utf8');
        var bytes  = AES.decrypt(ciphertext, cryptKey);
        var originalText = "";
        try {
            originalText = bytes.toString(enc);
        } catch (error) {
            console.error("Wrong key for decrption.");
        }
        console.log("originalText : " + originalText); 
        return originalText;
    },
    decryptFile(customerId, value, callback) 
    {
        var cryptKey = this.getCustomerCryptKey(customerId);
        var AES = require('crypto-js/AES');
        var decrypted = AES.decrypt(value, cryptKey);               // Decryption: I: Base64 encoded string (OpenSSL-format) -> O: WordArray
        var typedArray = this.convertWordArrayToUint8Array(decrypted);               // Convert: WordArray -> typed array

        var fileDec = new Blob([typedArray]);                                   // Create blob from typed array
        callback(fileDec);
        // var a = document.createElement("a");
        // var url = window.URL.createObjectURL(fileDec);
        // // var filename = file.name.substr(0, file.name.length - 4) + ".dec";
        // a.href = url;
        // a.download = filename;
        // a.click();
        // window.URL.revokeObjectURL(url);

    },
    convertWordArrayToUint8Array(wordArray) {
        var arrayOfWords = wordArray.hasOwnProperty("words") ? wordArray.words : [];
        var length = wordArray.hasOwnProperty("sigBytes") ? wordArray.sigBytes : arrayOfWords.length * 4;
        var uInt8Array = new Uint8Array(length), index=0, word, i;
        for (i=0; i<length; i++) {
            word = arrayOfWords[i];
            uInt8Array[index++] = word >> 24;
            uInt8Array[index++] = (word >> 16) & 0xff;
            uInt8Array[index++] = (word >> 8) & 0xff;
            uInt8Array[index++] = word & 0xff;
        }
        return uInt8Array;
    },
    encryptCustomer(customer) {
        var cryptKey = this.getCustomerMainCryptKey();
        // customer.firstNameCr = this.encryptText(cryptKey, customer.firstName);
        // customer.firstName = "";
        // customer.lastNameCr = this.encryptText(cryptKey, customer.lastName);
        // customer.lastName = "";
        customer.genderCr = this.encryptText(cryptKey, customer.gender+"");
        customer.gender = 0;
        // customer.phoneCr = this.encryptText(cryptKey, customer.phone);
        // customer.phone = null;
        customer.emailCr = this.encryptText(cryptKey, customer.email);
        customer.email = "";
        customer.birthdateCr = this.encryptText(cryptKey, customer.birthdate.toString());
        customer.birthdate = null;
        return customer;
    },
    encryptPayment(payment, customerId) {
        var cryptKey = this.getCustomerCryptKey(customerId);
        payment.priceCr = this.encryptText(cryptKey, payment.price);
        payment.price = 0;
        payment.paidCashCr = this.encryptText(cryptKey, payment.paidCash);
        payment.paidCash = 0;
        payment.paidCreditCr = this.encryptText(cryptKey, payment.paidCredit);
        payment.paidCredit = 0;
        payment.paidTransferCr = this.encryptText(cryptKey, payment.paidTransfer);
        payment.paidTransfer = 0;
        payment.restAmountCr = this.encryptText(cryptKey, payment.restAmount);
        payment.restAmount = 0;
        return payment;
    },
    encryptDocument(document, customerId) {
        var cryptKey = this.getCustomerCryptKey(customerId);
        document.noteCr = this.encryptText(cryptKey, document.note);
        document.note = "";
        document.titleCr = this.encryptText(cryptKey, document.title);
        document.title = "";
        return document;
    },
    encryptFormdata(formdata, customerId) {
        var cryptKey = this.getCustomerCryptKey(customerId);
        // for all data in a column //
        var jsonData = JSON.stringify(formdata.jsonData);
        formdata.jsonDataCr = this.encryptText(cryptKey, jsonData);
        formdata.jsonData = "";
        return formdata;

        // for single db columns //
        // var formDataCr = {};
        // for (const prop in formdata) {
        //     var firstChar = prop.substring(0,1);
        //     var rest = +prop.substring(1, prop.length);
        //     if ( ( firstChar.toUpperCase() == "S" || firstChar.toUpperCase() == "T" || firstChar.toUpperCase() == "M")
        //         && typeof rest == "number" )
        //     {
        //         var text = formdata[prop];
        //         if ( typeof text != "string" ){
        //             text = JSON.stringify(formdata[prop]);
        //         }
        //         formDataCr[prop+"Cr"] = this.encryptText(cryptKey, text);
        //     } else {
        //         formDataCr[prop] = formdata[prop];
        //     }
        // }
        // return formDataCr;
    },

    // decryptPayment(payment) {
    //     var cryptKey = this.getCustomerCryptKey(payment.customerId);
    //     payment.price = +this.decryptText(cryptKey, payment.priceCr);
    //     payment.paidCash = +this.decryptText(cryptKey, payment.paidCashCr);
    //     payment.paidCredit = +this.decryptText(cryptKey, payment.paidCreditCr);
    //     payment.paidTransfer = +this.decryptText(cryptKey, payment.paidTransferCr);
    //     payment.restAmount = +this.decryptText(cryptKey, payment.restAmountCr);
    //     return payment;
    // },
    // decryptExamPayments(exams) 
    // {
    //     var Constants = require('/helpers/Constants');
    //     // var columns = ["price", "paidCash", "paidCredit", "paidTransfer", "restAmount"]
    //     for ( var i = 0; i < exams.length; i++ ) 
    //     {
    //         Constants.PAYMENT_COLUMNS.forEach((col)=>{
    //             var colCr = col + Constants.CRYPT_SUFFIX ; 
    //             if ( exams[i].payment && exams[i].payment[colCr] ) {
    //                 var orgText = this.decrypt(exams[i].payment[colCr]);
    //                 this.isNumeric(orgText) ? exams[i].payment[col] = orgText : 0;
    //             }
    //         });
    //     }
    //     return exams;
    // },
    decryptEntity(tablename, entity, customerId)
    {
        if ( tablename == 'customer' ) {
            var cryptKey = this.getCustomerMainCryptKey();
            // if ( entity.firstNameCr ) entity.firstName = this.decryptText(cryptKey, entity.firstNameCr);
            // if ( entity.lastNameCr ) entity.lastName = this.decryptText(cryptKey, entity.lastNameCr);
            if ( entity.genderCr ) entity.gender = +this.decryptText(cryptKey, entity.genderCr);
            if ( entity.birthdateCr ) entity.birthdate = this.decryptText(cryptKey, entity.birthdateCr);
            // if ( entity.phoneCr ) entity.phone = this.decryptText(cryptKey, entity.phoneCr);
            if ( entity.emailCr ) entity.email = this.decryptText(cryptKey, entity.emailCr);
        } 
        else if ( tablename == 'payment' ) {
            var cryptKey = this.getCustomerCryptKey(customerId);
            if ( entity.priceCr ) entity.price = this.decryptText(cryptKey, entity.priceCr);
            if ( entity.paidCashCr ) entity.paidCash = this.decryptText(cryptKey, entity.paidCashCr);
            if ( entity.paidCreditCr ) entity.paidCredit = this.decryptText(cryptKey, entity.paidCreditCr);
            if ( entity.paidTransferCr ) entity.paidTransfer = this.decryptText(cryptKey, entity.paidTransferCr);
            if ( entity.restAmountCr ) entity.restAmount = this.decryptText(cryptKey, entity.restAmountCr);
        }
        else if ( tablename == 'document' ) {
            var cryptKey = this.getCustomerCryptKey(customerId);
            if ( entity.noteCr ) entity.note = this.decryptText(cryptKey, entity.noteCr);
            if ( entity.titleCr ) entity.title = this.decryptText(cryptKey, entity.titleCr);
        }
        else if ( tablename == 'formdata' ) {
            var cryptKey = this.getCustomerCryptKey(customerId);
            if ( entity.jsonDataCr ) entity.jsonData = this.decryptText(cryptKey, entity.jsonDataCr);

            // var formData = {};
            // for (const prop in entity) {
            //     var cr = prop.substring(prop.length-2,prop.length);
            //     var newProp = prop.substring(0, prop.length-2);
            //     if ( cr == "Cr" )
            //     {
            //         formData[newProp] = this.decryptText(cryptKey, entity[prop]);
            //     } else {
            //         formData[prop] = entity[prop];
            //     }
            // }
            // entity = formData;
        }
        return entity;    
    },
    decryptEntities(tablename, arrEntity, customerId)
    {
        if ( arrEntity ) {
            for (let i = 0; i < arrEntity.length; i++) {
                const element = arrEntity[i];
                element = this.decryptEntity(tablename, element, customerId);
                arrEntity[i] = element;
            }
        }
        return arrEntity;
    },

      
}

export default crypt;